import React from 'react';
import _ from 'lodash';
import cn from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import './SubscriptionOffers.scss';

import { addSubscriptionOffer, updateSubscriptionOffer, deleteSubscriptionOffer } from '../../api/subscriptions';
import { copyToClipboard } from '../../helpers/helpers';

const SubscriptionOffers = props => {
  const { offers, syncOffers } = props;
  const sortedOffers = _.orderBy(offers, ['isRequired', 'module', 'isBase', 'price'], ['desc', 'asc', 'desc', 'asc']);

  const moduleOptions = ['core', 'gifting', 'sociallistening', 'analytics', 'campaigns', 'communications', 'events', 'opportunities'];

  const updateOffer = (offer, updates) => {
    updateSubscriptionOffer(offer, updates).then(syncOffers);
  };

  const deleteOffer = async offer => {
    const confirm = await window.confirm(`Are you sure you want to delete ${offer.title || 'this offer'}`);
    confirm && deleteSubscriptionOffer(offer).then(syncOffers);
  };

  const promptToUpdateField = async (offer, field, message) => {
    const newField = await window.prompt(`Update ${field}${message ? ` (${message}):` : ':'}`, offer[field] || '');
    if (!_.isNil(newField)) {
      const isNumber = !_.isNaN(+newField);
      updateOffer(offer, { [field]: !newField ? null : isNumber ? _.toNumber(newField) : newField });
    }
  };

  const addNew = async () => {
    addSubscriptionOffer({
      title: 'New Offer'
    }).then(syncOffers);
  };

  return (
    <div className='subscription-offers-container'>
      <div className='row header'>
        <div className='cell xs'>ID</div>
        <div className='cell md'>Title</div>
        <div className='cell sm'>Description</div>
        <div className='cell sm'>Features</div>
        <div className='cell sm'>Used By</div>
        <div className='cell sm'>Retail Price</div>
        <div className='cell sm'>Cover Image</div>
        <div className='cell sm'>Tile Image</div>
        <div className='cell sm'>Video</div>
        <div className='cell sm'>Module</div>
        <div className='cell sm'>Stripe ID</div>
        <div className='cell sm'>Stripe Raw</div>
        <div className='cell sm'>Deprecated</div>
        <div className='cell sm'>Required</div>
        <div className='cell sm'>Base or Addon</div>
        <div className='cell sm'>Requires Base</div>
        <div className='cell sm'>Is Service</div>
        <div className='cell sm'>Is Hidden</div>
        <div className='cell sm'>Offers Trial</div>
        <div className='cell sm'>Trial Duration</div>
        <div className='cell sm'>Min Months</div>
        <div className='cell sm'>Unlocks</div>
        <div className='cell sm'>Unlocks (dep)</div>
        <div className='cell sm'>Variation Of</div>
        <div className='cell sm'>Delete</div>
      </div>
      {sortedOffers.map((offer, idx) => {
        const {
          id,
          title,
          description,
          features,
          usedBy,
          retailPrice,
          coverImage,
          tileImage,
          videoUrl,
          stripeProductId,
          stripeRaw,
          module,
          isDeprecated,
          isRequired,
          isBase,
          isAddOn,
          isHidden,
          addOnRequiresBase,
          isService,
          offersTrial,
          trialDurationDays,
          minDurationMonths,
          canAccessEnum,
          canAccessDeprecatedEnum
        } = offer;
        const variationOf = _.find(offers, { id: offer.VariationOfSubscriptionOffer_id });
        return (
          <div key={id} className={cn('row', { odd: idx % 2, base: isBase, disabled: isDeprecated })}>
            <div className='cell xs'>{id}</div>
            <div onClick={() => promptToUpdateField(offer, 'title')} className={cn('cell md', { error: !title })}>
              {title || '-'}
            </div>
            <div onClick={() => promptToUpdateField(offer, 'description')} className={cn('cell sm', { error: !description && isBase })}>
              {description || '-'}
            </div>
            <div onClick={() => promptToUpdateField(offer, 'features')} className={cn('cell sm', { error: !features && isBase })}>
              {features || '-'}
            </div>
            <div
              onClick={() => promptToUpdateField(offer, 'usedBy', 'Format: Nike,https://image.com/nike.png,Chanel,https://image.com/nike.png')}
              className={cn('cell sm', { error: !usedBy && isBase })}
            >
              {usedBy || '-'}
            </div>
            <div onClick={() => promptToUpdateField(offer, 'retailPrice')} className={cn('cell sm', { error: !retailPrice })}>
              {retailPrice || '-'}
            </div>
            <div onClick={() => promptToUpdateField(offer, 'coverImage')} className={cn('cell sm', { error: !coverImage })}>
              {coverImage ? 'Cover Image' : 'Set Cover Image'}
            </div>
            <div onClick={() => promptToUpdateField(offer, 'tileImage')} className={cn('cell sm', { error: !tileImage })}>
              {tileImage ? 'Tile Image' : 'Set Tile Image'}
            </div>
            <div onClick={() => promptToUpdateField(offer, 'videoUrl')} className={cn('cell sm', { error: !videoUrl && isBase })}>
              {videoUrl ? 'Edit Video' : 'Set Video'}
            </div>
            <div onClick={() => promptToUpdateField(offer, 'module', moduleOptions.join(', '))} className={cn('cell sm', { error: !module })}>
              {module || 'SET MODULE'}
            </div>
            <div onClick={() => promptToUpdateField(offer, 'stripeProductId')} className={cn('cell sm', { error: !stripeProductId })}>
              {stripeProductId || 'SET STRIPE ID'}
            </div>
            <div
              onClick={() =>
                !stripeProductId ? null : !stripeRaw ? alert('Waiting on nightly sync') : copyToClipboard(stripeRaw, 'Copied Raw Stripe Object')
              }
              className={cn('cell clickable sm', { error: stripeProductId && !stripeRaw })}
            >
              {stripeProductId ? (stripeRaw ? 'COPY RAW' : 'AWAITING STRIPE SYNC') : '-'}
            </div>

            <div onClick={() => updateOffer(offer, { isDeprecated: !isDeprecated })} className='cell clickable sm'>
              {isDeprecated ? 'DEPRECATED' : 'ACTIVE'}
            </div>
            <div onClick={() => updateOffer(offer, { isRequired: !isRequired })} className='cell clickable sm'>
              {isRequired ? 'REQUIRED' : 'OPTIONAL'}
            </div>
            <div onClick={() => updateOffer(offer, { isAddOn: !isAddOn, isBase: isAddOn })} className='cell clickable sm'>
              {isAddOn ? 'IS ADD ON' : 'IS BASE MODULE'}
            </div>
            <div onClick={() => updateOffer(offer, { addOnRequiresBase: !addOnRequiresBase })} className='cell clickable sm'>
              {isAddOn ? (addOnRequiresBase ? 'REQUIRES BASE' : 'STANDALONE') : '-'}
            </div>
            <div onClick={() => updateOffer(offer, { isService: !isService })} className='cell clickable sm'>
              {isService ? 'SERVICE' : 'NOT SERVICE'}
            </div>
            <div onClick={() => updateOffer(offer, { isHidden: !isHidden })} className='cell clickable sm'>
              {isHidden ? 'HIDDEN' : '-'}
            </div>
            <div
              onClick={() => updateOffer(offer, { offersTrial: !offersTrial, trialDurationDays: offersTrial ? null : trialDurationDays })}
              className='cell clickable sm'
            >
              {offersTrial ? 'OFFERS TRIAL' : 'NO TRIAL'}
            </div>
            <div
              onClick={() => promptToUpdateField(offer, 'trialDurationDays')}
              className={cn('cell sm', { error: offersTrial && !trialDurationDays })}
            >
              {offersTrial ? (trialDurationDays ? `${trialDurationDays} days` : 'Unknown Duration') : '-'}
            </div>
            <div onClick={() => promptToUpdateField(offer, 'minDurationMonths')} className={cn('cell sm', { error: !minDurationMonths })}>
              {`${minDurationMonths} month${minDurationMonths === 1 ? '' : 's'} min` || '-'}
            </div>
            <div
              onClick={() => promptToUpdateField(offer, 'canAccessEnum', 'Comma separated list, no spaces, ex: GIFTING,DISCOVER_2.0')}
              className={cn('cell sm', { error: !canAccessEnum && !isDeprecated })}
            >
              {canAccessEnum ? canAccessEnum : isDeprecated ? '-' : 'NO UNLOCKS'}
            </div>
            <div
              onClick={() =>
                promptToUpdateField(
                  offer,
                  'canAccessDeprecatedEnum',
                  'Comma separated list, no spaces, ex: GIFTING,UNLIMITED_GIFTING,SOCIAL_MENTIONS'
                )
              }
              className={cn('cell sm', { error: !canAccessDeprecatedEnum && isDeprecated })}
            >
              {canAccessDeprecatedEnum ? canAccessDeprecatedEnum : !isDeprecated ? '-' : 'NO UNLOCKS'}
            </div>
            <div
              onClick={() =>
                promptToUpdateField(offer, 'VariationOfSubscriptionOffer_id', 'Set the ID of the offer you want this to be a variation of.')
              }
              className={cn('cell sm')}
            >
              {variationOf ? variationOf.title : '-'}
            </div>
            <div onClick={() => deleteOffer(offer)} className='cell clickable sm'>
              <FontAwesomeIcon icon={faTimes} />
            </div>
          </div>
        );
      })}
      <div className='add-new-template'>
        <div onClick={addNew} className='btn'>
          + Add New Offer
        </div>
      </div>
    </div>
  );
};

export default SubscriptionOffers;
