import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import cn from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import './SubscriptionBundles.scss';

import {
  // Bundles
  addSubscriptionBundle,
  updateSubscriptionBundle,
  deleteSubscriptionBundle,

  // Bundle Items
  addSubscriptionBundleItem,
  deleteSubscriptionBundleItem
} from '../../api/subscriptions';

const SubscriptionBundles = props => {
  const { syncBundles, bundles } = props;
  const sortedBundles = _.orderBy(bundles, ['title'], ['asc']);

  const addItem = bundle => {
    const id = window.prompt('Enter the ID of the offer you want to add to this bundle:');
    if (id) {
      addSubscriptionBundleItem({ SubscriptionBundle_id: bundle.id, SubscriptionOffer_id: parseInt(id) }).then(resp => {
        syncBundles(); // Easier way of handling relations
      });
    }
  };

  const updateBundle = (bundle, updates) => {
    updateSubscriptionBundle(bundle, updates).then(syncBundles);
  };

  const deleteBundle = async bundle => {
    const confirm = await window.confirm(`Are you sure you want to delete ${bundle.title || 'this bundle'}`);
    confirm && deleteSubscriptionBundle(bundle).then(syncBundles);
  };

  const promptToUpdateField = async (bundle, field, message) => {
    const newField = await window.prompt(`Update ${field}${message ? ` (${message}):` : ':'}`, bundle[field] || '');
    if (!_.isNil(newField)) {
      const isNumber = !_.isNaN(+newField);
      updateBundle(bundle, { [field]: !newField ? null : isNumber ? _.toNumber(newField) : newField });
    }
  };

  const addNew = async () =>
    addSubscriptionBundle({
      title: 'New Bundle'
    }).then(syncBundles);

  return (
    <div className='subscription-bundles-container'>
      <div className='row header'>
        <div className='cell md'>Title</div>
        <div className='cell md'>Description</div>
        <div className='cell sm'>Stripe Coupon ID</div>
        <div className='cell sm'>Total Price</div>
        <div className='cell sm'>Discount Price</div>
        <div className='cell xl'>Offers</div>
        <div className='cell sm'>Delete</div>
      </div>
      {sortedBundles.map((bundle, idx) => {
        const { title, description, discountAmount, totalPrice, stripeCouponId, items } = bundle;
        const priceBasedOnOffers = _.sum(items.map(i => i.offer.retailPrice));
        const priceBasedOnOffersPlusDiscount = priceBasedOnOffers - discountAmount;
        const priceIsValid = totalPrice === priceBasedOnOffersPlusDiscount;
        return (
          <div key={bundle.id} className={cn('row', { odd: idx % 2 })}>
            <div onClick={() => promptToUpdateField(bundle, 'title')} className={cn('cell md', { error: !title })}>
              {title || '-'}
            </div>
            <div onClick={() => promptToUpdateField(bundle, 'description')} className={cn('cell md', { error: false })}>
              {description || '-'}
            </div>
            <div onClick={() => promptToUpdateField(bundle, 'stripeCouponId')} className={cn('cell sm', { error: !stripeCouponId })}>
              {stripeCouponId || '-'}
            </div>
            <div onClick={() => promptToUpdateField(bundle, 'totalPrice')} className={cn('cell sm', { error: _.isNil(totalPrice) || !priceIsValid })}>
              {_.isNil(totalPrice) ? '-' : totalPrice}
              {priceIsValid ? '' : ` ($${priceBasedOnOffersPlusDiscount} currently)`}
            </div>
            <div onClick={() => promptToUpdateField(bundle, 'discountAmount')} className={cn('cell sm', { error: !discountAmount })}>
              {discountAmount || '-'}
            </div>
            <div className='cell xl'>
              <div className='offers'>
                {items?.map(item => {
                  const { id, offer } = item;
                  const { title, retailPrice } = offer;
                  const remove = () => {
                    const confirm = window.confirm(`Are you sure you want to remove ${title} from this bundle?`);
                    confirm && deleteSubscriptionBundleItem(item).then(syncBundles);
                  };
                  return (
                    <div key={id} className='offer'>
                      <div className='title'>
                        {title} (${retailPrice})
                      </div>
                      <div onClick={remove} className='delete'>
                        <FontAwesomeIcon icon={faTimes} />
                      </div>
                    </div>
                  );
                })}
                <div onClick={() => addItem(bundle)} className='add-offer'>
                  + Add Offer
                </div>
              </div>
            </div>
            <div onClick={() => deleteBundle(bundle)} className='cell clickable sm'>
              <FontAwesomeIcon icon={faTimes} />
            </div>
          </div>
        );
      })}
      <div className='add-new-bundle'>
        <div onClick={addNew} className='btn'>
          + Add New Bundle
        </div>
      </div>
    </div>
  );
};

export default SubscriptionBundles;
