import APIClient from './index';
import _ from 'lodash';

export const getAllJobOpenings = () => {
  return new Promise((resolve, reject) => {
    APIClient.get(`JobOpenings`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const syncGreenhouseJobs = () => {
  return new Promise((resolve, reject) => {
    APIClient.post(`Greenhouse/sync`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const updateJobOpening = (JobOpening, updates) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`JobOpenings/${JobOpening.id}`, updates)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};
