import APIClient from './index';
import _ from 'lodash';

export const addPrompt = promptData => {
  return new Promise((resolve, reject) => {
    APIClient.post(`Prompts`, promptData)
      .then(response => resolve(response.data))
      .catch(err => reject(err));
  });
};

export const getRecentPromptsByType = params => {
  return new Promise((resolve, reject) => {
    APIClient.get(`Prompts/type?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(err => reject(err));
  });
};

/** @param {number} promptId */
export const getPromptById = promptId => {
  return new Promise((resolve, reject) => {
    APIClient.get(`Prompts/${promptId}`)
      .then(response => resolve(response.data))
      .catch(err => reject(err));
  });
};

export const updatePrompt = (promptId, promptData) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`Prompts/${promptId}`, promptData)
      .then(response => resolve(response.data))
      .catch(err => reject(err));
  });
};
