import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import './CreateInvoice.scss';

class CreateInvoice extends Component {
  static propTypes = {
    commissions: PropTypes.array.isRequired,
    brands: PropTypes.array.isRequired,
    accounts: PropTypes.array.isRequired,
    users: PropTypes.array.isRequired,
    brandOnboardedUsers: PropTypes.array.isRequired,
    sendInvoice: PropTypes.func.isRequired
  };

  getUserIdFromCommission = commission => {
    if (!commission.cid || !commission.cid.includes('user-')) return null;
    const userId = commission.cid.replace('user-', '').split('-')[0];
    return userId ? parseInt(userId) : null;
  };

  render() {
    const { commissions, brands, users, accounts, sendInvoice } = this.props;
    const brand = _.find(brands, brand => brand.id === commissions[0].Brand_id);
    // const userIdsOnboardedByBrandSet = new Set(
    //   this.props.brandOnboardedUsers.filter(user => user.ReferringBrand_id === commissions[0]?.Brand_id).map(i => i.id)
    // );
    const gmvTotal = _.sum(_.map(commissions, 'order_amount_usd'));
    const payoutTotal = _.sum(_.map(commissions, 'commission_amount_usd'));
    const networkFeeReal = brand.network_rate
      ? _.sum(_.map(commissions, c => (c.commission_amount_usd * brand.network_rate) / 100))
      : brand.network_rate_gmv_cut
      ? _.sum(_.map(commissions, c => (c.order_amount_usd * brand.network_rate_gmv_cut) / 100))
      : 0;
    const networkFee = brand?.waive_network_rate
      ? 0
      : brand.network_rate_gmv_cut
      ? gmvTotal * (brand.network_rate_gmv_cut / 100)
      : (payoutTotal * brand.network_rate) / 100;
    const user = _.find(users, user => user.Brand_id === brand.id);
    const account = _.find(accounts, account => account.User_id === user.id);
    const error = account ? null : 'No associated PayPal account!';
    return (
      <div className='create-invoice-outer-container'>
        <div className='ticket-header'>Invoice for {brand.name}</div>
        <div className='stats-container'>
          <div className='stat'>
            <div className='stat-header'>{commissions.length}</div>
            <div className='stat-label'>COMMISSION{commissions.length === 1 ? '' : 's'}</div>
          </div>
          <div className='stat'>
            <div className='stat-header'>${payoutTotal.toFixed(2)}</div>
            <div className='stat-label'>OWED</div>
          </div>
          {networkFee > 0 && (
            <>
              <div className='stat'>
                <div className='stat-header'>${networkFeeReal.toFixed(2)}</div>
                <div className='stat-label'>NETWORK FEE</div>
              </div>
              {networkFeeReal.toFixed(2) !== networkFee.toFixed(2) && (
                <div className='stat'>
                  <div className='stat-header'>${networkFee.toFixed(2)}</div>
                  <div className='stat-label'>NETWORK FEE (PRE-PAYING-FOR-ONBOARDED)</div>
                </div>
              )}
            </>
          )}
          <div className='stat'>
            <div className='stat-header'>{user.name}</div>
            <div className='stat-label'>CHARGED TO</div>
          </div>
        </div>
        <div className='action-btns-container'>
          <div onClick={() => sendInvoice(commissions, 'offline')} className='force-btn'>
            HANDLED OFFLINE
          </div>
          {brand.stripeCustomerId && (
            <div onClick={() => sendInvoice(commissions, 'stripe')} className='action-btn'>
              SEND STRIPE INVOICE
            </div>
          )}
          {error ? (
            <div className='error-msg'>{error}</div>
          ) : (
            <div onClick={() => sendInvoice(commissions, 'paypal')} className='action-btn'>
              SEND PAYPAL INVOICE
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default CreateInvoice;
