import React, { useState } from 'react';
import cogoToast from 'cogo-toast';
import moment from 'moment';
import Loader from '../General/Loader';
import { matchScrollHeight } from '../../helpers/helpers';
import './Comment.scss';

const Comment = props => {
  const { note, comment, isNew, createComment, updateComment, deleteComment } = props;

  const { updatedAt, user } = comment || {};
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(!isNew);
  const [text, setText] = useState(comment?.comment || '');

  const save = async () => {
    if (!text) return cogoToast.error('Comment cannot be empty', { hideAfter: 2 });
    else if (!isNew && !note) return cogoToast.error('No Note_id present', { hideAfter: 2 });

    setLoading(true);
    try {
      isNew ? await createComment({ comment: text, Note_id: note.id }) : await updateComment(comment, { comment: text });
      isNew && setText('');
    } catch (e) {
      cogoToast.error('Error saving new note');
    }

    setLoading(false);
  };

  return (
    <div className='comment-outer'>
      <div className='comment-inner'>
        {loading ? (
          <Loader />
        ) : (
          <>
            {!isNew && (
              <div className='comment-header'>
                <div>{user?.name}</div>
                <div>{moment(updatedAt).format('MMM DD, YYYY')}</div>
              </div>
            )}
            <textarea
              className='note-textarea'
              rows='1'
              placeholder={'Enter Comment Here'}
              onChange={e => setText(e.target.value)}
              value={text}
              disabled={disabled}
              ref={ref => {
                matchScrollHeight(ref);
              }}
            ></textarea>
            <div className='comment-footer'>
              {props?.comment?.comment !== text && (
                <div className='comment-button' onClick={() => save()}>
                  Save
                </div>
              )}
              {!isNew && (
                <>
                  <div
                    className='comment-button'
                    onClick={() => {
                      setDisabled(false);
                    }}
                  >
                    Edit
                  </div>
                  <div className='comment-button' onClick={() => deleteComment(comment)}>
                    Delete
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Comment;
