import APIClient from './index';
import _ from 'lodash';

export const fetchUsers = (params = {}) => {
  return new Promise((resolve, reject) => {
    APIClient.get(`Users?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const updateUser = (user, data) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`Users/${user.id}`, data)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const udpateUserAddress = (User_id, updates) => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/UserAddresses`, { User_id, ...updates })
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};
