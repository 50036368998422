import { scaleThreshold } from 'd3';

export const conciergeColors = [
  { color: '#d4513f', backgroundColor: '#d4513f55', base: '#d4513f' }, // red
  { color: '#d48a3f', backgroundColor: '#d48a3f55', base: '#d48a3f' }, // orange
  { color: '#444', backgroundColor: '#cccccc55', base: '#cccccc' }, // grey
  { color: '#98d43f', backgroundColor: '#b4d43f55', base: '#b4d43f' }, // light green
  { color: '#50d43f', backgroundColor: '#50d43f55', base: '#50d43f' } // green
];
export const scoreIntervals = [-10, -1, 1, 10];
export const analyticsIntervals = [-50, -20, 20, 50];
export const notificationIntervals = [1, 3, 8, 15];
export const actionableIntervals = [1, 3, 8, 15];

/**
 * helper function for concierge dashboard that maps colors to a given value
 * based on the attributes of the talent. All of the attributes have different
 * scales given by the interval variables exported from this file.
 *
 * @param {('score'|'analytics'|'notifications'|'actionables')} attribute
 * @param {number} value
 */
export const getConciergeAttributeColor = (attribute, value) => {
  let color;

  switch (attribute) {
    case 'score':
      const score_scale = scaleThreshold()
        .domain(scoreIntervals)
        .range(conciergeColors);
      color = score_scale(value);
      return { color: color.color, backgroundColor: color.backgroundColor };
    case 'analytics':
      const analytics_scale = scaleThreshold()
        .domain(analyticsIntervals)
        .range(conciergeColors);
      color = analytics_scale(value);
      return { color: color.color, backgroundColor: color.backgroundColor };
    case 'notifications':
      const notifications_scale = scaleThreshold()
        .domain(notificationIntervals)
        .range([...conciergeColors].reverse());
      color = notifications_scale(value);
      return { color: color.color, backgroundColor: color.backgroundColor };
    case 'actionables':
      const actionables_scale = scaleThreshold()
        .domain(actionableIntervals)
        .range([...conciergeColors].reverse());
      color = actionables_scale(value);
      return { color: color.color, backgroundColor: color.backgroundColor };
    default:
      return null;
  }
};

export const getScoreDelta = talent => {
  if (!talent) return 0;
  const { score, expectedScore } = talent;
  return score - expectedScore;
};

/**
 * converts the activity of a talent into a percentage change from the
 * previous week. The categories that are calculated for "activity" are:
 * 1. clicks
 * 2. pins
 * 3. volume
 *
 * The average acivity is calculated by only using clicks and volume. The
 * change in pins is not used in the average calculation.
 *
 * @param {Object} talent - user object with all base data on user
 */
export const getActivityPercentDelta = talent => {
  const { click_difference, pin_difference, volume_difference } = talent;
  if (!click_difference || !pin_difference || !volume_difference) return 0;

  const getDeltaPercent = (current, previous) => {
    if (typeof current !== 'number' || typeof previous !== 'number') return 0;
    else if (previous === 0) return current ? 100 : 0;
    else return (1 - current / previous) * 100 * -1;
  };

  const click_delta_percent = getDeltaPercent(click_difference.current, click_difference.previous);
  const pin_delta_percent = getDeltaPercent(pin_difference.current, pin_difference.previous);
  const volume_delta_percent = getDeltaPercent(volume_difference.current, volume_difference.previous);
  const average_delta_percent = (click_delta_percent + volume_delta_percent) / 2;

  return { click_delta_percent, pin_delta_percent, volume_delta_percent, average_delta_percent };
};

export const getNotificationCount = talent => {
  if (!talent) return 0;
  const { outstanding_gifting_requests, outstanding_collab_proposals, unread_chat_messages } = talent;
  return outstanding_gifting_requests + outstanding_collab_proposals + unread_chat_messages;
};

export const getActionableCount = talent => {
  if (!talent) return 0;
  const { new_discount_codes, new_custom_rates, new_relevant_sales } = talent;
  return new_discount_codes + new_custom_rates + new_relevant_sales;
};
