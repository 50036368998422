import React from 'react';
import { faDownload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Table } from '../../../BrandReports/GraphTypes/Table';
import Loader from '../../../General/Loader';

const ConciergeReferralHistory = props => {
  // console.info({ props });
  const { title, downloading, conciergeReferralHistory } = props;
  const success = !!conciergeReferralHistory?.length;
  const errorMessage = !success ? 'User has no referrals' : undefined;

  const download_table = async () => await props.download({ rows: make_table_rows(), fileName: 'concierge_custom_codes' });

  const make_table_rows = () => {
    const customCodeRows = conciergeReferralHistory
      .sort((a, b) => parseInt(b.totalVolume) - parseInt(a.totalVolume))
      .map(referral => {
        const { id: User_id, username, name, createdAt, totalVolume: volume, totalPins: pins, totalPinClicks: clicks } = referral;

        return {
          key: `referred_user_${User_id}`,
          label: name,
          href: `shopmy.us/${username}`,
          values: [
            { label: 'Date Joined', value: moment(createdAt).format('MMMM Do, YYYY') },
            { label: 'Volume', value: volume },
            { label: 'Pins', value: pins },
            { label: 'Clicks', value: clicks }
          ]
        };
      });

    return customCodeRows;
  };

  return (
    <div className='talent-report-card' id={title}>
      {!conciergeReferralHistory ? (
        <Loader />
      ) : (
        <>
          <div className='header'>
            <div className='description'>
              <div className='title'>{title}</div>
              <div className='sub-title'>Referrals are all the users that have signed up through the talent's referral page.</div>
            </div>
            {!downloading && (
              <div className='talent-report-controls' id={`${title}-card-controls`}>
                <div className='rounded-button' onClick={() => download_table()}>
                  {downloading ? (
                    <Loader />
                  ) : (
                    <>
                      <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                      Download
                    </>
                  )}
                </div>
              </div>
            )}
          </div>

          <Table title='Custom Code Revenue' data={make_table_rows()} errorMessage={errorMessage} />
        </>
      )}
    </div>
  );
};

ConciergeReferralHistory.propTypes = {
  title: PropTypes.string.isRequired,
  conciergeReferralHistory: PropTypes.array,
  groupingMethod: PropTypes.string.isRequired,

  download: PropTypes.func.isRequired,
  downloading: PropTypes.bool.isRequired
};

const ConciergeReferralHistoryConfig = {
  title: 'Referral Program History'
};

export { ConciergeReferralHistory, ConciergeReferralHistoryConfig };
