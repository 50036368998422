import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { fetchScrapers } from '../../api/scrapers';

const ScraperData = props => {
  useEffect(() => {
    fetchScrapers()
      .then(response => props.setScrapers(response.scrapers))
      .catch(err => console.log(err))
      .finally(() => props.setIsLoading(false));
  }, []);

  return null;
};

ScraperData.propTypes = {
  setIsLoading: PropTypes.func.isRequired,
  setScrapers: PropTypes.func.isRequired
};

export default ScraperData;
