import { getTags as getTagsAPI, addTag as addTagAPI, deleteTag as deleteTagAPI, editTag as editTagAPI } from '../api/tags';

export const GET_TAGS_SUCCESS = 'GET_TAGS_SUCCESS';
export const ADD_TAG_SUCCESS = 'ADD_TAG_SUCCESS';
export const DELETE_TAG_REQUEST = 'DELETE_TAG_REQUEST';
export const DELETE_TAG_SUCCESS = 'DELETE_TAG_SUCCESS';
export const EDIT_TAG_REQUEST = 'EDIT_TAG_REQUEST';
export const EDIT_TAG_SUCCESS = 'EDIT_TAG_SUCCESS';

export const getTags = (params = {}) => async (dispatch, getState) => {
  const resp = await getTagsAPI(params);
  return dispatch({
    type: GET_TAGS_SUCCESS,
    tags: resp.tags
  });
};

export const addTag = tag => async (dispatch, getState) => {
  const newTag = await addTagAPI(tag);
  return dispatch({
    type: ADD_TAG_SUCCESS,
    tag: newTag
  });
};
export const deleteTag = tag => async (dispatch, getState) => {
  dispatch({
    type: DELETE_TAG_REQUEST,
    tag
  });
  await deleteTagAPI(tag);
  return dispatch({
    type: DELETE_TAG_SUCCESS,
    tag
  });
};

export const editTag = (tag, edits) => async (dispatch, getState) => {
  dispatch({
    type: EDIT_TAG_REQUEST,
    tag,
    edits
  });
  await editTagAPI(tag, edits);
  return dispatch({
    type: EDIT_TAG_SUCCESS,
    tag,
    edits
  });
};
