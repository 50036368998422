import APIClient from './index';
import _ from 'lodash';

export const getTags = () => {
  return new Promise((resolve, reject) => {
    APIClient.get(`Tags`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const addTag = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`Tags`, data)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const editTag = (tag, updates) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`Tags/${tag.id}`, updates)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const deleteTag = tag => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`Tags/${tag.id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const getUserTagsBasicObjects = () => {
  return new Promise((resolve, reject) => {
    APIClient.get(`UserTags/basic_objects`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const applyTagToUsers = (tag, users) => {
  return new Promise((resolve, reject) => {
    APIClient.post(`UserTags/multiple`, { tagid: tag.id, userids: users.map(u => u.id) })
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const deleteUserTag = (tag, user) => {
  return new Promise((resolve, reject) => {
    APIClient.post(`UserTags/delete`, { tagid: tag.id, userid: user.id })
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const applyTagToBrands = (tag, brands) => {
  return new Promise((resolve, reject) => {
    APIClient.post(`BrandTags/multiple`, { tagid: tag.id, brandids: brands.map(u => u.id) })
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const deleteBrandTag = (tag, brand) => {
  return new Promise((resolve, reject) => {
    APIClient.post(`BrandTags/delete`, { tagid: tag.id, brandid: brand.id })
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const applyTagToMerchants = (tag, merchants) => {
  return new Promise((resolve, reject) => {
    APIClient.post(`MerchantTags/multiple`, { tagid: tag.id, merchantids: merchants.map(u => u.id) })
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const deleteMerchantTag = (tag, merchant) => {
  return new Promise((resolve, reject) => {
    APIClient.post(`MerchantTags/delete`, { tagid: tag.id, merchantid: merchant.id })
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};
