import React from 'react';
import { faDownload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import { Table } from '../../../BrandReports/GraphTypes/Table';
import Loader from '../../../General/Loader';

const ConciergeTopConvertingDomains = props => {
  // console.log({ props });
  const { title, downloading, topConvertingDomains } = props;
  const success = !!topConvertingDomains?.length;
  const errorMessage = !success ? 'No Attributable Clicks / Orders Found' : null;

  const download_table = async () => await props.download({ rows: make_table_rows(), fileName: 'concierge_custom_codes' });

  const make_table_rows = () => {
    const customCodeRows = topConvertingDomains
      .sort((a, b) => parseFloat(b.conversion_rate) - parseFloat(a.conversion_rate))
      .map(retailer => {
        const { domain, clicks, orders, revenue, conversion_rate } = retailer;

        return {
          key: `top_converting_domains_${domain}`,
          label: domain,
          href: `https://${domain}`,
          values: [
            { label: 'Clicks', value: clicks },
            { label: 'Orders', value: orders },
            { label: 'Revenue', value: `$${revenue}` },
            { label: 'Conversion Rate', value: `${parseInt(conversion_rate)}%` }
          ]
        };
      })
      .slice(0, 25);

    return customCodeRows;
  };

  return (
    <div className='talent-report-card' id={title}>
      {!topConvertingDomains ? (
        <Loader />
      ) : (
        <>
          <div className='header'>
            <div className='description'>
              <div className='title'>{title}</div>
              <div className='sub-title'>Top converting domains show the best ratios of orders to clicks on a domain over all time.</div>
            </div>
            {!downloading && (
              <div className='talent-report-controls' id={`${title}-card-controls`}>
                <div className='rounded-button' onClick={() => download_table()}>
                  {downloading ? (
                    <Loader />
                  ) : (
                    <>
                      <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                      Download
                    </>
                  )}
                </div>
              </div>
            )}
          </div>

          <Table title='Top Retailers' data={make_table_rows()} errorMessage={errorMessage} />
        </>
      )}
    </div>
  );
};

ConciergeTopConvertingDomains.propTypes = {
  title: PropTypes.string.isRequired,
  topConvertingDomains: PropTypes.array,

  download: PropTypes.func.isRequired,
  downloading: PropTypes.bool.isRequired
};

const ConciergeTopConvertingDomainsConfig = {
  title: 'Top Converting Domains'
};

export { ConciergeTopConvertingDomains, ConciergeTopConvertingDomainsConfig };
