import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import cn from 'classnames';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import './EditContractTemplatePricingModal.scss';

import Modal from '../General/Modal';

const EditContractTemplatePricingModal = props => {
  /* 
    Expected Format:

      pricingGuidance: {
        description: "",
        headers: ["", ""],
        rows: ["", ""],
      }
  */
  const { template, closeModal, updateTemplate } = props;
  const { title, priceGuidance } = template;
  const [newPriceGuidance, setNewPriceGuidance] = useState(typeof priceGuidance === 'string' ? JSON.parse(priceGuidance) : '');
  const [isComplexMode, setIsComplexMode] = useState(false);

  const saveAndCloseModal = async () => {
    try {
      const JSONGuidance = typeof newPriceGuidance === 'string' ? JSON.parse(newPriceGuidance || null) : newPriceGuidance;
      updateTemplate(template, { priceGuidance: JSONGuidance });
      closeModal();
    } catch (error) {
      cogoToast.error(`Had trouble parsing this result, please check the console.`);
      console.error(error);
    }
  };

  let isValidJSON = true;
  try {
    typeof newPriceGuidance !== 'object' && JSON.parse(newPriceGuidance || null);
  } catch (_) {
    isValidJSON = false;
  }

  const createBaseTable = () => {
    setNewPriceGuidance({
      description: '',
      headers: ['Followers', 'Count'],
      rows: [
        ['0 - 10k', '$100'],
        ['0 - 200k', '$1000']
      ]
    });
  };

  const updateDescription = newDescription => {
    setNewPriceGuidance({
      ...newPriceGuidance,
      description: newDescription
    });
  };

  const updateHeader = (index, text) => {
    setNewPriceGuidance({
      ...newPriceGuidance,
      headers: newPriceGuidance.headers.map((header, i) => (i !== index ? header : text))
    });
  };
  const updateRowCell = (rowIdx, cellIdx, text) => {
    setNewPriceGuidance({
      ...newPriceGuidance,
      rows: newPriceGuidance.rows.map((row, i) => (i !== rowIdx ? row : row.map((c, i) => (i !== cellIdx ? c : text))))
    });
  };

  const addRow = () => {
    setNewPriceGuidance({
      ...newPriceGuidance,
      rows: [...newPriceGuidance.rows, _.last(newPriceGuidance.rows)]
    });
  };

  const deleteRow = idx => {
    setNewPriceGuidance({
      ...newPriceGuidance,
      rows: newPriceGuidance.rows.filter((row, i) => i !== idx)
    });
  };

  return (
    <Modal close={closeModal}>
      <div className={cn('edit-contract-template-pricing-modal-outer-container', { complex: isComplexMode, builder: !isComplexMode })}>
        <h3>Adjust Pricing Guidance for {title}</h3>
        <div onClick={() => setIsComplexMode(!isComplexMode)} className='complex-toggle'>
          {isComplexMode ? 'Hide' : 'Show'} Complex Mode
        </div>
        {isComplexMode ? (
          <textarea
            type='text'
            rows={15}
            placeholder={
              'Offer some further description of the guidance you are providing below. This will be shown about the pricing guidance table in the modal.'
            }
            onChange={({ target }) => setNewPriceGuidance(target.value)}
            value={typeof newPriceGuidance === 'string' ? newPriceGuidance : JSON.stringify(newPriceGuidance)}
            className={cn({ valid: isValidJSON })}
          />
        ) : !newPriceGuidance || !isValidJSON ? (
          <div className='create-base-table' onClick={createBaseTable}>
            Create Base Table
          </div>
        ) : (
          <div className='section'>
            <div className='subheader'>
              <textarea
                type='text'
                rows={8}
                onChange={({ target }) => updateDescription(target.value)}
                placeholder={'Set Price Guidance'}
                value={newPriceGuidance.description}
              />
            </div>
            <div className='table'>
              <div className='row header-row'>
                {newPriceGuidance?.headers?.map((header, idx) => (
                  <div key={idx} className='cell'>
                    <input onChange={({ target }) => updateHeader(idx, target.value)} value={header} />
                  </div>
                ))}
              </div>
              {newPriceGuidance?.rows?.map((cells, idx) => (
                <div key={idx} className='row'>
                  {cells.map((content, i) => (
                    <div key={i} className='cell'>
                      <input onChange={({ target }) => updateRowCell(idx, i, target.value)} value={content} />
                    </div>
                  ))}
                  <div onClick={() => deleteRow(idx)} className='delete'>
                    <FontAwesomeIcon icon={faTimes} />
                  </div>
                </div>
              ))}
              <div onClick={addRow} className='add-row'>
                Add Row
              </div>
            </div>
          </div>
        )}
        <div className='actions'>
          <div onClick={closeModal} className='action cancel'>
            Cancel
          </div>
          {priceGuidance !== newPriceGuidance && (
            <div onClick={saveAndCloseModal} className='action save'>
              Save
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

EditContractTemplatePricingModal.propTypes = {
  template: PropTypes.object.isRequired,
  updateTemplate: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default EditContractTemplatePricingModal;
