import React, { Component } from 'react';
import { Auth } from 'aws-amplify'
import PropTypes from 'prop-types'
import cogoToast from 'cogo-toast'

import { configureAxios } from '../../Auth'

import './Login.scss';

class Login extends Component {
  static propTypes = {
    completeLogin: PropTypes.func.isRequired,
  }

  state = {
    email: '',
    password: '',
    isLoggingIn: false,
  }

  login = (e) => {
    e.preventDefault()
    this.setState({isLoggingIn: true})
    Auth.signIn({
      username: this.state.email,
      password: this.state.password
    })
      .then(async (res) => {
        configureAxios(res)
        this.props.completeLogin()
      })
      .catch((err) => {
        cogoToast.error(err.message)
      })
      .finally(() => {
        this.setState({isLoggingIn: false})
      })
  }

  render() {
    const { email, password, isLoggingIn } = this.state;
    return (
      <div className='login-outer-container'>
        <h1>Shop My Shelf Admin Portal</h1>
        <h2>Please log in below</h2>
        <form onSubmit={this.login}>
          <input
            placeholder='Email'
            value={email}
            onChange={({ target }) => this.setState({ email: target.value })}
          />
          <input
            placeholder={`Password`}
            value={password}
            type='password'
            onChange={({ target }) => this.setState({ password: target.value })}
          />
        </form>
        <div onClick={this.login} className='submit-btn'>{isLoggingIn ? '...' : 'Log In'}</div>
      </div>
    )
  }
}

export default Login
