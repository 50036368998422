import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import cn from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';
import './ContractTaskTemplates.scss';

import EditContractTemplatePricingModal from './EditContractTemplatePricingModal';

import { getContractTaskTemplates, updateContractTaskTemplate, addContractTaskTemplate, deleteContractTaskTemplate } from '../../api/contracts';

const ContractTaskTemplates = props => {
  const [tasks, setTasks] = useState([]);
  const [editingContractTemplatePricing, setEditingContractTemplatePricing] = useState(null);
  const sortedTasks = _.orderBy(tasks, ['type', 'subtype']);

  useEffect(() => {
    getContractTaskTemplates().then(setTasks);
  }, []);

  const updateTemplate = (template, updates) => {
    updateContractTaskTemplate(template, updates).then(resp => {
      setTasks(tasks.map(t => (t.id === resp.id ? resp : t)));
    });
  };

  const deleteTemplate = async template => {
    const confirm = await window.confirm(`Are you sure you want to delete ${template.title || 'this template'}`);
    confirm &&
      deleteContractTaskTemplate(template).then(resp => {
        setTasks(tasks.filter(t => t.id !== template.id));
      });
  };

  const promptToUpdateField = async (template, field, additionalMsg) => {
    const newField = await window.prompt(`Update ${field}${additionalMsg ? ` (${additionalMsg})` : ''}:`, template[field] || '');
    if (!_.isNil(newField)) {
      updateTemplate(template, { [field]: newField });
    }
  };

  const addNew = async () => {
    addContractTaskTemplate({
      title: 'New Template'
    }).then(resp => {
      setTasks([...tasks, resp]);
    });
  };

  const getPriceGuidanceBlock = template => {
    return <div>{template.priceGuidance || '-'}</div>;
  };

  return (
    <div className='contract-task-templates-outer-container'>
      <div className='contract-task-templates-inner-container'>
        <div className='row header'>
          <div className='cell sm'>Type</div>
          <div className='cell sm'>Subtype</div>
          <div className='cell md'>Title</div>
          <div className='cell sm'>Needs Approval</div>
          <div className='cell sm'>Is Add On</div>
          <div className='cell sm'>Is Archived</div>
          <div className='cell sm'>Is Opportunity Subtype</div>
          <div className='cell sm'>Fee Type</div>
          <div className='cell lg'>Description</div>
          <div className='cell xl'>Price Guidance</div>
          <div className='cell sm'>Delete</div>
        </div>
        {sortedTasks.map((template, idx) => {
          const { type, subtype, title, requiresApproval, isAddOn, isArchived, isOpportunitySubtype, fee_type, description } = template;
          return (
            <div key={template.id} className={cn('row', { odd: idx % 2, disabled: isArchived })}>
              <div onClick={() => promptToUpdateField(template, 'type')} className='cell sm'>
                {type || '-'}
              </div>
              <div
                onClick={() =>
                  promptToUpdateField(
                    template,
                    'subtype',
                    'Critical!! Please beware that Opportunity.mentionSubtypes uses this subtype field to specify which content can be accepted, when changing this please confirm that no Opportunities require this subtype.'
                  )
                }
                className='cell sm'
              >
                {subtype || '-'}
              </div>
              <div onClick={() => promptToUpdateField(template, 'title')} className='cell md'>
                {title}
              </div>
              <div onClick={() => updateTemplate(template, { requiresApproval: !requiresApproval })} className='cell clickable sm'>
                {requiresApproval ? <FontAwesomeIcon icon={faCheck} /> : '-'}
              </div>
              <div onClick={() => updateTemplate(template, { isAddOn: !isAddOn })} className='cell clickable sm'>
                {isAddOn ? <FontAwesomeIcon icon={faCheck} /> : '-'}
              </div>
              <div onClick={() => updateTemplate(template, { isArchived: !isArchived })} className='cell clickable sm'>
                {isArchived ? <FontAwesomeIcon icon={faCheck} /> : '-'}
              </div>
              <div onClick={() => updateTemplate(template, { isOpportunitySubtype: !isOpportunitySubtype })} className='cell clickable sm'>
                {isOpportunitySubtype ? <FontAwesomeIcon icon={faCheck} /> : '-'}
              </div>
              <div onClick={() => updateTemplate(template, { fee_type: fee_type === 'fixed' ? 'duration' : 'fixed' })} className='cell clickable sm'>
                {fee_type === 'fixed' ? 'Fixed Fee' : fee_type === 'duration' ? 'Duration' : 'ERROR'}
              </div>
              <div onClick={() => promptToUpdateField(template, 'description')} className='cell lg'>
                {description || '-'}
              </div>
              <div onClick={() => setEditingContractTemplatePricing(template)} className='cell xl'>
                {getPriceGuidanceBlock(template)}
              </div>
              <div onClick={() => deleteTemplate(template)} className='cell clickable sm'>
                <FontAwesomeIcon icon={faTimes} />
              </div>
            </div>
          );
        })}
        {editingContractTemplatePricing && (
          <EditContractTemplatePricingModal
            template={editingContractTemplatePricing}
            updateTemplate={updateTemplate}
            closeModal={() => setEditingContractTemplatePricing(null)}
          />
        )}
        <div className='add-new-template'>
          <div onClick={addNew} className='btn'>
            + Add New Template
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractTaskTemplates;
