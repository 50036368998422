import instagramWhite from '../static/images/social_icons/instagram_white.png';
import instagramLight from '../static/images/social_icons/instagram_light.png';
import instagramDark from '../static/images/social_icons/instagram_dark.png';

import tiktokWhite from '../static/images/social_icons/tiktok_white.png';
import tiktokLight from '../static/images/social_icons/tiktok_light.png';
import tiktokDark from '../static/images/social_icons/tiktok_dark.png';

import youtubeWhite from '../static/images/social_icons/youtube_white.png';
import youtubeLight from '../static/images/social_icons/youtube_light.png';
import youtubeDark from '../static/images/social_icons/youtube_dark.png';

export const getSocialIcon = (type, color = 'dark') => {
  return {
    Instagram: {
      white: instagramWhite,
      light: instagramLight,
      dark: instagramDark
    },
    TikTok: {
      white: tiktokWhite,
      light: tiktokLight,
      dark: tiktokDark
    },
    Youtube: {
      white: youtubeWhite,
      light: youtubeLight,
      dark: youtubeDark
    },
    Substack: {
      white:
        'https://substackcdn.com/image/fetch/w_500,c_limit,f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack.com%2Fimg%2Fsubstack.png',
      light:
        'https://substackcdn.com/image/fetch/w_500,c_limit,f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack.com%2Fimg%2Fsubstack.png',
      dark: 'https://cdn.icon-icons.com/icons2/3915/PNG/512/substack_logo_icon_249485.png'
    },
    Twitter: {
      white: 'https://rushem.org/wp-content/uploads/2020/09/twitter-icon-white-transparent-11549537259z0sowbg17j.png',
      light: 'https://rushem.org/wp-content/uploads/2020/09/twitter-icon-white-transparent-11549537259z0sowbg17j.png',
      dark: 'https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/twitter-icon.png'
    },
    Facebook: {
      white: 'https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/facebook-app-round-white-icon.png',
      light: 'https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/facebook-app-round-white-icon.png',
      dark: 'https://static-00.iconduck.com/assets.00/facebook-square-icon-511x512-9dz42ub9.png'
    },
    Pinterest: {
      white: 'https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/pinterest-round-white-icon.png',
      light: 'https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/pinterest-round-white-icon.png',
      dark: 'https://i.pinimg.com/736x/81/e1/a3/81e1a3e4baeb40a9974a17e77f8f7241.jpg'
    },
    Linktree: {
      white: 'https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/linktree-white-icon.png',
      light: 'https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/linktree-white-icon.png',
      dark: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3upTvZXEC9ihGWUS0DtYr_k7yQxWQ5z4LJQ&s'
    }
  }[type][color];
};

/**
 * function that parses the db user table "social_links" column and searches for common
 * social media links inside of it. then it turns the links found into an object that holds
 * each link individually listed
 *
 * @param {string} socialLink comma separated list of a user's social links
 * @returns object with each social individually listed
 */
export const splitDbSocialLinksIntoIndividualLinks = socialLink => {
  const links = {
    youtube_url: null,
    instagram_url: null,
    tiktok_url: null,
    twitter_url: null,
    facebook_url: null,
    website_url: null
  };

  const youtube_regex = /.*(youtube|youtu\.be).*/gi;
  const instagram_regex = /.*(instagram).*/gi;
  const tiktok_regex = /.*(tiktok).*/gi;
  const twitter_regex = /.*(twitter).*/gi;
  const facebook_regex = /.*(facebook).*/gi;

  if (!socialLink) return links;
  const split_links = socialLink.split(',');

  for (let link of split_links) {
    switch (true) {
      case link.match(youtube_regex) !== null:
        links.youtube_url = link;
        break;
      case link.match(instagram_regex) !== null:
        links.instagram_url = link;
        break;
      case link.match(tiktok_regex) !== null:
        links.tiktok_url = link;
        break;
      case link.match(twitter_regex) !== null:
        links.twitter_url = link;
        break;
      case link.match(facebook_regex) !== null:
        links.facebook_url = link;
        break;
      default:
        // because user could have multiple other websites, append to array and add all
        if (!links.website_url) links.website_url = [link];
        else links.website_url.push(link);
    }
  }

  return links;
};
