import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './LookbookOrder.scss';
import SelectOption from '../General/SelectOption';
import Select from 'react-select';
import moment from 'moment';

const LookbookOrder = props => {
  const { order, accountManagers } = props;
  const { id: LookbookOrder_id, user, brand, items, status, email, address, additionalNote, AccountManager_id, createdAt } = order;
  const { name: User_name, username } = user;
  const { name: Brand_name, domain } = brand;
  const { name: Order_name, address1, address2, city, state, zip, country, ward, district, prefecture } = JSON.parse(address);

  const statusOptions = [
    { label: 'Pending', value: 'pending', sublabel: 'Order has not been placed on website yet' },
    { label: 'Submitted', value: 'submitted', sublabel: 'Order placed but has no shipping information' },
    { label: 'Shipped', value: 'shipped', sublabel: 'Order has been shipped' },
    { label: 'Delivered', value: 'delivered', sublabel: 'Order has been delivered' },
    { label: 'Canceled', value: 'canceled', sublabel: 'Order has been canceled' }
  ];

  const accountManagerOptions = accountManagers?.map(manager => {
    const { id: AccountManager_id, name, accounts_managed } = manager;
    return { label: name, sublabel: accounts_managed, value: AccountManager_id };
  });

  const [trackingSource, setTrackingSource] = useState(order.trackingSource);
  const [trackingNumber, setTrackingNumber] = useState(order.trackingNumber);
  const [trackingUrl, setTrackingUrl] = useState(order.trackingUrl);
  const [statusOption, setStatusOption] = useState(statusOptions.find(option => option.value === status));
  const [expectedDeliveryDate, setExpectedDeliveryDate] = useState(moment(order.expectedDeliveryDate).format('YYYY-MM-DD'));

  const updateLookbookOrder = async updates => await props.updateLookbookOrder(LookbookOrder_id, updates);

  const saveOrder = () => {
    const update = { status: statusOption.value, trackingNumber, trackingUrl, expectedDeliveryDate, trackingSource };
    updateLookbookOrder(update).catch(() => alert('Error saving order'));
  };

  const updateAccountManagerId = option => updateLookbookOrder({ AccountManager_id: option.value });

  const totalItemCount = items.reduce((acc, item) => acc + item.quantity, 0);
  const totalPrice = items.reduce((acc, item) => acc + item.price * item.quantity, 0);

  return (
    <div className='lookbook-order-outer'>
      <div className='lookbook-order-inner'>
        <div className='lookbook-order-header'>
          <div>
            <div className='title'>{User_name}</div>
            <div className='subtitle'>
              <span>
                <a href={`https://shopmy.us/${username}`} target='_blank' rel='noreferrer'>
                  shopmy.us/{username}
                </a>
              </span>
              &nbsp;&nbsp;•&nbsp;&nbsp;
              {Brand_name}
              &nbsp;&nbsp;•&nbsp;&nbsp;
              <span>
                <a href={`https://${domain}`} target='_blank' rel='noreferrer'>
                  {domain}
                </a>
              </span>
            </div>
            <div className='subtitle'>
              {totalPrice ? (
                <>
                  ${totalPrice || 'N/A'}
                  &nbsp;&nbsp;•&nbsp;&nbsp;
                </>
              ) : null}
              {totalItemCount} {totalItemCount === 1 ? 'item' : 'items'}
              &nbsp;&nbsp;•&nbsp;&nbsp; ordered {moment().diff(moment(createdAt), 'days')} days ago
            </div>
          </div>
        </div>
        <div className='account-manager-container'>
          <Select
            styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }}
            className='select'
            placeholder='No Account Manager Assigned'
            value={accountManagerOptions.find(manager => manager.value === parseInt(AccountManager_id)) || null}
            onChange={updateAccountManagerId}
            options={[{ label: 'No Account Manager', value: null }, ...accountManagerOptions]}
            components={{ Option: SelectOption }}
          />
        </div>
        <div className='lookbook-order-body'>
          <div className='item-container'>
            {items.map(item => {
              const { id: LookbookOrderItem_id, title, url, image, price, quantity } = item;

              return (
                <a href={url} target='_blank' rel='noreferrer' className='item' key={`LookbookOrderItem-${LookbookOrderItem_id}`}>
                  <div className='item-info'>
                    <div className='item-title'>{title}</div>
                    <div className='item-subtitle'>
                      {price ? (
                        <>
                          price: ${price}
                          &nbsp;&nbsp;•&nbsp;&nbsp;
                        </>
                      ) : null}
                      quantity: {quantity}
                    </div>
                  </div>
                  <div className='item-image'>
                    <img src={image} alt={title} />
                    <div className='image-overlay'></div>
                  </div>
                </a>
              );
            })}
          </div>
          <div className='shipping-information-container'>
            <div className='order-input-container'>
              <div className='order-input'>
                <div className='order-input-label'>Status</div>
                <Select
                  className='select'
                  placeholder='Status'
                  defaultValue={statusOptions.find(option => option.value === status)}
                  options={statusOptions}
                  components={{ Option: SelectOption }}
                  onChange={option => setStatusOption(option)}
                />
              </div>
              <div className='order-input'>
                <div className='order-input-label'>Tracking Source</div>
                <input type='text' value={trackingSource} onChange={e => setTrackingSource(e.target.value)} />
              </div>
              <div className='order-input'>
                <div className='order-input-label'>Tracking Number</div>
                <input type='text' value={trackingNumber} onChange={e => setTrackingNumber(e.target.value)} />
              </div>
              <div className='order-input'>
                <div className='order-input-label'>Tracking URL</div>
                <input type='text' value={trackingUrl} onChange={e => setTrackingUrl(e.target.value)} />
              </div>
              <div className='order-input'>
                <div className='order-input-label'>Expected Delivery Date</div>
                <input type='date' value={expectedDeliveryDate} onChange={e => setExpectedDeliveryDate(e.target.value)} />
              </div>
              {additionalNote && (
                <div className='order-input'>
                  <div className='order-input-label'>Additional Note</div>
                  <textarea value={additionalNote || ''} disabled />
                </div>
              )}
            </div>
            <div className='order-details-container'>
              <div className='order-detail'>
                <div className='order-detail-label'>Email</div>
                <div className='order-detail-value'>{email}</div>
              </div>
              <div className='order-detail'>
                <div className='order-detail-label'>Address</div>
                <div className='order-detail-value'>
                  <div>{Order_name}</div>
                  <div>
                    {address1}
                    {address2 ? ` ${address2}` : null}
                  </div>
                  <div>{city}</div>
                  <div>{state}</div>
                  <div>{zip}</div>
                  <div>{country}</div>
                  {ward && <div>{ward}</div>}
                  {district && <div>{district}</div>}
                  {prefecture && <div>{prefecture}</div>}
                </div>
              </div>
              <div className='order-detail'>
                <button className='save-button' onClick={saveOrder}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

LookbookOrder.propTypes = {
  order: PropTypes.object.isRequired,
  accountManagers: PropTypes.array.isRequired,
  updateLookbookOrder: PropTypes.func.isRequired
};

export default LookbookOrder;
