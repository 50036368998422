import { getAllLinkHealth as getAllLinkHealthAPI, getLinkHealth as getLinkHealthAPI, updateLinkHealth as updateLinkHealthAPI } from '../api/health';

export const GET_ALL_LINK_HEALTH_SUCCESS = 'GET_ALL_LINK_HEALTH_SUCCESS';
export const GET_LINK_HEALTH_SUCCESS = 'GET_LINK_HEALTH_SUCCESS';
export const UPDATE_LINK_HEALTH_REQUEST = 'UPDATE_LINK_HEALTH_REQUEST';
export const UPDATE_LINK_HEALTH_SUCCESS = 'UPDATE_LINK_HEALTH_SUCCESS';

export const getAllLinkHealth = (params = {}) => async (dispatch, getState) => {
  const resp = await getAllLinkHealthAPI(params);
  return dispatch({
    type: GET_ALL_LINK_HEALTH_SUCCESS,
    links: resp.results,
    summary: resp.summary,
    params
  });
};

export const getLinkHealth = (id, params = {}) => async (dispatch, getState) => {
  const resp = await getLinkHealthAPI(id, params);
  return dispatch({
    type: GET_LINK_HEALTH_SUCCESS,
    ...resp
  });
};

export const updateLinkHealth = (link, updates = {}) => async (dispatch, getState) => {
  dispatch({ type: UPDATE_LINK_HEALTH_REQUEST, link, updates });
  await updateLinkHealthAPI(link, updates);
  return dispatch({
    type: UPDATE_LINK_HEALTH_SUCCESS,
    link,
    updates
  });
};
