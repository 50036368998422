import React from 'react';
import './Table.scss';
import commaNumber from 'comma-number';
import _ from 'lodash';
import cn from 'classnames';

/**
 * @typedef {Object} TableRow
 * @property {string} key - string to make react warning go away
 * @property {string=} label - Unique value for each row to be identified by
 * @property {boolean} smallerLabel - If the label is long, set this to true to make it smaller
 * @property {boolean} smallerValues - If the values are long, set this to true to make each smaller
 * @property {string=} description - allows for more clarity on what the label means
 * @property {string=} href - if the description should be a link to somewhere, set this to a url
 * @property {Object[]} values - object with values (the keys will become the table headers)
 *
 * @typedef {Object} TableProps
 * @property {TableRow[]} TableProps.data
 * @property {string} TableProps.title - The stirng that will diaplay abovve each graph
 * @property {string} TableProps.errorMessage - If there is an error, display this message
 * @property {boolean} TableProps.allowDecimals - If the values should be rounded to the nearest whole number
 */

/** @param {TableProps} props */
const Table = props => {
  const { title, data, errorMessage, allowDecimals } = props;

  const parseNumber = value => {
    if (typeof value === 'string') {
      value = value.replace(/[^0-9.]/g, '');
    }

    let parsed = parseFloat(value);
    if (allowDecimals) parsed = parsed.toFixed(2);
    else parsed = parsed.toFixed(0);

    return parsed;
  };

  const convert_value_to_something_safe = (value, isPercent) => {
    /* eslint-disable-next-line */
    if (value == '0' || value === '$0') return '-';
    if (isPercent) return parseFloat(value) ? `${parseFloat(value).toFixed(1)}%` : '-';
    if (typeof value === 'string' && !/\d/.test(value)) return value;
    if (_.isNaN(value) && !(typeof value === 'string' && value?.includes('$'))) return '-';
    if (typeof value === 'string' && value?.includes('NaN')) value = value.replace('NaN', '0');

    if (typeof value === 'string' && value?.includes('$')) value = `${value.split('$')[0]}$${commaNumber(parseNumber(value))}`;
    return commaNumber(value);
  };

  return (
    <div className='table-graph-outer'>
      <div className='table-graph-inner'>
        {/* <div className='table-title'>{title}</div> */}
        {data && data.length && !errorMessage ? (
          <div className='rows'>
            <div className='row' style={{ fontWeight: 600 }}>
              <div className='cell primary'></div>
              {data[0].values.map(({ label }) => {
                return (
                  <div className={cn('cell')} key={`${title} ${label} column`}>
                    {label}
                  </div>
                );
              })}
            </div>
            {data.map((row, row_index) => {
              const { key, values, description, href, label, smallerLabel, smallerValues } = row;

              return (
                <div className='row' key={key}>
                  <div className='cell primary'>
                    <div className={cn('cell-label', { smallerLabel })}>{label}</div>
                    <div className='cell-sub-label'>
                      {href ? (
                        <a href={href} target='_blank' rel='noreferrer' className='description' onClick={e => e.stopPropagation()}>
                          {description ? description : href}
                        </a>
                      ) : (
                        <div className='description'>{description}</div>
                      )}
                    </div>
                  </div>
                  {values.map(({ value, isPercent }, col_index) => (
                    <div className={cn('cell', { smallerValues })} key={`${key}${title}${value}${row_index}${col_index}`}>
                      {convert_value_to_something_safe(value, isPercent)}
                    </div>
                  ))}
                </div>
              );
            })}
          </div>
        ) : (
          <div className='no-results'>{errorMessage ? errorMessage : 'No Results'}</div>
        )}
      </div>
    </div>
  );
};

export { Table };
