import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cn from 'classnames';
import cogoToast from 'cogo-toast';
import ReactCrop from 'react-image-crop';
import OutsideClickHandler from 'react-outside-click-handler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagic, faRepeat } from '@fortawesome/pro-light-svg-icons';
import 'react-image-crop/lib/ReactCrop.scss';
import 'react-image-crop/dist/ReactCrop.css';
import './ProductPreview.scss';

import Loader from '../../General/Loader';

import { prettifyImage as prettifyImageAPI, flipImage as flipImageAPI } from '../../../api/images';

const IMAGE_SIZE = 200;

class ProductPreview extends Component {
  static propTypes = {
    isEditing: PropTypes.bool.isRequired,
    updateCrop: PropTypes.func.isRequired,
    updateImageUrl: PropTypes.func.isRequired,
    brand: PropTypes.string,
    title: PropTypes.string,
    image: PropTypes.string,
    category: PropTypes.object,
    product: PropTypes.object
  };

  componentDidUpdate(prevProps) {
    const prevTitle = _.get(prevProps, 'title');
    const curTitle = _.get(this.props, 'title');
    if (prevTitle !== curTitle) {
      this.setState({
        crop: { aspect: 1 },
        applyingCrop: false,
        appliedCrop: this.getStarterCrop()
      });
    }
  }

  getStarterCrop = () => ({
    unit: 'px',
    x: 0,
    y: 0,
    aspect: 1,
    height: 0,
    width: 0
  });

  state = {
    crop: { aspect: 1 },
    applyingCrop: false,
    appliedCrop: this.getStarterCrop(),
    prettifyingImage: false,
    flippingImage: false
  };

  setCrop = crop => {
    this.setState({
      crop: {
        ...crop,
        aspect: 1
      },
      applyingCrop: !_.isEqual(crop, this.state.appliedCrop) && !_.isEqual(crop, this.getStarterCrop())
    });
  };

  applyCrop = crop => {
    this.appliedZoomOffset = this.zoomOffset;
    this.appliedXTransformOffset = this.xTransformOffset;
    this.appliedYTransformOffset = this.yTransformOffset;
    this.setState(
      {
        crop: this.getStarterCrop(),
        appliedCrop: this.state.crop,
        trueCrop: this.state.appliedCrop.width
          ? {
              width: this.state.crop.width / this.appliedZoomOffset,
              height: this.state.crop.width / this.appliedZoomOffset,
              x: (this.state.crop.x - this.xTransformOffset) / this.appliedZoomOffset,
              y: (this.state.crop.y - this.yTransformOffset) / this.appliedZoomOffset
            }
          : this.state.crop,
        applyingCrop: false
      },
      () => {
        this.props.updateCrop({
          x: this.state.trueCrop.x,
          y: this.state.trueCrop.y,
          width: this.state.trueCrop.width,
          height: this.state.trueCrop.height,
          frame: IMAGE_SIZE
        });
      }
    );
  };

  removeCrop = () => {
    this.zoomOffset = null;
    this.xTransformOffset = null;
    this.yTransformOffset = null;
    this.appliedZoomOffset = null;
    this.appliedXTransformOffset = null;
    this.appliedYTransformOffset = null;
    this.setState({
      crop: {},
      appliedCrop: this.getStarterCrop(),
      applyingCrop: false
    });
    this.props.updateCrop(null);
  };

  isCropApplied = () => !_.isEqual(this.getStarterCrop(), this.state.appliedCrop);

  getCroppedImageStyle = () => {
    const { width, height, x, y } = this.state.appliedCrop;

    if (height < 10 || width < 10) {
      return {};
    }

    const adjustedWidth = width / (this.appliedZoomOffset || 1);
    const adjustedHeight = height / (this.appliedZoomOffset || 1);

    const fullImageWidth = _.get(this.curImageDimensions, 'width', adjustedWidth);
    const fullImageHeight = _.get(this.curImageDimensions, 'height', adjustedHeight);

    const trueWidthRatio = fullImageWidth / adjustedWidth;
    const trueHeightRatio = fullImageHeight / adjustedHeight;

    const newImageWidth = trueWidthRatio * IMAGE_SIZE;
    const newImageHeight = trueHeightRatio * IMAGE_SIZE;

    const newImageOffsetX = (-1 * IMAGE_SIZE * (x - (this.appliedXTransformOffset || 0))) / adjustedHeight / (this.appliedZoomOffset || 1);
    const newImageOffsetY = (-1 * IMAGE_SIZE * (y - (this.appliedYTransformOffset || 0))) / adjustedHeight / (this.appliedZoomOffset || 1);

    this.zoomOffset = _.max([trueWidthRatio, trueHeightRatio]);
    this.xTransformOffset = newImageOffsetX;
    this.yTransformOffset = newImageOffsetY;
    return {
      width: `${newImageWidth.toFixed(2)}px`,
      maxWidth: `${newImageWidth.toFixed(2)}px`,
      height: `${newImageHeight.toFixed(2)}px`,
      maxHeight: `${newImageHeight.toFixed(2)}px`,
      transform: `translate(${newImageOffsetX.toFixed(1)}px, ${newImageOffsetY.toFixed(1)}px)`,
      objectFit: 'contain'
    };
  };

  onImageLoaded = image => {
    this.curImageDimensions = {
      width: image.width / (this.zoomOffset || 1),
      height: image.height / (this.zoomOffset || 1)
    };
  };

  flipImage = () => {
    const { image, updateImageUrl } = this.props;
    this.setState({ flippingImage: true });
    flipImageAPI(image)
      .then(resp => {
        updateImageUrl(resp.url);
      })
      .catch(error => {
        cogoToast.error(error || 'Had an error, check console.');
      })
      .finally(() => {
        this.setState({ flippingImage: false });
      });
  };
  prettifyImage = () => {
    const { image, updateImageUrl } = this.props;
    this.setState({ prettifyingImage: true });

    prettifyImageAPI(image)
      .then(resp => {
        updateImageUrl(resp.url);
      })
      .catch(error => {
        cogoToast.error(error || 'Had an error, check console.');
      })
      .finally(() => {
        this.setState({ prettifyingImage: false });
      });
  };

  render() {
    const { image, title, product, brand, category, isEditing } = this.props;
    const { crop, applyingCrop, prettifyingImage, flippingImage } = this.state;
    return (
      <div className='product-preview-outer-container'>
        <div className='product-preview'>
          {image ? (
            <OutsideClickHandler onOutsideClick={() => this.setState({ applyingCrop: false })}>
              <div className={cn('collection-product-img-wrapper', { editing: prettifyingImage || flippingImage })}>
                {prettifyingImage || flippingImage ? (
                  <div className='loading-container'>
                    <Loader />
                    <div className='message'>{flippingImage ? 'Flipping' : 'Prettifying'} Image</div>
                  </div>
                ) : (
                  <div className='transform-image-btns'>
                    <div onClick={this.flipImage} className='rotate-image-btn'>
                      <FontAwesomeIcon icon={faRepeat} />
                    </div>
                    <div onClick={this.prettifyImage} className='prettify-image-btn'>
                      <FontAwesomeIcon icon={faMagic} />
                    </div>
                  </div>
                )}
                <ReactCrop
                  imageStyle={this.getCroppedImageStyle()}
                  src={image}
                  crop={crop}
                  ruleOfThirds
                  onChange={this.setCrop}
                  onImageLoaded={this.onImageLoaded}
                />
              </div>
              <div className='product-image-controls'>
                {this.isCropApplied() && (
                  <div onClick={this.removeCrop} className='remove-crop-btn btn'>
                    RESET
                  </div>
                )}
                {applyingCrop && (
                  <div onClick={this.applyCrop} className='apply-crop-btn btn'>
                    APPLY
                  </div>
                )}
                {!applyingCrop && !this.isCropApplied() && <div className='apply-crop-msg'>Drag to crop</div>}
              </div>
            </OutsideClickHandler>
          ) : (
            <div className='empty image' />
          )}
          <div className='main-data'>
            <div className={cn('title', { empty: !title })}>{title}</div>
            <div className={cn('brand', { empty: !brand })}>{brand}</div>
            <div className={cn('category', { empty: !category })}>{category && category.name}</div>
            {isEditing && product && <div className='in-catalog-badge'>{product.id}</div>}
          </div>
        </div>
      </div>
    );
  }
}

export default ProductPreview;
