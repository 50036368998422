import slugify from 'slugify';
import { stringToReadableDate } from './formatting';
const moment = require('moment');

export const getSlugifiedRegistrationCode = brandName => {
  /*
    This is used on both the front and back end so when updating this file ensure
    that the front end, backend AND the admin code are updated to reflect the change 
    and any necessary updates are made to the existing RegistrationCodes for the new formatting.
  */
  return slugify(brandName + '-' + moment().year(), { replacement: '' }).toUpperCase();
};

export const getBrandReportHeaderLabel = ({ groupingMethod, week, month, quarter }) => {
  if (!groupingMethod) {
    if (week) return `${stringToReadableDate({ week }).shortened_month} ${stringToReadableDate({ week }).day_of_month}`;
    else if (month) return stringToReadableDate({ month }).shortened_month;
    else if (quarter) return `Q${quarter}`;
    else return 'N/A';
  }

  switch (groupingMethod) {
    case 'week':
      return `${stringToReadableDate({ week }).shortened_month} ${stringToReadableDate({ week }).day_of_month}`;
    case 'month':
      return stringToReadableDate({ month }).shortened_month;
    case 'quarter':
      return `Q${quarter}`;
    default:
      return quarter ? `Q${quarter}` : stringToReadableDate({ month }).shortened_month;
  }
};
