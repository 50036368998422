import React, { Component } from 'react';
import PropTypes from 'prop-types';
import commaNumber from 'comma-number';
import moment from 'moment';
import _ from 'lodash';

import './AnalyticsPayouts.scss';

class AnalyticsPayouts extends Component {
  static propTypes = {
    payouts: PropTypes.array.isRequired
  };

  state = {
    sortTerm: 'order_amount',
    sortOrder: 'desc'
  };

  changeSort = newSort => {
    this.setState({
      sortTerm: newSort,
      sortOrder: newSort === this.state.sortTerm ? (this.state.sortOrder === 'desc' ? 'asc' : 'desc') : 'desc'
    });
  };

  getUserCommission = payout => payout.amountEarned;
  getSmsCommission = payout => payout.commission_amount - payout.amountEarned;
  getSortedPayouts = () => {
    const { payouts } = this.props;
    const { sortTerm, sortOrder } = this.state;
    switch (sortTerm) {
      case 'user_commission_amount':
        return _.orderBy(payouts, this.getUserCommission, sortOrder);
      case 'sms_commission_amount':
        return _.orderBy(payouts, this.getSmsCommission, sortOrder);
      default:
        return _.orderBy(payouts, sortTerm, sortOrder);
    }
  };

  render() {
    const sortedPayouts = this.getSortedPayouts();
    return (
      <div className='analytics-payouts-outer-container'>
        <div className='table'>
          <div className='row row-header'>
            <div onClick={() => this.changeSort('name')} className='cell medium'>
              Name
            </div>
            <div onClick={() => this.changeSort('order_amount')} className='cell'>
              Order Total
            </div>
            <div onClick={() => this.changeSort('commission_amount')} className='cell'>
              Commission
            </div>
            <div onClick={() => this.changeSort('user_commission_amount')} className='cell small'>
              User
            </div>
            <div onClick={() => this.changeSort('sms_commission_amount')} className='cell small'>
              SMS
            </div>
            <div onClick={() => this.changeSort('merchant')} className='cell medium'>
              Merchant
            </div>
            <div onClick={() => this.changeSort('source')} className='cell'>
              Source
            </div>
            <div onClick={() => this.changeSort('transaction_date')} className='cell'>
              Date
            </div>
            <div onClick={() => this.changeSort('title')} className='cell'>
              Product
            </div>
          </div>
          {_.map(sortedPayouts, payout => {
            const { id, source, merchant, name, commission_amount, Collection_id, title, order_amount, transaction_date } = payout;
            const userCommission = this.getUserCommission(payout);
            const smsCommission = this.getSmsCommission(payout);
            return (
              <div className='row' key={id}>
                <div className='cell medium'>{name}</div>
                <div className='cell'>{order_amount ? `$${commaNumber(order_amount.toFixed(2))}` : '-'}</div>
                <div className='cell'>{commission_amount ? `$${commaNumber(commission_amount.toFixed(2))}` : '-'}</div>
                <div className='cell small'>{userCommission ? `$${commaNumber(userCommission.toFixed(2))}` : '-'}</div>
                <div className='cell small'>{smsCommission ? `$${commaNumber(smsCommission.toFixed(2))}` : '-'}</div>
                <div className='cell medium'>{merchant}</div>
                <div className='cell'>{source}</div>
                <div className='cell'>{moment(transaction_date).format('MMMM Do')}</div>
                {Collection_id && title ? (
                  <a href={`https://shopmy.us/collections/${Collection_id}`} target='_blank' rel='noopener noreferrer' className='cell medium'>
                    {title.slice(0, 26)}
                    {title.length > 26 ? '...' : ''}
                  </a>
                ) : (
                  <div className='cell'>Quick Link</div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default AnalyticsPayouts;
