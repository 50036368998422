const BASE_GROUP = 'Base Prompts';
const GENERATED_GROUP = 'Generated Prompts';

export const USER_PROMPT = 'user';
export const BRAND_PROMPT = 'brand';
export const USER_BRAND_PROMPT = 'user_brand';
export const BASE_SHOPMY_ABOUT_PROMPT = 'base_shopmy_about';

export const PROMPTS = {
  [USER_PROMPT]: { label: 'Talent Prompt', is_base_prompt: false },
  [BRAND_PROMPT]: { label: 'Brand Prompt', is_base_prompt: false },
  [USER_BRAND_PROMPT]: { label: 'Talent & Brand Prompt', is_base_prompt: false },
  [BASE_SHOPMY_ABOUT_PROMPT]: { label: 'ShopMy About', is_base_prompt: true }
};

const generatePromptGroups = () => {
  const basePrompts = { label: BASE_GROUP, options: [] };
  const generatedPrompts = { label: GENERATED_GROUP, options: [] };

  Object.keys(PROMPTS).forEach(type => {
    if (PROMPTS[type].is_base_prompt) {
      const newPrompt = { label: PROMPTS[type].label, value: type };
      basePrompts.options.push(newPrompt);
    } else {
      const newPrompt = { label: PROMPTS[type].label, value: type };
      generatedPrompts.options.push(newPrompt);
    }
  });

  return [basePrompts, generatedPrompts];
};

export const getPromptCardTitle = (promptType, promptData) => {
  switch (promptType) {
    case USER_PROMPT:
      return `${PROMPTS[promptType].label} VERSION ${promptData.version}: ${promptData.user.name}`;
    case BRAND_PROMPT:
      return `${PROMPTS[promptType].label} VERSION ${promptData.version}: ${promptData.brand.name}`;
    case USER_BRAND_PROMPT:
      return `${PROMPTS[promptType].label} VERSION ${promptData.version}: ${promptData.user.name} + ${promptData.brand.name}`;
    default:
      return PROMPTS[promptType].label;
  }
};

export const promptGroupOptions = generatePromptGroups();
