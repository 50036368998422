import { getBrandNote as getBrandNoteAPI, updateNote as updateNoteAPI } from '../api/notes';
import { createComment as createCommentAPI, updateComment as updateCommentAPI, deleteComment as deleteCommentAPI } from '../api/note_comment';

export const GET_BRAND_NOTE = 'GET_BRAND_NOTE';
export const UPDATE_NOTE_SUCCESS = 'UPDATE_NOTE_SUCCESS';
export const UPDATE_NOTE_FAILURE = 'UPDATE_NOTE_FAILURE';
export const CREATE_COMMENT_SUCCESS = 'CREATE_COMMENT_SUCCESS';
export const CREATE_COMMENT_FAILURE = 'CREATE_COMMENT_FAILURE';
export const UPDATE_COMMENT_SUCCESS = 'UPDATE_COMMENT_SUCCESS';
export const DELETE_COMMENT_SUCCESS = 'DELETE_COMMENT_SUCCESS';
export const DELETE_COMMENT_REQUEST = 'DELETE_COMMENT_REQUEST';
export const DELETE_COMMENT_FAILURE = 'DELETE_COMMENT_FAILURE';

export const getBrandNote = (params = {}) => async (dispatch, getState) => {
  const resp = await getBrandNoteAPI(params);
  return dispatch({
    type: GET_BRAND_NOTE,
    note: resp.note || {},
    all_managers: resp.all_managers
  });
};

export const updateNote = (note, updates = {}) => async (dispatch, getState) => {
  try {
    const newNote = await updateNoteAPI(note, updates);
    return dispatch({
      type: UPDATE_NOTE_SUCCESS,
      note: newNote
    });
  } catch (error) {
    console.error(error);
    return dispatch({
      type: UPDATE_NOTE_FAILURE
    });
  }
};

export const createComment = (params = {}) => async (dispatch, getState) => {
  const comment = await createCommentAPI(params);
  return dispatch({
    type: CREATE_COMMENT_SUCCESS,
    comment
  });
};

export const updateComment = (comment, params = {}) => async (dispatch, getState) => {
  const updatedComment = await updateCommentAPI(comment, params);

  return dispatch({
    type: UPDATE_COMMENT_SUCCESS,
    comment: updatedComment
  });
};

export const deleteComment = comment => async (dispatch, getState) => {
  dispatch({
    type: DELETE_COMMENT_REQUEST,
    comment
  });

  try {
    await deleteCommentAPI(comment);
    return dispatch({ type: DELETE_COMMENT_SUCCESS, comment });
  } catch (e) {
    return dispatch({ type: DELETE_COMMENT_FAILURE, comment, error: e });
  }
};
