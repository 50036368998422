import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import './MlTrainingControls.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-regular-svg-icons';

const MlTrainingControls = props => {
  const { allBrands, brand, specificBrand } = props;
  const allBrandOptions = allBrands.map(brand => ({ value: brand, label: brand }));

  const setBrand = brand => {
    props.setBrand(brand.value);
    props.setSpecificBrand(null);
  };

  const setSpecificBrand = brand => {
    props.setSpecificBrand(brand.value);
  };

  const swapOptions = () => {
    props.setBrand(specificBrand);
    props.setSpecificBrand(brand);
  };

  return (
    <div className='ml-training-controls-outer'>
      <div className='training-controls'>
        <Select
          options={allBrandOptions}
          onChange={setBrand}
          value={allBrandOptions.find(b => b.value === brand)}
          className='select'
          placeholder='Select Brand...'
        />
        <div className='arrows' onClick={swapOptions}>
          <FontAwesomeIcon className='arrow-icon' icon={faArrowLeft} />
          <FontAwesomeIcon className='arrow-icon' icon={faArrowRight} />
        </div>
        <Select
          options={allBrandOptions}
          onChange={setSpecificBrand}
          value={allBrandOptions.find(b => b.value === specificBrand)}
          className='select'
          placeholder='Select Similar Brand (optional)'
        />
      </div>
    </div>
  );
};

MlTrainingControls.propTypes = {
  allBrands: PropTypes.array.isRequired,
  brand: PropTypes.string,
  specificBrand: PropTypes.string,

  setBrand: PropTypes.func.isRequired,
  setSpecificBrand: PropTypes.func.isRequired
};

export default MlTrainingControls;
