import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './ConciergeModalMainContainer.scss';
import { downloadImageUsingElementId, downloadToCsv } from '../../../helpers/helpers';

import * as queries from '../../../api/talent_report';

import { ConciergeSummary, ConciergeSummaryConfig } from './Sections/ConciergeSummary';
import { ConciergeTopConvertingDomains, ConciergeTopConvertingDomainsConfig } from './Sections/ConciergeTopConvertingDomains';
import { ConciergeTopRetailers, ConciergeTopRetialersConfig } from './Sections/ConciergeTopRetailers';
import { ConciergeTopPins, ConciergeTopPinsConfig } from './Sections/ConciergeTopPins';
import { ConciergeTopCollections, ConciergeTopCollectionsConfig } from './Sections/ConciergeTopCollections';
import { ConciergeClicks, ConciergeClicksConfig } from './Sections/ConciergeClicks';
import { ConciergeOrdersAndVolume, ConciergeOrdersAndVolumeConfig } from './Sections/ConciergeOrdersAndVolume';
import { ConciergePins, ConciergePinsConfig } from './Sections/ConciergePins';
import { ConciergeGifting, ConciergeGiftingConfig } from './Sections/ConciergeGifting';
import { ConciergeCustomCodes, ConciergeCustomCodesConfig } from './Sections/ConciergeCustomCodes';
import { ConciergeChats, ConciergeChatsConfig } from './Sections/ConciergeChats';
import { ConciergeChatBrands, ConciergeChatBrandsConfig } from './Sections/ConciergeChatBrands';
import { ConciergeScoreHistory, ConciergeScoreHistoryConfig } from './Sections/ConciergeScoreHistory';
import { ConciergeReferralHistory, ConciergeReferralHistoryConfig } from './Sections/ConciergeReferralHistory';

const ConciergeModalTalentSections = props => {
  const { talent, timePeriod, updateExpectedScore, updateSupportLevel } = props;
  const { id: User_id } = talent;
  const { groupingMethod, startDate, endDate } = timePeriod;

  // summary data
  const [talentSummary, setTalentSummary] = useState();
  const [topConvertingDomains, setTopConvertingDomains] = useState();
  const [topRetailers, setTopRetailers] = useState();
  const [topPins, setTopPins] = useState();
  const [topCollections, setTopCollections] = useState();
  const [conciergeCustomCodes, setConciergeCustomCodes] = useState();
  const [conciergeReferralHistory, setConciergeReferralHistory] = useState([]);

  // time based data
  const [conciergeClicks, setConciegeClicks] = useState();
  const [conciergeOrdersAndVolume, setConciergeOrdersAndVolume] = useState();
  const [conciergePins, setConciergePins] = useState();
  const [conciergeGifting, setConciergeGifting] = useState();
  const [conciergeChats, setConciergeChats] = useState();
  const [conciergeChatBrands, setConciergeChatBrands] = useState();

  // other variables
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    // reset data
    setTalentSummary();
    setTopConvertingDomains();
    setTopRetailers();
    setTopPins();
    setTopCollections();
    setConciergeCustomCodes();
    setConciegeClicks();
    setConciergeOrdersAndVolume();
    setConciergePins();
    setConciergeGifting();
    setConciergeChats();
    setConciergeChatBrands();
    setConciergeReferralHistory();

    // fetch new data
    const queryData = { User_id, groupingMethod, startDate, endDate };

    queries
      .getTalentReportUsers(queryData)
      .then(data => setTalentSummary(data?.users[0]))
      .catch(err => console.error(err));

    queries
      .getTalentTopConvertingDomains(queryData)
      .then(data => setTopConvertingDomains(data.results))
      .catch(err => console.error(err));

    queries
      .getTalentTopRetailers(queryData)
      .then(data => setTopRetailers(data.results))
      .catch(err => console.error(err));

    queries
      .getTalentTopPins(queryData)
      .then(data => setTopPins(data.results))
      .catch(err => console.error(err));

    queries
      .getTalentTopCollections(queryData)
      .then(data => setTopCollections(data.results))
      .catch(err => console.error(err));

    queries
      .getTalentReportClicks(queryData)
      .then(data => setConciegeClicks(data.results))
      .catch(err => console.error(err));

    queries
      .getTalentReportVolume(queryData)
      .then(data => setConciergeOrdersAndVolume(data.results))
      .catch(err => console.error(err));

    queries
      .getTalentReportPins(queryData)
      .then(data => setConciergePins(data.results))
      .catch(err => console.error(err));

    queries
      .getTalentReportGifting(queryData)
      .then(data => setConciergeGifting(data.results))
      .catch(err => console.error(err));

    queries
      .getTalentReportCustomCodes(queryData)
      .then(data => setConciergeCustomCodes({ allCustomCodes: data.all_custom_codes, results: data.results }))
      .catch(err => console.error(err));

    queries
      .getTalentReportChatsAggregated(queryData)
      .then(data => setConciergeChats(data.results))
      .catch(err => console.error(err));

    queries
      .getTalentReportChatsPerBrand(queryData)
      .then(data => setConciergeChatBrands(data.results))
      .catch(err => console.error(err));

    queries
      .getTalentReferralHistory(queryData)
      .then(data => setConciergeReferralHistory(data.results))
      .catch(err => console.error(err));
  }, [User_id, groupingMethod, startDate, endDate]);

  /**
   *
   * @param {Object} params
   * @param {Array} params.rows - array of objects with label and values - in this case download to csv
   * @param {String} params.fileName - name of file to download
   * @param {String} params.graph - string to the id of an element on the page to download picture of
   */
  const download = async params => {
    const { rows, fileName, graph } = params;

    setDownloading(true);

    if (fileName) {
      const csvRows = rows.map(row => {
        const { label, values } = row;
        const data = { label };
        values.forEach(value => (data[value.label] = value.value));
        return data;
      });

      await downloadToCsv(csvRows, fileName);
    } else if (graph) {
      await downloadImageUsingElementId(graph);
    }
    setDownloading(false);
  };

  return (
    <div className='concierge-modal-talent-sections'>
      <ConciergeSummary
        title={ConciergeSummaryConfig.title}
        talent={talent}
        talentSummary={talentSummary}
        updateExpectedScore={updateExpectedScore}
        updateSupportLevel={updateSupportLevel}
      />
      <ConciergeScoreHistory title={ConciergeScoreHistoryConfig.title} talent={talent} />
      <ConciergeClicks
        title={ConciergeClicksConfig.title}
        conciergeClicks={conciergeClicks}
        download={download}
        downloading={downloading}
        groupingMethod={groupingMethod}
      />
      <ConciergeOrdersAndVolume
        title={ConciergeOrdersAndVolumeConfig.title}
        conciergeVolume={conciergeOrdersAndVolume}
        download={download}
        downloading={downloading}
        groupingMethod={groupingMethod}
      />
      <ConciergePins
        title={ConciergePinsConfig.title}
        conciergePins={conciergePins}
        download={download}
        downloading={downloading}
        groupingMethod={groupingMethod}
      />
      <ConciergeGifting
        title={ConciergeGiftingConfig.title}
        conciergeGifting={conciergeGifting}
        download={download}
        downloading={downloading}
        groupingMethod={groupingMethod}
      />
      <ConciergeCustomCodes
        title={ConciergeCustomCodesConfig.title}
        conciergeCustomCodes={conciergeCustomCodes}
        download={download}
        downloading={downloading}
        groupingMethod={groupingMethod}
      />
      <ConciergeChats
        title={ConciergeChatsConfig.title}
        conciergeChats={conciergeChats}
        download={download}
        downloading={downloading}
        groupingMethod={groupingMethod}
      />
      <ConciergeChatBrands
        title={ConciergeChatBrandsConfig.title}
        conciergeChatBrands={conciergeChatBrands}
        download={download}
        downloading={downloading}
      />
      <ConciergeTopConvertingDomains
        title={ConciergeTopConvertingDomainsConfig.title}
        topConvertingDomains={topConvertingDomains}
        download={download}
        downloading={downloading}
      />
      <ConciergeTopRetailers title={ConciergeTopRetialersConfig.title} topRetailers={topRetailers} download={download} downloading={downloading} />
      <ConciergeTopPins
        title={ConciergeTopPinsConfig.title}
        topPins={topPins}
        download={download}
        downloading={downloading}
        groupingMethod={groupingMethod}
      />
      <ConciergeTopCollections
        title={ConciergeTopCollectionsConfig.title}
        topCollections={topCollections}
        download={download}
        downloading={downloading}
        groupingMethod={groupingMethod}
      />
      <ConciergeReferralHistory
        title={ConciergeReferralHistoryConfig.title}
        conciergeReferralHistory={conciergeReferralHistory}
        download={download}
        downloading={downloading}
        groupingMethod={groupingMethod}
      />
    </div>
  );
};

ConciergeModalTalentSections.propTypes = {
  talent: PropTypes.object.isRequired,
  timePeriod: PropTypes.object.isRequired,
  updateSupportLevel: PropTypes.func.isRequired,
  updateExpectedScore: PropTypes.func.isRequired
};

export default ConciergeModalTalentSections;

export const allConciergeTalentSectionTitles = [
  ConciergeSummaryConfig.title,
  ConciergeClicksConfig.title,
  ConciergeOrdersAndVolumeConfig.title,
  ConciergePinsConfig.title,
  ConciergeGiftingConfig.title,
  ConciergeCustomCodesConfig.title,
  ConciergeChatsConfig.title,
  ConciergeChatBrandsConfig.title,
  ConciergeTopConvertingDomainsConfig.title,
  ConciergeTopRetialersConfig.title,
  ConciergeTopPinsConfig.title,
  ConciergeTopCollectionsConfig.title,
  ConciergeReferralHistoryConfig.title
];
