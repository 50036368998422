import APIClient from './index';
import _ from 'lodash';

export const getContracts = (params = {}) => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/Contracts/all?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const updateContract = (contract, data) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/Contracts/${contract.id}`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const updateContractTask = (task, data) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/ContractTasks/${task.id}`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const getContractTaskTemplates = () => {
  return new Promise((resolve, reject) => {
    APIClient.get(`ContractTaskTemplates`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const addContractTaskTemplate = template => {
  return new Promise((resolve, reject) => {
    APIClient.post(`ContractTaskTemplates`, template)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const updateContractTaskTemplate = (template, data) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`ContractTaskTemplates/${template.id}`, data)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const deleteContractTaskTemplate = template => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`ContractTaskTemplates/${template.id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const addContractSale = sale => {
  return new Promise((resolve, reject) => {
    APIClient.post(`ContractSales`, sale)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const deleteContractSale = sale => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`ContractSales/${sale.id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const quickPayContract = contract => {
  return new Promise((resolve, reject) => {
    APIClient.post(`Contracts/quick_pay`, { Contract_id: contract.id })
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};
