import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './QueueTicket.scss';

import { getPrettyNumber } from '../../../helpers/formatting';

class QueueTicket extends Component {
  static propTypes = {
    selectTicket: PropTypes.func.isRequired,
    isSelected: PropTypes.bool.isRequired,
    isVariant: PropTypes.bool.isRequired,
    ticket: PropTypes.object.isRequired,
    orphanTicket: PropTypes.func.isRequired,
    kickBackToWorkers: PropTypes.func
  };

  getStats = () => {
    const { username, orders, volume, clicks } = this.props.ticket || {};

    let stats = [];
    if (orders) stats.push(`${getPrettyNumber(orders)} orders`);
    if (volume) stats.push(`$${getPrettyNumber(volume)}`);
    if (clicks) stats.push(`${getPrettyNumber(clicks)} clicks`);
    if (username) stats.push(username);
    return stats;
  };

  render() {
    const { ticket, selectTicket, isSelected, isVariant } = this.props;
    const { image, rawBrand, rawTitle, title, link, retailer, url, adminMatchNotes } = ticket || {};
    const stats = this.getStats();
    return (
      <div className='queue-ticket-outer-container'>
        <div onClick={selectTicket} className={classnames('queue-ticket-container', { selected: isSelected })}>
          <div className='ticket-header'>Assign to product</div>
          <div className='queue-ticket'>
            <img alt='Ticket Product' src={image} />
            <div className='product-data'>
              {!!stats.length && <div className='stats'>{stats.join(' • ')}</div>}
              <div className='title'>{isVariant ? rawTitle : title}</div>
              {isVariant && <div className='brand'>{rawBrand}</div>}
              {isVariant ? (
                <a href={url} target='_blank' rel='noopener noreferrer' className='retailer'>
                  {retailer}
                </a>
              ) : (
                <a href={link} target='_blank' rel='noopener noreferrer' className='link'>
                  {link && link.slice(0, 32)}...
                </a>
              )}
              {isVariant && adminMatchNotes && <div className='admin-notes'>{adminMatchNotes}</div>}
            </div>
          </div>
          {isVariant && (
            <div
              onClick={e => {
                e.stopPropagation();
                this.props.kickBackToWorkers();
              }}
              className='back-to-basic-btn'
            >
              Back to workers
            </div>
          )}
          <div onClick={this.props.orphanTicket} className='orphan-btn'>
            {isVariant ? "Don't show in catalog" : 'Outside of Catalog'}
          </div>
        </div>
      </div>
    );
  }
}

export default QueueTicket;
