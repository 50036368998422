import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import { getTierDisplay } from '../../helpers/tier_helpers';
import moment from 'moment';

import './ConciergeUnsetTalent.scss';

const ConciergeUnsetTalent = props => {
  const { unsetUser } = props;
  const { User_id, username, name, score, tier, image, email, createdAt } = unsetUser;

  const setActiveUser = () => props.setActiveTalentId({ id: User_id });

  return (
    <div className='unset-user-outer'>
      <div className='unset-user-image'>
        {image ? (
          <img className='unset-user-image' src={image} alt='profile_picture' />
        ) : (
          <div className='unset-user-image-replacement'>{name.split(' ')[0][0]}</div>
        )}
      </div>
      <div className='unset-user-info'>
        <div className='name'>
          <a className='shop-link' href={`https://shopmy.us/${username}`} target='_blank' rel='noreferrer'>
            {name}
            <FontAwesomeIcon icon={faExternalLink} className='href-icon' />
          </a>
        </div>
        <div className='tier'>
          {getTierDisplay(tier)} ({score})
        </div>
        <p>Email: {email}</p>
        <p>ID: {User_id}</p>
        <p className='link' onClick={setActiveUser}>
          View Analytics
        </p>
        <p className='joined'>Since {moment(createdAt).format('MMM DD, YYYY')}</p>
      </div>
    </div>
  );
};

ConciergeUnsetTalent.propTypes = {
  unsetUser: PropTypes.object.isRequired,
  setActiveTalentId: PropTypes.func.isRequired
};

export default ConciergeUnsetTalent;
