import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import './BrandReports.scss';

import { getBrands } from '../../api/brands';
import { getHierarchy } from '../../api/catalog';

import { getBrandNote, updateNote, createComment, updateComment, deleteComment } from '../../actions/notesActions';

import BrandReport from './BrandReport';

const BrandReports = props => {
  const { notes, getBrandNote, updateNote, createComment, updateComment, deleteComment } = props;

  const [allBrands, setAllBrands] = useState([]);
  const [allCompetitorBrands, setAllCompetitorBrands] = useState([]);

  useEffect(() => {
    syncBrands();
  }, []);

  const syncBrands = async () => {
    const brands = await getBrands();
    const hierarchy = await getHierarchy();
    setAllBrands(_.orderBy(brands, b => b.name.toLowerCase()));
    setAllCompetitorBrands(hierarchy.brands);
  };

  return (
    <div className='brand-reports-outer-container'>
      <div className='brand-reports-inner-container'>
        {!!allBrands.length && (
          <BrandReport
            allBrands={allBrands}
            allCompetitorBrands={allCompetitorBrands}
            getBrandNote={getBrandNote}
            notes={notes}
            updateNote={updateNote}
            createComment={createComment}
            updateComment={updateComment}
            deleteComment={deleteComment}
          />
        )}
      </div>
    </div>
  );
};

BrandReports.propTypes = {
  getBrandNote: PropTypes.func.isRequired,
  updateNote: PropTypes.func.isRequired,
  createComment: PropTypes.func.isRequired,
  updateComment: PropTypes.func.isRequired,
  deleteComment: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { notes } = state;
  return { notes };
};

export default connect(mapStateToProps, {
  getBrandNote,
  updateNote,
  createComment,
  updateComment,
  deleteComment
})(BrandReports);
