import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { getAccountManagers } from '../../api/admin';
import { getLookbookOrdersRequiringAction } from '../../api/lookbookorders';
import cogoToast from 'cogo-toast';

let lookbookOrderInterval;

const LookbookOrderData = props => {
  const { setAccountManagers, setLookbookOrders, setLoading } = props;

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setLoading(true);
        const accountManagersPromise = getAccountManagers('lookbookorders');
        const lookbookOrdersPromise = getLookbookOrdersRequiringAction();

        const [accountManagers, lookbookOrders] = await Promise.all([accountManagersPromise, lookbookOrdersPromise]);
        console.log(lookbookOrders);

        setAccountManagers(accountManagers.users);
        setLookbookOrders(lookbookOrders.ordersRequiringAction);
        setLoading(false);
      } catch (e) {
        cogoToast.error('Error fetching data. Reload the page to try again.');
      }
    };

    fetchInitialData();

    // Fetch data every minute to keep it up to date
    lookbookOrderInterval = setInterval(() => {
      getLookbookOrdersRequiringAction()
        .then(lookbookOrders => setLookbookOrders(lookbookOrders.ordersRequiringAction))
        .catch(() => cogoToast.error('Error fetching lookbook orders.'))
        .finally(() => setLoading(false));
    }, 60000);

    return () => clearInterval(lookbookOrderInterval);
  }, [setAccountManagers, setLookbookOrders, setLoading]);

  return null;
};

LookbookOrderData.propTypes = {
  setLoading: PropTypes.func.isRequired,
  setAccountManagers: PropTypes.func.isRequired,
  setLookbookOrders: PropTypes.func.isRequired
};

export default LookbookOrderData;
