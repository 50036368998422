import React from 'react';
import { extent } from 'd3';
import { useEffect } from 'react';
import { useState } from 'react';
import cn from 'classnames';

/**
 * @typedef {Object} BarGraphDataObject
 * @property {string} key - string to make react warning go away
 * @property {string=} label - Unique value for each bar that identifies it, will act as label on x axis
 * @property {number} value - value that scales on the y axis
 * @property {string=} display_value - what will be displayed for the y axis
 *
 *
 * @param {Object} BarGraphProps
 * @param {BarGraphDataObject[]} BarGraphProps.data
 * @param {string} BarGraphProps.title - The stirng that will diaplay abovve each graph
 * @param {boolean} BarGraphProps.fullWidth - If true, the graph will span 100% of the parent container
 * @param {boolean} BarGraphProps.tinyColumns - If true, the graph will space the columns out less
 * @param {boolean} BarGraphProps.hideLabels - If true, the graph will not display the label on the x axis
 * @param {boolean} BarGraphProps.hideHeader - If true, will not display the header
 * @returns
 */
const BarGraph = props => {
  const { data, title, key, fullWidth, tinyColumns, hideLabels, hideHeader } = props;
  const [range, setRange] = useState({ min: 0, max: 100 });

  useEffect(() => {
    const [, max] = extent(data.map(d => d.value));

    // important because if max is 0, graphs will break
    setRange({ min: 0, max: max || 1 });
  }, [data]);

  return (
    <div className={cn('bar-graph-outer brand-report-element', { shortened: !fullWidth })} id={key || `${title}_bar-graph`}>
      {!hideHeader && (
        <div className='bar-graph-header'>
          <div className='line' />
          <p>{title}</p>
          <div className='line' />
        </div>
      )}

      {/* <div className='bar-graph-bars'>{data.map(bar => {})}</div> */}
      {data && (
        <div className={cn('bar-graph-bars', { tinyColumns })} style={{ gridTemplateColumns: `repeat(${data.length}, 1fr)` }}>
          <>
            {data.map(({ key, value, display_value, label }) => (
              <div className='bar-graph-bar-holder' key={key}>
                <div className='bar-graph-bar' style={{ height: `${(value / range.max) * 80 + 15}%` }}>
                  <p>{display_value ? display_value : value?.toFixed(0)}</p>
                  {/* <h4 className={index % 2 === 0 ? 'high-bar' : 'low-bar'}>{label}</h4> */}
                  {!hideLabels && <div className='label'>{label}</div>}
                </div>
              </div>
            ))}
          </>
        </div>
      )}
    </div>
  );
};

export default BarGraph;
