import axios from 'axios';

// Need to create another axios instance so we don't interfere with main API
const mlAxios = axios.create({
  baseURL: 'https://api.ml.shopmy.us',
  headers: {
    'Content-Type': 'application/json'
  }
});

const APIClient = () => {
  return mlAxios;
};

export default APIClient();
