import React from 'react';
import cn from 'classnames';
import moment from 'moment';
import Select from 'react-select';
import './BrandReportControls.scss';
import { allPageSectionTitles } from './BrandReportSections';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PropTypes from 'prop-types';

export const BrandReportControls = props => {
  const { timeInterval, setTimeInterval, allBrands } = props;
  const { startDate, endDate, groupingMethod } = timeInterval;

  const getBrandOptions = () => {
    if (!allBrands) return [];

    const options = allBrands.map(brand => {
      return { label: brand.name, value: brand.id, brand };
    });
    return options;
  };

  const convertDate = date => moment(date).format('YYYY-MM-DD');

  const updateStartDate = date => {
    console.log({ date, type: typeof date });
    setTimeInterval({ ...timeInterval, startDate: convertDate(date) });
  };
  const updateEndDate = date => setTimeInterval({ ...timeInterval, endDate: convertDate(date) });
  const updateGroupingMethod = groupingMethod => setTimeInterval({ ...timeInterval, groupingMethod });
  const updateBrand = ({ brand }) => {
    if (!brand?.id) return;
    setTimeInterval({ ...timeInterval, brand, Brand_id: brand.id });
  };

  return (
    <div className='brand-report-controls'>
      <div className='brand-report-controls-inner'>
        <div className='control-section'>
          <div className='control-section-header'>Brand Selection</div>

          <div className='control-section-child'>
            <Select
              className='select'
              placeholder='Brand'
              value={getBrandOptions().find(brand => brand.value === parseInt(timeInterval.Brand_id))}
              onChange={updateBrand}
              options={getBrandOptions()}
            />
          </div>
        </div>
        <div className='control-section'>
          <div className='control-section-header'>time interval</div>

          <div className='control-section-child'>
            <div className='radio-btns'>
              <div className={cn('radio-btn', { active: groupingMethod === 'week' })} onClick={() => updateGroupingMethod('week')}>
                week
              </div>
              <div className={cn('radio-btn', { active: groupingMethod === 'month' })} onClick={() => updateGroupingMethod('month')}>
                month
              </div>
              <div className={cn('radio-btn', { active: timeInterval.groupingMethod === 'quarter' })} onClick={() => updateGroupingMethod('quarter')}>
                quarter
              </div>
            </div>
          </div>
        </div>

        <div className='control-section'>
          <div className='control-section-header'>start date</div>
          <div className='control-section-child'>
            <DatePicker
              selected={moment(startDate).toDate() || null}
              onChange={updateStartDate}
              wrapperClassName='date-picker-wrapper'
              className='date-picker'
              dateFormat={'MMM dd, yyyy'}
            />
          </div>
        </div>

        <div className='control-section'>
          <div className='control-section-header'>end date</div>
          <div className='control-section-child'>
            <DatePicker
              selected={moment(endDate).toDate() || null}
              onChange={updateEndDate}
              wrapperClassName='date-picker-wrapper'
              className='date-picker'
              dateFormat={'MMM dd, yyyy'}
            />
          </div>
        </div>

        <div className='control-section'>
          <div className='control-section-header'>page sections</div>

          <div className='control-section-child'>
            {allPageSectionTitles().map(pageSection => (
              <div
                className='clickable-page-section'
                key={`clickable${pageSection}`}
                onClick={() => document.getElementById(pageSection).scrollIntoView(true)}
              >
                {pageSection}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

BrandReportControls.propTypes = {
  timeInterval: PropTypes.object.isRequired,
  setTimeInterval: PropTypes.func.isRequired,
  allBrands: PropTypes.array.isRequired
};
