import React from 'react';
import PropTypes from 'prop-types';
import './MlTrainingSimilarBrand.scss';

const MlTrainingSimilarBrand = props => {
  const { brand, images, feedback = {} } = props.brand;
  const { score, FeedbackUser_name } = feedback;

  const setFeedback = score => props.setFeedback(brand, score);

  return (
    <div className='ml-training-similar-brand-outer'>
      <div className='main-container'>
        <div className='brand-name'>{brand}</div>
        {score ? (
          <div className='feedback-container'>
            {FeedbackUser_name}
            <span className='bullet'>•</span>
            {score}
          </div>
        ) : (
          <div className='rating-container'>
            <div className='rating' onClick={() => setFeedback(1)}>
              1
            </div>
            <div className='rating' onClick={() => setFeedback(2)}>
              2
            </div>
            <div className='rating' onClick={() => setFeedback(3)}>
              3
            </div>
            <div className='rating' onClick={() => setFeedback(4)}>
              4
            </div>
            <div className='rating' onClick={() => setFeedback(5)}>
              5
            </div>
          </div>
        )}
      </div>
      <div className='product-image-container'>
        {images
          .split(',')
          .slice(0, 5)
          .map((image, index) => (
            <img className='product-image' src={image} alt={image} key={`${brand}-product-image-${index}`} loading='lazy' />
          ))}
      </div>
    </div>
  );
};

MlTrainingSimilarBrand.propTypes = {
  brand: PropTypes.object.isRequired,
  setFeedback: PropTypes.func.isRequired
};

export default MlTrainingSimilarBrand;
