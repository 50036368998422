import React, { useState } from 'react';
import Loader from '../../General/Loader';

import { Table } from '../GraphTypes/Table';
import { getBrandReportHeaderLabel } from '../../../helpers/brand_helpers';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { faDownload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BrandTopProductsClicks = props => {
  const { title, brandTopProducts, downloading, groupingMethod } = props;
  const { allResults: products, dtcResults: dtcProducts, nonDtcResults: nonDtcProducts } = brandTopProducts || {};
  const success = !!products;

  const [tab, setTab] = useState('all');
  const download = async () => await props.download({ rows: make_table_rows(tab), fileName: `brand_top_product_clicks_${tab}` });

  const make_table_rows = tab => {
    if (!products || !products[0]) return;

    const uniqueMentions = new Map();
    let correctProducts;

    if (tab === 'all') correctProducts = products;
    else if (tab === 'dtc') correctProducts = dtcProducts;
    else correctProducts = nonDtcProducts;

    for (const { results: apiResults } of correctProducts) {
      for (const product of apiResults) uniqueMentions.set(product.Product_id, product);
    }

    const fetchProductFromGroup = (group, Product_id) => group.find(product => product.Product_id === Product_id);

    const finalResults = [];
    for (const mention of uniqueMentions.values()) {
      const { title, Product_id } = mention;
      const data = {
        key: `${Product_id}-products`,
        label: title,
        values: []
      };
      let oneIsNonZero = false;

      for (const group of correctProducts) {
        const { week, month, quarter, results: apiResults } = group;
        const productFromGroup = fetchProductFromGroup(apiResults, Product_id) || {};
        const clicks = parseInt(productFromGroup.clicks) || 0;
        if (clicks > 0) oneIsNonZero = true;
        data.values.push({ label: getBrandReportHeaderLabel({ week, month, quarter, groupingMethod }), value: clicks });
      }

      oneIsNonZero && finalResults.push(data);
    }

    return finalResults.sort((a, b) => {
      const aTotal = a.values.reduce((acc, { value }) => acc + parseInt(value), 0);
      const bTotal = b.values.reduce((acc, { value }) => acc + parseInt(value), 0);
      return bTotal - aTotal;
    });
  };

  return (
    <>
      <div className='brand-card' id={title}>
        <div className='padding'>
          {!brandTopProducts ? (
            <Loader />
          ) : (
            <>
              {success && (
                <>
                  <div className='header'>
                    <div className='description'>
                      <div className='title'>{title}</div>
                      <div className='sub-title'>
                        The top products group all the analytics for each pin and shows how many clicks each drove month over month. It is queryable
                        on all links, direct links, and non-direct links.
                      </div>
                    </div>
                    <div className='brand-card-controls'>
                      <div className='radio-btns'>
                        <div className={cn('radio-btn', { active: tab === 'dtc' })} onClick={() => setTab('dtc')}>
                          DTC
                        </div>
                        <div className={cn('radio-btn', { active: tab === 'non_dtc' })} onClick={() => setTab('non_dtc')}>
                          NON-DTC
                        </div>
                        <div className={cn('radio-btn', { active: tab === 'all' })} onClick={() => setTab('all')}>
                          All
                        </div>
                      </div>
                      <div className='rounded-button' onClick={() => download()}>
                        {downloading ? (
                          <Loader />
                        ) : (
                          <>
                            <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                            Download
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  {tab === 'dtc' && <Table title='Brand Top Products' data={make_table_rows('dtc')} />}
                  {tab === 'non_dtc' && <Table title='Brand Top Products' data={make_table_rows('non_dtc')} />}
                  {tab === 'all' && <Table title='Brand Top Products' data={make_table_rows('all')} />}
                </>
              )}

              {!success && (
                <>
                  <div className='error-centered'>
                    <div className='title'>Error Fetching {title}</div>
                    <div className='sub-title'>Could not fetch results</div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

BrandTopProductsClicks.propTypes = {
  title: PropTypes.string.isRequired,
  brandTopProducts: PropTypes.object,
  groupingMethod: PropTypes.string.isRequired,

  download: PropTypes.func.isRequired,
  downloading: PropTypes.bool.isRequired
};

const brandTopProductsClicksConfig = {
  title: 'Top Product Clicks'
};

export { BrandTopProductsClicks, brandTopProductsClicksConfig };
