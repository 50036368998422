import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './WaitlistPriorityTagsModal.scss';

import { createPriorityWaitlistTag, deletePriorityWaitlistTag } from '../../api/waitlist';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash, faTimes } from '@fortawesome/pro-regular-svg-icons';
import cogoToast from 'cogo-toast';

import Modal from '../General/Modal';

const WaitlistPriorityTagsModal = props => {
  const { waitlistPriorityTags, setWaitlistPriorityTags } = props;
  const [newTagTitle, setNewTagTitle] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const createTag = () => {
    if (isSaving) return;
    else if (!newTagTitle) return cogoToast.error('Tag title cannot be empty');
    setIsSaving(true);

    createPriorityWaitlistTag({ tag: newTagTitle })
      .then(tag => {
        setWaitlistPriorityTags([...waitlistPriorityTags, tag]);
        setNewTagTitle('');
      })
      .catch(() => cogoToast.error('Error creating tag'))
      .finally(() => setIsSaving(false));
  };

  const deleteTag = waitlistPriorityTag => {
    if (isSaving) return;
    const copyOfTags = [...waitlistPriorityTags];
    setWaitlistPriorityTags(copyOfTags.filter(t => t.id !== waitlistPriorityTag.id));
    setIsSaving(true);

    deletePriorityWaitlistTag(waitlistPriorityTag)
      .then(() => cogoToast.success(`"${waitlistPriorityTag.tag}" tag deleted`))
      .catch(() => {
        cogoToast.error(`Error deleting "${waitlistPriorityTag.tag}" tag`);
        setWaitlistPriorityTags(copyOfTags);
      })
      .finally(() => setIsSaving(false));
  };

  const handleKeyPress = e => {
    e.stopPropagation();

    if (e.key === 'Enter') createTag();
    else if (e.key === 'Escape') setNewTagTitle('');
  };

  return (
    <Modal visible={true} close={props.close}>
      <div className='waitlist-priority-tag-modal-outer'>
        <div className='close' onClick={props.close}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
        <div className='waitlist-priority-tag-modal-title'>Edit Tags</div>
        <div className='waitlist-priority-tag-modal-subtitle'>
          Priority tags are used to quickly identify which talent should be looked at first. The tags are not case sensitive and will be automatically
          lowercased.
        </div>

        <div className='waitlist-priority-tag-modal-tags'>
          {waitlistPriorityTags.map(waitlistPriorityTag => (
            <div key={waitlistPriorityTag.id} className='waitlist-priority-tag-modal-tag'>
              <div>
                <div>{waitlistPriorityTag.tag}</div>
                <div className='waitlist-priority-tag-modal-tab-actions'>
                  <FontAwesomeIcon icon={faTrash} onClick={() => deleteTag(waitlistPriorityTag)} />
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className='waitlist-priority-tag-modal-add-container'>
          <div className='waitlist-priority-tag-modal-tag' id='add-priority-tag'>
            <div>
              <FontAwesomeIcon icon={faPlus} onClick={createTag} />
              <input
                type='text'
                placeholder='Esthetician...'
                value={newTagTitle}
                onChange={e => {
                  let tag = e.target.value;
                  tag = tag.toLowerCase();
                  setNewTagTitle(tag);
                }}
                onKeyDown={handleKeyPress}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

WaitlistPriorityTagsModal.propTypes = {
  waitlistPriorityTags: PropTypes.array.isRequired,
  setWaitlistPriorityTags: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired
};

export default WaitlistPriorityTagsModal;
