import APIClient from './index';
// import _ from 'lodash';

export const fetch_all_growth_scraper_tags = async () => {
  try {
    const url = 'GrowthScraper/tags';
    const response = await APIClient.get(url);

    if (!response || response.data.error) throw response.data;

    return response.data.tags;
  } catch (e) {
    console.log(e);
    return [];
  }
};

/**
 * @param {Object} data - the necessary informaton to create a new tag
 * @param {string} data.id - unique id of a tag if it is supposed to be updated
 * @param {string} data.tag - tag that will be added to the database
 * @param {boolean} data.forceRun - whether or not to prioritize the new tag
 * @param {boolean} data.persistCampaignid - id of persist campaign assigned to this tag
 */
export const create_new_growth_scraper_tag = async data => {
  try {
    const url = 'GrowthScraper/tags/upsert';
    const response = await APIClient.post(url, data);

    if (!response || response.data.error) throw response.data;

    return { success: true, tag: response.data.tag };
  } catch (e) {
    console.log(e);
    return { success: false, tag: {} };
  }
};

/**
 * @param {Object} data - the necessary informaton to create a new tag
 * @param {Array} data.ids - the array of tag ids to update
 * @param {Object} data.updates - the updates to bulk apply to all tag ids
 */
export const bulk_update_growth_scraper_tags = async data => {
  try {
    const url = 'GrowthScraper/tags/bulk-update';
    const response = await APIClient.post(url, data);

    if (!response || response.data.error) throw response.data;

    return { success: true };
  } catch (e) {
    console.log(e);
    return { success: false };
  }
};

/**
 * @param {Object} data
 * @param {string} data.tag_id - the unique id of the tag
 */
export const delete_existing_growth_scraper_tag = async data => {
  try {
    const url = 'GrowthScraper/tags/delete';
    const response = await APIClient.post(url, data);

    if (!response || response.data.error) throw response.data;

    return { success: true };
  } catch (e) {
    console.log(e);
    return { success: false };
  }
};
