import React from 'react';
import PropTypes from 'prop-types';
import './ConciergeScoreHistory.scss';

import BarGraph from '../../../BrandReports/GraphTypes/BarGraph';
import Loader from '../../../General/Loader';

const ConciergeScoreHistory = props => {
  const { talent, title } = props;
  const success = !!talent?.score_history;
  const { score_history = '' } = talent || {};

  return (
    <div className='talent-report-card'>
      {!talent ? (
        <Loader />
      ) : (
        <>
          <div className='header'>
            <div className='description'>
              <div className='title'>{title}</div>
              <div className='sub-title'>The score history shows the past 60 days of a user's score.</div>
            </div>
          </div>

          {!success && (
            <>
              <div className='error-centered'>
                <div className='title'>{title}</div>
                <div className='sub-title'>No results.</div>
              </div>
            </>
          )}
          {success && (
            <BarGraph
              title='Clicks'
              fullWidth={true}
              tinyColumns={true}
              hideLabels={true}
              hideHeader={true}
              data={score_history.split(',').map((score, idx) => {
                const usableScore = parseInt(score);

                const data = {
                  key: `${usableScore}-${idx}`,
                  value: usableScore,
                  label: usableScore
                };
                return data;
              })}
            />
          )}
        </>
      )}
    </div>
  );
};

ConciergeScoreHistory.propTypes = {
  talent: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired
};

const ConciergeScoreHistoryConfig = {
  title: 'Score History'
};

export { ConciergeScoreHistory, ConciergeScoreHistoryConfig };
