import React, { useState } from 'react';
import cn from 'classnames';
import cogoToast from 'cogo-toast';
import './Greenhouse.scss';

import { updateJobOpening as updateJobOpeningAPI } from '../../api/greenhouse';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync, faExternalLink } from '@fortawesome/pro-light-svg-icons';
import GreenhouseData from './GreenhouseData';
import GreenhouseSelectedJob from './GreenhouseSelectedJob';

const Greenhouse = () => {
  const [isLoadingJobOpenings, setIsLoadingJobOpenings] = useState(true);
  const [isSyncingWithGreenhouse, setIsSyncingWithGreenhouse] = useState(false);
  const [jobOpenings, setJobOpenings] = useState([]);
  const [selectedJobOpeningId, setSelectedJobOpeningId] = useState(null);
  const selectedJobOpening = jobOpenings.find(job => selectedJobOpeningId && job.id === selectedJobOpeningId);
  const [slackUsers, setSlackUsers] = useState([]);

  const updateJobOpening = async (job, updates) => {
    if (updates.greenhousePromptSettings) updates.greenhousePromptSettings = JSON.stringify(updates.greenhousePromptSettings);
    const currentJobOpenings = [...jobOpenings];
    const updatedJobOpenings = currentJobOpenings.map(job => (job.id === selectedJobOpeningId ? { ...job, ...updates } : job));

    try {
      setJobOpenings(updatedJobOpenings);
      await updateJobOpeningAPI(selectedJobOpening, updates);
      cogoToast.success(`${job.title} updated successfully`);
    } catch (e) {
      cogoToast.error(`Failed to update ${job.title}`);
      setJobOpenings(currentJobOpenings);
    }
  };

  return (
    <div className='greenhouse-outer'>
      <GreenhouseData
        setJobOpenings={setJobOpenings}
        isSyncingWithGreenhouse={isSyncingWithGreenhouse}
        setIsSyncingWithGreenhouse={setIsSyncingWithGreenhouse}
        setIsLoadingJobOpenings={setIsLoadingJobOpenings}
        setSlackUsers={setSlackUsers}
      />

      <div className='greenhouse-inner'>
        <div className='greenhouse-header'>
          <div className='greenhouse-title'>Greenhouse Portal</div>
          <div className='greenhouse-controls'>
            <button className='greenhouse-button' onClick={() => setIsSyncingWithGreenhouse(true)}>
              Sync Greenhouse Jobs
              <FontAwesomeIcon icon={faSync} spin={isSyncingWithGreenhouse} />
            </button>
          </div>
        </div>

        <div className='greenhouse-content'>
          {/* Left Panel: Job Listings */}
          {isLoadingJobOpenings || isSyncingWithGreenhouse ? (
            <div className='greenhouse-skeleton-container'>
              {new Array(10).fill(0).map((_, index) => (
                <div key={index} className='greenhouse-skeleton'>
                  <div className='greenhouse-skeleton-title' />
                  <div className='greenhouse-skeleton-department' />
                </div>
              ))}
            </div>
          ) : (
            <div className='greenhouse-job-listings'>
              {jobOpenings.map(job => (
                <div
                  key={job.id}
                  className={cn('greenhouse-job-listing-preview', { selected: selectedJobOpeningId === job.id })}
                  onClick={() => setSelectedJobOpeningId(job.id)}
                >
                  <div className='greenhouse-job-listing-preview-title'>
                    {job.title}
                    <a href={job.url} target='_blank' rel='noreferrer'>
                      <FontAwesomeIcon icon={faExternalLink} />
                    </a>
                  </div>
                  <div className='greenhouse-job-listing-preview-department'>{job.department}</div>
                </div>
              ))}
            </div>
          )}

          <GreenhouseSelectedJob
            updateJobOpening={updateJobOpening}
            jobOpenings={jobOpenings}
            selectedJobOpeningId={selectedJobOpeningId}
            slackUsers={slackUsers}
          />
        </div>
      </div>
    </div>
  );
};

export default Greenhouse;
