import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';
import moment from 'moment';

import { formatCityCountryFromLocation } from '../../helpers/geo_helpers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';

const WaitlistBrand = props => {
  const { waitlistBrand, selectedFilter, accountManagers, setSelectedApplicationId } = props;
  const parsedLocation = JSON.parse(waitlistBrand.location || '{}');
  const formattedWaitlistUserLocation = formatCityCountryFromLocation(parsedLocation) || 'N/A';
  const { createdAt, name, pitch, domain, isAccepted, isRejected, id, AccountRepresentitive_id, Brand_id, howTheyHeard } = waitlistBrand;

  if (!selectedFilter.filter(waitlistBrand)) return null;
  return (
    <div className={cn('waitlist-user row', { accepted: isAccepted, rejected: isRejected })} key={id}>
      <div className='cell'>{moment(createdAt).format('MMM Do')}</div>
      <div className='cell md'>{name}</div>
      <div className='cell'>
        <a href={`https://${domain}`} target='_blank' rel='noopener noreferrer'>
          {domain}
        </a>
      </div>
      <div
        className={cn('cell', {
          xl: selectedFilter.label === 'Needs Decision',
          md: selectedFilter.label !== 'Needs Decision'
        })}
      >
        {isAccepted || isRejected ? (
          <div className=''>
            {AccountRepresentitive_id ? (
              <>
                {accountManagers.find(am => am.id === AccountRepresentitive_id).name} <FontAwesomeIcon icon={faCheck} />
              </>
            ) : (
              <>N/A</>
            )}
          </div>
        ) : (
          <>
            {pitch} || Heard through: {howTheyHeard || 'N/A'} || Location: {formattedWaitlistUserLocation}
          </>
        )}
      </div>
      <div className='cell lg'>
        {isAccepted ? (
          Brand_id ? (
            `Brand ID ${Brand_id}`
          ) : (
            '-'
          )
        ) : isRejected ? (
          <div className='decision rejected'>Rejected</div>
        ) : (
          <>
            <div className='btns'>
              <div onClick={() => setSelectedApplicationId(waitlistBrand.id)} className='btn accept'>
                Accept
              </div>
              <div onClick={() => setSelectedApplicationId(waitlistBrand.id)} className='btn reject'>
                Reject
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

WaitlistBrand.propTypes = {
  waitlistBrand: PropTypes.object.isRequired,
  selectedFilter: PropTypes.object.isRequired,

  accountManagers: PropTypes.array.isRequired,
  setSelectedApplicationId: PropTypes.func.isRequired
};

export default WaitlistBrand;
