import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import './ConciergeCodesModal.scss';

import Modal from '../../General/Modal';
import ConciergeCodesModalData from './ConciergeCodesModalData';
import ConciergeCodesModalCode from './ConciergeCodesModalCode';

import * as registrationCodesAPI from '../../../api/registration_codes';

const ConciergeCodesModal = props => {
  const { close, accountManagers } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [registrationCodes, setRegistrationCodes] = useState([]);
  const [searchVal, setSearchVal] = useState('');
  const visibleRegistrationCodes = registrationCodes
    .filter(rc => {
      if (!searchVal) return true;
      return rc.code.toLowerCase().includes(searchVal.toLowerCase());
    })
    .sort((a, b) => b.id - a.id)
    .slice(0, 100);

  const updateRegistrationCode = async (registrationCode, updates) => {
    const currentRegistrationCodes = [...registrationCodes];
    const updatedRegistrationCodes = currentRegistrationCodes.map(rc => (rc.id === registrationCode.id ? { ...rc, ...updates } : rc));
    setRegistrationCodes(updatedRegistrationCodes);

    try {
      await registrationCodesAPI.updateRegistrationCode(registrationCode, updates);
      cogoToast.success(`${updates.code || registrationCode.code} updated successfully`);
    } catch (e) {
      cogoToast.error(e);
      setRegistrationCodes(currentRegistrationCodes);
    }
  };

  const createRegistrationCode = async () => {
    const rawCode = window.prompt('Enter the code for the new registration code');
    const code = rawCode.trim();
    if (!code) return cogoToast.error('Code is required');

    try {
      const response = await registrationCodesAPI.createRegistrationCode({ code });
      setRegistrationCodes([...registrationCodes, response]);
      cogoToast.success(`${response.code} created successfully`);
    } catch (e) {
      if (e?.sqlMessage?.includes('Duplicate entry')) return cogoToast.error(`Registration code ${code} already exists`);
      else cogoToast.error(`Error creating ${code}, Please contact an engineer to troubleshoot`);
    }
  };

  const accountManagerOptions = accountManagers.map(am => ({
    value: am.id,
    label: am.name
  }));

  return (
    <Modal close={close}>
      <div className='concierge-codes-modal-outer'>
        <ConciergeCodesModalData setIsLoading={setIsLoading} setRegistrationCodes={setRegistrationCodes} />
        <div className='concierge-codes-modal-inner'>
          <div className='concierge-codes-modal-title'>Registration Codes</div>
          <div className='concierge-codes-modal-subtitle'>
            Only 100 registration codes are shown at a time for performace purposes, use the search bar to filter the results
          </div>
          <div className='concierge-codes-modal-search'>
            <input type='text' placeholder='Search' value={searchVal} onChange={e => setSearchVal(e.target.value)} />
          </div>

          <div className='concierge-codes-modal-codes-container'>
            {isLoading
              ? new Array(20).fill(0).map((_, i) => (
                  <div key={`registration-code-skeleton-${i}`} className='concierge-codes-modal-code-skeleton'>
                    <div>
                      <div className='concierge-codes-modal-code-skeleton-cell'></div>
                    </div>
                    <div>
                      <div className='concierge-codes-modal-code-skeleton-cell'></div>
                      <div className='concierge-codes-modal-code-skeleton-cell'></div>
                    </div>
                  </div>
                ))
              : visibleRegistrationCodes.map(rc => (
                  <ConciergeCodesModalCode
                    key={rc.id}
                    code={rc}
                    accountManagerOptions={accountManagerOptions}
                    updateRegistrationCode={updateRegistrationCode}
                  />
                ))}
          </div>
        </div>

        <div className='concierge-codes-modal-footer'>
          <button onClick={createRegistrationCode} className='save'>
            Create Registration Code
          </button>
          <button onClick={close}>Close</button>
        </div>
      </div>
    </Modal>
  );
};

ConciergeCodesModal.propTypes = {
  close: PropTypes.func.isRequired,
  accountManagers: PropTypes.array.isRequired
};

export default ConciergeCodesModal;
