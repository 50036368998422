import React, { useState } from 'react';
import './LookbookOrders.scss';
import LookbookOrderData from './LookbookOrderData';
import LookbookOrdersControls from './LookbookOrdersControls';
import LookbookOrder from './LookbookOrder';
import cn from 'classnames';
import { updateLookbookOrder as updateLookbookOrderAPI } from '../../api/lookbookorders';
import _ from 'lodash';

const LookbookOrders = () => {
  const [loading, setLoading] = useState(true);
  const [lookbookOrders, setLookbookOrders] = useState([]);
  const [accountManagers, setAccountManagers] = useState([]);
  const [tab, setTab] = useState('all');
  const [selectedAccountManager, setSelectedAccountManager] = useState(null);

  // console.log('lookbookOrders', lookbookOrders);

  const updateLookbookOrder = async (LookbookOrder_id, updates) => {
    console.log({ LookbookOrder_id, updates });
    if (_.isEmpty(updates)) return alert('No updates to make.');
    else if (!LookbookOrder_id) return alert('No LookbookOrder ID provided.');

    const currentLookbookOrders = [...lookbookOrders];
    const updatedLookbookOrders = currentLookbookOrders.map(order => {
      if (order.id === LookbookOrder_id) return { ...order, ...updates };
      else return order;
    });

    setLookbookOrders(updatedLookbookOrders);

    try {
      await updateLookbookOrderAPI(LookbookOrder_id, updates);
    } catch (e) {
      console.log(e);
      alert('Error updating LookbookOrder.');
      setLookbookOrders(currentLookbookOrders);
    }
  };

  const filterLookbookOrdersByTab = order => tab === 'all' || order.status === tab;
  const filterLookbookOrdersByAccountManager = order => (selectedAccountManager ? order.AccountManager_id === selectedAccountManager?.value : true);
  const sortLookbookOrdersByCreationDate = (a, b) => new Date(a.createdAt) - new Date(b.createdAt);

  return (
    <div className='lookbook-orders-outer'>
      <LookbookOrderData
        setAccountManagers={setAccountManagers}
        setLookbookOrders={setLookbookOrders}
        setLoading={setLoading}
        key={'LookbookOrderData'}
      />
      <div className='lookbook-orders-inner'>
        <div className='lookbook-order-title'>
          Lookbook Orders
          {loading && <div className='lookbook-orders-updating'>...Updating</div>}
        </div>
        <LookbookOrdersControls
          accountManagers={accountManagers}
          selectedAccountManager={selectedAccountManager}
          setSelectedAccountManager={setSelectedAccountManager}
          tab={tab}
          setTab={setTab}
        />

        <div className={cn('lookbook-orders-container', { loading })}>
          {lookbookOrders
            .filter(filterLookbookOrdersByTab)
            .filter(filterLookbookOrdersByAccountManager)
            .sort(sortLookbookOrdersByCreationDate)
            .map(order => (
              <LookbookOrder
                order={order}
                accountManagers={accountManagers}
                updateLookbookOrder={updateLookbookOrder}
                key={`LookbookOrder-${order.id}`}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default LookbookOrders;
