import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './ConciergeTalentResult.scss';
import * as concierge_helpers from '../../helpers/concierge_helpers';
import moment from 'moment';
import cn from 'classnames';

import { getTierDisplay } from '../../helpers/tier_helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faExternalLink, faTimes, faCopy } from '@fortawesome/pro-regular-svg-icons';
import { copyToClipboard } from '../../helpers/helpers';

const ConciergeTalentResult = props => {
  const { talent, setActiveTalentId, allComments, createComment, updateComment, updateSupportLevel, updateExpectedScore } = props;
  const talentComments = allComments
    ?.filter(comment => comment.User_id === talent.id && !comment.isDismissed)
    .sort((a, b) => a.isRead - b.isRead || b.updatedAt - a.updatedAt);
  const unreadTalentCommentCount = talentComments?.filter(comment => !comment.isRead && !comment.isDismissed && comment.type !== 'general').length;

  const { image, username, name, id: User_id, email, AccountManager_name, score, isArchived, lastLoggedIn } = talent;
  const { tier, expectedScore, supportLevel } = talent;
  const { outstanding_gifting_requests, outstanding_collab_proposals, unread_chat_messages } = talent;
  const { new_discount_codes, new_custom_rates, new_relevant_sales } = talent;
  const tierDisplayText = getTierDisplay(tier);

  const scoreDelta = concierge_helpers.getScoreDelta(talent);
  const activityPercentDelta = concierge_helpers.getActivityPercentDelta(talent);
  const notificationCount = concierge_helpers.getNotificationCount(talent);
  const actionableCount = concierge_helpers.getActionableCount(talent);

  const [currentPanel, setCurrentPanel] = useState('default'); // default, score_breakdown, comments

  const createNewComment = type => createComment(User_id, type);
  const promptCommentUpdate = comment => {
    const message = window.prompt('Update the comment.', comment.comment);
    if (!message) return;
    updateComment({ ...comment, comment: message });
  };

  const DefaultPanel = (
    <div className='concierge-default-panel'>
      {/* NOTIFICATION BUBBLE */}
      {(unreadTalentCommentCount && unreadTalentCommentCount) > 0 ? (
        <div className='notification-container' onClick={() => setCurrentPanel('comments')}>
          {unreadTalentCommentCount}
        </div>
      ) : null}
      {/* IMAGE */}
      <div className='image-container'>
        {image ? (
          <img className='concierge-image' src={image} alt='profile_picture' />
        ) : (
          <div className='concierge-image-replacement'>{name.split(' ')[0][0]}</div>
        )}
      </div>
      {/* MAIN CONTAINER */}
      <div className='content-container'>
        <div className='profile-info'>
          <div>
            <div className='name' onClick={() => setActiveTalentId(props.talent)}>
              {name}
              <span className='shop-link-icon'>
                <a className='tiny' href={`https://shopmy.us/${username}`} onClick={e => e.stopPropagation()} target='_blank' rel='noreferrer'>
                  <FontAwesomeIcon icon={faExternalLink} />
                </a>
              </span>
            </div>
            <div className='manager'>
              {tierDisplayText} • {supportLevel || 'N/A'} • {AccountManager_name || 'No Manager'}
              {isArchived ? ' • Archived' : ''}
            </div>
            <div className='tiny copyable' onClick={() => copyToClipboard(email)}>
              {email} <FontAwesomeIcon icon={faCopy} />
            </div>
            <div className='tiny copyable' onClick={() => copyToClipboard(User_id)}>
              ID: {User_id}
              <FontAwesomeIcon icon={faCopy} />
            </div>
            <div className='tiny copyable'>
              <i>Last Logged In: {moment(lastLoggedIn).isValid() ? moment(lastLoggedIn).format('MMM DD, YYYY') : 'N/A'}</i>
            </div>
            <div className='spacer'></div>
            <div className='tiny clickable' onClick={() => createNewComment('general')}>
              Add General Comment
            </div>
            <div className='tiny clickable' onClick={() => createNewComment('task')}>
              Add Task
            </div>
          </div>
        </div>
        <div className='attribute-container'>
          {/* SCORE */}
          <div
            className='attribute'
            style={concierge_helpers.getConciergeAttributeColor('score', scoreDelta)}
            onClick={() => setCurrentPanel('score_breakdown')}
          >
            <div className='attribute-text'>Score</div>
            <div className='attribute-value'>
              {score?.toFixed(1)} ({scoreDelta > 0 ? `+${scoreDelta.toFixed(1)}` : scoreDelta.toFixed(1)})
            </div>
          </div>
          {/* ANALYTICS */}
          <div
            className='attribute'
            style={concierge_helpers.getConciergeAttributeColor('analytics', activityPercentDelta.average_delta_percent)}
            onClick={() => setCurrentPanel('score_breakdown')}
          >
            <div className='attribute-text'>Analytics</div>
            <div className='attribute-value'>
              {activityPercentDelta.average_delta_percent > 0
                ? `+${activityPercentDelta.average_delta_percent.toFixed(1)}%`
                : `${activityPercentDelta.average_delta_percent.toFixed(1)}%`}
            </div>
          </div>
          {/* NOTIFICATIONS */}
          <div
            className='attribute'
            style={concierge_helpers.getConciergeAttributeColor('notifications', notificationCount)}
            onClick={() => setCurrentPanel('score_breakdown')}
          >
            <div className='attribute-text'>Notifications</div>
            <div className='attribute-value'>{notificationCount}</div>
          </div>
          {/* ACTIONABLES */}
          <div
            className='attribute'
            style={concierge_helpers.getConciergeAttributeColor('actionables', actionableCount)}
            onClick={() => setCurrentPanel('score_breakdown')}
          >
            <div className='attribute-text'>Actionables</div>
            <div className='attribute-value'>{actionableCount}</div>
          </div>
        </div>
      </div>
    </div>
  );

  const ScoreBreakdownPanel = (
    <div className='concierge-score-breakdown-panel'>
      <div className='close-button'>
        <FontAwesomeIcon icon={faTimes} onClick={() => setCurrentPanel('default')} />
      </div>
      <div className='user-details'>
        <div className='name'>{name}</div>
        <a className='shop-link' href={`https://shopmy.us/${username}`} target='_blank' rel='noreferrer'>
          <FontAwesomeIcon icon={faExternalLink} className='edit-icon' />
        </a>
      </div>

      <div className='stats'>
        <div className='stat-container'>
          <div className='stat' style={concierge_helpers.getConciergeAttributeColor('score', scoreDelta)}>
            <div className='stat-text'>Score</div>
            <div className='stat-value'>{parseFloat(score)?.toFixed(1)}</div>
          </div>
          <div className='stat' onClick={() => updateExpectedScore(User_id)}>
            <div className='stat-text'>
              Expected
              <FontAwesomeIcon icon={faPencil} className='edit-icon' />
            </div>
            <div className='stat-value'>{expectedScore}</div>
          </div>
          <div className='stat' onClick={() => updateSupportLevel(User_id)}>
            <div className='stat-text'>
              Support
              <FontAwesomeIcon icon={faPencil} className='edit-icon' />
            </div>
            <div className='stat-value'>{supportLevel}</div>
          </div>
          <div className='stat'>
            <div className='stat-text'>Tier</div>
            <div className='stat-value'>{tierDisplayText}</div>
          </div>
        </div>

        <div className='stat-container'>
          <div className='stat' style={concierge_helpers.getConciergeAttributeColor('analytics', activityPercentDelta.average_delta_percent)}>
            <div className='stat-text'>Analytics</div>
            <div className='stat-value'>
              {activityPercentDelta.average_delta_percent > 0
                ? `+${activityPercentDelta.average_delta_percent.toFixed(1)}%`
                : `${activityPercentDelta.average_delta_percent.toFixed(1)}%`}
            </div>
          </div>
          <div className='stat'>
            <div className='stat-text'>Clicks</div>
            <div className='stat-value'>
              {activityPercentDelta.click_delta_percent > 0
                ? `+${activityPercentDelta.click_delta_percent.toFixed(1)}%`
                : `${activityPercentDelta.click_delta_percent.toFixed(1)}%`}
            </div>
          </div>
          <div className='stat'>
            <div className='stat-text'>Pins</div>
            <div className='stat-value'>
              {activityPercentDelta.pin_delta_percent > 0
                ? `+${activityPercentDelta.pin_delta_percent.toFixed(1)}%`
                : `${activityPercentDelta.pin_delta_percent.toFixed(1)}%`}
            </div>
          </div>
          <div className='stat'>
            <div className='stat-text'>Volume</div>
            <div className='stat-value'>
              {activityPercentDelta.volume_delta_percent > 0
                ? `+${activityPercentDelta.volume_delta_percent.toFixed(1)}%`
                : `${activityPercentDelta.volume_delta_percent.toFixed(1)}%`}
            </div>
          </div>
        </div>

        <div className='stat-container'>
          <div className='stat' style={concierge_helpers.getConciergeAttributeColor('notifications', notificationCount)}>
            <div className='stat-text'>Notifications</div>
            <div className='stat-value'>{notificationCount}</div>
          </div>
          <div className='stat'>
            <div className='stat-text'>Gifting</div>
            <div className='stat-value'>{outstanding_gifting_requests}</div>
          </div>
          <div className='stat'>
            <div className='stat-text'>Collabs</div>
            <div className='stat-value'>{outstanding_collab_proposals}</div>
          </div>
          <div className='stat'>
            <div className='stat-text'>Chats</div>
            <div className='stat-value'>{unread_chat_messages}</div>
          </div>
        </div>

        <div className='stat-container'>
          <div className='stat' style={concierge_helpers.getConciergeAttributeColor('actionables', actionableCount)}>
            <div className='stat-text'>Actionables</div>
            <div className='stat-value'>{actionableCount}</div>
          </div>
          <div className='stat'>
            <div className='stat-text'>Codes</div>
            <div className='stat-value'>{new_discount_codes}</div>
          </div>
          <div className='stat'>
            <div className='stat-text'>Rates</div>
            <div className='stat-value'>{new_custom_rates}</div>
          </div>
          <div className='stat'>
            <div className='stat-text'>Sales</div>
            <div className='stat-value'>{new_relevant_sales}</div>
          </div>
        </div>
      </div>
    </div>
  );

  const CommentPanel = (
    <div className='concierge-comment-panel'>
      <div className='close-button'>
        <FontAwesomeIcon icon={faTimes} onClick={() => setCurrentPanel('default')} />
      </div>
      <div className='user-details'>
        <div className='name'>{name}</div>
        <a className='shop-link' href={`https://shopmy.us/${username}`} target='_blank' rel='noreferrer'>
          <FontAwesomeIcon icon={faExternalLink} className='edit-icon' />
        </a>
      </div>
      <div className='comment add-comment' onClick={() => createNewComment('task')}>
        +
      </div>
      {talentComments.map((commentObject, index) => {
        const { comment, Commenter_name, updatedAt, isRead, type } = commentObject;
        return (
          <div key={`comment_${index}`} className={cn('comment', { isRead })}>
            <div className='comment-body'>
              <div className='comment-text' onClick={() => promptCommentUpdate(commentObject)}>
                {comment}
              </div>

              <div className='comment-author'>
                <span>{Commenter_name}</span>
                &nbsp;&nbsp;
                <span className='bullet'>•</span>
                &nbsp;&nbsp;
                <span>{moment(updatedAt).format('MMM DD, YYYY')}</span>
              </div>
            </div>
            {type !== 'general' && (
              <div className='comment-buttons'>
                <input type='checkbox' className='checkbox' checked={isRead} onChange={() => updateComment({ ...commentObject, isRead: !isRead })} />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );

  return (
    <div className={cn('concierge-talent-result-outer', { important: unreadTalentCommentCount && unreadTalentCommentCount > 0 })}>
      {currentPanel === 'default' && DefaultPanel}
      {currentPanel === 'score_breakdown' && ScoreBreakdownPanel}
      {currentPanel === 'comments' && CommentPanel}
    </div>
  );
};

ConciergeTalentResult.propTypes = {
  talent: PropTypes.object.isRequired,
  setActiveTalentId: PropTypes.func.isRequired,
  allComments: PropTypes.array.isRequired,
  createComment: PropTypes.func.isRequired,
  updateComment: PropTypes.func.isRequired,
  updateSupportLevel: PropTypes.func.isRequired,
  updateExpectedScore: PropTypes.func.isRequired
};

export default ConciergeTalentResult;
