import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// import { logoutUser } from '../../Actions/UserActions';
import { logoutUser } from '../../actions/userActions';

import Loader from '../General/Loader';

const Logout = props => {
  const { logoutUser } = props;
  const [isLoggingOut, setIsLoggingOut] = useState(true);
  useEffect(() => {
    logoutUser().then(() => setIsLoggingOut(false));
  }, [logoutUser]);
  return isLoggingOut ? <Loader /> : <Redirect to={{ pathname: '/' }} />;
};

Logout.propTypes = {
  logoutUser: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {
  logoutUser
})(Logout);
