import _ from 'lodash'
import { getHierarchy }  from '../api/catalog'

export const SYNC_HIERARCHY_SUCCESS = 'SYNC_HIERARCHY_SUCCESS'
export const SYNC_HIERARCHY_ERROR = 'SYNC_HIERARCHY_ERROR'

export const syncCatalogHierarchy = (user) => async (dispatch, getState) => {
  const resp = await getHierarchy(user)
  if (resp.success) {
    return dispatch({
      type: SYNC_HIERARCHY_SUCCESS,
      departments: _.get(resp, 'departments', []),
      categories: _.get(resp, 'categories', []),
      tags: _.get(resp, 'tags', []),
      brands: _.get(resp, 'brands', []),
    })
  } else {
    return dispatch({
      type: SYNC_HIERARCHY_ERROR,
      error: resp.error
    })
  }
}
