import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './BrandWaitlistDecisionModal.scss';
import Modal from '../General/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/pro-solid-svg-icons';
import { copyToClipboard } from '../../helpers/helpers';

const initialEmailTemplate = `Hi BRAND_NAME Team,\n\nThank you so much for reaching out! We would be happy to walk you through our platform. Here is a link you can use to schedule a time with us whenever works best for you: PASTE_CALENDLY_LINK\n\nBest,\nSELECT_ACCOUNT_REPRESENTATIVE`;

const BrandWaitlistDecisionModal = props => {
  const { close, brandWaitlistApplication, accountManagers, acceptBrand, rejectBrand } = props;
  const { name, email, domain, pitch } = brandWaitlistApplication;

  const [AccountRepresentitive_id, setAccountRepresentitive_id] = useState(brandWaitlistApplication.AccountRepresentitive_id);
  const [calendlyLink, setCalendlyLink] = useState();
  const [emailPreview, setEmailPreview] = useState(initialEmailTemplate);

  useEffect(() => {
    const accountRepresentative = accountManagers.find(accountManager => accountManager.id === parseInt(AccountRepresentitive_id));

    let newEmailPreview = initialEmailTemplate;
    if (calendlyLink) newEmailPreview = newEmailPreview.replace('PASTE_CALENDLY_LINK', calendlyLink);
    if (accountRepresentative) newEmailPreview = newEmailPreview.replace('SELECT_ACCOUNT_REPRESENTATIVE', accountRepresentative.name);
    if (name) newEmailPreview = newEmailPreview.replace('BRAND_NAME', name);

    setEmailPreview(newEmailPreview);
  }, [AccountRepresentitive_id, calendlyLink, name, pitch, accountManagers, brandWaitlistApplication]);

  const acceptWithEmail = async () => {
    await acceptBrand({
      waitlistBrand: brandWaitlistApplication,
      email_body: emailPreview,
      AccountRepresentitive_id
    });
    close();
  };

  const acceptWithoutEmail = async () => {
    await acceptBrand({
      waitlistBrand: brandWaitlistApplication,
      AccountRepresentitive_id,
      manual_outreach: true
    });
    close();
  };

  const reject = async () => {
    await rejectBrand({ waitlistBrand: brandWaitlistApplication });
    close();
  };

  return (
    <Modal close={close}>
      <div className='brand-waitlist-decision-modal-outer'>
        <div className='brand-waitlist-decision-modal-inner'>
          <div className='header-container'>
            <div className='name'>{name}</div>
            <div className='domain-email' onClick={() => copyToClipboard(email, true)}>
              <a href={`https://${domain}`} target='_blank' rel='noopener noreferrer'>
                {domain}
              </a>{' '}
              || {email} <FontAwesomeIcon icon={faCopy} />
            </div>
          </div>
          <div className='pitch-container'>
            <div className='pitch'>{pitch}</div>
          </div>

          <div className='form-container'>
            <div className='form-group'>
              <label htmlFor='AccountRepresentitive_id'>Account Representative</label>
              <select
                className='form-control'
                id='AccountRepresentitive_id'
                value={AccountRepresentitive_id}
                onChange={e => setAccountRepresentitive_id(e.target.value)}
              >
                <option value={null}>Select Account Representative</option>
                {accountManagers.map(accountManager => {
                  return (
                    <option key={accountManager.id} value={accountManager.id}>
                      {accountManager.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className='form-group'>
              <label htmlFor='calendlyLink'>Calendly Link</label>
              <input type='text' className='form-control' id='calendlyLink' value={calendlyLink} onChange={e => setCalendlyLink(e.target.value)} />
            </div>
          </div>

          <div className='email-preview-container'>
            <textarea className='email-preview' value={emailPreview} onChange={e => setEmailPreview(e.target.value)} />
          </div>

          <div className='buttons-container'>
            <button className='btn btn-primary' onClick={reject}>
              Reject
            </button>
            <button className='btn btn-success' onClick={acceptWithEmail}>
              Accept
            </button>
            <button className='btn btn-success' onClick={acceptWithoutEmail}>
              Accept Without Email
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

BrandWaitlistDecisionModal.propTypes = {
  close: PropTypes.func.isRequired,
  brandWaitlistApplication: PropTypes.object.isRequired,
  accountManagers: PropTypes.array.isRequired,
  acceptBrand: PropTypes.func.isRequired,
  rejectBrand: PropTypes.func.isRequired
};

export default BrandWaitlistDecisionModal;
