import APIClient from './index';
import _ from 'lodash'

export const getHierarchy = () => {
  return new Promise((resolve, reject) => {
    APIClient.get(`Catalog/hierarchy?includeBrands=true`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const addCategory = (data) => {
  return new Promise((resolve, reject) => {
    APIClient.post(`Categories`, data)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const addDepartment = (data) => {
  return new Promise((resolve, reject) => {
    APIClient.post(`Departments`, data)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const addTag = (data) => {
  return new Promise((resolve, reject) => {
    APIClient.post(`Tags`, data)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const addFeature = (data) => {
  return new Promise((resolve, reject) => {
    APIClient.post(`FeaturedBestInBeauty`, data)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const updateCategory = (id, data) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`Categories/${id}`, data)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const updateDepartment = (id, data) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`Departments/${id}`, data)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const updateTag = (id, data) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`Tags/${id}`, data)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const deleteCategory = (id) => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`Categories/${id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const deleteDepartment = (id) => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`Departments/${id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const deleteTag = (id) => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`Tags/${id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const deleteFeature = (id) => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`FeaturedBestInBeauty/${id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};
