import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './MLApiResults.scss';

const MLApiResults = props => {
  const { results } = props;
  return (
    <div className={cn('ml-api-results-outer-container', { fetching: props.isFetching })}>
      <div className='ml-api-results'>
        {results.map(result => {
          const { instagram_username } = result;
          const igUrl = `https://instagram.com/${instagram_username}`;
          return (
            <div className='ml-api-result'>
              <a href={igUrl} target='_blank' rel='noopener noreferrer'>
                @{instagram_username}
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
};

MLApiResults.propTypes = {
  results: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired
};

export default MLApiResults;
