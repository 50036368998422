import React, { useState } from 'react';
import cn from 'classnames';
import { faDownload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import { Table } from '../../../BrandReports/GraphTypes/Table';
import BarGraph from '../../../BrandReports/GraphTypes/BarGraph';
import Loader from '../../../General/Loader';

import { getBrandReportHeaderLabel } from '../../../../helpers/brand_helpers';

const ConciergeClicks = props => {
  // console.log({ props });
  const { conciergeClicks, title, groupingMethod, downloading } = props;
  const success = !!conciergeClicks?.length;

  const [tab, setTab] = useState('table'); // graph, table
  const download_table = async () => await props.download({ rows: make_table_rows(), fileName: 'concierge_clicks' });
  const download_graph = async () => await props.download({ graph: title });

  const make_table_rows = () => {
    let clicks_running_total = 0;
    const clicks = {
      key: 'clicks_total',
      label: 'Clicks',
      description: '(clicks driven to all brands)',
      values: conciergeClicks.map(result => {
        const { week, month, quarter, clicks } = result;
        clicks_running_total += parseInt(clicks || 0);
        return { label: getBrandReportHeaderLabel({ groupingMethod, week, month, quarter }), value: clicks };
      })
    };
    clicks.values.push({ label: 'Total', value: clicks_running_total });

    return [clicks];
  };

  return (
    <div className='talent-report-card' id={title}>
      {!conciergeClicks ? (
        <Loader />
      ) : (
        <>
          {success && (
            <>
              <div className='header'>
                <div className='description'>
                  <div className='title'>{title}</div>
                  <div className='sub-title'>Clicks are counted when a random user clicks on a talent's pin.</div>
                </div>
                {!downloading && (
                  <div className='talent-report-controls' id={`${title}-card-controls`}>
                    <div className='radio-btns'>
                      <div className={cn('radio-btn', { active: tab === 'graph' })} onClick={() => setTab('graph')}>
                        Graph
                      </div>
                      <div className={cn('radio-btn', { active: tab === 'table' })} onClick={() => setTab('table')}>
                        Table
                      </div>
                    </div>
                    <div className='rounded-button' onClick={() => (tab === 'table' ? download_table() : download_graph())}>
                      {downloading ? (
                        <Loader />
                      ) : (
                        <>
                          <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                          Download
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>

              {tab === 'table' && <Table title='Orders and Volume' data={make_table_rows()} />}
              {tab === 'graph' && (
                <BarGraph
                  title='Clicks'
                  data={conciergeClicks.map(click => {
                    const { week, month, quarter, year, clicks } = click;
                    const data = {
                      key: `${week || month || quarter}${year}clicks`,
                      value: clicks,
                      label: getBrandReportHeaderLabel({ groupingMethod, week, month, quarter })
                    };
                    return data;
                  })}
                />
              )}
            </>
          )}

          {!success && (
            <>
              <div className='error-centered'>
                <div className='title'>{title}</div>
                <div className='sub-title'>No results.</div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

ConciergeClicks.propTypes = {
  title: PropTypes.string.isRequired,
  conciergeClicks: PropTypes.array,
  groupingMethod: PropTypes.string.isRequired,

  download: PropTypes.func.isRequired,
  downloading: PropTypes.bool.isRequired
};

const ConciergeClicksConfig = {
  title: 'Clicks'
};

export { ConciergeClicks, ConciergeClicksConfig };
