import { combineReducers } from 'redux';
import { catalog } from './catalogReducer';
import { brands } from './brandsReducer';
import { merchants } from './merchantsReducer';
import { health } from './healthReducer';
import { queue } from './queueReducer';
import { ui } from './uiReducer';
import { users } from './usersReducer';
import { tags } from './tagsReducer';
import { notes } from './notesReducer';

const appReducer = combineReducers({
  catalog,
  health,
  queue,
  brands,
  merchants,
  ui,
  users,
  tags,
  notes
});

const rootReducer = (state, action) => {
  window.a = state;
  return appReducer(state, action);
};

export default rootReducer;
