import React, { Component } from 'react';
import cogoToast from 'cogo-toast';
import _ from 'lodash';

import { getPendingPayouts, sendPayment, getPayments } from '../../api/payouts';

import './Payments.scss';

import CreatePayout from './CreatePayout';
import PastPayments from './PastPayments';
import PendingPayouts from './PendingPayouts';

class Payments extends Component {
  static propTypes = {
    // user: PropTypes.object.isRequired,
  };

  syncData = () => {
    cogoToast.warn('This tab is no longer functional, please use PopSQL!');
  };

  componentDidMount() {
    this.syncData();
  }

  state = {
    payouts: [],
    consultResults: [],
    contracts: [],
    payments: [],
    accounts: [],
    selectedPayouts: [],
    selectedConsultResults: [],
    selectedContracts: [],
    fetchingPayouts: true,
    fetchingPayments: true
  };

  sendPayment = async (payouts, consultResults, contracts, method) => {
    cogoToast.info('Submitting Payment...');
    this.setState({ selectedPayouts: [], selectedConsultResults: [], selectedContracts: [] });
    sendPayment(payouts, consultResults, contracts, method)
      .then(resp => {
        cogoToast.success('Successfully Submitted Payment');
        this.syncData();
      })
      .catch(err => {
        cogoToast.error(_.get(err, 'error', 'Error Submitting Payment, see console.'));
        console.log(err);
      });
  };

  render() {
    const {
      payouts,
      payments,
      consultResults,
      contracts,
      accounts,
      fetchingPayouts,
      fetchingPayments,
      selectedPayouts,
      selectedConsultResults,
      selectedContracts
    } = this.state;
    const hasResults = !!payouts.length || !!consultResults.length || !!contracts.length;
    return (
      <div className='payments-outer-container'>
        {!fetchingPayouts && hasResults && (
          <PendingPayouts
            accounts={accounts}
            payouts={payouts}
            selectedPayouts={selectedPayouts}
            setSelectedPayouts={selectedPayouts => this.setState({ selectedPayouts })}
            consultResults={consultResults}
            selectedConsultResults={selectedConsultResults}
            setSelectedConsultResults={selectedConsultResults => this.setState({ selectedConsultResults })}
            contracts={contracts}
            selectedContracts={selectedContracts}
            setSelectedContracts={selectedContracts => this.setState({ selectedContracts })}
          />
        )}
        {(!!selectedPayouts.length || !!selectedConsultResults.length || !!selectedContracts.length) && (
          <CreatePayout
            payouts={selectedPayouts}
            consultResults={selectedConsultResults}
            contracts={selectedContracts}
            accounts={accounts}
            sendPayment={this.sendPayment}
          />
        )}
        <PastPayments payments={payments} fetching={fetchingPayments} />
      </div>
    );
  }
}

export default Payments;
