import React, { useState } from 'react';
import BarGraph from '../GraphTypes/BarGraph';
import Loader from '../../General/Loader';
import cn from 'classnames';

import { Table } from '../GraphTypes/Table';
import { getBrandReportHeaderLabel } from '../../../helpers/brand_helpers';
import PropTypes from 'prop-types';
import { faDownload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BrandDiscountCodes = props => {
  const { title, brandDiscountCodes: discountCodes, downloading } = props;
  const success = !!discountCodes?.length;

  const [tab, setTab] = useState('table');
  const download_table = async () => await props.download({ rows: make_table_rows(), fileName: 'brand_discount_codes' });
  const download_graph = async () => await props.download({ graph: title });

  const make_table_rows = () => {
    if (!discountCodes || !discountCodes[0]) return;

    const codes = new Map();

    for (const time_period of discountCodes) {
      for (const { code } of time_period.performance)
        codes.set(code, {
          key: code,
          label: code,
          values: [],
          running_total: 0
        });
    }

    for (const timePeriod of discountCodes) {
      const { week, month, quarter, performance } = timePeriod;
      const get_code_from_performance = code => performance.find(r => r.code === code);

      for (const { key: code } of codes.values()) {
        const table_code_object = codes.get(code);
        const code_performance = get_code_from_performance(code);
        const volume = code_performance?.volume || 0;
        table_code_object.values.push({ label: getBrandReportHeaderLabel({ week, month, quarter }), value: `$${parseFloat(volume)}` });
        table_code_object.running_total += parseFloat(volume);
      }
    }

    const final_codes = Array.from(codes.values());
    for (const code of final_codes) code.values.push({ label: 'Total', value: `$${code.running_total}` });

    return final_codes;
  };

  return (
    <div className='brand-card' id={title}>
      <div className='padding'>
        {!discountCodes ? (
          <Loader />
        ) : (
          <>
            {success && (
              <>
                <div className='header'>
                  <div className='description'>
                    <div className='title'>{title}</div>
                    <div className='sub-title'>Displays the codes and revenue month over month.</div>
                  </div>
                  {!downloading && (
                    <div className='brand-card-controls'>
                      <div className='radio-btns'>
                        <div className={cn('radio-btn', { active: tab === 'graph' })} onClick={() => setTab('graph')}>
                          Graph
                        </div>
                        <div className={cn('radio-btn', { active: tab === 'table' })} onClick={() => setTab('table')}>
                          Table
                        </div>
                      </div>
                      <div className='rounded-button' onClick={() => (tab === 'table' ? download_table() : download_graph())}>
                        {downloading ? (
                          <Loader />
                        ) : (
                          <>
                            <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                            Download
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>

                {tab === 'table' && <Table allowDecimals title='Brand Discount Codes' data={make_table_rows()} />}
                {tab === 'graph' && (
                  <BarGraph
                    title='Discount Codes Issued'
                    data={discountCodes.map(discountCode => {
                      const { week, month, quarter, year, issued } = discountCode;

                      const data = {
                        key: `${week || month || quarter}${year}discountcodes`,
                        value: issued,
                        label: getBrandReportHeaderLabel({ week, month, quarter })
                      };
                      return data;
                    })}
                  />
                )}
              </>
            )}

            {!success && (
              <>
                <div className='error-centered'>
                  <div className='title'>Error Fetching {title}</div>
                  <div className='sub-title'>Could not fetch results</div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

BrandDiscountCodes.propTypes = {
  title: PropTypes.string.isRequired,
  brandDiscountCodes: PropTypes.array,

  download: PropTypes.func.isRequired,
  downloading: PropTypes.bool.isRequired
};

const brandDiscountCodeConfig = {
  title: 'Discount Codes'
};

export { BrandDiscountCodes, brandDiscountCodeConfig };
