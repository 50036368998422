import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faRedo } from '@fortawesome/pro-light-svg-icons';
import './PinSearchPanel.scss';

class PinSearchPanel extends Component {
  static propTypes = {
    search: PropTypes.func.isRequired
  };

  state = {
    searchVal: '',
    searchVals: []
  };

  submitValue = e => {
    const { searchVal, searchVals } = this.state;
    e.stopPropagation();
    e.preventDefault();
    if (!searchVal) return;

    this.setState(
      {
        searchVal: '',
        searchVals: _.uniq([...searchVals, searchVal])
      },
      () => {
        this.props.search(this.state.searchVals);
      }
    );
  };

  removeValue = val => {
    this.setState(
      {
        searchVals: _.without(this.state.searchVals, val)
      },
      () => {
        this.props.search(this.state.searchVals);
      }
    );
  };

  clearAllValues = () => {
    this.setState(
      {
        searchVals: []
      },
      () => {
        this.props.search(this.state.searchVals);
      }
    );
  };

  render() {
    const { searchVal, searchVals } = this.state;
    return (
      <div className='pin-search-panel-container'>
        <form onSubmit={this.submitValue}>
          <input
            placeholder='Search by Influencer Name, Username, Pin Title or URL'
            value={searchVal}
            onChange={({ target }) => this.setState({ searchVal: target.value })}
          />
        </form>
        <div className='search-values'>
          {searchVals.map(val => (
            <div key={val} onClick={() => this.removeValue(val)} className='search-value'>
              {val}
              <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
            </div>
          ))}
          {searchVals.length > 0 && (
            <div onClick={() => this.props.search(searchVals)} className='refresh-btn'>
              <FontAwesomeIcon icon={faRedo}></FontAwesomeIcon>
            </div>
          )}
          {searchVals.length > 1 && (
            <div onClick={this.clearAllValues} className='clear-btn'>
              <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default PinSearchPanel;
