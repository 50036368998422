import React from 'react';
import { faDownload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import { Table } from '../../../BrandReports/GraphTypes/Table';
import Loader from '../../../General/Loader';
import { getBrandReportHeaderLabel } from '../../../../helpers/brand_helpers';

const ConciergeTopPins = props => {
  // console.log({ props });
  const { title, downloading, topPins, groupingMethod } = props;
  const success = !!topPins?.length;

  const download_table = async () => await props.download({ rows: make_table_rows(), fileName: 'concierge_custom_codes' });

  const make_table_rows = () => {
    if (!topPins || !topPins.length) return [];
    const uniquePins = new Map();

    for (const timePeriod of topPins) {
      for (const pin of timePeriod.pins || []) {
        uniquePins.set(pin.id, pin);
      }
    }

    const fetchPinFromGroup = (group, pinId) => group?.pins?.find(pin => pin.id === pinId);

    const finalPinRows = [];
    for (const pin of uniquePins.values()) {
      const { id: pinId, title, Collection_name, Collection_id, domain } = pin;

      const data = {
        key: `top_pins_${pinId}`,
        label: title,
        smallerLabel: true,
        smallerValues: true,
        description: Collection_name || 'Link',
        href: Collection_name ? `https://shopmy.us/collections/${Collection_id}` : undefined,
        values: [{ label: 'Domain', value: domain }]
      };

      let runningTotal = 0;
      for (const timePeriod of topPins) {
        const { year, [groupingMethod]: group } = timePeriod;
        const pinFromGroup = fetchPinFromGroup(timePeriod, pinId);
        runningTotal += pinFromGroup?.volume || 0;
        data.values.push({ label: getBrandReportHeaderLabel({ [groupingMethod]: group, year }), value: `$${pinFromGroup?.volume?.toFixed() || 0}` });
      }
      data.values.push({ label: 'Total', value: `$${runningTotal.toFixed()}` });
      data.runningTotal = runningTotal;

      finalPinRows.push(data);
    }

    // sort by total volume and return top 30
    return finalPinRows.sort((a, b) => b.runningTotal - a.runningTotal).slice(0, 30);
  };

  return (
    <div className='talent-report-card' id={title}>
      {!topPins ? (
        <Loader />
      ) : (
        <>
          {success && (
            <>
              <div className='header'>
                <div className='description'>
                  <div className='title'>{title}</div>
                  <div className='sub-title'>Top pins show how the talent's best pins are performing over time.</div>
                </div>
                {!downloading && (
                  <div className='talent-report-controls' id={`${title}-card-controls`}>
                    <div className='rounded-button' onClick={() => download_table()}>
                      {downloading ? (
                        <Loader />
                      ) : (
                        <>
                          <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                          Download
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>

              <Table title='Top Retailers' data={make_table_rows()} />
            </>
          )}

          {!success && (
            <>
              <div className='error-centered'>
                <div className='title'>{title}</div>
                <div className='sub-title'>No results.</div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

ConciergeTopPins.propTypes = {
  title: PropTypes.string.isRequired,
  topPins: PropTypes.array,
  groupingMethod: PropTypes.string.isRequired,

  download: PropTypes.func.isRequired,
  downloading: PropTypes.bool.isRequired
};

const ConciergeTopPinsConfig = {
  title: 'Top Pins'
};

export { ConciergeTopPins, ConciergeTopPinsConfig };
