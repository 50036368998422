import _ from 'lodash';

export const isTaggingMode = ui => _.get(ui, 'isTaggingMode', []);
export const ensureUINotNull = ui => {
  if (!ui) console.error(`Function issue: Must define UI to use this function.`);
  return true;
};

// Tags
export const getActiveTags = ui => ensureUINotNull(ui) && _.get(ui, 'activeTags', []);
export const getHiddenTagTypes = ui => ensureUINotNull(ui) && _.get(ui, 'hiddenTagTypes', []);
export const isTagActive = (tag, ui) => ensureUINotNull(ui) && !!_.find(getActiveTags(ui), t => t.id === tag.id);
export const isTagTypeHidden = (type, ui) => !!_.find(getHiddenTagTypes(ui), t => t === type);

// Users
export const getUsersBeingTagged = ui => ensureUINotNull(ui) && _.get(ui, 'usersBeingTagged', []);
export const getBrandsBeingTagged = ui => ensureUINotNull(ui) && _.get(ui, 'brandsBeingTagged', []);
export const getMerchantsBeingTagged = ui => ensureUINotNull(ui) && _.get(ui, 'merchantsBeingTagged', []);
export const isUserBeingTagged = (user, ui) => ensureUINotNull(ui) && !!_.find(getUsersBeingTagged(ui), t => t.id === user.id);
export const isBrandBeingTagged = (brand, ui) => ensureUINotNull(ui) && !!_.find(getBrandsBeingTagged(ui), t => t.id === brand?.id);
export const isMerchantBeingTagged = (merchant, ui) => ensureUINotNull(ui) && !!_.find(getMerchantsBeingTagged(ui), t => t.id === merchant.id);
