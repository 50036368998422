import _ from 'lodash';

const youtube_regex = /(http|www).*(youtube|youtu\.be).*/gi;
const instagram_regex = /.*instagram.com/gi;
const tiktok_regex = /(http|www).*(tiktok).*/gi;
const twitter_regex = /(http|www).*(twitter).*/gi;
const facebook_regex = /(http|www).*(facebook).*/gi;
const pinterest_regex = /(http|www).*(pinterest\.(com|co\.uk|de|es|fr|it|jp|com\.au|ca|co\.nz)|pin\.it).*/gi;
const substack_regex = /(http|www).*(\.substack\.com|substack\.com).*/gi;
const linktree_regex = /(http|www).*(linktr\.ee).*/gi;

// export const waitlistUserNeedsDecision = waitlistUser =>
//   !waitlistUser.isAccepted && !waitlistUser.isRejected && !waitlistUser.User_id && !waitlistUser.uses;
export const waitlistUserNeedsDecision = waitlistUser => !waitlistUser.isAccepted && !waitlistUser.isRejected && !waitlistUser.User_id;
export const waitlistUserIsAccepted = waitlistUser => waitlistUser.isAccepted;
export const waitlistUserIsRejected = waitlistUser => waitlistUser.isRejected;
export const waitlistUserHasPriority = (waitlistUser, priorityTags) => {
  if (!waitlistUserNeedsDecision(waitlistUser)) return false;

  const lowerPitch = waitlistUser.pitch?.toLowerCase() || '';
  if (priorityTags.some(priorityTag => lowerPitch.includes(priorityTag.tag))) return true;
  else return false;
};

export const getPitchSocials = pitch => {
  if (!pitch) return [];
  const splitPitch = pitch.split(/\s/);

  const urls = [];

  for (let i = 0; i < splitPitch.length; i++) {
    // this type of for loop because we may need next / previous word in edge cases
    const word = splitPitch[i];

    if (youtube_regex.test(word)) urls.push(cleanSocialUrl(word, 'https://youtube.com/@'));
    else if (instagram_regex.test(word)) urls.push(cleanSocialUrl(word, 'https://instagram.com/'));
    else if (tiktok_regex.test(word)) urls.push(cleanSocialUrl(word, 'https://tiktok.com/@'));
    else if (twitter_regex.test(word)) urls.push(cleanSocialUrl(word, 'https://twitter.com/'));
    else if (facebook_regex.test(word)) urls.push(cleanSocialUrl(word, 'https://facebook.com/'));
    else if (pinterest_regex.test(word)) urls.push(cleanSocialUrl(word, 'https://pinterest.com/'));
    else if (linktree_regex.test(word)) urls.push(cleanSocialUrl(word, 'https://linktr.ee/'));
    else if (substack_regex.test(word)) urls.push(word);
    // we make a special exception, if the user has @something in their pitch, we assume it's an instagram handle
    else if (word[0] === '@') urls.push(`https://instagram.com/${word.split('@').join('')}`);
    else {
      // some more special case handling here
      // CASE 1: pitch has ig: username or instagram: username
      const instagram_edge_case = /^(ig|instagram|insta)[\s-_,:]*/gi;
      const tiktok_edge_case = /^(tt|tiktok)[\s-_,:]*/gi;
      const youtube_edge_case = /^(yt|youtube)[\s-_,:]*/gi;

      const handleIsNextWord = word => {
        const splitWord = word.split(/[\s-_,:]+/);
        if (splitWord.length < 2) return true;
        return splitWord[1].length === 0;
      };

      if (instagram_edge_case.test(word)) {
        if (handleIsNextWord(word)) urls.push(cleanSocialUrl(`/${splitPitch[i + 1]}`, 'https://instagram.com/'));
        else urls.push(cleanSocialUrl(`/${word.replace(instagram_edge_case, '')}`, 'https://instagram.com/'));
      } else if (!urls.length && tiktok_edge_case.test(word)) {
        if (handleIsNextWord(word)) urls.push(cleanSocialUrl(`/${splitPitch[i + 1]}`, 'https://tiktok.com/@'));
        else urls.push(cleanSocialUrl(`/${word.replace(tiktok_edge_case, '')}`, 'https://tiktok.com/@'));
      } else if (!urls.length && youtube_edge_case.test(word)) {
        if (handleIsNextWord(word)) urls.push(cleanSocialUrl(`/${splitPitch[i + 1]}`, 'https://youtube.com/@'));
        else urls.push(cleanSocialUrl(`/${word.replace(youtube_edge_case, '')}`, 'https://youtube.com/@'));
      }
    }
  }

  const flattenedUrls = _.uniq(urls).filter(url => url.includes('https'));

  if (!flattenedUrls.length) {
    // as a last resort we'll see if there is standalone text at the end of the pitch (... || some_username)
    // if there is, we'll assume it's an instagram handle

    const pitchSplitBySocialIndicator = pitch.split(' || ');
    const splitSecondHalf = pitchSplitBySocialIndicator.length > 1 ? pitchSplitBySocialIndicator[1].trim().split(/\s/) : [];

    if (splitSecondHalf.length === 1) flattenedUrls.push(`https://instagram.com/${splitSecondHalf[0]}`);
  }

  return flattenedUrls;
};

/**
 * We try to recreate the social urls to make sure they are valid and clean.
 * basically we only need to rely on them spelling the social username correctly.
 * In the case we are not able to parse a valid username, we will return the original
 * url.
 *
 * @param {string} url - the full url of the social account
 * @param {string} baseUrl - what the username will be combined with to create the full url
 */
const cleanSocialUrl = (url, baseUrl) => {
  const username = getUsernameFromSocialUrl(url, true);
  if (!username) return url;

  return `${baseUrl}${username}`;
};

/**
 * function that will parse a string and search for a username in a social
 * url. i.e. youtube.com/c/HarryRein => HarryRein or
 * no_username_in_url.com => null or tiktok.com/@HarryRein => HarryRein
 *
 * important to note that the username must be at the end of the url. if
 * the username is in the middle (social.com/HarryRein/account), it will
 * not successfully return HarryRein, it will instead return "account"
 *
 * @param {string=} socialUrl the link to a user's social account i.e. youtube, tiktok, instagram, etc....
 * @param {boolean=} removeTrailingUrlPaths - if true, will remove everything after the second "/"
 * @return a standalone username that is unique to the user or null if a username wasn't found
 */
export const getUsernameFromSocialUrl = (socialUrl, removeTrailingUrlPaths) => {
  if (typeof socialUrl !== 'string') return null;

  socialUrl = socialUrl.trim();
  if (removeTrailingUrlPaths) {
    // get rid of any "/" before the domain
    socialUrl = socialUrl.replace('https://', '');
    socialUrl = socialUrl.replace('http://', '');

    // get rid of everything after the second "/"
    const splitUrl = socialUrl.split('/');
    if (splitUrl.lengh > 2) socialUrl = splitUrl.slice(0, 2).join('/');
  }

  // common paths in urls
  socialUrl = socialUrl.replace('/featured', '');
  socialUrl = socialUrl.replace('/videos', '');
  socialUrl = socialUrl.replace('/shorts', '');
  socialUrl = socialUrl.replace('/playlists', '');
  socialUrl = socialUrl.replace('/community', '');
  socialUrl = socialUrl.replace('/channels', '');
  socialUrl = socialUrl.replace('/about', '');
  socialUrl = socialUrl.replace('/pins', '');

  const username_regex = /\/@?([\w.-]+)(\/)?(\?.*)?$/gim;
  const matches = username_regex.exec(socialUrl);
  if (!matches) return null;

  const [, username] = matches;
  return username ? username : null;
};
