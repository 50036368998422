import React, { useState, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import './GraphTypes/GraphStyles.scss';
import './BrandReport.scss';

import { BrandReportControls } from './BrandReportControls';
import { BrandReportSections } from './BrandReportSections';
import { BrandReportComments } from './BrandReportComments';

import { updateBrand as updateBrandAPI } from '../../api/brands';

import { setUrlParam, getUrlParam } from '../../helpers/helpers';

const BrandReport = props => {
  const { allBrands, getBrandNote, notes } = props;
  const { note } = notes || {};

  const getUrlDates = () => {
    const today = new Date();
    const start = new Date(today);
    start.setMonth(start.getMonth() - 4);
    start.setDate(1); // start at beginning of month

    const startDate = getUrlParam('startDate') || moment(start).format('YYYY-MM-DD');
    const endDate = getUrlParam('endDate') || moment(today).format('YYYY-MM-DD');

    return { startDate, endDate };
  };

  const getBrand = () => {
    const Brand_id = getUrlParam('Brand_id');
    const brand = allBrands.filter(brand => String(brand.id) === Brand_id);
    return brand[0] || allBrands[0];
  };

  const [timeInterval, setTimeInterval] = useState({
    startDate: getUrlDates().startDate,
    endDate: getUrlDates().endDate,
    groupingMethod: getUrlParam('groupingMethod') || 'month',
    Brand_id: parseInt(getUrlParam('Brand_id')),
    brand: getBrand()
  });

  const updateBrand = async updates => {
    await updateBrandAPI(note.brand, updates);
  };

  // Fetch Data
  const [isFetchingBrandNote, setIsFetchingBrandNote] = useState(false);
  useEffect(() => {
    const { startDate, endDate, Brand_id, groupingMethod } = timeInterval;
    // console.log({ timeInterval });
    if (endDate) setUrlParam('endDate', endDate);
    if (startDate) setUrlParam('startDate', startDate);
    if (groupingMethod) setUrlParam('groupingMethod', groupingMethod);
    if (Brand_id) setUrlParam('Brand_id', Brand_id);

    // Fetch Data
    if (Brand_id) {
      setIsFetchingBrandNote(true);
      getBrandNote({ Brand_id }).then(() => {
        setIsFetchingBrandNote(false);
      });
    }
  }, [timeInterval, getBrandNote]);

  return (
    <div className='brand-report-outer-container'>
      <div className='sidebar-section'>
        <BrandReportControls notes={notes} setTimeInterval={setTimeInterval} timeInterval={timeInterval} allBrands={allBrands} />
      </div>
      <div className='main-section'>
        <BrandReportSections
          notes={notes}
          {...timeInterval}
          brand={timeInterval.brand}
          allBrands={allBrands}
          allCompetitorBrands={props.allCompetitorBrands}
        />
      </div>
      <div className='sidebar-section'>
        {!isFetchingBrandNote && note && <BrandReportComments {...props} updateBrand={updateBrand} notes={notes} note={note} />}
      </div>
    </div>
  );
};

BrandReport.propTypes = {
  allBrands: PropTypes.array.isRequired,
  allCompetitorBrands: PropTypes.array.isRequired,
  updateNote: PropTypes.func.isRequired,
  createComment: PropTypes.func.isRequired,
  updateComment: PropTypes.func.isRequired,
  deleteComment: PropTypes.func.isRequired
};

export default BrandReport;
