import React, { Component } from 'react';
import _ from 'lodash';
// import cn from 'classnames'
import './Chat.scss';

import ChatMessage from './ChatMessage';

import { getRecentMessages } from '../../api/admin';

let ws;
let MAX_RECONNECT_ATTEMPTS = 10;

class Chat extends Component {
  componentDidMount = () => {
    const connectToWSS = (attempt = 0) => {
      ws = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL);
      ws.onopen = () => console.log('Connected to message server.');
      ws.onclose = () => MAX_RECONNECT_ATTEMPTS > attempt && setTimeout(() => connectToWSS(attempt + 1), 1000);
      ws.onerror = () => console.log(`Disconnected from message server ${attempt}/${MAX_RECONNECT_ATTEMPTS}.`);
      ws.onmessage = evt => {
        const decodedData = JSON.parse(evt.data);
        const newMessages = _.concat(decodedData, this.state.messages);
        this.setState({ messages: newMessages });
      };
    };
    connectToWSS();
    getRecentMessages().then(messages => {
      this.setState({ messages });
    });
  };

  state = {
    messages: []
  };
  render() {
    const { messages } = this.state;
    return (
      <div className='chat-outer-container'>
        <div className='chat-inner-container'>
          {messages.map(({ chat, message }) => {
            return <ChatMessage key={message.id} chat={chat} message={message} />;
          })}
        </div>
      </div>
    );
  }
}

Chat.propTypes = {};

export default Chat;
