import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';
import { faEyeSlash, faSearch } from '@fortawesome/pro-light-svg-icons';

import './ProductSearchResult.scss';

import Tooltip from '../../General/Tooltip';

class ProductSearchResult extends Component {
  static propTypes = {
    catalog: PropTypes.object.isRequired,
    result: PropTypes.object.isRequired,
    selectProduct: PropTypes.func.isRequired,
    matchSelectedTicket: PropTypes.func.isRequired,
    inMatchingMode: PropTypes.bool.isRequired,
    isAlgolia: PropTypes.bool.isRequired,
    selectedProduct: PropTypes.object,
    selectedTicket: PropTypes.object,
    activeFilters: PropTypes.shape({
      department: PropTypes.object,
      category: PropTypes.object,
      tag: PropTypes.object,
      brand: PropTypes.string
    })
  };

  getCategory = () => _.find(this.props.catalog.categories, category => category.id === parseFloat(this.props.result?.Category_id));
  getDepartmentFromCategory = category => _.find(this.props.catalog.departments, department => department.id === category?.Department_id);

  isLeafLevel = () => !!this.props.activeFilters.tag || !!this.props.activeFilters.brand;
  isCategoryLevel = () => !this.isLeafLevel() && this.props.activeFilters.category;
  isDepartmentLevel = () => !this.isLeafLevel() && !this.isCategoryLevel() && this.props.activeFilters.department;
  isRootLevel = () => !this.isLeafLevel() && !this.isCategoryLevel() && !this.isDepartmentLevel();

  render() {
    const { result, selectProduct, selectedProduct, inMatchingMode, selectedTicket, isAlgolia } = this.props;
    const {
      title,
      brand,
      image,
      id,
      variants,
      fallbackUrl,
      fallbackPrice,
      isHidden,
      isSeoPrerendered,
      departmentRank,
      categoryRank,
      totalRank,
      insiderMentionCount,
      shelfMentionCount
    } = result;
    const category = this.getCategory();
    const department = this.getDepartmentFromCategory(category);
    const selected = selectedProduct && selectedProduct.id === id;

    const leafLevel = this.isLeafLevel();
    const categoryLevel = this.isCategoryLevel();
    const departmentLevel = this.isDepartmentLevel();
    const rootLevel = this.isRootLevel();

    const totalRankDiv = solo => (
      <div className={classnames('insider-mentions stat', { solo })}>
        <div className='number'>#{totalRank}</div>
        <div className='desc'>
          in {!solo && <br />}
          <b>Beauty</b>
        </div>
      </div>
    );
    const departmentRankDiv = solo => (
      <div className={classnames('insider-mentions stat', { solo })}>
        <div className='number'>#{departmentRank}</div>
        <div className='desc'>
          in {!solo && <br />}
          <b>{department.name}</b>
        </div>
      </div>
    );
    const categoryRankDiv = solo => (
      <div className={classnames('insider-mentions stat', { solo })}>
        <div className='number'>#{categoryRank}</div>
        <div className='desc'>
          in {!solo && <br />}
          <b>{category.name}</b>
        </div>
      </div>
    );

    return (
      <div className='product-search-result-outer-container'>
        <div
          onClick={() => !inMatchingMode && (selected ? selectProduct(null) : selectProduct(result))}
          className={classnames('product-search-result-inner-container', { selected, 'match-mode': inMatchingMode })}
        >
          <div className='product-wrapper'>
            <img className='product-image' alt={title} src={image} />
            <div className='product-data'>
              <div className='brand'>{brand}</div>
              <div className='title'>{title}</div>
              <div className='category'>{category && category.name}</div>
            </div>
          </div>
          {!isAlgolia && (
            <div className='metadata'>
              {insiderMentionCount} artist{insiderMentionCount === 1 ? '' : 's'}, {shelfMentionCount} {shelfMentionCount === 1 ? 'shelf' : 'shelves'}
            </div>
          )}
          {inMatchingMode && selectedTicket ? (
            <div onClick={() => this.props.matchSelectedTicket(result)} className='match-btn'>
              Make Match
            </div>
          ) : (
            !isAlgolia &&
            !!departmentRank && (
              <div className={classnames('ranking-box', { empty: leafLevel })}>
                {rootLevel && <>{totalRankDiv(true)}</>}
                {departmentLevel && <>{departmentRankDiv(true)}</>}
                {categoryLevel && (
                  <>
                    {categoryRankDiv(false)}
                    {departmentRankDiv(false)}
                  </>
                )}
              </div>
            )
          )}
          <div className='icons'>
            {!isAlgolia && !variants.length && (
              <div className={classnames('warning-icn', { full: !fallbackUrl || !fallbackPrice })}>
                <Tooltip
                  message={!fallbackUrl || !fallbackPrice ? 'No variants or fallback plan' : 'No current variants are available.'}
                  getIconDiv={() => <FontAwesomeIcon icon={faExclamationTriangle}></FontAwesomeIcon>}
                />
              </div>
            )}
            {!!isHidden && (
              <div className='hidden-icn full'>
                <Tooltip message='Hidden from rankings' getIconDiv={() => <FontAwesomeIcon icon={faEyeSlash}></FontAwesomeIcon>} />
              </div>
            )}
            {!!isSeoPrerendered && (
              <div className='hidden-icn full'>
                <Tooltip message='Pre-rendered for SEO' getIconDiv={() => <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ProductSearchResult;
