import React from 'react';
import cogoToast from 'cogo-toast';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './MLApiTabs.scss';

const MLApiTabs = props => {
  const { tabs } = props;
  return (
    <div className='ml-api-tabs-outer-container'>
      <div className='ml-api-tabs'>
        {tabs.map(tab => {
          const { variable, isSelected, isDisabled, display } = tab;
          const select = () => (isDisabled ? cogoToast.warn(`Coming soon!`) : props.selectTab(tab));
          return (
            <div key={variable} onClick={select} className={cn('ml-api-tab', { selected: isSelected, disabled: isDisabled })}>
              <div>{display}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

MLApiTabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  selectTab: PropTypes.func.isRequired
};

export default MLApiTabs;
