import React from 'react';
import PropTypes from 'prop-types';
import './LookbookOrdersControls.scss';
import cn from 'classnames';
import Select from 'react-select';
import SelectOption from '../General/SelectOption';

const LookbookOrdersControls = props => {
  const { tab, setTab, accountManagers, selectedAccountManager, setSelectedAccountManager } = props;

  const accountManagerOptions = accountManagers?.map(manager => {
    const { id: AccountManager_id, name, accounts_managed } = manager;
    return { label: name, sublabel: accounts_managed, value: AccountManager_id };
  });

  const updateAccountManagerId = option => {
    const { value: AccountManager_id } = option;
    if (AccountManager_id === 'all') setSelectedAccountManager(null);
    else setSelectedAccountManager(option);
  };

  return (
    <div className='lookbook-orders-controls-outer'>
      <div className='lookbook-orders-controls-inner'>
        <div className='lookbook-order-tab-container'>
          <div className={cn('lookbook-order-tab', { active: tab === 'all' })} onClick={() => setTab('all')}>
            <span>All</span>
          </div>
          <div className={cn('lookbook-order-tab', { active: tab === 'pending' })} onClick={() => setTab('pending')}>
            <span>Pending</span>
          </div>
          <div className={cn('lookbook-order-tab', { active: tab === 'submitted' })} onClick={() => setTab('submitted')}>
            <span>Submitted</span>
          </div>
          <div className={cn('lookbook-order-tab', { active: tab === 'shipped' })} onClick={() => setTab('shipped')}>
            <span>Shipped</span>
          </div>
          <div className={cn('lookbook-order-tab', { active: tab === 'delivered' })} onClick={() => setTab('delivered')}>
            <span>Delivered</span>
          </div>
          <div className={cn('lookbook-order-tab', { active: tab === 'canceled' })} onClick={() => setTab('canceled')}>
            <span>Canceled</span>
          </div>
        </div>

        <div className='lookbook-order-assignee-container'>
          <Select
            styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }}
            className='select'
            placeholder='Account Manager'
            value={selectedAccountManager}
            onChange={updateAccountManagerId}
            options={[{ label: 'All Managers', value: 'all' }, ...accountManagerOptions]}
            components={{ Option: SelectOption }}
          />
        </div>
      </div>
    </div>
  );
};

LookbookOrdersControls.propTypes = {
  tab: PropTypes.string.isRequired,
  setTab: PropTypes.func.isRequired,
  accountManagers: PropTypes.array.isRequired,
  selectedAccountManager: PropTypes.object,
  setSelectedAccountManager: PropTypes.func.isRequired
};

export default LookbookOrdersControls;
