import APIClient from './index';
import _ from 'lodash';

export const getTalentReportUsers = (params = {}) => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/TalentReports?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const getTalentReportModalUser = user_id => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/TalentReports/detailed/${user_id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const getUsersWithNoAccountManager = () => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/TalentReports/unset_users`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

/**
 * Each of the following functions require the same parameters:
 *
 * @typedef {Object} TalentReportSectionParams
 * @property {string} User_id - the user id of the talent
 * @property {string} startDate - YYYY-MM-DD the start of the date range
 * @property {string} endDate - YYYY-MM-DD the end of the date range
 * @property {('week'|'month'|'year')} groupingMethod - the method of grouping the data for the chart
 */

/** @param {TalentReportSectionParams} params */
export const getTalentReportClicks = (params = {}) => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/TalentReports/user_clicks?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

/** @param {TalentReportSectionParams} params */
export const getTalentReportPins = (params = {}) => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/TalentReports/user_pins?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

/** @param {TalentReportSectionParams} params */
export const getTalentReportVolume = (params = {}) => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/TalentReports/user_volume?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

/** @param {TalentReportSectionParams} params */
export const getTalentReportGifting = (params = {}) => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/TalentReports/user_gifting?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

/** @param {TalentReportSectionParams} params */
export const getTalentReportCustomCodes = (params = {}) => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/TalentReports/user_custom_codes?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

/** @param {TalentReportSectionParams} params */
export const getTalentReportChatsAggregated = (params = {}) => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/TalentReports/user_chats_aggregated?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

/** @param {TalentReportSectionParams} params */
export const getTalentReportChatsPerBrand = (params = {}) => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/TalentReports/user_chats_per_brand?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

/** @param {TalentReportSectionParams} params */
export const getTalentReportCollaborations = (params = {}) => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/TalentReports/user_collaborations?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

/** @param {TalentReportSectionParams} params */
export const getTalentTopConvertingDomains = (params = {}) => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/TalentReports/user_top_converting_retailers?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

/** @param {TalentReportSectionParams} params */
export const getTalentTopRetailers = (params = {}) => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/TalentReports/user_top_retailers?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

/** @param {TalentReportSectionParams} params */
export const getTalentTopPins = (params = {}) => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/TalentReports/user_top_pins?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

/** @param {TalentReportSectionParams} params */
export const getTalentTopCollections = (params = {}) => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/TalentReports/user_top_collections?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

/** @param {TalentReportSectionParams} params */
export const getTalentReferralHistory = (params = {}) => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/TalentReports/user_referral_history?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};
