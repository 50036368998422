import React from 'react';
import PropTypes from 'prop-types';
import './ScoreBreakdown.scss';

const ScoreBreakdown = props => {
  const { rawScore } = props;

  const scoreJson = JSON.parse(rawScore) || {};

  // this is an example structure of the raw score
  // {
  //     "name": "Adaleta",
  //     "tier": 4,
  //     "score": 18.04,
  //     "Bio (2)": 2,
  //     "Network": "SMS",
  //     "User_id": 13181,
  //     "username": "adaatude",
  //     "Photo (2)": 0,
  //     "Social (2)": 2,
  //     "Activity (15)": 6.91,
  //     "Pins Per Week": "10",
  //     "Stale Gifting": "0",
  //     "Total Gifting": "1",
  //     "Monthly Volume": 0,
  //     "Monthly Traffic": 22,
  //     "Weeks With Pins": "2",
  //     "Monthly Earnings": "-",
  //     "Setup Collabs (2)": 0,
  //     "Monthly Volume (50)": 0,
  //     "Referral Bonus (20)": 0,
  //     "Monthly Traffic (25)": 5.13,
  //     "Recent Collections (2)": 2
  // }

  const iconCutoff = 85;
  const trendsetterCutoff = 70;
  const ambassadorCutoff = 30;

  // score
  const score = scoreJson['score'] || 0;
  const scoreMax = 100;
  const scorePercent = (Math.min(score, 100) / scoreMax) * 100;

  // profile completion
  const photo = scoreJson['Photo (2)'] || 0;
  const bio = scoreJson['Bio (2)'] || 0;
  const setupCollabs = scoreJson['Setup Collabs (2)'] || 0;
  const connectedSocials = scoreJson['Social (2)'] || 0;
  const recentCollections = scoreJson['Recent Collections (2)'] || 0;
  const profileCompletionScore = photo + bio + setupCollabs + connectedSocials + recentCollections;
  const profileCompletionMax = 10;
  const profilePercent = (profileCompletionScore / profileCompletionMax) * 100;

  // recent activity
  const activity = scoreJson['Activity (15)'];
  const activityMax = 15;
  const activityPercent = (activity / activityMax) * 100;

  // traffic
  const traffic = scoreJson['Monthly Traffic (25)'];
  const trafficMax = 25;
  const trafficPercent = (traffic / trafficMax) * 100;

  // volume
  const volume = scoreJson['Monthly Volume (50)'];
  const volumeMax = 50;
  const volumePercent = (volume / volumeMax) * 100;

  return (
    <div className='score-breakdown-outer'>
      <div className='score-breakdown-inner'>
        {/* Total Score */}
        <div className='score-breakdown-section'>
          <div className='score-breakdown-section-title'>Score</div>
          <div className='background-pill'>
            <div className='foreground-pill' style={{ left: '0%', right: `${100 - scorePercent}%` }}></div>
            <div className='pill-label' style={{ left: '0%' }}>
              0
            </div>
            {scorePercent > 0 && scorePercent < 100 && (
              <div className='pill-label active' style={{ left: `${scorePercent}%` }}>
                {scorePercent}
              </div>
            )}
            <div className='pill-label inactive' style={{ left: `${iconCutoff}%`, transform: `translateX(-${iconCutoff}%)` }}>
              {iconCutoff}
            </div>
            <div className='pill-label inactive' style={{ left: `${trendsetterCutoff}%`, transform: `translateX(-${trendsetterCutoff}%)` }}>
              {trendsetterCutoff}
            </div>
            <div className='pill-label inactive' style={{ left: `${ambassadorCutoff}%`, transform: `translateX(-${ambassadorCutoff}%)` }}>
              {ambassadorCutoff}
            </div>
            <div className='pill-label' style={{ right: '0%' }}>
              {Math.max(scoreMax, score)}
            </div>
          </div>
        </div>

        {/* Profile Completion */}
        <div className='score-breakdown-section'>
          <div className='score-breakdown-section-title'>Profile Completion</div>
          <div className='background-pill'>
            <div className='foreground-pill' style={{ left: '0%', right: `${100 - profilePercent}%` }}></div>
            <div className='pill-label' style={{ left: '0%' }}>
              0
            </div>
            {profilePercent > 0 && profilePercent < 100 && (
              <div className='pill-label active' style={{ left: `${profilePercent}%` }}>
                {profileCompletionScore}
              </div>
            )}
            <div className='pill-label' style={{ right: '0%' }}>
              {profileCompletionMax}
            </div>
          </div>
        </div>

        {/* Activity */}
        <div className='score-breakdown-section'>
          <div className='score-breakdown-section-title'>Activity</div>
          <div className='background-pill'>
            <div className='foreground-pill' style={{ left: '0%', right: `${100 - activityPercent}%` }}></div>
            <div className='pill-label' style={{ left: '0%' }}>
              0
            </div>
            {activityPercent > 0 && activityPercent < 100 && (
              <div className='pill-label active' style={{ left: `${activityPercent}%` }}>
                {activity}
              </div>
            )}
            <div className='pill-label' style={{ right: '0%' }}>
              {activityMax}
            </div>
          </div>
        </div>

        {/* Traffic */}
        <div className='score-breakdown-section'>
          <div className='score-breakdown-section-title'>Traffic</div>
          <div className='background-pill'>
            <div className='foreground-pill' style={{ left: '0%', right: `${100 - trafficPercent}%` }}></div>
            <div className='pill-label' style={{ left: '0%' }}>
              0
            </div>
            {trafficPercent > 0 && trafficPercent < 100 && (
              <div className='pill-label active' style={{ left: `${trafficPercent}%` }}>
                {traffic}
              </div>
            )}
            <div className='pill-label' style={{ right: '0%' }}>
              {trafficMax}
            </div>
          </div>
        </div>

        {/* Volume */}
        <div className='score-breakdown-section'>
          <div className='score-breakdown-section-title'>Volume</div>
          <div className='background-pill'>
            <div className='foreground-pill' style={{ left: '0%', right: `${100 - volumePercent}%` }}></div>
            <div className='pill-label' style={{ left: '0%' }}>
              0
            </div>
            {volumePercent > 0 && volumePercent < 100 && (
              <div className='pill-label active' style={{ left: `${volumePercent}%` }}>
                {volume}
              </div>
            )}
            <div className='pill-label' style={{ right: '0%' }}>
              {volumeMax}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ScoreBreakdown.propTypes = {
  rawScore: PropTypes.string
};

export default ScoreBreakdown;
