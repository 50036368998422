import React from 'react';
import Loader from '../../General/Loader';
import { Table } from '../GraphTypes/Table';

import { getBrandReportHeaderLabel } from '../../../helpers/brand_helpers';
import PropTypes from 'prop-types';
import { faDownload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BrandConversionRates = props => {
  const { title, brandClicks: clicks, brandOrders: orders, downloading } = props;
  const success = !!clicks?.length || !!orders?.length;

  const download = async () => await props.download({ rows: make_table_rows(), fileName: 'brand_conversion_rate' });

  const make_table_rows = () => {
    const arr = orders || clicks;

    const total_clicks = {
      key: 'converstion_rate_total',
      label: 'Total Conversion Rate',
      description: '(orders to retail site + DTC site)',
      values: arr.map((_, index) => {
        const { week, month, quarter, total_orders } = orders[index] || {};
        const { total_clicks } = clicks[index] || {};
        const conversion_rate = (parseInt(total_orders || '0') / parseInt(total_clicks || '1')) * 100;
        return { label: getBrandReportHeaderLabel({ week, month, quarter }), value: `${conversion_rate.toFixed(3)}%` };
      })
    };

    const dtc_clicks = {
      key: 'converstion_rate_dtc',
      label: 'Total Conversion Rate DTC',
      description: `(order orders to dtc site)`,
      values: arr.map((_, index) => {
        const { week, month, quarter, total_orders_dtc } = orders[index] || {};
        const { total_clicks_dtc } = clicks[index];
        const conversion_rate = (parseInt(total_orders_dtc || '0') / parseInt(total_clicks_dtc || '1')) * 100;
        return { label: getBrandReportHeaderLabel({ week, month, quarter }), value: `${conversion_rate.toFixed(3)}%` };
      })
    };

    const retailers_clicks = {
      key: 'converstion_rate_retailers_total',
      label: 'Total Conversion Rate to Retailers',
      description: `(order orders to retailers of the brand)`,
      values: arr.map((_, index) => {
        const { week, month, quarter, total_orders, total_orders_dtc } = orders[index] || {};
        const { total_clicks, total_clicks_dtc } = clicks[index] || {};
        const conversion_rate =
          ((parseInt(total_orders || '0') - parseInt(total_orders_dtc || '0')) /
            (parseInt(total_clicks || '0') - parseInt(total_clicks_dtc || '1'))) *
          100;
        return { label: getBrandReportHeaderLabel({ week, month, quarter }), value: `${conversion_rate.toFixed(3)}%` };
      })
    };

    let dtc_is_same_as_total = true;
    for (let i = 0; i < total_clicks.values.length; ++i) {
      const total = total_clicks.values[i].value,
        dtc = dtc_clicks.values[i].value;
      dtc_is_same_as_total = dtc_is_same_as_total && total === dtc;
    }

    return dtc_is_same_as_total ? [total_clicks] : [total_clicks, dtc_clicks, retailers_clicks];
  };

  return (
    <div className='brand-card' id={title}>
      <div className='padding'>
        {!clicks || !orders ? (
          <Loader />
        ) : (
          <>
            {success && (
              <>
                <div className='header'>
                  <div className='description'>
                    <div className='title'>{title}</div>
                    <div className='sub-title'>
                      Conversion rates are defined as the ratio of orders to clicks. A high conversion rate would mean that the amount of orders is
                      similar to the amount of clicks, whereas as low conversion rate would have a much smaller amount of orders compared to clicks.
                    </div>
                  </div>
                  {!downloading && (
                    <div className='brand-card-controls' id={`${title}-card-controls`}>
                      <div className='rounded-button' onClick={() => download()}>
                        {downloading ? (
                          <Loader />
                        ) : (
                          <>
                            <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                            Download
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>

                <Table title='Brand Conversion Rates' data={make_table_rows()} />
              </>
            )}

            {!success && (
              <>
                <div className='error-centered'>
                  <div className='title'>Error Fetching {title}</div>
                  <div className='sub-title'>Could not fetch results</div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

BrandConversionRates.propTypes = {
  title: PropTypes.string.isRequired,
  brandClicks: PropTypes.array,
  brandOrders: PropTypes.array,

  download: PropTypes.func.isRequired,
  downloading: PropTypes.bool.isRequired
};

const brandConversionRatesConfig = {
  title: 'Conversion Rates'
};

export { BrandConversionRates, brandConversionRatesConfig };
