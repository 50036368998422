import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import './CreatePayout.scss';

class CreatePayout extends Component {
  static propTypes = {
    payouts: PropTypes.array.isRequired,
    consultResults: PropTypes.array.isRequired,
    contracts: PropTypes.array.isRequired,
    accounts: PropTypes.array.isRequired,
    sendPayment: PropTypes.func.isRequired
  };

  render() {
    const { payouts, consultResults, contracts, sendPayment } = this.props;
    const { name } = _.first(payouts) || {};
    const payoutTotal = this.getPayoutTotal();
    const paymentMethod = this.getPaymentMethod();
    const error = this.getErrorMessage();
    return (
      <div className='create-payout-outer-container'>
        <div className='ticket-header'>Payout for {name}</div>
        <div className='stats-container'>
          {!!payouts.length && (
            <div className='stat'>
              <div className='stat-header'>{payouts.length}</div>
              <div className='stat-label'>PAYOUT{payouts.length === 1 ? '' : 's'}</div>
            </div>
          )}
          {!!consultResults.length && (
            <div className='stat'>
              <div className='stat-header'>{consultResults.length}</div>
              <div className='stat-label'>CONSULT{consultResults.length === 1 ? '' : 's'}</div>
            </div>
          )}
          {!!contracts.length && (
            <div className='stat'>
              <div className='stat-header'>{contracts.length}</div>
              <div className='stat-label'>CONTRACT{contracts.length === 1 ? '' : 's'}</div>
            </div>
          )}
          <div className='stat'>
            <div className='stat-header'>${payoutTotal.toFixed(2)}</div>
            <div className='stat-label'>EARNINGS</div>
          </div>
          <div className='stat'>
            <div className='stat-header'>{paymentMethod || '-'}</div>
            <div className='stat-label'>METHOD</div>
          </div>
        </div>
        <div className='action-btns-container'>
          {this.isPrematureSubmission() && <div className='warning-msg'>Warning: This payment has not been fully paid to us</div>}
          {error ? (
            <div className='error-msg'>
              <div>{error}</div>
              <div onClick={() => sendPayment(payouts, consultResults, contracts, 'OFFLINE')} className='force-btn'>
                HANDLED OFFLINE
              </div>
            </div>
          ) : (
            <div onClick={() => sendPayment(payouts, consultResults, contracts, paymentMethod)} className='action-btn'>
              SEND PAYMENT
            </div>
          )}
        </div>
      </div>
    );
  }

  getUserId = () =>
    _.get(this.props.payouts, '0.User_id') || _.get(this.props.consultResults, '0.User_id') || _.get(this.props.contracts, '0.User_id');
  getAccounts = () => _.filter(this.props.accounts, account => account.User_id === this.getUserId());

  getPayoutTotal = () => {
    const { payouts, consultResults, contracts } = this.props;
    const fromCommissions = payouts.length ? _.sum(_.map(payouts, 'amountEarned')) : 0;
    const fromConsults = consultResults.length ? _.sum(_.map(consultResults, 'amountEarned')) : 0;
    const fromContracts = contracts.length ? _.sum(_.map(contracts, 'amountEarned')) : 0;
    return fromCommissions + fromConsults + fromContracts;
  };

  getPaymentMethod = () => {
    return _.get(this.getAccounts(), '0.source');
  };

  isPrematureSubmission = () => {
    return !!_.find(this.props.payouts, payout => payout.payment_status !== 'paid');
  };
  getErrorMessage = () => {
    const payoutTotal = this.getPayoutTotal();
    const userAccounts = this.getAccounts();
    const missingAccount = !userAccounts.length && payoutTotal;
    if (missingAccount) {
      return 'User does not have an associated payout account.';
    }
  };
}

export default CreatePayout;
