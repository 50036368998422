import React, { useState } from 'react';
import cn from 'classnames';
import { faDownload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import { Table } from '../../../BrandReports/GraphTypes/Table';
import BarGraph from '../../../BrandReports/GraphTypes/BarGraph';
import Loader from '../../../General/Loader';

import { getBrandReportHeaderLabel } from '../../../../helpers/brand_helpers';

const ConciergePins = props => {
  const { conciergePins, title, groupingMethod, downloading } = props;
  const success = !!conciergePins?.length;

  const [tab, setTab] = useState('table'); // graph, table
  const download_table = async () => await props.download({ rows: make_table_rows(), fileName: 'concierge_pins' });
  const download_graph = async () => await props.download({ graph: title });

  const make_table_rows = () => {
    let pin_count_running_total = 0;
    const pin_count = {
      key: 'pin_count_total',
      label: 'Pin Count',
      description: '(pins added to collections or links)',
      values: conciergePins.map(result => {
        const { week, month, quarter, pins } = result;
        pin_count_running_total += parseInt(pins || 0);
        return { label: getBrandReportHeaderLabel({ groupingMethod, week, month, quarter }), value: pins };
      })
    };
    pin_count.values.push({ label: 'Total', value: pin_count_running_total });

    let domains_running_total = 0;
    const unique_domains = {
      key: 'unique_domains_total',
      label: 'Unique Domains',
      description: `(number of different domains pins added from)`,
      values: conciergePins.map(result => {
        const { week, month, quarter, unique_domains } = result;
        domains_running_total += parseInt(unique_domains || 0);
        return { label: getBrandReportHeaderLabel({ groupingMethod, week, month, quarter }), value: unique_domains };
      })
    };
    unique_domains.values.push({ label: 'Total', value: domains_running_total });

    let collections_running_total = 0;
    const collections = {
      key: 'unique_collections_total',
      label: 'Unique Collections',
      description: `(number of different collections pins added to)`,
      values: conciergePins.map(result => {
        const { week, month, quarter, unique_collections } = result;
        collections_running_total += parseInt(unique_collections || 0);
        return { label: getBrandReportHeaderLabel({ groupingMethod, week, month, quarter }), value: unique_collections };
      })
    };
    collections.values.push({ label: 'Total', value: collections_running_total });

    let web_running_total = 0;
    const web = {
      key: 'web_total',
      label: 'Web Additions',
      description: `(pins added from website)`,
      values: conciergePins.map(result => {
        const { week, month, quarter, web } = result;
        web_running_total += parseInt(web || 0);
        return { label: getBrandReportHeaderLabel({ groupingMethod, week, month, quarter }), value: web };
      })
    };
    web.values.push({ label: 'Total', value: web_running_total });

    let app_running_total = 0;
    const app = {
      key: 'app_total',
      label: 'App Additions',
      description: `(pins added from app)`,
      values: conciergePins.map(result => {
        const { week, month, quarter, mobile } = result;
        app_running_total += parseInt(mobile || 0);
        return { label: getBrandReportHeaderLabel({ groupingMethod, week, month, quarter }), value: mobile };
      })
    };
    app.values.push({ label: 'Total', value: app_running_total });

    let snapshop_running_total = 0;
    const all_snapshop = {
      key: 'snapshop_total',
      label: 'Snapshop Additions',
      description: `(pins added from snapshop)`,
      values: conciergePins.map(result => {
        const { week, month, quarter, snapshop } = result;
        snapshop_running_total += parseInt(snapshop || 0);
        return { label: getBrandReportHeaderLabel({ groupingMethod, week, month, quarter }), value: snapshop };
      })
    };
    all_snapshop.values.push({ label: 'Total', value: snapshop_running_total });

    return [pin_count, unique_domains, collections, web, app, all_snapshop];
  };

  return (
    <div className='talent-report-card' id={title}>
      {!conciergePins ? (
        <Loader />
      ) : (
        <>
          {success && (
            <>
              <div className='header'>
                <div className='description'>
                  <div className='title'>{title}</div>
                  <div className='sub-title'>
                    Pins are the products that are added to the platform by talent. Pins can be added through the website, app, or snapshop.
                  </div>
                </div>
                {!downloading && (
                  <div className='talent-report-controls' id={`${title}-card-controls`}>
                    <div className='radio-btns'>
                      <div className={cn('radio-btn', { active: tab === 'graph' })} onClick={() => setTab('graph')}>
                        Graph
                      </div>
                      <div className={cn('radio-btn', { active: tab === 'table' })} onClick={() => setTab('table')}>
                        Table
                      </div>
                    </div>
                    <div className='rounded-button' onClick={() => (tab === 'table' ? download_table() : download_graph())}>
                      {downloading ? (
                        <Loader />
                      ) : (
                        <>
                          <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                          Download
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>

              {tab === 'table' && <Table title='Pin Creations' data={make_table_rows()} />}
              {tab === 'graph' && (
                <BarGraph
                  title='Pin Creations'
                  data={conciergePins.map(click => {
                    const { week, month, quarter, year, pins } = click;
                    const data = {
                      key: `${week || month || quarter}${year}pins`,
                      value: pins,
                      label: getBrandReportHeaderLabel({ groupingMethod, week, month, quarter })
                    };
                    return data;
                  })}
                />
              )}
            </>
          )}

          {!success && (
            <>
              <div className='error-centered'>
                <div className='title'>Error Fetching {title}</div>
                <div className='sub-title'>No results.</div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

ConciergePins.propTypes = {
  title: PropTypes.string.isRequired,
  conciergePins: PropTypes.array,
  groupingMethod: PropTypes.string.isRequired,

  download: PropTypes.func.isRequired,
  downloading: PropTypes.bool.isRequired
};

const ConciergePinsConfig = {
  title: 'Pins'
};

export { ConciergePins, ConciergePinsConfig };
