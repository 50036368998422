import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './ConfirmPrompt.scss';

const ConfirmPrompt = props => {
  const {
    isSingleLine,
    onCancel,
    onCustom,
    preloaded,
    placeholder,
    onSubmit,
    header,
    subheader,
    rows,
    submitBtnDisplay,
    cancelBtnDisplay,
    customBtnDisplay
  } = props;
  const [inputVal, setInputVal] = useState(preloaded || '');
  return (
    <div className='confirm-prompt-outer-container react-confirm-alert-body'>
      <h1 className='header'>{header}</h1>
      <div className='subheader'>{subheader}</div>
      {isSingleLine ? (
        <input type='text' placeholder={placeholder || ''} onChange={({ target }) => setInputVal(target.value)} value={inputVal} />
      ) : (
        <textarea
          type='text'
          rows={rows || 6}
          placeholder={placeholder || ''}
          onChange={({ target }) => setInputVal(target.value)}
          value={inputVal}
        />
      )}
      <div className='react-confirm-alert-button-group'>
        <button className='cancel-btn' onClick={() => onCancel()}>
          {cancelBtnDisplay || 'Cancel'}
        </button>
        {customBtnDisplay && (
          <button className='cancel-btn' onClick={() => onCustom(inputVal)}>
            {customBtnDisplay}
          </button>
        )}
        <button onClick={() => onSubmit(inputVal)}>{submitBtnDisplay || 'Submit'}</button>
      </div>
    </div>
  );
};

ConfirmPrompt.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCustom: PropTypes.func,
  header: PropTypes.string.isRequired,
  subheader: PropTypes.string,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  customBtnDisplay: PropTypes.string,
  submitBtnDisplay: PropTypes.string,
  cancelBtnDisplay: PropTypes.string,
  isSingleLine: PropTypes.bool
};

export default ConfirmPrompt;
