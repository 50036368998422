import APIClient from './index';
import _ from 'lodash';

/**
 * Gets all the notes for multiple user ids
 * @param {Object} params
 * @param {string|string[]} params.user_ids - multiple user ids separated by commas
 * @returns
 */
export const getMultipleUserNotes = (params = {}) => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/Notes/users?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const getBrandNote = (params = {}) => {
  return new Promise((resolve, reject) => {
    APIClient.get(`Notes/brand/${params.Brand_id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const updateNote = (note, updates = {}) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`Notes/${note.id}`, updates)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const getUserNote = (params = {}) => {
  return new Promise((resolve, reject) => {
    APIClient.get(`Notes/user/${params.User_id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};
