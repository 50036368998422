import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';

import './PastInvoices.scss';

class PastInvoices extends Component {
  static propTypes = {
    invoices: PropTypes.array.isRequired
  };

  render() {
    const { invoices } = this.props;
    return (
      <div className='pending-payouts-outer-container'>
        {
          <div className='accordion'>
            <h1>Invoices (Last 12 Months)</h1>
            <div className='section'>
              <div className='body'>
                <div className='row header'>
                  <div className='cell'>ID</div>
                  <div className='cell'>Brand</div>
                  <div className='cell'>Sent On</div>
                  <div className='cell'>Amount</div>
                  <div className='cell'>Network Fee</div>
                  <div className='cell'>Status</div>
                  <div className='cell'>Type</div>
                  <div className='cell'>Payment URL</div>
                </div>
                {_.map(_.orderBy(invoices, 'createdAt', 'desc'), (invoice, idx) => {
                  const { url, amount, network_fee, createdAt, stripeInvoiceId, status, id, Brand_name } = invoice;

                  return (
                    <div key={idx} className='row'>
                      <div className='cell'>{id}</div>
                      <div className='cell'>{Brand_name}</div>
                      <div className='cell'>{moment(createdAt).format('MMM Do')}</div>
                      <div className='cell'>${amount.toFixed(2)}</div>
                      <div className='cell'>${network_fee.toFixed(2)}</div>
                      <div className='cell'>{status}</div>
                      <div className='cell'>{stripeInvoiceId ? 'STRIPE' : url ? 'PAYPAL' : 'MANUAL'}</div>
                      <a href={url} target='_blank' rel='noopener noreferrer' className='cell'>
                        {url ? 'INVOICE URL' : '-'}
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default PastInvoices;
