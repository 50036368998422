import APIClient from './index';
import _ from 'lodash';

/**
 * @param {Object} data
 * @param {number} params.Note_id - id of the note this comment is attributed to
 * @param {string} params.type - type of comment (general, reminder, task)
 * @param {string} params.comment - full comment string
 */
export const createComment = (data = {}) => {
  return new Promise((resolve, reject) => {
    APIClient.post(`NoteComments`, data)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

/**
 * @param {Object} comment - full comment object
 * @param {number} comment.id - id of the comment being updated
 * @param {Object} params
 * @param {string} params.comment - full comment string
 * @param {number} params.User_id
 */
export const updateComment = (comment, params = {}) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`NoteComments/${comment.id}`, params)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

/**
 * @param {Object} comment - full comment object
 * @param {number} comment.id - id of the comment being updated
 */
export const deleteComment = comment => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`NoteComments/${comment.id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};
