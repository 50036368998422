import APIClient from './index';
import _ from 'lodash';

export const getPayouts = () => {
  return new Promise((resolve, reject) => {
    APIClient.get(`Payouts`)
      .then(response => resolve(response.data))
      .catch(err => reject(err));
  });
};

export const getPendingPayouts = () => {
  return new Promise((resolve, reject) => {
    APIClient.get(`Payouts/pending_payouts`)
      .then(response => resolve(response.data))
      .catch(err => reject(err));
  });
};

export const getPayments = () => {
  return new Promise((resolve, reject) => {
    APIClient.get(`Payments`)
      .then(response => resolve(response.data))
      .catch(err => reject(err));
  });
};

export const sendPayment = (payouts, consultResults, contracts, source) => {
  return new Promise((resolve, reject) => {
    APIClient.post(`Payments`, {
      consultresultids: _.map(consultResults, 'id'),
      payoutids: _.map(payouts, 'id'),
      contractids: _.map(contracts, 'id'),
      userid: _.get(payouts, '0.User_id') || _.get(consultResults, '0.User_id') || _.get(contracts, '0.User_id'),
      source,
      allowUnpaidPayouts: true
    })
      .then(response => resolve(response.data))
      .catch(err => reject(err.data));
  });
};
