import React, { Component } from 'react';
import PropTypes from 'prop-types'
// import _ from 'lodash'
// import classnames from 'classnames'

import ProductSearchResult from './ProductSearchResult'

import './ProductSearchResults.scss';

class ProductSearchResults extends Component {
  static propTypes = {
    catalog: PropTypes.object.isRequired,
    results: PropTypes.array.isRequired,
    isSearching: PropTypes.bool.isRequired,
    selectProduct: PropTypes.func.isRequired,
    matchSelectedTicket: PropTypes.func.isRequired,
    inMatchingMode: PropTypes.bool.isRequired,
    selectedTicket: PropTypes.object,
    selectedProduct: PropTypes.object,
    activeFilters: PropTypes.shape({
      department: PropTypes.object,
      category: PropTypes.object,
      tag: PropTypes.object,
      brand: PropTypes.string,
    }),
  }

  render() {
    const { results, catalog, isSearching, activeFilters } = this.props;
    return (
      <div className='product-search-results-outer-container'>
        {
          results.length ?
            <div className='product-search-results'>
              {
                results.map(result => (
                  <ProductSearchResult
                    key={result.objectID || result.id}
                    isAlgolia={!!result.objectID}
                    catalog={catalog}
                    result={result}
                    selectProduct={this.props.selectProduct}
                    matchSelectedTicket={this.props.matchSelectedTicket}
                    inMatchingMode={this.props.inMatchingMode}
                    selectedTicket={this.props.selectedTicket}
                    activeFilters={activeFilters}
                    selectedProduct={this.props.selectedProduct}
                  />
                ))
              }
            </div>
            :
            !isSearching && <div className='empty-msg'>No results</div>
        }
      </div>
    )
  }
}

export default ProductSearchResults
