import axios from 'axios';

axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}/api`;
axios.defaults.headers['Content-Type'] = 'application/json';

const APIClient = () => {
  return axios;
};

export default APIClient();
