import React, { useState } from 'react';
import Loader from '../../General/Loader';

import { Table } from '../GraphTypes/Table';
import { getBrandReportHeaderLabel } from '../../../helpers/brand_helpers';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { faDownload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BrandTopClicks = props => {
  const { title, brandTopPromoters, downloading, groupingMethod } = props;
  const { allResults: promoters, dtcResults: dtcPromoters, nonDtcResults: nonDtcPromoters } = brandTopPromoters || {};
  const success = !!promoters;

  const [tab, setTab] = useState('all');
  const download = async () => await props.download({ rows: make_table_rows(tab), fileName: `brand_top_clicks_${tab}` });

  const make_table_rows = tab => {
    if (!promoters || !promoters[0]) return;

    const uniquePromoters = new Map();
    let promoterType;

    if (tab === 'all') promoterType = promoters;
    else if (tab === 'dtc') promoterType = dtcPromoters;
    else promoterType = nonDtcPromoters;

    for (const { results: apiResults } of promoterType) {
      for (const promoter of apiResults) {
        if (!uniquePromoters.has(promoter.User_name)) uniquePromoters.set(promoter.User_name, promoter);
      }
    }

    const fetchPromoterFromGroup = (group, User_id) => group.find(promoter => promoter.User_id === User_id);

    const finalResults = [];
    for (const promoter of uniquePromoters.values()) {
      const { User_name, username, User_id } = promoter;
      const data = { key: User_id, label: User_name, href: `https://shopmy.us/${username}`, description: `shopmy.us/${username}`, values: [] };
      let oneIsNonZero = false;

      let runningTotal = 0;
      for (const group of promoterType) {
        const { week, month, quarter, results: apiResults } = group;
        const promoterFromGroup = fetchPromoterFromGroup(apiResults, User_id) || {};
        const clicks = parseInt(promoterFromGroup.views) || 0;
        if (clicks > 0) oneIsNonZero = true;
        runningTotal += clicks;
        data.values.push({ label: getBrandReportHeaderLabel({ week, month, quarter, groupingMethod }), value: `${clicks}` });
      }
      data.values.push({ label: 'Total', value: `${runningTotal}` });

      oneIsNonZero && finalResults.push(data);
    }

    return finalResults
      .sort((a, b) => {
        // sort by total clicks
        const aTotal = a.values.reduce((acc, { value }) => acc + parseInt(value), 0);
        const bTotal = b.values.reduce((acc, { value }) => acc + parseInt(value), 0);
        return bTotal - aTotal;
      })
      .slice(0, 50);
  };

  return (
    <>
      <div className='brand-card' id={title}>
        <div className='padding'>
          {!brandTopPromoters ? (
            <Loader />
          ) : (
            <>
              {success && (
                <>
                  <div className='header'>
                    <div className='description'>
                      <div className='title'>{title}</div>
                      <div className='sub-title'>
                        The top promoters show best performers for a brand. It shows how many clicks the top promoters have generated for a brand.
                        This is queryable on all promoters, direct promoters, and non-direct promoters.
                      </div>
                    </div>
                    <div className='brand-card-controls'>
                      <div className='radio-btns'>
                        <div className={cn('radio-btn', { active: tab === 'dtc' })} onClick={() => setTab('dtc')}>
                          DTC
                        </div>
                        <div className={cn('radio-btn', { active: tab === 'non_dtc' })} onClick={() => setTab('non_dtc')}>
                          NON-DTC
                        </div>
                        <div className={cn('radio-btn', { active: tab === 'all' })} onClick={() => setTab('all')}>
                          All
                        </div>
                      </div>
                      <div className='rounded-button' onClick={() => download()}>
                        {downloading ? (
                          <Loader />
                        ) : (
                          <>
                            <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                            Download
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  {tab === 'dtc' && <Table title='Brand Top Clicks' data={make_table_rows('dtc')} />}
                  {tab === 'non_dtc' && <Table title='Brand Top Clicks' data={make_table_rows('non_dtc')} />}
                  {tab === 'all' && <Table title='Brand Top Clicks' data={make_table_rows('all')} />}
                </>
              )}

              {!success && (
                <>
                  <div className='error-centered'>
                    <div className='title'>Error Fetching {title}</div>
                    <div className='sub-title'>Could not fetch results</div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

BrandTopClicks.propTypes = {
  title: PropTypes.string.isRequired,
  brandTopPromoters: PropTypes.object,
  groupingMethod: PropTypes.string.isRequired,

  download: PropTypes.func.isRequired,
  downloading: PropTypes.bool.isRequired
};

const BrandTopClicksConfig = {
  title: 'Top Clicks'
};

export { BrandTopClicks, BrandTopClicksConfig };
