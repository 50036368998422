import { useEffect } from 'react';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';

import { getAllRegistrationCodes } from '../../../api/registration_codes';

const ConciergeCodesModalData = props => {
  const { setIsLoading, setRegistrationCodes } = props;

  useEffect(() => {
    setIsLoading(true);
    getAllRegistrationCodes()
      .then(response => setRegistrationCodes(response))
      .catch(err => cogoToast.error(err))
      .finally(() => setIsLoading(false));
  }, []);

  return null;
};

ConciergeCodesModalData.propTypes = {
  setIsLoading: PropTypes.func.isRequired,
  setRegistrationCodes: PropTypes.func.isRequired
};

export default ConciergeCodesModalData;
