export const isSubscribedToFeature = (brand, feature) => {
  /*
    Checks whether one of the brand's subscription items includes access to the feature.
  */
  const items = brand?.subscription?.items || [];
  const itemThatUnlocksFeature = items.find(item => {
    const itemUnlocksFeature =
      item.offer.canAccessEnum?.split(',')?.includes(feature) || item.offer.canAccessDeprecatedEnum?.split(',')?.includes(feature);
    const itemIsActive = !item.isCancelled;
    return itemUnlocksFeature && itemIsActive;
  });

  return !!itemThatUnlocksFeature;
};
