import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Comment from '../Comment/Comment';
import Select from 'react-select';
import './BrandReportComments.scss';

import { matchScrollHeight } from '../../helpers/helpers';

let debouncers = {},
  DEBOUNCE_DURATION = 500;

export const BrandReportComments = props => {
  const { note, notes } = props;
  const { brand } = note;
  const { account_manager } = brand || {};
  const [internalNote, setInternalNote] = useState(note.internalNote);
  const [externalNote, setExternalNote] = useState(note.externalNote);

  const unassignedOption = {
    label: 'Unassigned',
    value: null
  };
  const accountManagerOptions = [
    unassignedOption,
    ...notes.all_managers.map(u => ({
      label: `${u.name} (${u.accounts_managed || 0})`,
      value: u.id
    }))
  ];

  const onChangeAccountManager = ({ value }) => {
    props.updateBrand({ AccountManager_id: value });
  };

  const updateInternalNote = newVal => {
    setInternalNote(newVal);
    clearTimeout(debouncers.internalNote);
    debouncers.internalNote = setTimeout(() => {
      props.updateNote(note, { internalNote: newVal });
    }, DEBOUNCE_DURATION);
  };

  const updateExternalNote = newVal => {
    setExternalNote(newVal);
    clearTimeout(debouncers.externalNote);
    debouncers.externalNote = setTimeout(() => {
      props.updateNote(note, { externalNote: newVal });
    }, DEBOUNCE_DURATION);
  };

  return (
    <div className='brand-report-comments-outer'>
      <div className='brand-report-comments-inner'>
        {note && (
          <>
            {/* <div className='section'>
              <div className='header'>ShopMy Notes</div>
              <div className='sub-header'>Come here to document any notes you might have for a brand.</div>
            </div> */}

            <div className='section'>
              <div className='section-header-section'>
                <div className='section-header'>Account Manager</div>
                <Select
                  className='select'
                  placeholder='Brand'
                  defaultValue={account_manager ? accountManagerOptions.find(u => account_manager.id === u.value) : unassignedOption}
                  onChange={onChangeAccountManager}
                  options={accountManagerOptions}
                />
              </div>
            </div>

            <div className='section'>
              <div className='section-header-section'>
                <div className='section-header'>Internal Summary</div>
                <div className='section-subheader'>
                  This is an internal only summary that you should use to describe this brand and their behavior at a high level.
                </div>
              </div>
              <textarea
                // rows='10'
                defaultValue={internalNote}
                placeholder={"Gifts every month, doesn't offer codes, ...."}
                onChange={e => updateInternalNote(e.target.value)}
                ref={ref => {
                  matchScrollHeight(ref);
                }}
              ></textarea>
            </div>

            <div className='section'>
              <div className='section-header-section'>
                <div className='section-header'>External Summary</div>
                <div className='section-subheader'>
                  Coming Soon. This note will be shown to our icons and trendsetters to give more context on how we recommend partnering with the
                  brand.
                </div>
              </div>
              <textarea
                // rows='10'
                defaultValue={externalNote}
                placeholder={'Looking for new collaborations, offers large discount codes, ...'}
                onChange={e => updateExternalNote(e.target.value)}
                ref={ref => {
                  matchScrollHeight(ref);
                }}
              ></textarea>
            </div>

            <div className='section'>
              <div className='section-header-section'>
                <div className='section-header'>Comments</div>
              </div>
              <Comment {...props} note={note} isNew={true} />
              {note?.comments?.map(comment => (
                <Comment comment={comment} note={note} {...props} key={comment.id} />
              ))}
              {/* <Comment Note_id={note.id} comment={'This is the best comment of all time'} /> */}
            </div>
          </>
        )}

        {!note && (
          <>
            <div>Error fetching Brand comments</div>
          </>
        )}
      </div>
    </div>
  );
};

BrandReportComments.propTypes = {
  note: PropTypes.object.isRequired,
  notes: PropTypes.object.isRequired,
  updateNote: PropTypes.func.isRequired,
  updateBrand: PropTypes.func.isRequired,
  createComment: PropTypes.func.isRequired,
  updateComment: PropTypes.func.isRequired,
  deleteComment: PropTypes.func.isRequired
};

export default BrandReportComments;
