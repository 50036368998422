import React from 'react';
import PropTypes from 'prop-types';
import './ConciergeComment.scss';
import moment from 'moment';
import cn from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';
import cogoToast from 'cogo-toast';

const ConciergeComment = props => {
  const { Comment_id, comment, User_name, Commenter_name, isRead, isDismissed, createdAt, type, dueDate } = props.comment;

  const setCommentRead = () => props.updateComment({ ...props.comment, isRead: !isRead });
  const setCommentDismissed = () => props.updateComment({ ...props.comment, isDismissed: !isDismissed });
  const setCommentDueDate = () => {
    const dueDate = window.prompt(
      'What do you want the due date for this comment to be?\n\nAcceptable formats:\n6/27\n06-27-2023\nJune 27\n\nLeave blank to remove due date.'
    );
    const dueDateMoment = moment(dueDate);
    if (!dueDateMoment.isValid()) return cogoToast.error('Invalid date format. Please try again.');

    // handle the case that they only entered a month and day
    dueDateMoment.year(moment().year());
    if (dueDateMoment.isBefore(moment())) dueDateMoment.add(1, 'year');

    console.log({ dueDate: dueDateMoment.format('MMMM Do YYYY'), id: Comment_id });
    props.updateComment({ ...props.comment, dueDate: dueDateMoment.format('YYYY-MM-DD 00:00:00') });
  };
  const update_comment = () => {
    const message = window.prompt('Update the comment.', comment);
    if (!message) return;
    props.updateComment({ ...props.comment, comment: message });
  };

  return (
    <div className='concierge-comment-outer'>
      <div className='comment-icons'>
        <FontAwesomeIcon className={cn('comment-icon', { isRead })} icon={faCheck} onClick={setCommentRead} />
        <FontAwesomeIcon className={cn('comment-icon', { isDismissed })} icon={faTimes} onClick={setCommentDismissed} />
      </div>
      <div className={cn('concierge-comment-inner', { isRead })}>
        <div className='comment'>
          <div className='comment-text' onClick={update_comment}>
            {comment}
          </div>
          <div className='authors'>
            <span>{User_name}</span>
            &nbsp;&nbsp;
            <span className='bullet'>•</span>
            &nbsp;&nbsp;
            <span>{Commenter_name}</span>
            <div>
              <span>{moment(createdAt).format('MMM DD, YYYY')}</span>
              &nbsp;&nbsp;
              <span className='bullet'>•</span>
              &nbsp;&nbsp;
              <span>{type}</span>
              {type === 'task' && (
                <>
                  &nbsp;&nbsp;
                  <span className='bullet'>•</span>
                  &nbsp;&nbsp;
                  <span className='clickable' onClick={setCommentDueDate}>
                    {dueDate ? `Due ${moment(dueDate).format('MMM DD')}` : 'Set Due Date'}
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ConciergeComment.propTypes = {
  comment: PropTypes.object.isRequired,
  updateComment: PropTypes.func.isRequired
};

export default ConciergeComment;
