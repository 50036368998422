import cogoToast from 'cogo-toast';
import moment from 'moment';
import _ from 'lodash';
import slugify from 'slugify';
import * as htmlToImage from 'html-to-image';

export const getRootSMSUrl = () => (isDevelopment() ? 'http://localhost:3000' : `https://${isProduction() ? '' : 'staging.'}shopmy.us`);

// export const copyToClipboard = (str, logSuccess, logTime = 1) => {
//   const el = document.createElement('textarea'); // Create a <textarea> element
//   el.value = str; // Set its value to the string that you want copied
//   el.setAttribute('readonly', ''); // Make it readonly to be tamper-proof
//   el.style.position = 'absolute';
//   el.style.left = '-9999px'; // Move outside the screen to make it invisible
//   document.body.appendChild(el); // Append the <textarea> element to the HTML document
//   const selected =
//     document.getSelection().rangeCount > 0 // Check if there is any content selected previously
//       ? document.getSelection().getRangeAt(0) // Store selection if found
//       : false; // Mark as false to know no selection existed before
//   el.select(); // Select the <textarea> content
//   document.execCommand('copy'); // Copy - only works as a result of a user action (e.g. click events)
//   document.body.removeChild(el); // Remove the <textarea> element
//   if (selected) {
//     // If a selection existed before copying
//     document.getSelection().removeAllRanges(); // Unselect everything on the HTML document
//     document.getSelection().addRange(selected); // Restore the original selection
//   }
//   logSuccess && cogoToast.success(typeof logSuccess === 'string' ? logSuccess : 'Copied to clipboard', { hideAfter: logTime });
// };

/**
 *
 * @param {string} str - string to copy to clipboard
 * @param {boolean} logSuccess - whether to log success
 * @param {number} logTime - how long the success message should be displayed (default 1 second)
 */
export const copyToClipboard = async (str, logSuccess, logTime = 1) => {
  await navigator.clipboard.writeText(str);
  logSuccess && cogoToast.success(typeof logSuccess === 'string' ? logSuccess : 'Copied to clipboard', { hideAfter: logTime });
};

export const isProduction = () => window.location.href.includes('shopmyshelf.us');
export const isDevelopment = () => window.location.href.includes('localhost');

export const getPrettyPriceDisplay = priceRaw => {
  if (!priceRaw) return '';
  const price = parseFloat(priceRaw);
  return `$${price.toFixed(price === Math.floor(price) ? 0 : 2)}`;
};
export const getServerFormattedDate = () => moment().format('YYYY-MM-DD HH:mm:ss');

export const getSmartImage = img => {
  if (!img) {
    return null;
  }

  let newImage = img;

  // Parse object if it exists
  newImage = typeof newImage === 'object' ? _.first(newImage) : newImage;

  // Add https in case it doesn't exist
  newImage = newImage.split('http://').join('https://');

  // Add Custom handling for Target
  if (img.includes('target.scene7') || img.includes('images.bloomingdaleassetts') || img.includes('r29static')) {
    newImage = `${newImage}${newImage.includes('?') ? '&' : '?'}&fmt=pjpeg`;
  }

  // Handle cloudfront optimization
  newImage = newImage.replace('production-shopmyshelf-pins.s3.us-east-2.amazonaws.com', 'static.myshlf.us/pins');
  newImage = newImage.replace('production-shopmyshelf-uploads.s3-us-east-2.amazonaws.com', 'static.myshlf.us/uploads');
  newImage = newImage.replace('askemma-static-public.s3.us-east-2.amazonaws.com', 'static.myshlf.us');

  return newImage;
};

export const getUrlParam = param => new URLSearchParams(window.location.search).get(param);
export const setUrlParam = (param, value) => {
  const urlParams = new URLSearchParams(window.location.search);
  urlParams.set(param, value);
  const new_url = window.location.origin + window.location.pathname + '?' + urlParams.toString();
  window.history.replaceState({}, document.title, new_url);
};
export const deleteUrlParam = param => {
  const urlParams = new URLSearchParams(window.location.search);
  urlParams.delete(param);
  const new_url = window.location.origin + window.location.pathname + '?' + urlParams.toString();
  window.history.replaceState({}, document.title, new_url);
};

export const downloadToCsv = async (rows, name = 'Download') => {
  /*
    data = [{
      'Header 1': 'Value 1' 
    },{
      'Header 1': 'Value 2' 
    }]
  */
  let headers = _.keys(rows[0]);
  const cleanValue = val =>
    typeof val !== 'string'
      ? val
      : val
          ?.split(',')
          ?.join('-')
          ?.split('#')
          ?.join('Number');
  let csvContent =
    'data:text/csv;charset=utf-8,' +
    `${headers.map(cleanValue).join(',')}\n` +
    rows
      .map(d =>
        _.values(d)
          .map(cleanValue)
          .join(',')
      )
      .join('\n');
  const encodedUri = encodeURI(csvContent);
  var downloadLink = document.createElement('a');
  downloadLink.href = encodedUri;
  downloadLink.download = `${name}.csv`;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export const saveImageAs = (dataUrl, name) => {
  const image_element = document.createElement('a');
  image_element.href = dataUrl;
  image_element.download = name;
  document.body.appendChild(image_element);
  image_element.click();
  document.body.removeChild(image_element);
};

export const downloadImageUsingElementId = async elementId => {
  const downloadName = `${slugify(elementId.split('_')[0])}.png`;
  const node = document.getElementById(elementId);
  const config = { cacheBust: true };

  htmlToImage.toPng(node, config).then(data_url => {
    saveImageAs(data_url, downloadName);
  });
};

export const wait = (timeToWait = 500) => new Promise(r => setTimeout(r, timeToWait));

export const matchScrollHeight = element => {
  /*
    Use this to lock a textarea to the height of it's inner contents, for example:
      <textarea rows={4} ref={e => matchScrollHeight(e)} />
  */
  element && (element.style.height = `inherit`);
  element && (element.style.height = `${element.scrollHeight}px`);
};

export const randomizeArray = array => {
  const newArr = [...array];
  for (let i = 0; i < newArr.length; i++) {
    const j = Math.floor(Math.random() * (i + 1));
    [newArr[i], newArr[j]] = [newArr[j], newArr[i]];
  }

  // return copy so react can map over it
  return newArr;
};
