import APIClient from './index';
import _ from 'lodash';

export const getBrands = () => {
  return new Promise((resolve, reject) => {
    APIClient.get(`Brands`)
      .then(response => resolve(response.data))
      .catch(err => reject(err));
  });
};

export const getBrandBasicObjects = () => {
  return new Promise((resolve, reject) => {
    APIClient.get(`Brands/basic_objects`)
      .then(response => resolve(response.data))
      .catch(err => reject(err));
  });
};

export const updateBrand = (brand, updates) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`Brands/${brand.id}`, updates)
      .then(response => resolve(response.data))
      .catch(err => reject(err));
  });
};
export const updateBrandSettings = (settings, updates) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`BrandSettings/${settings.id}`, updates)
      .then(response => resolve(response.data))
      .catch(err => reject(err));
  });
};

export const getRegistrationCodes = () => {
  return new Promise((resolve, reject) => {
    APIClient.get(`RegistrationCodes`)
      .then(response => resolve(response.data))
      .catch(err => reject(err));
  });
};

export const addRegistrationCode = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`RegistrationCodes`, data)
      .then(response => resolve(response.data))
      .catch(err => reject(err));
  });
};

export const updateRegistrationCode = (code, updates) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`RegistrationCodes/${code.id}`, updates)
      .then(response => resolve(response.data))
      .catch(err => reject(err));
  });
};

export const deleteRegistrationCode = code => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`RegistrationCodes/${code.id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(err));
  });
};

export const getPendingCommissions = () => {
  return new Promise((resolve, reject) => {
    APIClient.get(`AffiliateCommissions/pending_commissions`)
      .then(response => resolve(response.data))
      .catch(err => reject(err));
  });
};

export const getAffiliateCommissions = () => {
  return new Promise((resolve, reject) => {
    APIClient.get(`AffiliateCommissions`)
      .then(response => resolve(response.data))
      .catch(err => reject(err));
  });
};

export const getAffiliateInvoices = () => {
  return new Promise((resolve, reject) => {
    APIClient.get(`AffiliateInvoices`)
      .then(response => resolve(response.data))
      .catch(err => reject(err));
  });
};

export const sendAffiliateInvoice = (commissions, type) => {
  return new Promise((resolve, reject) => {
    APIClient.post(`AffiliateInvoices`, {
      Brand_id: _.get(commissions, '0.Brand_id'),
      commissionids: _.map(commissions, 'id'),
      type
    })
      .then(response => resolve(response.data))
      .catch(err => reject(err));
  });
};

export const sendBudgetInvoice = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`BrandBudgetInvoices`, data)
      .then(response => resolve(response.data))
      .catch(err => reject(err));
  });
};

export const updateInvoice = (invoice, updates) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`AffiliateInvoices/${invoice.id}`, updates)
      .then(response => resolve(response.data))
      .catch(err => reject(err));
  });
};

export const updateCommission = (commission, updates) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`AffiliateCommissions/${commission.id}`, updates)
      .then(response => resolve(response.data))
      .catch(err => reject(err));
  });
};
