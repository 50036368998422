import { fetchUsers } from '../api/users';
import { applyTagToUsers as applyTagToUsersAPI, deleteUserTag as deleteUserTagAPI } from '../api/tags';
import { cognitoSignOut } from '../Auth';

export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const APPLY_TAG_TO_USERS_REQUEST = 'APPLY_TAG_TO_USERS_REQUEST';
export const APPLY_TAG_TO_USERS_SUCCESS = 'APPLY_TAG_TO_USERS_SUCCESS';
export const DELETE_USER_TAG_REQUEST = 'DELETE_USER_TAG_REQUEST';
export const DELETE_USER_TAG_SUCCESS = 'DELETE_USER_TAG_SUCCESS';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';

export const getUsers = (params = {}) => async (dispatch, getState) => {
  dispatch({
    type: GET_USERS_REQUEST
  });
  const users = await fetchUsers(params);
  return dispatch({
    type: GET_USERS_SUCCESS,
    users
  });
};

export const applyTagToUsers = (tag, users) => async (dispatch, getState) => {
  dispatch({
    type: 'APPLY_TAG_TO_USERS_REQUEST',
    tag,
    users
  });
  await applyTagToUsersAPI(tag, users);
  return dispatch({
    type: 'APPLY_TAG_TO_USERS_SUCCESS',
    tag,
    users
  });
};

export const deleteUserTag = (tag, user) => async (dispatch, getState) => {
  dispatch({
    type: 'DELETE_USER_TAG_REQUEST',
    tag,
    user
  });
  await deleteUserTagAPI(tag, user);
  return dispatch({
    type: 'DELETE_USER_TAG_SUCCESS'
  });
};

export const logoutUser = () => async (dispatch, getState) => {
  await cognitoSignOut();
  return dispatch({ type: LOGOUT_USER_SUCCESS });
};
