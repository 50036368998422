import _ from 'lodash';
import * as ActionTypes from '../actions/userActions';

export const users = (
  state = {
    users: []
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.users
      };

    case ActionTypes.APPLY_TAG_TO_USERS_REQUEST:
      const userTagsBeingApplied = _.reduce(action.users, (r, u) => ({ ...r, [u.id]: true }), {}); // For performance
      return {
        ...state,
        users: state.users.map(user => {
          const isTagApplied = userTagsBeingApplied[user.id];
          return {
            ...user,
            tags: isTagApplied ? _.uniq(_.concat(user.tags, action.tag), 'id') : user.tags
          };
        })
      };

    case ActionTypes.DELETE_USER_TAG_REQUEST:
      return {
        ...state,
        users: state.users.map(user => {
          return {
            ...user,
            tags: user.id === action.user.id ? _.filter(user.tags, t => t.id !== action.tag.id) : user.tags
          };
        })
      };

    case ActionTypes.LOGOUT_USER_SUCCESS:
      return {
        ...state,
        profile: {},
        payouts: {},
        payments: [],
        invoices: [],
        contractInvoices: [],
        budgetInvoices: [],
        recommendations: [],
        recommendationsMap: {},
        isLoggedIn: false,
        simulatedUsername: null,
        jwtToken: null
      };

    default:
      return state;
  }
};
