import React, { Component } from 'react';
import _ from 'lodash';
import cogoToast from 'cogo-toast';
import './Invoices.scss';

import PendingCommissions from './PendingCommissions';
import PastInvoices from './PastInvoices';
import CreateInvoice from './CreateInvoice';

import { getAffiliateInvoices, getPendingCommissions, sendAffiliateInvoice, updateCommission } from '../../api/brands';

class Invoices extends Component {
  componentDidMount() {
    this.syncData();
  }

  syncData = () => {
    getAffiliateInvoices().then(invoices => this.setState({ invoices }));
    getPendingCommissions().then(({ brands, commissions, accounts, users, brandOnboardedUsers }) =>
      this.setState({ brands, commissions, accounts, users, brandOnboardedUsers })
    );
  };

  state = {
    brands: null,
    commissions: null,
    invoices: null,
    accounts: null,
    users: null,

    selectedCommissions: []
  };

  sendInvoice = async (commissions, type) => {
    cogoToast.info('Generating Invoice...', { hideAfter: 3 });
    sendAffiliateInvoice(commissions, type)
      .then(resp => {
        cogoToast.success(
          `Successfully sent invoice, see it here: https://dashboard.stripe.com${window.__IS_PROD__ ? '' : '/test'}/invoices/${
            resp.invoice?.stripeInvoiceId
          }`,
          {
            hideAfter: 8
          }
        );
        this.syncData();
      })
      .catch(err => cogoToast.error(_.get(err, 'data.error', 'Error Submitting Invoice, see console.')))
      .finally(() => {
        this.setState({ selectedCommissions: [] });
      });
  };

  updateCommission = (commission, updates) => {
    updateCommission(commission, updates).then(resp => {
      this.setState({
        commissions: this.state.commissions.map(c => (c.id === resp.id ? resp : c))
      });
    });
  };

  render() {
    const { commissions, selectedCommissions, users, brands, brandOnboardedUsers, invoices, accounts } = this.state;
    return (
      <div className='invoices-outer-container'>
        {commissions && brands && accounts && invoices && !!commissions.length && (
          <PendingCommissions
            commissions={commissions}
            accounts={accounts}
            brands={brands}
            users={users}
            selectedCommissions={selectedCommissions}
            setSelectedCommissions={selectedCommissions => this.setState({ selectedCommissions })}
            updateCommission={this.updateCommission}
          />
        )}
        {!!selectedCommissions.length && (
          <CreateInvoice
            commissions={selectedCommissions}
            brands={brands}
            accounts={accounts}
            users={users}
            brandOnboardedUsers={brandOnboardedUsers}
            sendInvoice={this.sendInvoice}
          />
        )}
        {invoices && !!invoices.length && <PastInvoices invoices={invoices} />}
      </div>
    );
  }
}

export default Invoices;
