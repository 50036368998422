import React, { useState } from 'react';
import Loader from '../../General/Loader';

import { Table } from '../GraphTypes/Table';
import { getBrandReportHeaderLabel } from '../../../helpers/brand_helpers';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { faDownload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BrandTopMentions = props => {
  const { title, brandTopMentions, downloading, groupingMethod } = props;
  const { allResults: mentions, dtcResults: dtcMentions, nonDtcResults: nonDtcMentions } = brandTopMentions || {};
  const success = !!mentions;

  const [tab, setTab] = useState('all');
  const download = async () => await props.download({ rows: make_table_rows(tab), fileName: `brand_top_mentions_${tab}` });

  const make_table_rows = tab => {
    if (!mentions || !mentions[0]) return;

    const uniqueMentions = new Map();
    let mentionType;

    if (tab === 'all') mentionType = mentions;
    else if (tab === 'dtc') mentionType = dtcMentions;
    else mentionType = nonDtcMentions;

    for (const { results: apiResults } of mentionType) {
      for (const mention of apiResults) uniqueMentions.set(mention.id, mention);
    }

    const fetchMentionFromGroup = (group, Mention_id) => group.find(mention => mention.id === Mention_id);

    const finalResults = [];
    for (const mention of uniqueMentions.values()) {
      const { title, User_name, id: Mention_id, Product_title } = mention;
      const data = {
        key: `${Mention_id}-brand-top-mentions`,
        label: title,
        description: Product_title,
        values: [{ label: 'User', value: User_name || '-' }]
      };
      let oneIsNonZero = false;

      let runningTotal = 0;
      for (const group of mentionType) {
        const { week, month, quarter, results: apiResults } = group;
        const mentionFromGroup = fetchMentionFromGroup(apiResults, Mention_id) || {};
        const revenue = parseInt(mentionFromGroup.orderVolumeTotal) || 0;
        if (revenue > 0) oneIsNonZero = true;
        runningTotal += revenue;
        data.values.push({ label: getBrandReportHeaderLabel({ week, month, quarter, groupingMethod }), value: `$${revenue}` });
      }
      data.values.push({ label: 'Total', value: `$${runningTotal}` });

      oneIsNonZero && finalResults.push(data);
    }

    return finalResults.sort((a, b) => {
      const aTotal = a.values.reduce((acc, { value }) => acc + parseInt(value.replace('$', '')), 0);
      const bTotal = b.values.reduce((acc, { value }) => acc + parseInt(value.replace('$', '')), 0);
      return bTotal - aTotal;
    });
  };

  return (
    <>
      <div className='brand-card' id={title}>
        <div className='padding'>
          {!brandTopMentions ? (
            <Loader />
          ) : (
            <>
              {success && (
                <>
                  <div className='header'>
                    <div className='description'>
                      <div className='title'>{title}</div>
                      <div className='sub-title'>
                        The top mentions show the best performing links for a brand. It shows how much revenue each drove month over month. It is
                        queryable on all links, direct links, and non-direct links.
                      </div>
                    </div>
                    <div className='brand-card-controls'>
                      <div className='radio-btns'>
                        <div className={cn('radio-btn', { active: tab === 'dtc' })} onClick={() => setTab('dtc')}>
                          DTC
                        </div>
                        <div className={cn('radio-btn', { active: tab === 'non_dtc' })} onClick={() => setTab('non_dtc')}>
                          NON-DTC
                        </div>
                        <div className={cn('radio-btn', { active: tab === 'all' })} onClick={() => setTab('all')}>
                          All
                        </div>
                      </div>
                      <div className='rounded-button' onClick={() => download()}>
                        {downloading ? (
                          <Loader />
                        ) : (
                          <>
                            <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                            Download
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  {tab === 'dtc' && <Table title='Brand Top Mentions' data={make_table_rows('dtc')} />}
                  {tab === 'non_dtc' && <Table title='Brand Top Mentions' data={make_table_rows('non_dtc')} />}
                  {tab === 'all' && <Table title='Brand Top Mentions' data={make_table_rows('all')} />}
                </>
              )}

              {!success && (
                <>
                  <div className='error-centered'>
                    <div className='title'>Error Fetching {title}</div>
                    <div className='sub-title'>Could not fetch results</div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

BrandTopMentions.propTypes = {
  title: PropTypes.string.isRequired,
  brandTopMentions: PropTypes.object,
  groupingMethod: PropTypes.string.isRequired,

  download: PropTypes.func.isRequired,
  downloading: PropTypes.bool.isRequired
};

const brandTopMentionsConfig = {
  title: 'Top Mentions'
};

export { BrandTopMentions, brandTopMentionsConfig };
