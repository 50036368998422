import { useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './Waitlist.scss';

import { getWaitlist, getBrandWaitlist, searchWaitlist, getAllPriorityWaitlistTags } from '../../api/waitlist';
import { getAccountManagers } from '../../api/admin';

let searchDebouncer;
const WaitlistData = props => {
  const { setWaitlist, setBrandWaitlist, setAccountManagers, setWaitlistPriorityTags } = props;
  const { curSearchValue, setSearchResults, setIsSearching, curTab, setFilter } = props;

  // on page load fetch all waitlist data
  useEffect(() => {
    const orderWaitlist = users => _.orderBy(users, 'createdAt', 'desc');

    getWaitlist()
      .then(waitlistUsers => setWaitlist(orderWaitlist(waitlistUsers)))
      .catch(() => window.ALERT?.error('Error fetching waitlist users'));

    getBrandWaitlist()
      .then(results => setBrandWaitlist(orderWaitlist(results.brandWaitlist)))
      .catch(() => window.ALERT?.error('Error fetching brand waitlist'));

    getAccountManagers('brandwaitlist')
      .then(result => setAccountManagers(result.users))
      .catch(() => window.ALERT?.error('Error fetching account managers'));

    getAllPriorityWaitlistTags()
      .then(tags => setWaitlistPriorityTags(tags))
      .catch(() => window.ALERT?.error('Error fetching priority tags'));
  }, [setAccountManagers, setBrandWaitlist, setWaitlist, setWaitlistPriorityTags]);

  // search functionality
  useEffect(() => {
    if (!setSearchResults) return;
    else if (!setIsSearching) return;

    if (!curSearchValue) {
      searchDebouncer && clearTimeout(searchDebouncer);
      setSearchResults([]);
      setIsSearching(false);
      setFilter('Needs Decision');
      return;
    }

    setIsSearching(true);
    searchDebouncer && clearTimeout(searchDebouncer);
    searchDebouncer = setTimeout(() => {
      searchWaitlist({ query: curSearchValue, forBrands: curTab === 'Brands' ? 1 : 0 })
        .then(res => setSearchResults(res.results))
        .catch(err => console.error(err))
        .finally(() => setIsSearching(false));
    }, 500);
  }, [curSearchValue, curTab]);

  return null;
};

WaitlistData.propTypes = {
  setWaitlist: PropTypes.func.isRequired,
  setBrandWaitlist: PropTypes.func.isRequired,
  setAccountManagers: PropTypes.func.isRequired,
  setWaitlistPriorityTags: PropTypes.func.isRequired,

  // searching state
  curSearchValue: PropTypes.string.isRequired,
  setSearchResults: PropTypes.func.isRequired,
  setIsSearching: PropTypes.func.isRequired,
  curTab: PropTypes.string.isRequired,
  setFilter: PropTypes.func.isRequired
};

export default WaitlistData;
