import React, { useState } from 'react';
import cn from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import cogoToast from 'cogo-toast';
import './Scrapers.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';

import * as scraperAPI from '../../api/scrapers';
import Tooltip from '../General/Tooltip';
import ScraperData from './ScraperData';
import ScraperModal from './ScraperModal';

const Scrapers = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [scrapers, setScrapers] = useState([]);
  const [searchVal, setSearchVal] = useState('');
  const [selectedScraperName, setSelectedScraperName] = useState(null);
  const close = () => setSelectedScraperName(null);

  const filteredScrapers = searchVal
    ? scrapers.filter(scraper => scraper.name.toLowerCase().includes(searchVal.toLowerCase()))
    : _.orderBy(scrapers, 'name');

  /**
   * This will be called when the user creates a scraper from a scraper
   * that has a template but no Scraper object. Therefore, after creation
   * we just have to update the scraper array with the new scraper object
   */
  const createManualScraper = async data => {
    const { name } = data;
    const scraper = scrapers.find(scrape => scrape.name === name);
    if (!scraper) return cogoToast.error('Could not find scraper to create manual scraper for');

    try {
      const newScraper = await scraperAPI.addScraper(data);
      const newScraperObject = {
        ...scraper,
        manualScraper: newScraper,
        hasManualScraper: true,
        type: { title: 'refresh', color: '#FFA500', tooltip: 'Refresh tab to get type details' },
        scraperObject: { ...(scraper.scraperObject || {}), ...newScraper }
      };

      const updatedScrapers = scrapers.map(s => (s.name === name && !s.hasManualScraper ? newScraperObject : s));
      setScrapers(updatedScrapers);
      cogoToast.success('Successfully created manual scraper');
    } catch (e) {
      cogoToast.error('Error creating manual scraper');
    }
  };

  // Must call with Scraper db row
  const updateScraper = async (scraper, updates) => {
    if (!scraper.scraperObject?.id) return cogoToast.error('Cannot update scraper without an id');

    const oldScrapers = [...scrapers];
    const updatedScrapers = oldScrapers.map(oldScraper =>
      oldScraper.scraperObject.id === scraper.scraperObject.id
        ? { ...oldScraper, scraperObject: { ...oldScraper.scraperObject, ...updates } }
        : oldScraper
    );
    setScrapers(updatedScrapers);

    try {
      await scraperAPI.updateScraper(scraper.scraperObject, updates);
      cogoToast.success(`Successfully updated ${scraper.name}`);
    } catch (e) {
      cogoToast.error(`Error updating ${scraper.name}`);
      setScrapers(oldScrapers);
    }
  };

  // Must be called with Scraper db row
  const deleteScraper = async scraper => {
    if (!scraper.scraperObject?.id) return cogoToast.error('Cannot delete scraper without an id');

    const oldScrapers = [...scrapers];
    const updatedScrapers = oldScrapers.filter(s => {
      // Only can delete if it is a matching db scraper (name and id match)
      const nameMatch = s.name === scraper.name;
      const idMatch = s.scraperObject.id === scraper.scraperObject.id;
      return !nameMatch || !idMatch;
    });

    setSelectedScraperName(null);
    setScrapers(updatedScrapers);

    try {
      await scraperAPI.deleteScraper(scraper.scraperObject);
      cogoToast.success(`Successfully deleted ${scraper.name}`);
      setSelectedScraperName(null);
    } catch (e) {
      cogoToast.error(`Error deleting ${scraper.name}, please try again`);
      setScrapers(oldScrapers);
    }
  };

  return (
    <div className='scrapers-outer-container'>
      <ScraperData setIsLoading={setIsLoading} setScrapers={setScrapers} />
      {selectedScraperName && (
        <ScraperModal
          close={close}
          scrapers={scrapers}
          selectedScraperName={selectedScraperName}
          createManualScraper={createManualScraper}
          updateScraper={updateScraper}
          deleteScraper={deleteScraper}
        />
      )}

      <div className='scrapers-inner-container'>
        <div className='scrapers-action-container'>
          <div>
            <input type='text' placeholder='Search...' value={searchVal} onChange={e => setSearchVal(e.target.value)} />
          </div>
        </div>

        <div className='scraper row header'>
          <div className='cell sm'>Next</div>
          <div className='cell lg'>Name</div>
          <div className='cell md'>Tag</div>
          <div className='cell sm'>Items</div>
          <div className='cell md'>Last Crawl</div>
          <div className='cell sm'>Days Between</div>
          <div className='cell sm'>Force Recrawl</div>
          <div className='cell sm'>Needs Fix</div>
          <div className='cell sm'>Requires Manual</div>
          <div className='cell lg'>Notes</div>
        </div>

        {isLoading
          ? new Array(100).fill(0).map((_, i) => (
              <div className='scraper row skeleton-row' key={i}>
                <div className='cell sm skeleton'></div>
                <div className='cell lg skeleton'></div>
                <div className='cell md skeleton'></div>
                <div className='cell sm skeleton'></div>
                <div className='cell md skeleton'></div>
                <div className='cell sm skeleton'></div>
                <div className='cell sm skeleton'></div>
                <div className='cell sm skeleton'></div>
                <div className='cell sm skeleton'></div>
                <div className='cell lg skeleton'></div>
              </div>
            ))
          : _.map(filteredScrapers, scraper => {
              const { type, scraperObject, name, hasShopifyScraper, hasManualScraper } = scraper;
              const { color, tooltip } = type;
              const variantCount = scraper.shopifyScraper?.variantCount || scraper.manualScraper?.variantCount;
              const { notes, lastCrawl, requiresManual, daysBetweenCrawls, needsFix, forceRecrawl } = scraperObject;
              const broken = needsFix && !requiresManual;
              const needsManualUpdate = requiresManual && (daysBetweenCrawls < moment().diff(moment(lastCrawl), 'days') || !lastCrawl);
              const isUpcoming = scraperObject.isUpcoming || forceRecrawl;

              return (
                <div className={cn('scraper row', { broken, 'manual-needs-update': needsManualUpdate })} key={`${name} ${tooltip}`}>
                  <div className='cell sm'>{isUpcoming ? '✓' : ''}</div>
                  <div className='cell lg' onClick={() => setSelectedScraperName(name)}>
                    {name}
                  </div>
                  <div className='cell md'>
                    <div className={`badge`} style={{ color: color, backgroundColor: `${color}1A` }}>
                      {tooltip ? (
                        <Tooltip message={tooltip}>
                          {type.title}
                          <FontAwesomeIcon icon={faInfoCircle} />
                        </Tooltip>
                      ) : (
                        <div>{type.title}</div>
                      )}
                    </div>
                  </div>
                  <div className='cell sm'>{variantCount || 'N/A'}</div>
                  <div onClick={() => updateScraper(scraper, { lastCrawl: true })} className='cell md'>
                    {lastCrawl ? moment(lastCrawl).format('MMM Do') : '-'}
                  </div>
                  <div onClick={() => updateScraper(scraper, { daysBetweenCrawl: 21 })} className='cell sm'>
                    {daysBetweenCrawls}
                  </div>
                  <div className='cell sm'>
                    <div className={cn('btn', { on: forceRecrawl })} onClick={() => updateScraper(scraper, { forceRecrawl: !forceRecrawl })}>
                      {hasShopifyScraper || !hasManualScraper ? 'N/A' : forceRecrawl ? '✓' : '-'}
                    </div>
                  </div>
                  <div className='cell sm'>
                    <div
                      className={cn('btn', { error: broken })}
                      onClick={() => (requiresManual ? cogoToast.warn('Turn off needs manual.') : updateScraper(scraper, { needsFix: !needsFix }))}
                    >
                      {hasShopifyScraper || !hasManualScraper ? 'N/A' : broken ? 'Yes' : '-'}
                    </div>
                  </div>
                  <div className='cell sm'>
                    <div className={'btn'} onClick={() => updateScraper(scraper, { requiresManual: !requiresManual })}>
                      {hasShopifyScraper || !hasManualScraper ? 'N/A' : requiresManual ? 'Manual Required' : '-'}
                    </div>
                  </div>
                  <div onClick={() => updateScraper(scraper, { note: 'TODO: need to fix ' })} title={notes} className='cell lg'>
                    {notes ? `${notes.slice(0, 50)}${notes.length > 50 ? '...' : ''}` : ''}
                  </div>
                </div>
              );
            })}
      </div>
    </div>
  );
};

export default Scrapers;
