import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './ConciergeTalentExport.scss';
import { downloadToCsv } from '../../../helpers/helpers';
import cogoToast from 'cogo-toast';

const ConciergeSidebarTalentExport = props => {
  const { allTalent } = props;

  const [selections, setSelections] = useState([]);

  const exportSelections = () => {
    if (selections.length === 0) return cogoToast.error('No talent selected for export', { position: 'top-right' });

    const selectedTalent = allTalent.filter(talent => selections.includes(talent.id));
    const csvData = selectedTalent.map(talent => {
      return {
        id: talent.id,
        name: talent.name,
        email: talent.email,
        username: talent.username,
        social_links: talent.social_links,
        'Account Manager': talent.AccountManager_name,
        tier: talent.tier,
        score: talent.score,
        'Expected Score': talent.expectedScore,
        'Monthly Clicks': talent.monthlyPinClicks,
        'Monthly Pins Added': talent.monthlyPins,
        'Monthly Volume': talent.monthlyVolume
      };
    });

    downloadToCsv(csvData, 'talent_export');
  };

  const selectAllTalent = () => {
    if (selections.length === allTalent.length) setSelections([]);
    else setSelections(allTalent.map(talent => talent.id));
  };

  const selectTalent = talent => {
    if (selections.includes(talent.id)) setSelections(selections.filter(id => id !== talent.id));
    else setSelections([...selections, talent.id]);
  };

  return (
    <div className='concierge-sidebar-talent-export-outer'>
      <div className='export-button' onClick={exportSelections}>
        Export Selections
      </div>
      <div className='talent-select-list'>
        <div className='talent-select-item select-all' onClick={selectAllTalent}>
          <input type='checkbox' className='checkbox' checked={selections.length === allTalent.length} readOnly />
          {selections.length === allTalent.length ? 'Deselect All' : 'Select All'}
        </div>
        {allTalent.map(talent => (
          <div className='talent-select-item' key={`talent_${talent.id}`} onClick={() => selectTalent(talent)}>
            <input type='checkbox' className='checkbox' checked={selections.includes(talent.id)} readOnly />
            {talent.name}
          </div>
        ))}
      </div>
    </div>
  );
};

ConciergeSidebarTalentExport.propTypes = {
  allTalent: PropTypes.array
};

export default ConciergeSidebarTalentExport;
