import _ from 'lodash';
import * as ActionTypes from '../actions/tagActions';

export const tags = (
  state = {
    tags: []
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.GET_TAGS_SUCCESS:
      return {
        ...state,
        tags: action.tags
      };

    case ActionTypes.ADD_TAG_SUCCESS:
      return {
        ...state,
        tags: _.concat(state.tags, action.tag)
      };

    case ActionTypes.DELETE_TAG_REQUEST:
      return {
        ...state,
        tags: state.tags?.filter(tag => tag.id !== action.tag.id)
      };

    case ActionTypes.EDIT_TAG_REQUEST:
      return {
        ...state,
        tags: state.tags?.map(tag => (tag.id === action.tag.id ? { ...tag, ...action.edits } : tag))
      };

    default:
      return state;
  }
};
