import APIClient from './index';
import _ from 'lodash';

/**
 * @param {Object} params
 * @param {string} params.query - search term
 * @param {boolean} params.forBrands - search brand waitlist or user waitlist
 */
export const searchWaitlist = params => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/Waitlist/search?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const getWaitlist = () => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/Waitlist`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const getBrandWaitlist = () => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/BrandWaitlist`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const updateWaitlistUser = (user, data) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`Waitlist/${user.id}`, data)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const updateWaitlistBrand = (waitlistBrand, data) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`BrandWaitlist/${waitlistBrand.id}`, data)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const createRegistrationCode = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`RegistrationCodes`, data)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const acceptBrandWaitlistUser = waitlistUser => {
  return new Promise((resolve, reject) => {
    APIClient.post(`BrandWaitlist/accept`, waitlistUser)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const getAllPriorityWaitlistTags = () => {
  return new Promise((resolve, reject) => {
    APIClient.get(`WaitlistPriorityTags`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

/**
 * @param {Object} data
 * @param {string} data.tag
 * @param {string} data.description - optional
 */
export const createPriorityWaitlistTag = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`WaitlistPriorityTags`, data)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const deletePriorityWaitlistTag = priorityWaitlistTag => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`WaitlistPriorityTags/${priorityWaitlistTag.id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const fetchRecommendedRegistrationCodes = name => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/Waitlist/suggest_registration_code?${new URLSearchParams({ name }).toString()}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};
