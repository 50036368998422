import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './ConciergeCodesModalCode.scss';

import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/pro-regular-svg-icons';

let debouncer;
const ConciergeCodesModalCode = props => {
  const { code, accountManagerOptions, updateRegistrationCode } = props;
  const { uses, useLimit } = code || {};
  const subtitle = [];
  subtitle.push(`${uses || 0} uses`);
  subtitle.push(`${useLimit || 0} use limit`);

  const [codeText, setCodeText] = useState(code.code);

  useEffect(() => {
    if (code.code === codeText) return;

    clearTimeout(debouncer);
    debouncer = setTimeout(() => {
      if (codeText === code.code) return;
      updateRegistrationCode(code, { code: codeText });

      return () => clearTimeout(debouncer);
    }, 500);
  }, [codeText]);

  const accountManager = code.AccountManager_id ? accountManagerOptions.find(am => am.value === code.AccountManager_id) : null;
  const creatorGrowthManager = code.CreatorGrowth_id ? accountManagerOptions.find(cgm => cgm.value === code.CreatorGrowth_id) : null;

  const updateAccountManager = async accountManager => {
    if (accountManager.value === code.AccountManager_id) return;
    await updateRegistrationCode(code, { AccountManager_id: accountManager.value });
  };

  const updateCreatorGrowthManager = async creatorGrowthManager => {
    if (creatorGrowthManager.value === code.CreatorGrowth_id) return;
    await updateRegistrationCode(code, { CreatorGrowth_id: creatorGrowthManager.value });
  };

  return (
    <div className='concierge-codes-modal-code-outer'>
      <div className='concierge-codes-modal-code-inner'>
        <div className='concierge-codes-modal-code-text'>
          <input type='text' className='concierge-codes-modal-code-input' value={codeText} onChange={e => setCodeText(e.target.value)} />
          <div
            className='concierge-codes-modal-code-subtitle'
            onClick={() => {
              const newUseLimit = prompt('Enter new use limit');
              if (!newUseLimit) return;
              else if (!parseInt(newUseLimit)) return alert('Please enter a valid number');
              updateRegistrationCode(code, { useLimit: parseInt(newUseLimit) });
            }}
          >
            {subtitle.join(' | ')}
            <FontAwesomeIcon icon={faEdit} />
          </div>
        </div>

        <div className='concierge-codes-modal-code-managers'>
          <Select
            className='concierge-codes-select'
            placeholder='Account Manager'
            value={accountManager}
            onChange={updateAccountManager}
            options={accountManagerOptions}
          />
          <Select
            className='concierge-codes-select'
            placeholder='Growth Manager'
            value={creatorGrowthManager}
            onChange={updateCreatorGrowthManager}
            options={accountManagerOptions}
          />
        </div>
      </div>
    </div>
  );
};

ConciergeCodesModalCode.propTypes = {
  code: PropTypes.object.isRequired,
  accountManagerOptions: PropTypes.array.isRequired,
  updateRegistrationCode: PropTypes.func.isRequired
};

export default ConciergeCodesModalCode;
