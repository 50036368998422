import React, { useState, useEffect, useRef, useCallback } from 'react';
import PromptCard from './elements/PromptCard';
import { addPrompt, getRecentPromptsByType, updatePrompt } from '../../api/prompts';
import { getBrandBasicObjects } from '../../api/brands';
import SelectOption from '../General/SelectOption';
import {
  promptGroupOptions,
  getPromptCardTitle,
  PROMPTS,
  BASE_SHOPMY_ABOUT_PROMPT,
  USER_PROMPT,
  BRAND_PROMPT,
  USER_BRAND_PROMPT
} from '../../helpers/prompt_helpers';
import { getUrlParam } from '../../helpers/helpers';
import Loader from '../General/Loader';
import cn from 'classnames';
import cogoToast from 'cogo-toast';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import './Prompts.scss';

// algolia
import { getSearchIndex } from '../../helpers/search_helpers';
const searchIndex = getSearchIndex('sms_users_production');

const skeletonElements = new Array(5).fill(0);

const Prompts = () => {
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [displayPrompts, setDisplayPrompts] = useState([]);
  const [displayMessage, setDisplayMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [brandOptions, setBrandOptions] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const loadingRef = useRef(null); // used to show loading spinner for a minimum time

  const handleLoadPromptsOnTypeSelect = useCallback(async promptType => {
    try {
      setIsLoading(true);
      await loadPrompts(promptType);
    } catch (error) {
      console.log(error);
      cogoToast.error('Error loading prompts. Please try again');
    } finally {
      loadingRef.current = setTimeout(() => setIsLoading(false), 300);
    }
  }, []);

  const loadBrandsIfNeeded = useCallback(
    async promptType => {
      if (![BRAND_PROMPT, USER_BRAND_PROMPT].includes(promptType) || brandOptions.length) return;

      const result = await getBrandBasicObjects();
      const formattedOptions = result.map(brand => ({ label: brand.name, value: brand.id }));
      setBrandOptions(formattedOptions);
    },
    [brandOptions.length]
  );

  useEffect(() => {
    if (!selectedPrompt) return;

    handleLoadPromptsOnTypeSelect(selectedPrompt.value);
    loadBrandsIfNeeded(selectedPrompt.value);
  }, [selectedPrompt, handleLoadPromptsOnTypeSelect, loadBrandsIfNeeded]);

  useEffect(() => {
    const userIdQueryParam = getUrlParam('user_id');

    if (userIdQueryParam) {
      setSelectedPrompt({ label: PROMPTS[USER_PROMPT].label, value: USER_PROMPT });
      const newPromptData = {
        User_id: +userIdQueryParam,
        type: USER_PROMPT,
        isBasePrompt: PROMPTS[USER_PROMPT].is_base_prompt
      };
      generatePrompt(newPromptData);
    }

    return () => {
      if (loadingRef.current) {
        clearTimeout(loadingRef.current);
      }
    };
  }, []);

  const onPromptSelect = prompt => {
    if (prompt === selectedPrompt) return;
    setSelectedUser(null);
    setSelectedBrand(null);
    setDisplayPrompts([]);
    setSelectedPrompt(prompt);
    setDisplayMessage('');
  };

  const loadPrompts = async promptType => {
    if (!promptType) return;

    const params = {
      type: promptType,
      isBasePrompt: PROMPTS[promptType].is_base_prompt,
      limit: 3
    };

    const promptsRes = await getRecentPromptsByType(params);
    setDisplayPrompts(promptsRes.prompts);

    if (promptsRes.prompts.length === 0) {
      setDisplayMessage(`${PROMPTS[promptType].is_base_prompt ? 'There are' : 'You have'} no prompts of this type yet. Generate one to see it here`);
    } else {
      setDisplayMessage('');
    }
  };

  const searchForTalent = async searchValue => {
    const { hits } = await searchIndex.search(searchValue, { hitsPerPage: 30 });
    const options = hits.map(user => ({ label: `${user.name}`, sublabels: [user.username, user.email], value: user.id }));
    return options;
  };

  const generatePrompt = async (newPromptData = null) => {
    try {
      setIsLoading(true);

      let promptData = newPromptData;

      if (!promptData) {
        const { value: promptType } = selectedPrompt;
        const { value: userId } = selectedUser || {};
        const { value: brandId, label: brandName } = selectedBrand || {};

        promptData = {
          User_id: userId,
          Brand_id: brandId,
          Brand_name: brandName,
          type: promptType,
          isBasePrompt: PROMPTS[promptType].is_base_prompt
        };
      }

      await addPrompt(promptData);
      await loadPrompts(promptData.type);
      cogoToast.success(`Successfully generated new prompt`);
    } catch (error) {
      console.log(error);
      cogoToast.error(`Error generating new prompt. Please try again`);
    } finally {
      loadingRef.current = setTimeout(() => setIsLoading(false), 300);
    }
  };

  const handleSaveBasePrompt = async (promptId, promptText) => {
    try {
      const patchedBasePrompt = await updatePrompt(promptId, { prompt: promptText });
      // update state to new patched base prompt
      setDisplayPrompts([patchedBasePrompt]);
      cogoToast.success('Saved');
    } catch (error) {
      console.error(error);
      cogoToast.error('Error saving prompt. Please try again');
    }
  };

  const checkIsGenerateDisabled = () => {
    if (isLoading) return true;
    if (selectedPrompt?.value === USER_PROMPT && selectedUser) return false;
    if (selectedPrompt?.value === BASE_SHOPMY_ABOUT_PROMPT && !displayPrompts.length) return false;
    if (selectedPrompt?.value === BRAND_PROMPT && selectedBrand) return false;
    if (selectedPrompt?.value === USER_BRAND_PROMPT && selectedUser && selectedBrand) return false;

    return true;
  };

  const getSelectStyles = (baseStyles, state) => ({
    ...baseStyles,
    border: 'none',
    backgroundColor: '#f7f7f7',
    opacity: state.isDisabled ? 0.5 : 1
  });

  const generatePromptBtnDisabled = checkIsGenerateDisabled();

  return (
    <div className='prompts-outer-container'>
      <div className='prompt-options-container'>
        <Select
          className='prompt-option-select'
          options={promptGroupOptions}
          placeholder='Prompt Type'
          onChange={onPromptSelect}
          value={selectedPrompt}
          isClearable={true}
          styles={{ control: getSelectStyles }}
        />
        <AsyncSelect
          className='prompt-option-select'
          placeholder='Talent Name'
          isClearable={true}
          isDisabled={![USER_PROMPT, USER_BRAND_PROMPT].includes(selectedPrompt?.value)}
          cacheOptions={true}
          defaultOptions={true}
          loadOptions={searchForTalent}
          components={{ Option: SelectOption }}
          value={selectedUser}
          onChange={setSelectedUser}
          styles={{ control: getSelectStyles }}
        />
        <Select
          className='prompt-option-select'
          placeholder='Brand Name'
          options={brandOptions}
          onChange={setSelectedBrand}
          value={selectedBrand}
          isClearable={true}
          isSearchable={true}
          isDisabled={![BRAND_PROMPT, USER_BRAND_PROMPT].includes(selectedPrompt?.value)}
          styles={{ control: getSelectStyles }}
        />
        <button
          disabled={generatePromptBtnDisabled}
          className={cn('action-btn', { disabled: generatePromptBtnDisabled })}
          onClick={() => generatePrompt()}
        >
          Generate Prompt
        </button>
      </div>
      <div className='prompt-display'>
        {!isLoading &&
          displayPrompts.length > 0 &&
          displayPrompts.map(prompt => (
            <PromptCard
              key={prompt.id}
              promptText={prompt.prompt}
              isBasePrompt={prompt.isBasePrompt}
              title={getPromptCardTitle(selectedPrompt.value, prompt)}
              onSave={promptText => handleSaveBasePrompt(prompt.id, promptText)}
            />
          ))}
        {isLoading && (
          <div className='loading-container'>
            {skeletonElements.map((_, idx) => {
              return (
                <div className='skeleton' key={idx}>
                  <Loader />
                </div>
              );
            })}
          </div>
        )}
        {displayMessage && !isLoading && <div className='no-prompts-message'>{displayMessage}</div>}
        {!selectedPrompt && 'Select a prompt type to get started'}
      </div>
    </div>
  );
};

export default Prompts;
