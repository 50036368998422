import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';

import './PastPayments.scss';
import './PendingPayouts.scss';

class PastPayments extends Component {
  static propTypes = {
    payments: PropTypes.array.isRequired,
    fetching: PropTypes.bool.isRequired
  };

  render() {
    const { payments, fetching } = this.props;
    return (
      <div className='pending-payouts-outer-container'>
        {fetching ? (
          <h2>Fetching...</h2>
        ) : (
          <div className='accordion'>
            <h1>Past Payments</h1>
            <div className='section'>
              <div className='body'>
                <div className='row header'>
                  <div className='cell'>Sent On</div>
                  <div className='cell'>Amount</div>
                  <div className='cell'>User Pay</div>
                  <div className='cell'>SMS Pay</div>
                  <div className='cell'>Trans Fee</div>
                  <div className='cell'>User ID</div>
                  <div className='cell'>Source</div>
                </div>
                {_.map(_.orderBy(payments, 'sent_date', 'desc'), (payment, idx) => {
                  const { amount, sent_date, user_amount, sms_amount, transaction_fee, User_id, source } = payment;
                  return (
                    <div key={idx} className='row'>
                      <div className='cell'>{moment(sent_date).format('MMMM Do')}</div>
                      <div className='cell'>${amount.toFixed(2)}</div>
                      <div className='cell'>${user_amount.toFixed(2)}</div>
                      <div className='cell'>${sms_amount.toFixed(2)}</div>
                      <div className='cell'>${transaction_fee.toFixed(2)}</div>
                      <div className='cell'>{User_id}</div>
                      <div className='cell'>{source}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default PastPayments;
