export const getNewsletterSectionOptions = () => [
  {
    type: 'BRANDS',
    display: 'Brands'
  },
  {
    type: 'SALES',
    display: 'Sales'
  },
  {
    type: 'PRODUCTS',
    display: 'Products'
  },
  {
    type: 'RATES',
    display: 'Rates'
  },
  {
    type: 'ARTICLES',
    display: 'Blogs/Articles'
  },
  {
    type: 'WEBINARS',
    display: 'Webinars'
  },
  {
    type: 'SHOPS',
    display: 'Featured Shops'
  },
  {
    type: 'COLLECTIONS',
    display: 'Featured Collections'
  }
];

export const getDisplayForSectionType = type => getNewsletterSectionOptions().find(d => d.type === type)?.display;

export const getDisplayForCtaType = type =>
  ({
    ABOUT: 'Learn More',
    GIFTING: 'Request Gifting',
    CODES: 'Request Discount Code',
    COLLABS: 'Propose Collaborations',
    CHAT: 'Go To Chat',
    URL: 'Visit Webpage'
  }[type]);

export const getBackgroundColorOptions = () => ['WHITE', 'PRETTYMUCHWHITE', 'DARKEST'];
