/**
 * function that parses the db user table "social_links" column and searches for common
 * social media links inside of it. then it turns the links found into an object that holds
 * each link individually listed
 *
 * @param {string} social_links comma separated list of a user's social links
 * @returns object with each social individually listed
 */
export const splitDbSocialLinksIntoIndividualLinks = social_links => {
  if (!social_links) return {};

  const links = {
    youtube_url: null,
    instagram_url: null,
    tiktok_url: null,
    twitter_url: null,
    facebook_url: null,
    website_url: null
  };

  const youtube_regex = /.*(youtube|youtu\.be).*/gi;
  const instagram_regex = /.*(instagram).*/gi;
  const tiktok_regex = /.*(tiktok).*/gi;
  const twitter_regex = /.*(twitter).*/gi;
  const facebook_regex = /.*(facebook).*/gi;

  if (!social_links) return links;
  const split_links = social_links.split(',');

  for (let link of split_links) {
    switch (true) {
      case link.match(youtube_regex) !== null:
        links.youtube_url = link;
        break;
      case link.match(instagram_regex) !== null:
        links.instagram_url = link;
        break;
      case link.match(tiktok_regex) !== null:
        links.tiktok_url = link;
        break;
      case link.match(twitter_regex) !== null:
        links.twitter_url = link;
        break;
      case link.match(facebook_regex) !== null:
        links.facebook_url = link;
        break;
      default:
        // because user could have multiple other websites, append to array and add all
        if (!links.website_url) links.website_url = [link];
        else links.website_url.push(link);
    }
  }

  return links;
};
