import React from 'react';
import Loader from '../../General/Loader';

import { Table } from '../GraphTypes/Table';
import moment from 'moment';
import PropTypes from 'prop-types';
import { faDownload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BrandCustomRateUsers = props => {
  const { title, brandCustomRateUsers, downloading } = props;
  const success = !!brandCustomRateUsers?.length;
  const errorMessage = !success ? 'No Results' : undefined;

  const download = async () => await props.download({ rows: make_table_rows(), fileName: 'brand_custom_rate_users' });

  const make_table_rows = () => {
    if (!brandCustomRateUsers || !brandCustomRateUsers[0]) return;

    // row: { User_id, name, username, rate, createdAt }
    const users = [];
    for (const user of brandCustomRateUsers) {
      const { User_id, name, username, rate, createdAt } = user;

      const data = { key: User_id, label: name, href: `https://shopmy.us/${username}`, description: `shopmy.us/${username}`, values: [] };
      data.values = [
        { label: 'Rate', value: rate },
        { label: 'Since', value: moment(createdAt).format('MMM DD, YYYY') }
      ];

      users.push(data);
    }

    return users;
  };

  return (
    <div className='brand-card' id={title}>
      <div className='padding'>
        {!brandCustomRateUsers ? (
          <Loader />
        ) : (
          <>
            <>
              <div className='header'>
                <div className='description'>
                  <div className='title'>{title}</div>
                  <div className='sub-title'>
                    This displays all of the users that have been granted a custom rate. Showing you who they are, what rate they have, and when they
                    received this rate.
                  </div>
                </div>
                {!downloading && (
                  <div className='brand-card-controls'>
                    <div className='rounded-button' onClick={() => download()}>
                      {downloading ? (
                        <Loader />
                      ) : (
                        <>
                          <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                          Download
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>

              <Table title='Brand Custom Rate Users' data={make_table_rows()} errorMessage={errorMessage} />
            </>
          </>
        )}
      </div>
    </div>
  );
};

BrandCustomRateUsers.propTypes = {
  title: PropTypes.string.isRequired,
  brandCustomRateUsers: PropTypes.array,

  download: PropTypes.func.isRequired,
  downloading: PropTypes.bool.isRequired
};

const brandCustomRateUsersConfig = {
  title: 'Custom Rate Users'
};

export { BrandCustomRateUsers, brandCustomRateUsersConfig };
