import _ from 'lodash';
import moment from 'moment';

export const CONTRACT_INVOICE_DUE_DAYS = 14; // Brands owe contracts in 14 days

export const getStatusDisplayForContract = contract =>
  ({
    pending: 'Editing',
    review: 'In Review', // Not currently used but can be if we start to see too many bad proposals
    accepted: 'In Progress',
    active: 'Active',
    closed: 'Closed',
    rejected: 'Dismissed',
    expired: 'Expired'
  }[contract.status] || _.startCase(contract.status));

const getDueDateForSubmission = (task, contract) => {
  if (task.submissionDueDate) return task.submissionDueDate;
  if (task.submissionDueDateDays) return moment(contract.acceptedDate).add(task.submissionDueDateDays, 'days');
  return null;
};
const getDueDateForContentLive = (task, contract) => {
  if (task.contentLiveDueDate) return task.submissionDueDate;
  if (task.contentLiveDueDateDays) return moment(task.approvedDate).add(task.contentLiveDueDateDays, 'days');
  return null;
};

export const getStatusDataForContractTask = (task, contract) => {
  const { status } = task;

  let display, displayMsg, dueDate, warningMsg, errorMsg;

  const formatDate = date => moment(date).format('MMMM Do');

  if (task.template?.isAddOn) {
    display = 'Add On';
    displayMsg = 'Add On';
  } else if (status === 'pending') {
    dueDate = getDueDateForSubmission(task, contract);
    displayMsg = dueDate ? formatDate(dueDate) : 'Not Started';
    display = 'Pending';
  } else if (status === 'inreview') {
    displayMsg = `In Review, submitted ${formatDate(task.proposedDate)}`;
    display = 'In Review';
  } else if (status === 'approved') {
    dueDate = getDueDateForContentLive(task, contract);
    displayMsg = dueDate ? formatDate(dueDate) : 'Not Yet Live';
    display = 'Approved';
  } else if (status === 'active') {
    displayMsg = `Active on ${formatDate(task.activeDate)}`;
    display = 'Active';
  } else if (status === 'complete') {
    displayMsg = `Completed on ${formatDate(task.completeDate)}`;
    display = 'Complete';
  }

  if (dueDate) {
    const daysUntilDue = moment(dueDate).diff(moment(), 'days');
    if (daysUntilDue < 0) {
      errorMsg = `Was due ${-1 * daysUntilDue} days ago`;
    } else if (daysUntilDue === 0) {
      warningMsg = `Due today`;
    } else if (daysUntilDue < 4) {
      warningMsg = `Due in ${daysUntilDue} day${daysUntilDue === 1 ? '' : 's'}`;
    }
  }

  return {
    status,
    display,
    displayMsg,
    warningMsg,
    errorMsg
  };
};

export const getStatusTimeDisplayForContract = contract => {
  let time, display;
  switch (contract.status) {
    case 'review':
      display = 'Proposed On';
      time = contract.proposedDate;
      break;
    case 'proposed':
      display = 'Proposed On';
      time = contract.proposedDate;
      break;
    case 'accepted':
      display = 'Accepted On';
      time = contract.acceptedDate;
      break;
    case 'active':
      display = 'Started On';
      time = contract.activeDate;
      break;
    case 'closed':
      display = 'Closed On';
      time = contract.closedDate;
      break;
    case 'expiredDate':
      display = 'Expired On';
      time = contract.expiredDate;
      break;
    case 'rejected':
      display = 'Dismissed On';
      time = contract.rejectedDate;
      break;
    case 'pending':
    default:
      display = 'Created On';
      time = contract.createdAt;
      break;
  }
  return `${display} ${moment(time).format('MMMM D, YYYY')}`;
};

export const getTitleForMedia = media => {
  return (
    media.title ||
    _.get(
      {
        instagram: {
          CAROUSEL_ALBUM: 'Instagram Post',
          IMAGE: 'Instagram Post',
          VIDEO: 'Instagram Video'
        }
      },
      [media.type, media.subtype],
      `${_.startCase(media.type)} Content`
    )
  );
};
