export const getTierDisplay = tier => getUserTiers().find(t => t.tier === tier)?.label || 'Unknown';
export const getUserTiers = () => {
  /*
    Get tier data.

    Make sure to keep this in sync with tier_helpers.js on the frontend, admin and server.
  */
  return [
    {
      label: 'Enthusiast',
      tier: 4
    },
    {
      label: 'Ambassador',
      tier: 3
    },
    {
      label: 'Trendsetter',
      tier: 2
    },
    {
      label: 'Icon',
      tier: 1
    }
  ];
};
