import APIClient from './index';
import _ from 'lodash';

/**
 * api that will fetch brands that are similar to the brand passed in
 * and any feedback that has been given for the similar brands
 *
 * @param {Object} params
 * @param {string} params.brand - name of a brand to get similar brands for
 * @param {string} params.similarBrand - optional name of a similar brand to get feedback for
 */
export const getSimilarBrands = params => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/ml/training/similar_brands?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};

/**
 * api that will create feedback for the ml response
 *
 * @param {Object} data
 * @param {('similar_performer')} data.type - the type of model you are giving feecback on
 * @param {number} data.score - whether the ml response was a fit or not
 * @param {string} data.message - a description of why they chose that option
 * @param {string} data.model - the model that will be using the training data
 */
export const postTrainingSample = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/ml/training`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};
