import React, { useState, useRef, useEffect, useCallback } from 'react';
import './PromptCard.scss';
import { copyToClipboard } from '../../../helpers/helpers';
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';

const PromptCard = props => {
  const { promptText, title, onSave, isBasePrompt = false } = props;

  const [showCopiedText, setShowCopiedText] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [inputText, setInputText] = useState('');
  const [promptEditHeight, setPromptEditHeight] = useState('auto'); // this is used to set text area height to height of content
  const copiedTextRef = useRef(null);
  const textAreaRef = useRef(null);

  useEffect(() => {
    if (isEditing) {
      setPromptEditHeight(`${textAreaRef.current.scrollHeight}px`);
      textAreaRef.current.focus();
    }

    return () => {
      if (copiedTextRef.current) {
        clearTimeout(copiedTextRef.current);
      }
    };
  }, [isEditing, inputText]);

  useEffect(() => {
    setInputText(promptText);
  }, [promptText]);

  const handleCopy = () => {
    if (isEditing) return;
    setShowCopiedText(true);
    copyToClipboard(promptText);
    copiedTextRef.current = setTimeout(() => setShowCopiedText(false), 1000);
  };

  const handleBasePromptTextChange = evt => {
    setPromptEditHeight('auto'); // shrink or expand to fit content before setting to scrollHeight
    setInputText(evt.target.value);
  };

  const handleEditChange = async () => {
    if (isEditing) {
      await onSave(inputText);
      setIsEditing(false);
    } else {
      setIsEditing(true);
    }
  };

  const handleCancelEditing = () => {
    setIsEditing(false);
    setInputText(promptText);
    setPromptEditHeight('auto');
  };

  return (
    <div className='prompt-card-outer-wrapper'>
      <div className='prompt-card-container'>
        <div className='prompt-card-header'>
          <div className='prompt-card-title'>{title}</div>
          <div className='prompt-card-actions'>
            {isEditing && (
              <div className='action-btn cancel-text' onClick={handleCancelEditing}>
                CANCEL
              </div>
            )}
            {!!isBasePrompt && (
              <div className={cn('action-btn', { 'save-text': isEditing })} onClick={handleEditChange}>
                {isEditing ? 'SAVE' : 'EDIT'}
              </div>
            )}
            <div className={cn('action-btn', { 'copy-success': showCopiedText, disabled: isEditing })} onClick={handleCopy}>
              {showCopiedText ? (
                <div className='action-btn-text-icon'>
                  <FontAwesomeIcon icon={faCheckCircle} />
                </div>
              ) : (
                'COPY'
              )}
            </div>
          </div>
        </div>
        <div className='prompt-card-body-wrapper'>
          {isEditing ? (
            <textarea
              ref={textAreaRef}
              className='prompt-card-edit-area'
              value={inputText}
              onChange={handleBasePromptTextChange}
              style={{ height: promptEditHeight }}
            />
          ) : (
            <div className='prompt-card-body'>{promptText}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PromptCard;
