import React from 'react';
import { faDownload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import { Table } from '../../../BrandReports/GraphTypes/Table';
import Loader from '../../../General/Loader';

const ConciergeChatBrands = props => {
  const { conciergeChatBrands, title, downloading } = props;
  const success = !!conciergeChatBrands?.length;
  const errorMessage = !success ? 'No Unread Chats' : null;

  const download = async () => await props.download({ rows: make_table_rows(), fileName: `concierge_chat_brands` });

  const make_table_rows = () => {
    const chatRows = conciergeChatBrands
      .sort((a, b) => parseInt(b.inbound) - parseInt(a.inbound))
      .map(chat => {
        const { inbound, outbound, name, domain } = chat;

        return {
          key: `chat_brands_${name}`,
          label: name,
          description: domain,
          href: `https://${domain}/`,
          values: [
            { label: 'Inbound', value: inbound },
            { label: 'Outbound', value: outbound }
          ]
        };
      });

    return chatRows;
  };

  return (
    <div className='talent-report-card' id={title}>
      {!conciergeChatBrands ? (
        <Loader />
      ) : (
        <>
          <div className='header'>
            <div className='description'>
              <div className='title'>{title}</div>
              <div className='sub-title'>All the chats with Brands that the talent hasn't read messages for.</div>
            </div>
            {!downloading && (
              <div className='talent-report-controls' id={`${title}-card-controls`}>
                <div className='rounded-button' onClick={() => download()}>
                  {downloading ? (
                    <Loader />
                  ) : (
                    <>
                      <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                      Download
                    </>
                  )}
                </div>
              </div>
            )}
          </div>

          <Table title='Chat Brands' data={make_table_rows()} errorMessage={errorMessage} />
        </>
      )}
    </div>
  );
};

ConciergeChatBrands.propTypes = {
  title: PropTypes.string.isRequired,
  conciergeChatBrands: PropTypes.array,

  download: PropTypes.func.isRequired,
  downloading: PropTypes.bool.isRequired
};

const ConciergeChatBrandsConfig = {
  title: 'Unread Chats'
};

export { ConciergeChatBrands, ConciergeChatBrandsConfig };
