import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cn from 'classnames';
import cogoToast from 'cogo-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faArchive, faEyeSlash, faEye } from '@fortawesome/pro-regular-svg-icons';
import { faPlus, faArchive as faArchiveSolid } from '@fortawesome/pro-solid-svg-icons';
import './Newsletter.scss';

import NewsletterSection from './Sections/NewsletterSection';
import NewsletterTabs from './NewsletterTabs';
import NewsletterTags from './NewsletterTags';

import { getServerFormattedDate } from '../../helpers/formatting';
import { getNewsletterSectionOptions } from '../../helpers/newsletter_helpers';

import {
  getNewsletter,
  createNewsletterSection,
  editNewsletterSection,
  editNewsletter,
  deleteNewsletterSection,
  createNewsletterSectionItem,
  editNewsletterSectionItem,
  deleteNewsletterSectionItem,
  createNewsletterSectionItemTag,
  deleteNewsletterSectionItemTag
} from '../../api/newsletters';

import { getBrandBasicObjects } from '../../api/brands';
import { getHierarchy } from '../../api/catalog';
import { getUserTagsBasicObjects } from '../../api/tags';

const Newsletters = props => {
  const [allBrands, setAllBrands] = useState([]);
  const [allBrandPartners, setAllBrandPartners] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [allDepartments, setAllDepartments] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [newsletter, setNewsletter] = useState(props.newsletter);
  const [loadingNewsletter, setLoadingNewsletter] = useState(true);
  const { id, title, isHidden, isArchived, sections } = newsletter;

  // Tab / Tag Management
  const [activeTab, setActiveTab] = useState(null);
  const [activeTag, setActiveTag] = useState(null);
  const [isEditingStructure, setIsEditingStructure] = useState(false);

  const syncNewsletter = async () => {
    const newsletter = await getNewsletter(id);
    setNewsletter({
      ...newsletter,
      sections: _.map(_.orderBy(newsletter.sections, 'sortOrderRank', 'asc'), s => ({
        ...s,
        items: _.orderBy(s.items, 'sortOrderRank', 'asc')
      }))
    });
    setLoadingNewsletter(false);
  };

  useEffect(() => {
    syncNewsletter();
  }, [id]);

  useEffect(() => {
    getBrandBasicObjects().then(setAllBrandPartners);
    getUserTagsBasicObjects().then(setAllTags);
    getHierarchy().then(resp => {
      setAllBrands(resp.brands);
      setAllCategories(resp.categories);
      setAllDepartments(resp.departments);
    });
  }, []);

  const potentialSections = getNewsletterSectionOptions();

  /*
    Actions to edit the Newsletter
  */

  const updateNewsletter = async updates => {
    setNewsletter({ ...newsletter, ...updates });
    await editNewsletter(newsletter, updates);
  };

  const changeTitle = () => {
    const newTitle = window.prompt('Set new title', newsletter.title);
    if (newTitle) {
      updateNewsletter({ title: newTitle });
    }
  };

  const toggleVisibility = () => {
    const isPublishing = !!isHidden;
    updateNewsletter({ isHidden: !isPublishing, publishedOn: isPublishing ? getServerFormattedDate(new Date()) : null });
    if (isPublishing) cogoToast.success(`Newsletter is now live!`);
  };
  const toggleArchive = () => updateNewsletter({ isArchived: !isArchived });

  /*
    Actions to edit the NewsletterSections
  */
  const isSameSection = (s1, s2) => s1.id === s2.id;
  const isSameSectionItem = (i1, i2) => i1.id === i2.id;
  const addSection = async type => {
    const newSectionResp = await createNewsletterSection({
      type,
      Newsletter_id: id
    });
    setNewsletter({
      ...newsletter,
      sections: [...sections, newSectionResp]
    });
  };
  const deleteSection = async section => {
    const confirm = !section.title || window.confirm(`Are you sure you want to delete this section?`);
    if (!confirm) return;
    setNewsletter({
      ...newsletter,
      sections: sections.filter(s => !isSameSection(s, section))
    });
    await deleteNewsletterSection(section);
  };

  const updateSection = async (section, updates) => {
    setNewsletter({
      ...newsletter,
      sections: sections.map(s => (!isSameSection(s, section) ? s : { ...section, ...updates }))
    });
    await editNewsletterSection(section, updates);
  };

  const moveSection = (section, distance) => {
    const sectionToSwap = sections[_.findIndex(sections, s => isSameSection(s, section)) + distance];
    if (!sectionToSwap) return;
    setNewsletter({
      ...newsletter,
      sections: _.orderBy(
        sections.map(s =>
          isSameSection(s, section)
            ? { ...s, sortOrderRank: sectionToSwap.sortOrderRank }
            : s.id === sectionToSwap.id
            ? { ...s, sortOrderRank: section.sortOrderRank }
            : s
        ),
        'sortOrderRank'
      )
    });
    editNewsletterSection(section, { sortOrderRank: sectionToSwap.sortOrderRank });
    editNewsletterSection(sectionToSwap, { sortOrderRank: section.sortOrderRank });
  };

  /*
    Actions to edit the NewsletterSectionItems
  */
  const addSectionItem = async section => {
    const previousCtaType = _.get(_.last(section.items), 'ctaType');
    const newItem = await createNewsletterSectionItem({ NewsletterSection_id: section.id, ...(previousCtaType ? { ctaType: previousCtaType } : {}) });
    setNewsletter({
      ...newsletter,
      sections: newsletter.sections.map(s =>
        isSameSection(s, section)
          ? {
              ...s,
              items: [...s.items, newItem]
            }
          : s
      )
    });
  };
  const deleteSectionItem = async (section, item) => {
    setNewsletter({
      ...newsletter,
      sections: newsletter.sections.map(s => (isSameSection(s, section) ? { ...s, items: s.items.filter(i => !isSameSectionItem(i, item)) } : s))
    });
    await deleteNewsletterSectionItem(item);
  };

  const updateSectionItemWaitOnResponse = (section, item, updates) => updateSectionItem(section, item, updates, true);
  const updateSectionItem = async (section, item, updates, updateBefore = false) => {
    let newItem;
    if (updateBefore) {
      // Run this if you rely on the bookshelf response.
      newItem = await editNewsletterSectionItem(item, updates);
    } else {
      newItem = { ...item, ...updates };
      editNewsletterSectionItem(item, updates); // No need to await
    }

    setNewsletter({
      ...newsletter,
      sections: newsletter.sections.map(s =>
        isSameSection(s, section)
          ? {
              ...s,
              items: s.items.map(i => (isSameSectionItem(i, item) ? newItem : i))
            }
          : s
      )
    });
  };

  const moveSectionItem = (section, item, distance) => {
    const itemToSwap = section.items[_.findIndex(section.items, i => isSameSectionItem(i, item)) + distance];
    if (!itemToSwap) return;
    setNewsletter({
      ...newsletter,
      sections: newsletter.sections.map(s =>
        isSameSection(s, section)
          ? {
              ...s,
              items: _.orderBy(
                s.items.map(i =>
                  isSameSectionItem(i, item)
                    ? { ...i, sortOrderRank: itemToSwap.sortOrderRank }
                    : isSameSectionItem(i, itemToSwap)
                    ? { ...i, sortOrderRank: item.sortOrderRank }
                    : i
                ),
                'sortOrderRank'
              )
            }
          : s
      )
    });
    editNewsletterSectionItem(item, { sortOrderRank: itemToSwap.sortOrderRank });
    editNewsletterSectionItem(itemToSwap, { sortOrderRank: item.sortOrderRank });
  };

  /*
    Actions to edit the NewsletterSectionItemTags
  */
  const addSectionItemTag = async (section, item, data) => {
    const newTag = await createNewsletterSectionItemTag({
      NewsletterSectionItem_id: item.id,
      ...data
    });
    setNewsletter({
      ...newsletter,
      sections: newsletter.sections.map(s =>
        isSameSection(s, section)
          ? {
              ...s,
              items: s.items.map(i =>
                isSameSectionItem(i, item)
                  ? {
                      ...item,
                      tags: _.concat(item.tags, newTag)
                    }
                  : i
              )
            }
          : s
      )
    });
  };

  const deleteSectionItemTag = async (section, item, tag) => {
    deleteNewsletterSectionItemTag(tag);
    setNewsletter({
      ...newsletter,
      sections: newsletter.sections.map(s =>
        isSameSection(s, section)
          ? {
              ...s,
              items: s.items.map(i =>
                isSameSectionItem(i, item)
                  ? {
                      ...item,
                      tags: item.tags.filter(t => t.id !== tag.id)
                    }
                  : i
              )
            }
          : s
      )
    });
  };

  return (
    <div className='newsletter-outer-container'>
      {loadingNewsletter ? (
        <h1>Loading...</h1>
      ) : (
        <div className='newsletter-sections'>
          <div className='newsletter-header-section'>
            <div onClick={changeTitle} className='newsletter-header'>
              {title}
            </div>
            <div className='newsletter-metadata'>
              <div className='data'>{isArchived ? 'Archived' : isHidden ? 'Hidden' : 'Live'}</div>
            </div>
          </div>
          <div>
            <NewsletterTabs
              newsletter={newsletter}
              setActiveTab={setActiveTab}
              activeTab={activeTab}
              syncNewsletter={syncNewsletter}
              isEditingStructure={isEditingStructure}
              setIsEditingStructure={setIsEditingStructure}
            />
            <NewsletterTags
              newsletter={newsletter}
              setActiveTag={setActiveTag}
              activeTag={activeTag}
              syncNewsletter={syncNewsletter}
              isEditingStructure={isEditingStructure}
              setIsEditingStructure={setIsEditingStructure}
            />
          </div>
          {sections.map((section, idx) => {
            const isFirst = idx === 0;
            const isLast = idx === sections.length - 1;
            const remove = () => deleteSection(section);
            const update = updates => updateSection(section, updates);
            const up = () => moveSection(section, -1);
            const down = () => moveSection(section, 1);
            return (
              <NewsletterSection
                key={section.id}
                section={section}
                newsletter={newsletter}
                syncNewsletter={syncNewsletter}
                deleteSection={remove}
                updateSection={update}
                allBrands={allBrands}
                isEditingStructure={isEditingStructure}
                allBrandPartners={allBrandPartners}
                allCategories={allCategories}
                allDepartments={allDepartments}
                allTags={allTags}
                moveUp={isFirst ? null : up}
                moveDown={isLast ? null : down}
                addSectionItem={addSectionItem}
                deleteSectionItem={deleteSectionItem}
                updateSectionItem={updateSectionItem}
                updateSectionItemWaitOnResponse={updateSectionItemWaitOnResponse}
                moveSectionItem={moveSectionItem}
                addSectionItemTag={addSectionItemTag}
                deleteSectionItemTag={deleteSectionItemTag}
              />
            );
          })}
        </div>
      )}
      <div className='add-new-section'>
        <div className='add-new-title'>Add New Section</div>
        <div className='section-options'>
          {potentialSections.map(({ type, display }) => {
            const add = () => addSection(type);
            return (
              <div key={type} onClick={add} className='section-option'>
                {display}
                <FontAwesomeIcon icon={faPlus} />
              </div>
            );
          })}
        </div>
      </div>
      <div className='actions'>
        <div onClick={toggleArchive} className={cn('action', { inactive: !isArchived })}>
          <FontAwesomeIcon icon={isArchived ? faArchiveSolid : faArchive} />
        </div>
        <div onClick={toggleVisibility} className={cn('action', { inactive: !isHidden })}>
          <FontAwesomeIcon icon={isHidden ? faEyeSlash : faEye} />
        </div>
        <div onClick={props.deleteNewsletter} className='action'>
          <FontAwesomeIcon icon={faTimes} />
        </div>
      </div>
    </div>
  );
};

Newsletters.propTypes = {
  newsletter: PropTypes.object.isRequired,
  deleteNewsletter: PropTypes.func.isRequired
};

export default Newsletters;
