import React, { useState } from 'react';
import cn from 'classnames';
import { faDownload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import { Table } from '../../../BrandReports/GraphTypes/Table';
import BarGraph from '../../../BrandReports/GraphTypes/BarGraph';
import Loader from '../../../General/Loader';

import { getBrandReportHeaderLabel } from '../../../../helpers/brand_helpers';

const ConciergeCustomCodes = props => {
  // console.log({ props });
  const { title, groupingMethod, downloading } = props;
  const { allCustomCodes, results: timedCustomCodes } = props?.conciergeCustomCodes || {};
  const success = !!timedCustomCodes?.length;

  const [tab, setTab] = useState('table'); // graph, table
  const download_table = async () => await props.download({ rows: make_table_rows(), fileName: 'concierge_custom_codes' });
  const download_graph = async () => await props.download({ graph: title });

  const make_table_rows = () => {
    const customCodeRows = allCustomCodes
      .sort((a, b) => b.rate - a.rate)
      .map(code => {
        const { Brand_name, createdAt, displayText, rate } = code;

        return {
          key: `custom_code_${Brand_name}_${createdAt}`,
          label: displayText,
          description: `${Brand_name}`,
          values: [{ label: 'Rate', value: rate }]
        };
      });

    for (const timePeriod of timedCustomCodes) {
      const { week, month, quarter, code_info } = timePeriod;

      for (const customCode of customCodeRows) {
        const { label: displayText, description: Brand_name } = customCode;
        const timePeriodKey = getBrandReportHeaderLabel({ groupingMethod, week, month, quarter });
        const codeData = code_info.find(code_data => code_data.displayText === displayText && code_data.Brand_name === Brand_name);
        const value = codeData ? `$${codeData.volume}` : '$0';

        customCode.values.push({ label: timePeriodKey, value });
      }
    }

    return customCodeRows;
  };

  return (
    <div className='talent-report-card' id={title}>
      {!timedCustomCodes ? (
        <Loader />
      ) : (
        <>
          {success && (
            <>
              <div className='header'>
                <div className='description'>
                  <div className='title'>{title}</div>
                  <div className='sub-title'>
                    Custom Codes are unique codes that brands create for talent to use to get special rates when used at checkout.
                  </div>
                </div>
                {!downloading && (
                  <div className='talent-report-controls' id={`${title}-card-controls`}>
                    <div className='radio-btns'>
                      <div className={cn('radio-btn', { active: tab === 'graph' })} onClick={() => setTab('graph')}>
                        Graph
                      </div>
                      <div className={cn('radio-btn', { active: tab === 'table' })} onClick={() => setTab('table')}>
                        Table
                      </div>
                    </div>
                    <div className='rounded-button' onClick={() => (tab === 'table' ? download_table() : download_graph())}>
                      {downloading ? (
                        <Loader />
                      ) : (
                        <>
                          <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                          Download
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>

              {tab === 'table' && <Table title='Custom Code Revenue' data={make_table_rows()} />}
              {tab === 'graph' && (
                <BarGraph
                  title='Custom Code Revenue'
                  data={timedCustomCodes.map(result => {
                    const { week, month, quarter, year, code_info } = result;
                    let totalRevenue = 0;
                    for (const code of code_info) totalRevenue += parseInt(code.volume) || 0;
                    const data = {
                      key: `${week || month || quarter}${year}custom_codes`,
                      label: getBrandReportHeaderLabel({ groupingMethod, week, month, quarter }),
                      value: totalRevenue,
                      displayValue: `$${totalRevenue}`
                    };
                    return data;
                  })}
                />
              )}
            </>
          )}

          {!success && (
            <>
              <div className='error-centered'>
                <div className='title'>Error Fetching {title}</div>
                <div className='sub-title'>No results.</div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

ConciergeCustomCodes.propTypes = {
  title: PropTypes.string.isRequired,
  conciergeCustomCodes: PropTypes.object,
  groupingMethod: PropTypes.string.isRequired,

  download: PropTypes.func.isRequired,
  downloading: PropTypes.bool.isRequired
};

const ConciergeCustomCodesConfig = {
  title: 'Custom Codes'
};

export { ConciergeCustomCodes, ConciergeCustomCodesConfig };
