import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './UserWaitlistDecisionModal.scss';
import cogoToast from 'cogo-toast';

import * as waitlistHelpers from '../../helpers/waitlist_helpers';
import * as socialHelpers from '../../helpers/social_helpers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/pro-solid-svg-icons';
import { copyToClipboard } from '../../helpers/helpers';
import Modal from '../General/Modal';

import { fetchRecommendedRegistrationCodes } from '../../api/waitlist';

const UserWaitlistDecisionModal = props => {
  const { close, waitlistUser, accountManagers } = props;
  const { name, email, pitch } = waitlistUser;
  const socialUrls = waitlistHelpers.getPitchSocials(pitch) || [];

  const [AccountRepresentitive_id, setAccountRepresentitive_id] = useState(waitlistUser.AccountRepresentitive_id);
  const [suggestedCodes, setSuggestedCodes] = useState([]);
  const [code, setCode] = useState('');

  useEffect(() => {
    const fetchCodes = async () => {
      const suggestedCodesResponse = await fetchRecommendedRegistrationCodes(name);
      setSuggestedCodes(suggestedCodesResponse?.suggestedCodes || []);
    };

    fetchCodes();
  }, [name]);

  const acceptUserOffline = async () => {
    await props.acceptUser(waitlistUser, {
      code,
      handledOffline: true,
      isAccepted: true,
      isRejected: false,
      ...(AccountRepresentitive_id ? { AutoAssignHubspotUser_id: AccountRepresentitive_id } : {})
    });
    cogoToast.success(`Accepted ${name}, handling offline`);
    close();
  };

  const acceptUser = async () => {
    if (!code) return cogoToast.error('Please enter a code');
    await props.acceptUser(waitlistUser, {
      code,
      isAccepted: true,
      isRejected: false,
      ...(AccountRepresentitive_id ? { AutoAssignHubspotUser_id: AccountRepresentitive_id } : {})
    });
    cogoToast.success(`Accepted ${name}, sent code ${code} to ${email}`);
    close();
  };

  return (
    <Modal close={close}>
      <div className='user-waitlist-decision-modal-outer'>
        <div className='user-waitlist-decision-modal-inner'>
          <div className='header-container'>
            <div className='user-waitlist-decision-modal-title'>{name}</div>
            <div className='user-waitlist-decision-modal-subtitle' onClick={() => copyToClipboard(email, true)}>
              {email} <FontAwesomeIcon icon={faCopy} />
            </div>
            <div className='user-waitlist-decision-modal-socials' onClick={() => copyToClipboard(email, true)}>
              {socialUrls.map(socialUrl => {
                const platform = socialUrl.includes('instagram')
                  ? 'Instagram'
                  : socialUrl.includes('tiktok')
                  ? 'TikTok'
                  : socialUrl.includes('youtube')
                  ? 'Youtube'
                  : socialUrl.includes('facebook')
                  ? 'Facebook'
                  : socialUrl.includes('twitter')
                  ? 'Twitter'
                  : socialUrl.includes('substack')
                  ? 'Substack'
                  : socialUrl.includes('linktr')
                  ? 'Linktree'
                  : socialUrl.includes('pinterest')
                  ? 'Pinterest'
                  : 'Instagram';

                return (
                  <a target='_blank' rel='noopener noreferrer' href={socialUrl} key={socialUrl}>
                    <img className='social-icon' src={socialHelpers.getSocialIcon(platform)} alt='Social' />
                  </a>
                );
              })}
            </div>
          </div>
          <div className='pitch-container'>
            <div className='pitch'>{pitch}</div>
          </div>

          <div className='form-container'>
            <div className='form-group'>
              <label htmlFor='AccountRepresentitive_id'>Account Representative</label>
              <select
                className='form-control'
                id='AccountRepresentitive_id'
                value={AccountRepresentitive_id}
                onChange={e => setAccountRepresentitive_id(e.target.value)}
              >
                <option value={null}>Select Account Representative</option>
                {accountManagers.map(accountManager => {
                  return (
                    <option key={accountManager.id} value={accountManager.id}>
                      {accountManager.name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className='form-group suggested-codes'>
              {suggestedCodes.map(code => {
                return (
                  <div key={code} className='suggested-code' onClick={() => copyToClipboard(code, `${code} copied!`)}>
                    {code}
                    <FontAwesomeIcon icon={faCopy} />
                  </div>
                );
              })}

              <input className='form-control' type='text' placeholder={`NAME-2021`} value={code} onChange={e => setCode(e.target.value)} />
            </div>
          </div>

          <div className='buttons-container'>
            <button className='btn btn-primary' onClick={() => close()}>
              Cancel
            </button>
            <button className='btn btn-success' onClick={acceptUserOffline}>
              Handle Offline
            </button>
            <button className='btn btn-success' onClick={acceptUser}>
              Send Code By Email
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

UserWaitlistDecisionModal.propTypes = {
  close: PropTypes.func.isRequired,
  waitlistUser: PropTypes.object.isRequired,
  accountManagers: PropTypes.array.isRequired,
  acceptUser: PropTypes.func.isRequired
};

export default UserWaitlistDecisionModal;
