import moment from 'moment';
import { getPins } from '../api/analytics';

const makePinApi = async ({ start, end, hideOtherRetailers = false, Brand_id, groupByMode, groupingMethod }) => {
  const timezoneOffset = new Date().getTimezoneOffset();
  const query_data = { Brand_id, startDate: start, endDate: end, limit: 1e6, groupByMode, hideOtherRetailers, timezoneOffset };
  const { results } = await getPins(query_data);

  const sorted = results.sort((a, b) => parseInt(b.orderVolumeTotal) - parseInt(a.orderVolumeTotal));
  const data = { year: moment(start).year(), success: true, results: sorted };

  if (groupingMethod === 'month') data.month = moment(start).month() + 1;
  else if (groupingMethod === 'quarter') data.quarter = moment(start).quarter();
  else if (groupingMethod === 'week') data.week = moment(start).week();
  else throw new Error('Invalid grouping method');

  return data;
};

/**
 *
 * @param {Object} props
 * @param {string} props.startDate - start of time interval
 * @param {string} props.endDate - end of time interval
 * @param {('month'|'quarter')} props.groupingMethod
 * @param {('users'|'mentions')} props.groupByMode - the way in which to get results from the pins api
 * @param {string} props.Brand_id - the brand in which to query the results on
 */
export const get_pin_analytics = async props => {
  const { startDate, endDate, groupingMethod, groupByMode, Brand_id } = props;

  // MAKE API CALL HERE
  const start = moment(startDate);
  let end = moment(endDate);

  const pinApis = [];
  const dtcPinApis = [];
  for (let i = 0; start.isBefore(moment(end)); ++i) {
    let start_date;
    if (moment(end).startOf(groupingMethod).isBefore(start)) start_date = moment(start);
    else start_date = moment(end).startOf(groupingMethod); // prettier-ignore

    let end_date;
    if (i === 0) end_date = moment(end);
    else end_date = moment(end).endOf(groupingMethod);

    pinApis.unshift(
      makePinApi({
        start: start_date.format('YYYY-MM-DD'),
        end: end_date.format('YYYY-MM-DD'),
        hideOtherRetailers: false,
        Brand_id,
        groupByMode,
        groupingMethod
      })
    );

    dtcPinApis.unshift(
      makePinApi({
        start: start_date.format('YYYY-MM-DD'),
        end: end_date.format('YYYY-MM-DD'),
        hideOtherRetailers: true,
        Brand_id,
        groupByMode,
        groupingMethod
      })
    );

    end.subtract(1, `${groupingMethod}s`);
    // groupingMethod === 'month' ? end.subtract(1, 'months') : end.subtract(1, 'quarters');
  }

  const allResults = await Promise.all(pinApis);
  const dtcResults = await Promise.all(dtcPinApis);
  const nonDtcResults = makeNonDtcResults({ all: allResults, dtc: dtcResults, groupByMode });

  return { allResults, dtcResults, nonDtcResults };
};

const makeNonDtcResults = ({ all, dtc, groupByMode }) => {
  const nonDtcPromoterTimePeriods = [];

  for (let timePeriod = 0; timePeriod < all.length; ++timePeriod) {
    const currentAll = all[timePeriod].results;
    const currentDtc = dtc[timePeriod].results;
    const nonDtcResults = { ...all[timePeriod] };
    nonDtcResults.results = [];

    for (const result of currentAll) {
      const { id: Mention_id, User_id, Product_id } = result;

      if (groupByMode === 'users' && !!currentDtc.find(result => result.User_id === User_id && result.orderVolumeTotal)) continue;
      else if (groupByMode === 'mentions' && !!currentDtc.find(result => result.id === Mention_id && result.orderVolumeTotal)) continue;
      else if (groupByMode === 'products' && !!currentDtc.find(result => result.Product_id === Product_id && result.orderVolumeTotal)) continue;
      else nonDtcResults.results.push(result);
    }

    nonDtcPromoterTimePeriods.push(nonDtcResults);
  }

  return nonDtcPromoterTimePeriods;
};
