import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './ConciergeSidebar.scss';
import Mousetrap from 'mousetrap';
import ConciergeComments from './ConciergeComments';
import ConciergeSidebarTalentExport from './CociergeSidebarTalentExport';

const ConciergeSidebar = props => {
  const { allComments, updateComment, allTalent } = props;

  const [tab, setTab] = useState('comments');
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    Mousetrap.bind('/', () => setIsVisible(!isVisible));
    return () => Mousetrap.unbind('tab');
  }, [isVisible, setIsVisible]);

  return (
    <>
      <div className='keybinding-container'>
        {isVisible ? 'Hide Panel' : 'Show Panel'} <kbd>/</kbd>
      </div>
      {isVisible && (
        <div className='concierge-sidebar-outer'>
          <div className='tab-container'>
            <div className={`tab ${tab === 'comments' ? 'active' : ''}`} onClick={() => setTab('comments')}>
              Comments
            </div>
            <div className={`tab ${tab === 'export' ? 'active' : ''}`} onClick={() => setTab('export')}>
              Talent Export
            </div>
          </div>

          {tab === 'comments' && <ConciergeComments allComments={allComments} updateComment={updateComment} />}
          {tab === 'export' && <ConciergeSidebarTalentExport allTalent={allTalent} />}
        </div>
      )}
    </>
  );
};

ConciergeSidebar.propTypes = {
  allComments: PropTypes.array,
  updateComment: PropTypes.func.isRequired,
  allTalent: PropTypes.array
};

export default ConciergeSidebar;
