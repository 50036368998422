import APIClient from './index_ml';
import _ from 'lodash';

export const getSimilarPerformers = User_id => {
  return new Promise((resolve, reject) => {
    APIClient.get(`get_similar_performers?${new URLSearchParams({ user_id: User_id }).toString()}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};
