import _ from 'lodash';
import cogoToast from 'cogo-toast';
import * as ActionTypes from '../actions/notesActions';

export const notes = (state = { notes: {}, all_managers: [] }, action) => {
  switch (action.type) {
    case ActionTypes.GET_BRAND_NOTE:
      return {
        ...state,
        note: {
          ...action.note,
          comments: _.orderBy(action.note.comments, 'createdAt', 'desc')
        },
        all_managers: action.all_managers
      };

    case ActionTypes.UPDATE_NOTE_SUCCESS:
      return {
        ...state,
        note: action.note
      };

    case ActionTypes.UPDATE_NOTE_FAILURE:
      cogoToast.error('Failed to update node, see console.');
      return {
        ...state
      };

    case ActionTypes.CREATE_COMMENT_SUCCESS:
      return {
        ...state,
        note: {
          ...state.note,
          comments: [action.comment, ...state.note.comments]
        }
      };

    case ActionTypes.UPDATE_COMMENT_SUCCESS:
      return {
        ...state,
        note: {
          ...state.note,
          comments: state.note.comments?.map(comment => (comment.id === action.comment.id ? action.comment : comment))
        }
      };

    case ActionTypes.DELETE_COMMENT_REQUEST:
      return {
        ...state,
        note: {
          ...state.note,
          comments: state.note.comments.filter(comment => comment.id !== action.comment.id)
        }
      };

    case ActionTypes.DELETE_COMMENT_FAILURE:
      cogoToast.error('Failed to delete comment');
      return {
        ...state,
        note: {
          ...state.note,
          comments: [...state.note.comments, action.comment]
        }
      };

    default:
      return state;
  }
};
