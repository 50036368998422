import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';

import './AnalyticsPins.scss';

class AnalyticsPins extends Component {
  static propTypes = {
    pins: PropTypes.array.isRequired
  };

  state = {
    sortTerm: 'views',
    sortOrder: 'desc'
  };

  changeSort = newSort => {
    this.setState({
      sortTerm: newSort,
      sortOrder: newSort === this.state.sortTerm ? (this.state.sortOrder === 'desc' ? 'asc' : 'desc') : 'desc'
    });
  };

  getSortedPins = () => {
    const { pins } = this.props;
    const { sortTerm, sortOrder } = this.state;
    switch (sortTerm) {
      default:
        return _.orderBy(pins, [sortTerm, 'views'], [sortOrder, 'desc']);
    }
  };

  render() {
    const getSortedPins = this.getSortedPins();
    return (
      <div className='analytics-collections-outer-container'>
        <div className='table'>
          <div className='row row-header'>
            <div onClick={() => this.changeSort('User_name')} className='cell medium'>
              User
            </div>
            <div onClick={() => this.changeSort('Collection_name')} className='cell medium'>
              Collection
            </div>
            <div onClick={() => this.changeSort('title')} className='cell medium'>
              Title
            </div>
            <div onClick={() => this.changeSort('views')} className='cell tiny'>
              Clicks
            </div>
            <div onClick={() => this.changeSort('orders')} className='cell tiny'>
              Orders
            </div>
            <div onClick={() => this.changeSort('Product_id')} className='cell tiny'>
              Product
            </div>
            <div onClick={() => this.changeSort('fullPayout')} className='cell tiny'>
              Payout
            </div>
            <div onClick={() => this.changeSort('source')} className='cell'>
              Aff Program
            </div>
            <div onClick={() => this.changeSort('merchant')} className='cell'>
              Merchant
            </div>
            <div onClick={() => this.changeSort('source')} className='cell widt'>
              Aff Link
            </div>
            <div onClick={() => this.changeSort('createdAt')} className='cell'>
              Created On
            </div>
          </div>
          {_.map(getSortedPins, (pin, idx) => {
            const {
              User_name,
              createdAt,
              id,
              Product_id,
              link,
              title,
              merchant,
              fullPayout,
              source,
              Collection_id,
              affiliate_link,
              Collection_name,
              views,
              orders
            } = pin;
            return (
              <div className='row' key={id}>
                <div className='cell medium'>{User_name}</div>
                {Collection_id ? (
                  <a href={`https://shopmy.us/collections/${Collection_id}`} target='_blank' rel='noopener noreferrer' className='cell medium'>
                    {Collection_name.slice(0, 16)}
                    {Collection_name.length > 16 ? '...' : ''}
                  </a>
                ) : (
                  <div>-</div>
                )}
                <a href={link} target='_blank' rel='noopener noreferrer' className='cell medium'>
                  {title ? title.slice(0, 16) : '-'}
                  {title && title.length > 16 ? '...' : ''}
                </a>
                <div className='cell tiny'>{views || '-'}</div>
                <div className='cell tiny'>{orders || '-'}</div>
                <div className='cell tiny'>{Product_id ? '✓' : '-'}</div>
                <div className='cell tiny'>{fullPayout ? `${fullPayout}%` : '-'}</div>
                <div className='cell'>{source || '-'}</div>
                <div className='cell'>{merchant || '-'}</div>
                <a href={affiliate_link} target='_blank' rel='noopener noreferrer' className='cell wide'>
                  {affiliate_link ? `${affiliate_link.slice(0, 24)}...` : '-'}
                </a>
                <div className='cell'>{createdAt ? moment(createdAt).format('MMMM Do') : '-'}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default AnalyticsPins;
