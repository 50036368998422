import React, { useState } from 'react';
import cn from 'classnames';
import { faDownload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import { Table } from '../../../BrandReports/GraphTypes/Table';
import BarGraph from '../../../BrandReports/GraphTypes/BarGraph';
import Loader from '../../../General/Loader';

import { getBrandReportHeaderLabel } from '../../../../helpers/brand_helpers';

const ConciergeChats = props => {
  // console.log({ props });
  const { conciergeChats, title, groupingMethod, downloading } = props;
  const success = !!conciergeChats?.length;

  const [tab, setTab] = useState('table'); // graph, table
  const download_table = async () => await props.download({ rows: make_table_rows(), fileName: 'concierge_chats' });
  const download_graph = async () => await props.download({ graph: title });

  const make_table_rows = () => {
    // inbound, outbound, brands

    let inbound_running_total = 0;
    const inbound_count = {
      key: 'inbound_total',
      label: 'Inbound',
      description: '(chats received from brands)',
      values: conciergeChats.map(result => {
        const { week, month, quarter, inbound } = result;
        inbound_running_total += parseInt(inbound || 0);
        return { label: getBrandReportHeaderLabel({ groupingMethod, week, month, quarter }), value: inbound };
      })
    };
    inbound_count.values.push({ label: 'Total', value: inbound_running_total });

    let outbound_running_total = 0;
    const outbound_count = {
      key: 'outbound_total',
      label: 'Outbound',
      description: '(chats sent to brands)',
      values: conciergeChats.map(result => {
        const { week, month, quarter, outbound } = result;
        outbound_running_total += parseInt(outbound || 0);
        return { label: getBrandReportHeaderLabel({ groupingMethod, week, month, quarter }), value: outbound };
      })
    };
    outbound_count.values.push({ label: 'Total', value: outbound_running_total });

    const brands_count = {
      key: 'brands_total',
      label: 'Unique Brands',
      description: '(number of different brands they chatted with)',
      values: conciergeChats.map(result => {
        const { week, month, quarter, brands } = result;
        return { label: getBrandReportHeaderLabel({ groupingMethod, week, month, quarter }), value: brands };
      })
    };
    brands_count.values.push({ label: 'Total', value: 0 });

    return [inbound_count, outbound_count, brands_count];
  };

  return (
    <div className='talent-report-card' id={title}>
      {!conciergeChats ? (
        <Loader />
      ) : (
        <>
          {success && (
            <>
              <div className='header'>
                <div className='description'>
                  <div className='title'>{title}</div>
                  <div className='sub-title'>
                    Chats are the primary way for brands to communicate with talent. This report shows the number of chats sent and received by
                    talent, as well as the number of unique brands they have chatted with.
                  </div>
                </div>
                {!downloading && (
                  <div className='talent-report-controls' id={`${title}-card-controls`}>
                    <div className='radio-btns'>
                      <div className={cn('radio-btn', { active: tab === 'graph' })} onClick={() => setTab('graph')}>
                        Graph
                      </div>
                      <div className={cn('radio-btn', { active: tab === 'table' })} onClick={() => setTab('table')}>
                        Table
                      </div>
                    </div>
                    <div className='rounded-button' onClick={() => (tab === 'table' ? download_table() : download_graph())}>
                      {downloading ? (
                        <Loader />
                      ) : (
                        <>
                          <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                          Download
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>

              {tab === 'table' && <Table title='Talent Chat Counts' data={make_table_rows()} />}
              {tab === 'graph' && (
                <BarGraph
                  title='Total Chats'
                  data={conciergeChats.map(result => {
                    const { week, month, quarter, year, inbound, outbound } = result;
                    const data = {
                      key: `${week || month || quarter}${year}chat_counts`,
                      value: (parseInt(inbound) || 0) + (parseInt(outbound) || 0),
                      label: getBrandReportHeaderLabel({ groupingMethod, week, month, quarter })
                    };
                    return data;
                  })}
                />
              )}
            </>
          )}

          {!success && (
            <>
              <div className='error-centered'>
                <div className='title'>{title}</div>
                <div className='sub-title'>No results.</div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

ConciergeChats.propTypes = {
  title: PropTypes.string.isRequired,
  conciergeChats: PropTypes.array,
  groupingMethod: PropTypes.string.isRequired,

  download: PropTypes.func.isRequired,
  downloading: PropTypes.bool.isRequired
};

const ConciergeChatsConfig = {
  title: 'Chats'
};

export { ConciergeChats, ConciergeChatsConfig };
