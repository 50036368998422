import APIClient from './index';
import _ from 'lodash';

export const getPins = params => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/Pins?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const getAnalytics = data => {
  return new Promise((resolve, reject) => {
    APIClient.get(`Analytics/activity?${new URLSearchParams(data).toString()}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const getBrandClicks = async data => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/BrandReports/brand_clicks?${new URLSearchParams(data).toString()}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const getBrandOrders = data => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/BrandReports/brand_orders?${new URLSearchParams(data).toString()}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const getBrandGifting = data => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/BrandReports/brand_gifting?${new URLSearchParams(data).toString()}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const getBrandCodes = data => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/BrandReports/brand_codes?${new URLSearchParams(data).toString()}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const getBrandCustomRates = data => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/BrandReports/custom_rates?${new URLSearchParams(data).toString()}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const getBrandCompetitorComparison = data => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/BrandReports/competitor_comparison?${new URLSearchParams(data).toString()}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const getBrandCustomCodeUsers = data => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/BrandReports/custom_code_users?${new URLSearchParams(data).toString()}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const getBrandCustomRateUsers = data => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/BrandReports/custom_rate_users?${new URLSearchParams(data).toString()}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const getBrandChats = data => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/BrandReports/brand_chats?${new URLSearchParams(data).toString()}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const getBrandUnreadChatUsers = data => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/BrandReports/unread_chat_users?${new URLSearchParams(data).toString()}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const getBrandTopTalentActivity = data => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/BrandReports/top_talent_activity?${new URLSearchParams(data).toString()}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};
