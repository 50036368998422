import APIClient from './index';
import _ from 'lodash';

export const getMerchants = (params = {}) => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/Merchants?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};
export const updateMerchant = (merchant, updates) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`Merchants/${merchant.id}`, updates)
      .then(response => resolve(response.data))
      .catch(err => reject(err));
  });
};

export const getMerchantTestCases = (params = {}) => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/MerchantTestCases?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const addMerchantTestCase = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`MerchantTestCases`, data)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const updateMerchantTestCase = (testCase, updates) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`MerchantTestCases/${testCase.id}`, updates)
      .then(response => resolve(response.data))
      .catch(err => reject(err));
  });
};

export const deleteMerchantTestCase = test => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`MerchantTestCases/${test.id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};
