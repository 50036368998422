import APIClient from './index';
import _ from 'lodash';

export const updatePin = (id, data) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`Pins/${id}`, data)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const searchPins = queries => {
  return new Promise((resolve, reject) => {
    APIClient.post(`Admin/search_pins`, { queries })
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const getMerchants = urls => {
  return new Promise((resolve, reject) => {
    APIClient.post(`Pins/get_merchant_data`, { urls })
      .then(response => resolve(response.data))
      .catch(err => reject(false));
  });
};

export const getPinsFromUrl = url => {
  return new Promise((resolve, reject) => {
    APIClient.post(`Pins/from_url`, { url })
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};
