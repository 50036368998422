import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cogoToast from 'cogo-toast';
import moment from 'moment';
import './PinResult.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign, faDoNotEnter, faLink } from '@fortawesome/pro-solid-svg-icons';

import { copyToClipboard } from '../../../helpers/helpers';
import { updatePin } from '../../../api/pins';

import Tooltip from '../../General/Tooltip';

class PinResult extends Component {
  static propTypes = {
    pin: PropTypes.object.isRequired
  };

  state = {
    pin: this.props.pin,
    isEditing: false
  };

  cancel = () => {
    this.setState({
      pin: this.props.pin,
      isEditing: false
    });
  };

  save = async () => {
    const { pin } = this.state;

    let updates = {};
    if (this.props.pin.title !== pin.title) {
      updates.title = pin.title;
    }
    if (this.props.pin.link !== pin.link) {
      updates.link = pin.link;
    }

    if (!_.isEmpty(updates)) {
      try {
        const pinResp = await updatePin(pin.id, updates);
        this.setState({
          pin: {
            ...pin,
            ...pinResp.pin
          },
          isEditing: false
        });
        cogoToast.success('Successfully updated.', { hideAfter: 1 });
      } catch (error) {
        console.error(error);
        cogoToast.error('There was an error, please check the console.');
      }
    } else {
      this.setState({
        isEditing: false
      });
    }
  };
  copy = (str, msg) => {
    copyToClipboard(str);
    cogoToast.success(`Copied ${msg || str}`, { hideAfter: 1 });
  };

  render() {
    const { pin, isEditing } = this.state;
    const {
      id,
      createdAt,
      title,
      link,
      allow_optimization,
      image,
      name,
      ConsultResult_stub,
      clientName,
      Collection_id,
      User_id,
      Product_id,
      Collection_name
    } = pin;
    return (
      <div className='pin-result-container'>
        <div className='pin-result'>
          {image ? <img alt={title} src={image} /> : <div className='empty-img' />}
          {isEditing ? (
            <>
              <textarea
                placeholder='Title'
                value={title}
                rows={3}
                onChange={({ target }) => this.setState({ pin: { ...pin, title: target.value } })}
              />
              <textarea placeholder='Link' value={link} rows={3} onChange={({ target }) => this.setState({ pin: { ...pin, link: target.value } })} />
              <div className='save-btns'>
                <div onClick={this.cancel} className='cancel'>
                  Cancel
                </div>
                <div onClick={this.save} className='save'>
                  Save
                </div>
              </div>
            </>
          ) : (
            <div className='metadata' onClick={() => this.setState({ isEditing: !isEditing })}>
              <div>
                <div className='title'>{title || '-'}</div>
                <div className='link'>{link.slice(0, 36)}...</div>
              </div>
              <div>
                {Collection_name ? (
                  <a
                    href={`https://shopmy.us/collections/${Collection_id}`}
                    target='_blank'
                    onClick={e => e.stopPropagation()}
                    rel='noopener noreferrer'
                    className='collection'
                  >
                    {Collection_name}
                  </a>
                ) : ConsultResult_stub ? (
                  <a
                    href={`https://shopmy.us/consults/results/${ConsultResult_stub}`}
                    target='_blank'
                    onClick={e => e.stopPropagation()}
                    rel='noopener noreferrer'
                    className='collection'
                  >
                    Consult For {clientName}
                  </a>
                ) : (
                  <div className='collection quick'>Quick Link</div>
                )}
                <div className='name'>{name}</div>
                <div className='time'>{moment(createdAt).format('MMMM Do, h:mma')}</div>
              </div>
            </div>
          )}
          <div className='action-icons'>
            {!allow_optimization && (
              <Tooltip
                message='Cannot change this URL. Marked as Do Not Optimize.'
                getIconDiv={() => <FontAwesomeIcon icon={faDoNotEnter}></FontAwesomeIcon>}
              />
            )}
            {Product_id && <FontAwesomeIcon onClick={() => this.copy(Product_id, `Product id ${Product_id}`)} icon={faLink}></FontAwesomeIcon>}
            <FontAwesomeIcon onClick={() => this.copy(`user-${User_id}-pin-${id}-puser-undefined`)} icon={faDollarSign}></FontAwesomeIcon>
          </div>
        </div>
      </div>
    );
  }
}

export default PinResult;
