import APIClient from './index';
import _ from 'lodash';

export const getSubscriptionOffers = (params = {}) => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/SubscriptionOffers?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const addSubscriptionOffer = data => {
  return new Promise((resolve, reject) => {
    APIClient.post('/SubscriptionOffers', data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const updateSubscriptionOffer = (offer, data) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/SubscriptionOffers/${offer.id}`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const deleteSubscriptionOffer = offer => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`SubscriptionOffers/${offer.id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const getSubscriptionBundles = (params = {}) => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/SubscriptionBundles?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const addSubscriptionBundle = data => {
  return new Promise((resolve, reject) => {
    APIClient.post('/SubscriptionBundles', data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const updateSubscriptionBundle = (bundle, data) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/SubscriptionBundles/${bundle.id}`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const deleteSubscriptionBundle = bundle => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`SubscriptionBundles/${bundle.id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const addSubscriptionBundleItem = data => {
  return new Promise((resolve, reject) => {
    APIClient.post('/SubscriptionBundleItems', data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const updateSubscriptionBundleItem = (item, data) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/SubscriptionBundleItems/${item.id}`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const deleteSubscriptionBundleItem = item => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`SubscriptionBundleItems/${item.id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};
