import _ from 'lodash';
import * as ActionTypes from '../actions/merchantActions';

export const merchants = (
  state = {
    all: []
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.GET_MERCHANTS_SUCCESS:
      return {
        ...state,
        all: _.orderBy(action.merchants, 'lastMonthPinCount', 'desc')
      };

    case ActionTypes.APPLY_TAG_TO_MERCHANTS_REQUEST:
      const merchantTagsBeingApplied = _.reduce(action.merchants, (r, u) => ({ ...r, [u.id]: true }), {}); // For performance
      return {
        ...state,
        all: state.all.map(merchant => {
          const isTagApplied = merchantTagsBeingApplied[merchant.id];
          return {
            ...merchant,
            tags: isTagApplied ? _.uniq(_.concat(merchant.tags || [], action.tag), 'id') : merchant.tags
          };
        })
      };

    case ActionTypes.DELETE_MERCHANT_TAG_REQUEST:
      return {
        ...state,
        all: state.all.map(merchant => {
          return {
            ...merchant,
            tags: merchant.id === action.merchant.id ? _.filter(merchant.tags || [], t => t.id !== action.tag.id) : merchant.tags
          };
        })
      };

    default:
      return state;
  }
};
