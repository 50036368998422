import * as ActionTypes from '../actions/catalogActions'

export const catalog = (state = {
  departments: [],
  categories: [],
  tags: [],
  brands: [],
}, action) => {
  switch (action.type) {
    case ActionTypes.SYNC_HIERARCHY_SUCCESS:
      return {
        ...state,
        departments: action.departments,
        categories: action.categories,
        tags: action.tags,
        brands: action.brands
      }

    default:
      return state;
  }
}
