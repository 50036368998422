import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getAccountManagers } from '../../api/admin';
import { getTalentReportUsers, getUsersWithNoAccountManager } from '../../api/talent_report';
import { deleteUrlParam, getUrlParam, setUrlParam } from '../../helpers/helpers';
import { getMultipleUserNotes } from '../../api/notes';
import _ from 'lodash';
import cogoToast from 'cogo-toast';
import './Concierge.scss';

import * as commentAPI from '../../api/note_comment';
import { updateUserTier as updateUserTierAPI } from '../../api/user_tiers';
import { updateUser as updateUserAPI, udpateUserAddress as updateUserAddressAPI } from '../../api/users';
import { setActiveTalentId } from '../../actions/uiActions';
import { getScoreDelta } from '../../helpers/concierge_helpers';

import Loader from '../General/Loader';
import ConciergeControls from './ConciergeControls';
import ConciergeTalentResults from './ConciergeTalentResults';
import ConciergeSummary from './ConciergeSummary';
import ConciergeModal from './ConciergeModal/ConciergeModal';
import ConciergeCodesModal from './ConciergeCodesModal/ConciergeCodesModal';
import ConciergeSidebar from './ConciergeSidebar/ConciergeSidebar';

const Concierge = props => {
  const { ui, setActiveTalentId } = props;
  const { activeTalentId } = ui;
  const isValidActiveTalentId = !!parseInt(ui.activeTalentId);

  const [registrationCodesModalOpen, setRegistrationCodesModalOpen] = useState(false);
  const [accountManagers, setAccountManagers] = useState([]);
  const [usersWithNoManager, setUsersWithNoManager] = useState([]);
  const [loading, setLoading] = useState(true);
  const [talentLoading, setTalentLoading] = useState(true);
  const [allTalent, setAllTalent] = useState([]);
  const [allComments, setAllComments] = useState([]);
  const [talentQuery, setTalentQuery] = useState({
    AccountManager_id: getUrlParam('AccountManager_id'),
    Talent_id: getUrlParam('Talent_id')
  });

  const sortValues = [
    { display: 'Name', value: 'name', sort: (a, b) => a.name.localeCompare(b.name) },
    { display: 'Score', value: 'score', sort: (a, b) => b.score - a.score },
    { display: 'Score Delta', value: 'delta', sort: (a, b) => getScoreDelta(b) - getScoreDelta(a) },
    { display: 'Support Level', value: 'supportLevel', sort: (a, b) => b.supportLevel - a.supportLevel }
  ];

  const [selectedSortValue, setSelectedSortValue] = useState(sortValues[0]);
  const [sortDirection, setSortDirection] = useState(true);

  // these are filters that can be applied to in ConciergeTalentResults but are on the top level so that the filtering affects the summary as well
  // if you want to add a new filter, make sure 'conditionalSortValue' is an option in the sortValues array
  const [extraFilters, setExtraFilters] = useState({});
  const extraFilterOptions = [
    {
      conditionalSortValue: 'supportLevel',
      values: [
        { display: 0, filter: user => user.supportLevel === 0 },
        { display: 1, filter: user => user.supportLevel === 1 },
        { display: 2, filter: user => user.supportLevel === 2 },
        { display: 3, filter: user => user.supportLevel === 3 }
      ]
    },
    {
      conditionalSortValue: 'score',
      values: [
        { display: 'Icon', filter: user => user.tier === 1 },
        { display: 'Trendsetter', filter: user => user.tier === 2 },
        { display: 'Ambasssabor', filter: user => user.tier === 3 }
      ]
    }
  ];

  const [filteredAndSortedTalent, setFilteredAndSortedTalent] = useState([]); // this is the talent that is displayed on the page

  useEffect(() => {
    const applyExtraFilters = user => {
      let passed_each_extra_filter = true;

      for (const [, value] of Object.entries(extraFilters)) passed_each_extra_filter = passed_each_extra_filter && value.filter(user);
      return passed_each_extra_filter;
    };

    const allTalentFiltered = allTalent.filter(applyExtraFilters).sort(selectedSortValue.sort);
    if (!sortDirection) allTalentFiltered.reverse();

    setFilteredAndSortedTalent(allTalentFiltered);
  }, [allTalent, extraFilters, selectedSortValue, sortDirection]);

  // get all the account managers (doesn't depend on page data)
  useEffect(() => {
    const fetch_initial_info = async () => {
      const [accountManagerResponse, usersWithNoManagerResponse] = await Promise.all([getAccountManagers('user'), getUsersWithNoAccountManager()]);
      setAccountManagers(accountManagerResponse.users || []);
      setUsersWithNoManager(usersWithNoManagerResponse.users || []);

      setLoading(false);
    };

    fetch_initial_info();
  }, []);

  // fetch talent information
  useEffect(() => {
    setTalentLoading(true);
    const { AccountManager_id, Talent_id } = talentQuery;

    AccountManager_id ? setUrlParam('AccountManager_id', AccountManager_id) : deleteUrlParam('AccountManager_id');
    Talent_id ? setUrlParam('Talent_id', Talent_id) : deleteUrlParam('Talent_id');

    if (!AccountManager_id && !Talent_id) {
      cogoToast.info('Please select an Account Manager or Specefic Talent to view.');
      setTalentLoading(false);
      return;
    }

    const fetchTalent = async () => {
      const data = { AccountManager_id, User_id: Talent_id };
      !AccountManager_id && delete data.AccountManager_id;
      !Talent_id && delete data.User_id;

      try {
        const talentResponse = await getTalentReportUsers(data);
        setAllTalent(talentResponse.users);
        setTalentLoading(false);

        const comments = collectNotesFromTalent({ allTalent: talentResponse.users });
        setAllComments(comments);
      } catch (e) {
        cogoToast.error('There was an error fetching the talent');
      }
    };

    fetchTalent();
  }, [talentQuery]);

  /**
   * Helper function that extracts all the NoteComments from an array of talent or notes
   * then returns all of them in a single array.
   *
   * @param {Object} props
   * @param {Object[]} props.allTalent - an array of all the talent to collect notes from
   * @param {Object[]} props.notes - an array of notes to collect comments from
   * @returns
   */
  const collectNotesFromTalent = ({ allTalent, notes }) => {
    const comments = [];
    if (allTalent) for (const talent of allTalent) comments.push(...talent.notes);
    if (notes) for (const note of notes) comments.push(...note.notes);
    _.orderBy(comments, 'updatedAt', 'desc');
    return comments;
  };

  /**
   * General function that prompts then creates a new NoteComment for a user.
   * Once the comment is created, it updates the state and returns an array of
   * all the notes (including the new one)
   *
   * @param {number} User_id - the id of the user to set the NoteComment to
   */
  const createComment = async (User_id, type) => {
    const message = window.prompt('Enter your comment.');
    if (!message) return;

    try {
      await commentAPI.createComment({ User_id, comment: message, type });
    } catch (e) {
      return cogoToast.error('There was an error creating the comment.');
    }

    const user_ids = allTalent.map(talent => talent.id);
    const notes_response = await getMultipleUserNotes({ user_ids });
    const notes = collectNotesFromTalent({ notes: notes_response.notes });
    setAllComments(notes);
    return notes;
  };

  /**
   * Function that recieves and updates a NoteComment. Once the comment is updated,
   * it updates the state and returns an array of all the notes (including the updated one)
   *
   * @param {Object} commentObject - a NoteComment object with the unique id and the properties to update
   * @param {number} commentObject.Comment_id - the unique id of the NoteComment being updated
   * @param {string} commentObject.comment - the new display value for the NoteComment
   * @param {boolean} commentObject.isRead - whether or not the NoteComment has been read
   * @returns
   */
  const updateComment = async commentObject => {
    if (!commentObject) return;
    const { Comment_id, isRead, isDismissed, comment, dueDate } = commentObject;

    try {
      const data = { id: Comment_id, comment, isRead, isDismissed, dueDate };
      await commentAPI.updateComment({ id: Comment_id }, data);
    } catch (e) {
      return cogoToast.error('There was an error updating the comment.');
    }

    const tempAllComments = allComments.map(comment =>
      comment.Comment_id === Comment_id ? { ...comment, ...commentObject, updatedAt: new Date() } : comment
    );
    // console.info(tempAllComments);
    setAllComments(tempAllComments);
    return tempAllComments;
  };

  /**
   * General function that updates the UserTier modal for a user. Once the user is updated,
   * it updates the state and returns the updated user to the caller.
   *
   * @param {Object} props
   * @param {number} props User_id - the id of the user to update
   * @param {Object} props updates - the updates to make to the user
   * @param {number} props.updates.supportLevel - the new support level for the user
   * @param {number} props.updates.expectedScore - the new expected score for the user
   */
  const updateUserTier = async ({ User_id, updates }) => {
    const tempAllTalent = [...allTalent];
    let talent = tempAllTalent.find(talent => talent.id === parseInt(User_id));

    try {
      await updateUserTierAPI({ id: talent.UserTier_id }, updates);
    } catch (e) {
      return cogoToast.error('There was an error updating the user tier.');
    }

    for (const key of Object.keys(updates)) talent[key] = updates[key];
    setAllTalent(tempAllTalent);
    return { ...talent, ...updates };
  };

  /**
   * General function that updates the User model. Currently, this is only used to update the
   * AccountManager_id. Once the user is updated, it updates the state and returns the updated
   * user to the caller.
   *
   * @param {Object} props
   * @param {number} props.User_id - the id of the user to update
   * @param {Object} props.updates - the updates to make to the user
   * @param {number} props.updates.AccountManager_id - the new account manager for the user
   * @param {boolean} props.updates.isArchived - the new archived status for the user
   * @returns
   */
  const updateUser = async ({ User_id, updates }) => {
    let tempAllTalent = [...allTalent];
    let talent = tempAllTalent.find(talent => talent.id === parseInt(User_id));

    try {
      await updateUserAPI({ id: talent.id }, updates);

      if (updates.AccountManager_id === null) tempAllTalent = tempAllTalent.filter(talent => talent.id !== User_id);
    } catch (e) {
      return cogoToast.error('There was an error updating the user.');
    }

    // apply all the updates
    for (const key of Object.keys(updates)) talent[key] = updates[key];

    // have to update the account manager name as well as id
    if (updates.AccountManager_id) {
      const accountManager = accountManagers.find(manager => manager.id === parseInt(updates.AccountManager_id));
      talent.AccountManager_id = accountManager.id;
      talent.AccountManager_name = accountManager.name;
    }

    setAllTalent(tempAllTalent);
    return { ...talent, ...updates };
  };

  const updateUserAddress = async ({ User_id, updates }) => {
    const talent = allTalent.find(talent => talent.id === parseInt(User_id));
    const newTalent = { ...talent, ...updates };

    return new Promise(resolve => {
      updateUserAddressAPI(User_id, updates)
        .then(() => resolve(newTalent))
        .catch(() => resolve());
    });
  };

  const updateSupportLevel = User_id => {
    const supportLevel = window.prompt(
      'Enter new support level for talent.\n\n3: Highest Support\n1: Lowest Support\n0: No Support (will be removed from dashboard)'
    );
    if (!supportLevel) return;
    else if (_.isNaN(parseInt(supportLevel))) return cogoToast.error('Entry must be an integer.');
    else if (parseInt(supportLevel) > 3 || parseInt(supportLevel < 0)) return cogoToast.error('Enter a number between 0 and 3.');

    updateUserTier({ User_id, updates: { supportLevel: supportLevel } });
    return supportLevel;
  };

  const updateExpectedScore = User_id => {
    const expectedScore = window.prompt('Enter new expected score for talent.');
    if (!expectedScore) return;
    else if (_.isNaN(parseInt(expectedScore))) return cogoToast.error('Entry must be an integer.');

    updateUserTier({ User_id, updates: { expectedScore: expectedScore } });
    return expectedScore;
  };

  return (
    <>
      <div className='concierge-outer'>
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className='concierge-header-buttons'>
              <button onClick={() => setRegistrationCodesModalOpen(true)} className='concierge-header-button'>
                Registration Codes
              </button>
            </div>
            <div className='concierge-header'>
              <ConciergeControls {...talentQuery} setTalentQuery={setTalentQuery} accountManagers={accountManagers} />
              {allTalent.length > 1 && <ConciergeSummary allTalent={filteredAndSortedTalent} />}
            </div>
            {talentLoading ? (
              <Loader />
            ) : (
              <>
                <ConciergeTalentResults
                  {...talentQuery}
                  allTalent={filteredAndSortedTalent}
                  usersWithNoManager={usersWithNoManager}
                  setActiveTalentId={props.setActiveTalentId}
                  allComments={allComments}
                  updateComment={updateComment}
                  createComment={createComment}
                  updateExpectedScore={updateExpectedScore}
                  updateSupportLevel={updateSupportLevel}
                  // sorting
                  sortValues={sortValues}
                  selectedSortValue={selectedSortValue}
                  setSelectedSortValue={setSelectedSortValue}
                  sortDirection={sortDirection}
                  setSortDirection={setSortDirection}
                  // extra filters get set in this level
                  extraFilterOptions={extraFilterOptions}
                  extraFilters={extraFilters}
                  setExtraFilters={setExtraFilters}
                />
                <ConciergeSidebar updateComment={updateComment} allComments={allComments} allTalent={filteredAndSortedTalent} />
              </>
            )}

            {registrationCodesModalOpen && (
              <ConciergeCodesModal close={() => setRegistrationCodesModalOpen(false)} accountManagers={accountManagers} />
            )}

            {isValidActiveTalentId && (
              <ConciergeModal
                close={() => setActiveTalentId()}
                activeTalentId={activeTalentId}
                accountManagers={accountManagers}
                updateUser={updateUser}
                updateUserTier={updateUserTier}
                updateUserAddress={updateUserAddress}
                updateComment={updateComment}
                createComment={createComment}
                updateExpectedScore={updateExpectedScore}
                updateSupportLevel={updateSupportLevel}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = state => {
  const { ui, notes } = state;
  return { ui, notes };
};

export default connect(mapStateToProps, {
  setActiveTalentId
})(Concierge);
