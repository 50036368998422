import React from 'react';
import Loader from '../../General/Loader';
import './BrandInformation.scss';
import { copyToClipboard } from '../../../helpers/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/pro-light-svg-icons';
import { useEffect } from 'react';
import PropTypes from 'prop-types';

const BrandInformation = ({ brand, title }) => {
  const { id, name, commission_rate, merchant = {}, email, domain, username, logo, sl_commission_rate } = brand;
  const { source } = merchant;

  const loading = false;
  const success = true;
  const errorMessage = '';

  useEffect(() => {}, [brand]);

  return (
    <div className='brand-card' id={title}>
      <div className='padding'>
        {loading ? (
          <Loader />
        ) : (
          <>
            {success && (
              <>
                <div className='header-information'>
                  <div className='header-section'>
                    <img src={logo} alt='brand_logo' className='logo' />
                  </div>
                  <div className='header-section'>
                    <div className='title'>{name}</div>
                  </div>
                  <div className='header-section'>
                    <div className='brand-info-container'>
                      <div className='brand-info' onClick={() => copyToClipboard(id, true)}>
                        Brand ID: {id} <FontAwesomeIcon icon={faCopy} />
                      </div>
                      <div className='brand-info' onClick={() => copyToClipboard(domain, true)}>
                        Domain: {domain ? domain : 'N/A'} <FontAwesomeIcon icon={faCopy} />
                      </div>
                      <div className='brand-info' onClick={() => copyToClipboard(email, true)}>
                        Email: {email} <FontAwesomeIcon icon={faCopy} />
                      </div>
                      <div className='brand-info' onClick={() => copyToClipboard(source, true)}>
                        Source: {source ? source : 'N/A'} <FontAwesomeIcon icon={faCopy} />
                      </div>
                      <div className='brand-info' onClick={() => copyToClipboard(username, true)}>
                        Username: {username} <FontAwesomeIcon icon={faCopy} />
                      </div>
                      <div className='brand-info' onClick={() => copyToClipboard(commission_rate, true)}>
                        Commission Rate: {commission_rate || sl_commission_rate || 0}% <FontAwesomeIcon icon={faCopy} />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            {!success && (
              <>
                <div className='error-centered'>
                  <div className='title'>Error Fetching {title}</div>
                  <div className='sub-title'>{errorMessage}</div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

BrandInformation.propTypes = {
  title: PropTypes.string.isRequired,
  brand: PropTypes.object.isRequired
};

const brandInformationConfig = {
  title: 'Brand Info'
};

export { BrandInformation, brandInformationConfig };
