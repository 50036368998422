import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Mousetrap from 'mousetrap';
import cogoToast from 'cogo-toast';
import commaNumber from 'comma-number';
import moment from 'moment';
import cn from 'classnames';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortAmountDownAlt, faTimes } from '@fortawesome/pro-light-svg-icons';

import './Users.scss';

import TagSidebar from '../Tags/TagSidebar';

import { updateUser } from '../../api/users';
import { getUsers, deleteUserTag } from '../../actions/userActions';
import { toggleUserBeingTagged, setUsersBeingTagged } from '../../actions/uiActions';
import { copyToClipboard } from '../../helpers/helpers';
import { getPrettyNumber } from '../../helpers/formatting';
import { getSocialIcon } from '../../helpers/social_helpers';
import { objectMatchesActiveTagGroups } from '../../helpers/tag_helpers';
import { getTierDisplay } from '../../helpers/tier_helpers';
import { isTaggingMode, isUserBeingTagged, getActiveTags, isTagTypeHidden } from '../../helpers/ui_helpers';

const VISIBLE_RESULTS_INITIAL = 80;
const RESULTS_PER_PAGE = 80;

class Users extends Component {
  static propTypes = {
    ui: PropTypes.object.isRequired,
    users: PropTypes.object.isRequired,
    getUsers: PropTypes.func.isRequired,
    deleteUserTag: PropTypes.func.isRequired,
    setUsersBeingTagged: PropTypes.func.isRequired,
    toggleUserBeingTagged: PropTypes.func.isRequired
  };

  componentDidMount() {
    isTaggingMode(this.props.ui) ? this.props.getUsers({ withTags: true }) : this.props.getUsers();
    Mousetrap.bind('/', () => setTimeout(() => this.searchRef && this.searchRef.focus(), 100));
    this.scrollToBottomHandler();
  }

  componentWillUnmount() {
    Mousetrap.unbind('/');
    Mousetrap.unbind('p');
  }

  componentDidUpdate(prevProps, prevState) {
    const prevActiveTags = getActiveTags(prevProps.ui);
    const activeTags = getActiveTags(this.props.ui);
    const prevSearch = prevState.searchVal;
    const curSearch = this.state.searchVal;
    if (prevActiveTags.length !== activeTags.length || curSearch.length !== prevSearch.length) {
      this.setState({ visibleResults: VISIBLE_RESULTS_INITIAL });
      window.scrollTo(0, 0);
    }

    if (!isTaggingMode(prevProps.ui) && isTaggingMode(this.props.ui)) {
      this.props.getUsers({ withTags: true });
    }
  }

  scrollToBottomHandler = () => {
    window.addEventListener('scroll', e => {
      const pixelsFromBottom = document.body.offsetHeight - window.pageYOffset - window.innerHeight;
      if (pixelsFromBottom < 250 && !this.state.scrollingToBottom) {
        this.setState({ scrollingToBottom: true, visibleResults: this.state.visibleResults + RESULTS_PER_PAGE }, () => {
          this.setState({ scrollingToBottom: false });
        });
      }
    });
  };

  state = {
    sortType: ['score'],
    sortOrder: ['desc'],
    searchVal: '',
    showingDescriptions: false,
    visibleResults: VISIBLE_RESULTS_INITIAL
  };

  setSort = (sortType = ['score'], order = ['desc']) => {
    const switchingSort = _.isEqual(order, this.state.sortOrder);
    this.setState({ sortType, sortOrder: switchingSort ? order.map(a => (a === 'desc' ? 'asc' : 'desc')) : order });
  };

  getFilteredAndSortedUsers = () => {
    const { ui, users } = this.props;
    const { searchVal } = this.state;
    const activeTags = getActiveTags(ui);
    const allUsers = _.get(users, 'users');
    const tagGroups = _.groupBy(activeTags, 'type');
    const groupsToCheck = _.values(tagGroups);
    const filteredUsers = _.filter(allUsers, user => {
      const matchesTags = !isTaggingMode(ui) || objectMatchesActiveTagGroups(user, groupsToCheck);

      const matchesSearchQuery =
        user.name?.toLowerCase().includes(searchVal.toLowerCase()) ||
        user.username?.toLowerCase().includes(searchVal.toLowerCase()) ||
        user.description?.toLowerCase().includes(searchVal.toLowerCase()) ||
        String(user.id).includes(searchVal);
      return matchesTags && matchesSearchQuery;
    });
    return _.orderBy(filteredUsers, this.state.sortType, this.state.sortOrder);
  };

  updateUser = (user, updates) => {
    updateUser(user, updates).then(resp => {
      this.props.getUsers();
    });
  };

  changeRate = async (user, type) => {
    let [updates, msg] = [{}, ''];

    const newRate = window.prompt('New rate?', user[type] || '');
    const rateVal = parseInt(newRate);

    if (_.isNaN(rateVal)) {
      cogoToast.error('Please enter a valid value');
      return;
    }

    switch (type) {
      case 'user_percentage':
        updates = {
          user_percentage: rateVal
        };
        msg = `Change rate for ${user.name} from ${user.user_percentage}% to ${newRate}%?`;
        break;
      case 'agent_percentage':
        updates = {
          agent_percentage: rateVal
        };
        msg = `Change agent rate for ${user.name} from ${user.agent_percentage}% to ${newRate}%?`;
        break;
      default:
        break;
    }
    var confirm = window.confirm(msg);
    if (confirm) {
      await updateUser(user, updates);
      this.props.getUsers();
    }
  };

  setType = async (user, type) => {
    let [updates, msg] = [{}, ''];
    switch (type) {
      case 'pro':
        updates = {
          isPro: !user.isPro
        };
        msg = `Are you sure you want to ${user.isPro ? 'remove' : 'make'} ${user.name} a Pro?`;
        break;
      default:
        cogoToast.error('Invalid Type');
    }
    var confirm = window.confirm(msg);
    if (confirm) {
      await updateUser(user, updates);
      this.props.getUsers();
    }
  };

  archiveUser = async (user, type) => {
    var confirm = user.isArchived
      ? window.confirm(`Are you sure you want to unarchive user ${user.name}? This will hide them from all brands.`)
      : window.confirm(`Are you sure you want to archive user ${user.name}?`);
    if (confirm) {
      await updateUser(user, { isArchived: !user.isArchived });
      this.props.getUsers();
    }
  };

  banUser = async (user, type) => {
    const banGloballyResp = window.prompt(
      'Do you want to edit a global ban or a chat-only ban? GLOBAL or CHAT',
      user.isBannedFromChat ? 'CHAT' : 'GLOBAL'
    );
    if (!banGloballyResp || !['GLOBAL', 'CHAT'].includes(banGloballyResp))
      return banGloballyResp && cogoToast.error('Invalid response, please type GLOBAL or CHAT');
    const banGlobally = banGloballyResp === 'GLOBAL';
    const confirm = banGlobally
      ? user.isBanned
        ? window.confirm(
            `Are you sure you want to re-enable user ${user.name}? We will re-enable monetization on all of their pins and allow them to be found by brands.`
          )
        : window.confirm(
            `Are you sure you want to Ban user ${user.name}? Wew will disable monetization on all of their pins and hide them from all brands.`
          )
      : user.isBannedFromChat
      ? window.confirm(`Are you sure you want to re-enable chat for user ${user.name}?`)
      : window.confirm(`Are you sure you want to Ban chat for user ${user.name}?`);

    if (confirm) {
      banGlobally ? await updateUser(user, { isBanned: !user.isBanned }) : await updateUser(user, { isBannedFromChat: !user.isBannedFromChat });
      this.props.getUsers();
    }
  };

  copyEmail = user => {
    copyToClipboard(user.email);
    cogoToast.success(`Copied ${user.email}`);
  };

  getSocial = user => {
    const { social_links } = user;
    if (!social_links) return null;

    // For performance we can memoize these
    // if (_.get(this, ['socialTags', user.id])) return _.get(this, ['socialTags', user.id]);

    const links = social_links.split(',');
    const instagramUrl = _.find(links, p => p.includes('instagram'));
    const tiktokUrl = _.find(links, p => p.includes('tiktok'));
    const youtubeUrl = _.find(links, p => p.includes('youtube'));
    const tags = (
      <>
        {instagramUrl && (
          <a target='_blank' rel='noopener noreferrer' href={instagramUrl}>
            <img className='social-icon' src={getSocialIcon('Instagram')} alt='Instagram' />
          </a>
        )}
        {tiktokUrl && (
          <a target='_blank' rel='noopener noreferrer' href={tiktokUrl}>
            <img className='social-icon' src={getSocialIcon('TikTok')} alt='TikTok' />
          </a>
        )}
        {youtubeUrl && (
          <a target='_blank' rel='noopener noreferrer' href={youtubeUrl}>
            <img className='social-icon' src={getSocialIcon('Youtube')} alt='Youtube' />
          </a>
        )}
      </>
    );
    // this.socialTags = this.projectionTags || {};
    // this.socialTags[user.id] = tags;
    return tags;
  };

  getTypeTag = user => {
    const { tier, score, isAdmin, Brand_id, isArchived } = user;
    const tierDisplay = getTierDisplay(tier);
    if (isAdmin) {
      return {
        display: 'ADMIN',
        className: 'admin'
      };
    }

    if (Brand_id) {
      return {
        display: 'BRAND',
        className: 'brand'
      };
    }

    if (isArchived) {
      return {
        display: 'Archived',
        className: 'archived'
      };
    }

    return {
      display: `${tierDisplay} - ${score?.toFixed(0)}`,
      className: tierDisplay.toLowerCase()
    };
  };

  getPredictedTag = user => {
    const { Brand_id, mlPredictedScore } = user;
    if (Brand_id) return null;
    return {
      display: mlPredictedScore || '',
      className: mlPredictedScore ? `grade-${String(mlPredictedScore)}` : ''
    };
  };

  getPredictedTag2 = user => {
    const { Brand_id, mlPredictedScore2 } = user;
    if (Brand_id) return null;
    return {
      display: mlPredictedScore2 || '',
      className: mlPredictedScore2 ? `grade-${String(mlPredictedScore2)}` : ''
    };
  };

  getProjectionTag = (user, idx) => {
    // For performance we can memoize these
    // if (_.get(this, ['projectionTags', user.id])) return _.get(this, ['projectionTags', user.id]);

    const { perMonthEarnings, thisMonthEarnings } = user;
    const projMonthEarnings = (thisMonthEarnings * 30.5) / moment().date();
    const ratio = projMonthEarnings / perMonthEarnings;
    const growth = 100 * ratio || 0;

    let tag;
    if (growth > 250) {
      tag = { className: 'highest' };
    } else if (growth > 175) {
      tag = { className: 'higher' };
    } else if (growth > 120) {
      tag = { className: 'high' };
    } else if (growth > 80) {
      tag = { className: 'middle' };
    } else if (growth > 60) {
      tag = { className: 'low' };
    } else if (growth > 40) {
      tag = { className: 'lower' };
    } else if (growth > 1) {
      tag = { className: 'lowest' };
    } else {
      tag = { className: 'empty' };
    }

    // this.projectionTags = this.projectionTags || {};
    // this.projectionTags[user.id] = tag;

    return tag;
  };

  getTotalProjections = users => {
    let [perMonthEarningsTotal, thisMonthEarningsTotalProj] = [0, 0];
    let [tiny, small, medium, large, huge] = [0, 0, 0, 0, 0];
    let [etiny, esmall, emedium, elarge, ehuge] = [0, 0, 0, 0, 0];
    const today = moment().date();
    users.forEach(({ perMonthEarnings, thisMonthEarnings }) => {
      const projMonthEarnings = (thisMonthEarnings * 30.5) / today;
      perMonthEarningsTotal += perMonthEarnings;
      thisMonthEarningsTotalProj += projMonthEarnings;
      if (perMonthEarnings > 0 && perMonthEarnings < 10) tiny++;
      if (perMonthEarnings >= 10 && perMonthEarnings < 100) small++;
      if (perMonthEarnings >= 100 && perMonthEarnings < 1000) medium++;
      if (perMonthEarnings >= 1000 && perMonthEarnings < 10000) large++;
      if (perMonthEarnings >= 10000) huge++;
      if (projMonthEarnings > 0 && projMonthEarnings < 10) etiny++;
      if (projMonthEarnings >= 10 && projMonthEarnings < 100) esmall++;
      if (projMonthEarnings >= 100 && projMonthEarnings < 1000) emedium++;
      if (projMonthEarnings >= 1000 && projMonthEarnings < 10000) elarge++;
      if (projMonthEarnings >= 10000) ehuge++;
    });
    return {
      expected: perMonthEarningsTotal,
      projected: thisMonthEarningsTotalProj,
      expectedCounts: {
        tiny,
        small,
        medium,
        large,
        huge
      },
      projectedCounts: {
        tiny: etiny,
        small: esmall,
        medium: emedium,
        large: elarge,
        huge: ehuge
      }
    };
  };

  render() {
    const { ui, toggleUserBeingTagged } = this.props;
    const { showingDescriptions, searchVal } = this.state;
    const users = this.getFilteredAndSortedUsers();
    const totalProjections = this.getTotalProjections(users);
    const selectedTagIds = _.map(getActiveTags(this.props.ui), 'id');
    const inTaggingMode = isTaggingMode(ui);
    return (
      <div className={cn('users-outer-container', { tagging: inTaggingMode })}>
        <TagSidebar />
        <div className='users-inner-container'>
          <div className='header-stats'>
            <input
              placeholder='Search by name, username, bio, id'
              value={searchVal}
              ref={ref => (this.searchRef = ref)}
              onChange={({ target }) => this.setState({ searchVal: target.value })}
            />
            <div className='header-stat'>
              <div className='stat'>
                Expected {totalProjections.expectedCounts.huge},{totalProjections.expectedCounts.large},{totalProjections.expectedCounts.medium},
                {totalProjections.expectedCounts.small},{totalProjections.expectedCounts.tiny} - Projected {totalProjections.projectedCounts.huge}{' '}
                HUGE, {totalProjections.projectedCounts.large} LARGE, {totalProjections.projectedCounts.medium} MEDIUM,{' '}
                {totalProjections.projectedCounts.small} SMALL, {totalProjections.projectedCounts.tiny} TINY
              </div>
              <div className='stat'>
                Expected ${commaNumber(totalProjections.expected.toFixed(0))} - Projected ${commaNumber(totalProjections.projected.toFixed(0))}
              </div>
            </div>
          </div>
          <div className='user row header'>
            {inTaggingMode ? (
              <>
                <div onClick={() => this.props.setUsersBeingTagged(users)} className='cell small'>
                  Select
                </div>
                <div onClick={() => this.setSort(['name'], ['asc'])} className='cell lg has-filter'>
                  Name
                  <FontAwesomeIcon icon={faSortAmountDownAlt}></FontAwesomeIcon>
                </div>
                <div className='cell md has-filter'>Username</div>
                <div className='cell md'>Social</div>
                <div className='cell md'>Followers</div>
                <div onClick={() => this.setSort(['perMonthEarnings'], ['desc'])} className='cell md has-filter'>
                  Per Month
                  <FontAwesomeIcon icon={faSortAmountDownAlt}></FontAwesomeIcon>
                </div>
                <div onClick={() => this.setSort(['score'], ['desc'])} className='cell lg has-filter'>
                  Tier
                  <FontAwesomeIcon icon={faSortAmountDownAlt}></FontAwesomeIcon>
                </div>
                <div onClick={() => this.setSort(['mlPredictedScore', 'score'], ['desc', 'desc'])} className='cell md has-filter'>
                  Predicted
                </div>
                {/* <div onClick={() => this.setSort(['mlPredictedScore2'], ['desc'])} className='cell md has-filter'> */}
                {/*   Predicted v2 */}
                {/* </div> */}
                <div className='cell xl'>Tags</div>
                <div onClick={() => this.setSort(['createdAt'], ['desc'])} className='cell md has-filter'>
                  Created On
                  <FontAwesomeIcon icon={faSortAmountDownAlt}></FontAwesomeIcon>
                </div>
                <div className='cell xl'>Description</div>
                <div className='cell small'>Email</div>
              </>
            ) : (
              <>
                <div onClick={() => this.setSort(['name'], ['asc'])} className='cell lg has-filter'>
                  Name
                  <FontAwesomeIcon icon={faSortAmountDownAlt}></FontAwesomeIcon>
                </div>
                <div onClick={() => this.setSort(['username'], ['asc'])} className='cell lg has-filter'>
                  Username
                  <FontAwesomeIcon icon={faSortAmountDownAlt}></FontAwesomeIcon>
                </div>
                <div className='cell md'>Social</div>
                <div className='cell md'>Followers</div>
                <div onClick={() => this.setSort(['perMonthEarnings'], ['desc'])} className='cell md has-filter'>
                  Per Month
                  <FontAwesomeIcon icon={faSortAmountDownAlt}></FontAwesomeIcon>
                </div>
                <div onClick={() => this.setSort(['thisMonthEarnings'], ['desc'])} className='cell md has-filter'>
                  This Month
                  <FontAwesomeIcon icon={faSortAmountDownAlt}></FontAwesomeIcon>
                </div>
                <div onClick={() => this.setSort(['score'], ['desc'])} className='cell md has-filter'>
                  Tier
                  <FontAwesomeIcon icon={faSortAmountDownAlt}></FontAwesomeIcon>
                </div>
                <div onClick={() => this.setSort(['mlPredictedScore', 'score'], ['desc', 'desc'])} className='cell md has-filter'>
                  Predicted
                </div>
                {/* <div onClick={() => this.setSort(['mlPredictedScore2'], ['desc'])} className='cell md has-filter'> */}
                {/*   Predicted v2 */}
                {/* </div> */}
                <div className='cell sm'>Can Chat</div>
                <div className='cell sm'>Is Featured</div>
                <div className='cell sm'>Rate</div>
                <div onClick={() => this.setSort(['createdAt'], ['desc'])} className='cell md has-filter'>
                  Created On
                  <FontAwesomeIcon icon={faSortAmountDownAlt}></FontAwesomeIcon>
                </div>
                <div onClick={() => this.setSort(['id'], ['asc'])} className='cell sm has-filter'>
                  User ID
                  <FontAwesomeIcon icon={faSortAmountDownAlt}></FontAwesomeIcon>
                </div>
                <div className='cell sm'>Email</div>
                <div className='cell sm'>Ban</div>
                <div className='cell sm'>Archive</div>
              </>
            )}
          </div>
          {_.map(users.slice(0, this.state.visibleResults), (user, idx) => {
            const { id, name, username, description, tags, tier, perMonthEarnings, thisMonthEarnings, createdAt, canFeature, user_percentage } = user;
            const projTag = this.getProjectionTag(user, idx);
            const typeTag = this.getTypeTag(user);
            const predictedTag = this.getPredictedTag(user);
            // const predictedTag2 = this.getPredictedTag2(user);
            const social = this.getSocial(user);
            const visibleTags = _.filter(tags, tag => !isTagTypeHidden(tag.type, ui));
            const canChatDueToTier = tier <= 3;
            const canChat = user.isPromoterOverride || canChatDueToTier;
            const followers = user.instagramCount + user.youtubeCount + user.tiktokCount;
            return (
              <div className={cn('users row', { odd: idx % 2 })} key={id}>
                {inTaggingMode ? (
                  <>
                    <div onClick={() => toggleUserBeingTagged(user)} className='cell small'>
                      <div className={cn('checkbox', { checked: isUserBeingTagged(user, ui) })}></div>
                    </div>
                    <div className='cell lg'>{name}</div>
                    <a target='_blank' rel='noopener noreferrer' href={`https://shopmy.us/${username}`} className='cell md'>
                      {username}
                    </a>
                    <div className='cell md'>{social}</div>
                    <div className='cell md'>{followers ? getPrettyNumber(followers) : '-'}</div>
                    <div className='cell md'>{perMonthEarnings ? `$${perMonthEarnings.toFixed(2)}` : '-'}</div>
                    <div className='cell md'>
                      <div className={`badge ${typeTag.className}`}>{typeTag.display}</div>
                    </div>
                    <div className='cell md ml-proj'>
                      <div className={`badge ${predictedTag?.className}`}>{predictedTag?.display}</div>
                    </div>
                    {/* <div className='cell md ml-proj'> */}
                    {/*   <div className={`badge ${predictedTag2?.className}`}>{predictedTag2?.display}</div> */}
                    {/* </div> */}
                    <div className='cell xl tags'>
                      {_.orderBy(
                        visibleTags,
                        tag => {
                          if (selectedTagIds.includes(tag.id)) return 10;
                          if (tag.type === 'general') return 9;
                          if (tag.type === 'geo') return 8;
                          if (tag.type === 'catalog') return 7;
                          if (tag.type === 'social') return 6;
                          if (tag.type === 'activity') return 5;
                          if (tag.type === 'behavior') return 4;
                          return 0;
                        },
                        'desc'
                      ).map((tag, idx) => {
                        const isSelected = selectedTagIds.includes(tag.id);
                        return (
                          <div
                            onClick={() => this.props.deleteUserTag(tag, user)}
                            key={tag.value + idx}
                            className={cn('tag', tag.type, { selected: isSelected })}
                          >
                            {tag.value}
                            <FontAwesomeIcon icon={faTimes} />
                          </div>
                        );
                      })}
                    </div>
                    <div className='cell md'>
                      {createdAt ? moment(createdAt).format(this.state.sortType.includes('createdAt') ? 'MMM Do, YYYY' : 'MMM Do') : '-'}
                    </div>
                    <div
                      className={cn('cell xl description', { 'visible-description': showingDescriptions })}
                      onClick={() => this.setState({ showingDescriptions: !showingDescriptions })}
                    >
                      {description}
                    </div>
                    <div className='cell small btn' onClick={() => this.copyEmail(user)}>
                      Copy
                    </div>
                  </>
                ) : (
                  <>
                    <div className='cell lg'>{name}</div>
                    <a target='_blank' rel='noopener noreferrer' href={`https://shopmy.us/${username}`} className='cell lg'>
                      {username}
                    </a>
                    <div className='cell md'>{social}</div>
                    <div className='cell md'>{followers ? getPrettyNumber(followers) : '-'}</div>
                    <div className='cell md'>{perMonthEarnings ? `$${perMonthEarnings.toFixed(2)}` : '-'}</div>
                    <div className={cn('cell md proj', projTag.className)}>{thisMonthEarnings ? `$${thisMonthEarnings.toFixed(2)}` : '-'}</div>
                    <div className='cell md'>
                      <div className={`badge ${typeTag.className}`}>{typeTag.display}</div>
                    </div>
                    <div className='cell md ml-proj'>
                      <div className={`badge ${predictedTag?.className}`}>{predictedTag?.display}</div>
                    </div>
                    {/* <div className='cell md ml-proj'> */}
                    {/*   <div className={`badge ${predictedTag2?.className}`}>{predictedTag2?.display}</div> */}
                    {/* </div> */}
                    <div
                      onClick={() =>
                        canChatDueToTier
                          ? cogoToast.warn(`This user can already chat due to their tier.`)
                          : this.updateUser(user, { isPromoterOverride: !user.isPromoterOverride })
                      }
                      className='cell sm btn'
                    >
                      {canChat ? '✓' : '-'}
                    </div>
                    <div onClick={() => this.updateUser(user, { canFeature: !user.canFeature })} className='cell sm btn'>
                      {canFeature ? '✓' : '-'}
                    </div>
                    <div onClick={() => this.changeRate(user, 'user_percentage')} className='cell sm'>
                      {user_percentage ? `${user_percentage}%` : '-'}
                    </div>
                    <div className='cell md'>
                      {createdAt ? moment(createdAt).format(this.state.sortType.includes('createdAt') ? 'MMM Do, YYYY' : 'MMM Do, YYYY') : '-'}
                    </div>
                    <div className='cell sm'>{id}</div>
                    <div className='cell sm btn' onClick={() => this.copyEmail(user)}>
                      Copy
                    </div>
                    <div onClick={() => this.banUser(user)} className='cell sm btn'>
                      {user.isBanned ? 'Unban' : user.isBannedFromChat ? 'Banned From Chat' : 'Ban'}
                    </div>
                    <div onClick={() => this.archiveUser(user)} className='cell sm btn'>
                      {user.isArchived ? 'Unarchive' : 'Archive'}
                    </div>
                  </>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { users, ui } = state;
  return { users, ui };
};

export default connect(mapStateToProps, {
  getUsers,
  deleteUserTag,
  setUsersBeingTagged,
  toggleUserBeingTagged
})(Users);
