import React, { useState } from 'react';
import BarGraph from '../GraphTypes/BarGraph';
import Loader from '../../General/Loader';
import cn from 'classnames';
import { Table } from '../GraphTypes/Table';

import { getBrandReportHeaderLabel } from '../../../helpers/brand_helpers';
import PropTypes from 'prop-types';
import { faDownload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BrandGifting = props => {
  const { title, brandGifting: gifting, downloading } = props;
  const success = !!gifting?.length;

  const [tab, setTab] = useState('table');
  const download_table = async () => await props.download({ rows: make_table_rows(), fileName: 'brand_gifting' });
  const download_graph = async () => await props.download({ graph: title });

  const make_table_rows = () => {
    let total_running_total = 0;
    const total_clicks = {
      key: 'gifting_total',
      label: 'Total Gifting Requests',
      description: '(amount of offers that were sent to creators)',
      values: gifting.map(gifts => {
        const { week, month, quarter, sent } = gifts;
        total_running_total += parseInt(sent || '0');
        return { label: getBrandReportHeaderLabel({ week, month, quarter }), value: sent };
      })
    };
    total_clicks.values.push({ label: 'Total', value: total_running_total });

    let dtc_running_total = 0;
    const dtc_clicks = {
      key: 'gifting_answered',
      label: 'Gifting Requests Answered',
      description: `(amount of offers that were responded to)`,
      values: gifting.map(order => {
        const { week, month, quarter, sent, pending } = order;
        dtc_running_total += parseInt(sent) - parseInt(pending);
        return { label: getBrandReportHeaderLabel({ week, month, quarter }), value: parseInt(sent) - parseInt(pending) };
      })
    };
    dtc_clicks.values.push({ label: 'Total', value: dtc_running_total });

    let retailer_running_total = 0;
    const retailers_clicks = {
      key: 'gifting_accepted',
      label: 'Gifting Requests Accepted',
      description: `(amount of offers accepted by creators)`,
      values: gifting.map(order => {
        const { week, month, quarter, accepted } = order;
        retailer_running_total += parseInt(accepted || '0');
        return { label: getBrandReportHeaderLabel({ week, month, quarter }), value: accepted };
      })
    };
    retailers_clicks.values.push({ label: 'Total', value: retailer_running_total });

    return [total_clicks, dtc_clicks, retailers_clicks];
  };

  return (
    <div className='brand-card' id={title}>
      <div className='padding'>
        {!gifting ? (
          <Loader />
        ) : (
          <>
            {success && (
              <>
                <div className='header'>
                  <div className='description'>
                    <div className='title'>{title}</div>
                    <div className='sub-title'>
                      Gifting is the amount of products sent to creators for them to try out and promote. These are grouped over time.
                    </div>
                  </div>
                  {!downloading && (
                    <div className='brand-card-controls'>
                      <div className='radio-btns'>
                        <div className={cn('radio-btn', { active: tab === 'graph' })} onClick={() => setTab('graph')}>
                          Graph
                        </div>
                        <div className={cn('radio-btn', { active: tab === 'table' })} onClick={() => setTab('table')}>
                          Table
                        </div>
                      </div>
                      <div className='rounded-button' onClick={() => (tab === 'table' ? download_table() : download_graph())}>
                        {downloading ? (
                          <Loader />
                        ) : (
                          <>
                            <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                            Download
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>

                {tab === 'table' && (
                  <Table
                    title='Brand Gifting'
                    data={make_table_rows()}
                    // data={[ { } ]}
                  />
                )}
                {tab === 'graph' && (
                  <BarGraph
                    title='Gifting Accepted'
                    data={gifting.map(click => {
                      const { week, month, quarter, year, accepted } = click;

                      const data = {
                        key: `${week || month || quarter}${year}gifting`,
                        value: accepted,
                        label: getBrandReportHeaderLabel({ month, quarter })
                      };
                      return data;
                    })}
                  />
                )}
              </>
            )}

            {!success && (
              <>
                <div className='error-centered'>
                  <div className='title'>Error Fetching {title}</div>
                  <div className='sub-title'>Could not fetch results</div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

BrandGifting.propTypes = {
  title: PropTypes.string.isRequired,
  brandGifting: PropTypes.array,

  download: PropTypes.func.isRequired,
  downloading: PropTypes.bool.isRequired
};

const brandGiftingConfig = {
  title: 'Gifting'
};

export { BrandGifting, brandGiftingConfig };
