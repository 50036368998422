import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import './ChatMessage.scss';

import { getSmartImage } from '../../helpers/helpers';

const getShopUrlFromUser = user => (user.logo ? '#' : `https://shopmy.us/${user.username}`);

const ChatMessage = props => {
  const { chat, message } = props;
  const { isUserMessage, isAutomated } = message;
  const sender = chat[isUserMessage ? 'user' : 'brand'];
  const receiver = chat[!isUserMessage ? 'user' : 'brand'];

  return isAutomated ? (
    <div className='automated-chat-message-container'>
      <div className='automated-chat-message'>{message.message}</div>
    </div>
  ) : (
    <div className={cn('chat-message-container', { user: message.isUserMessage, brand: !message.isUserMessage })}>
      <div className='sender-and-receiver'>
        <a
          target='_blank'
          rel='noopener noreferrer'
          href={getShopUrlFromUser(sender)}
          className={cn('image-container sender', { brand: !!sender.logo })}
        >
          {sender.image || sender.logo ? <img alt={sender.name} src={getSmartImage(sender.image || sender.logo)} /> : <div className='empty-img' />}
        </a>
        <div className='arrow'>
          <FontAwesomeIcon icon={faArrowRight} />
        </div>
        <a
          target='_blank'
          rel='noopener noreferrer'
          href={getShopUrlFromUser(receiver)}
          className={cn('image-container receiver', { brand: !!receiver.logo })}
        >
          {receiver.image || receiver.logo ? (
            <img alt={receiver.name} src={getSmartImage(receiver.image || receiver.logo)} />
          ) : (
            <div className='empty-img' />
          )}
        </a>
      </div>
      <div className='message-block'>
        <div className='content'>{message.message}</div>
        <div className='time'>{moment(message.createdAt).format('MMM DD h:mma')}</div>
      </div>
    </div>
  );
};

ChatMessage.propTypes = {
  chat: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired
};

export default ChatMessage;
