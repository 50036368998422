import APIClient from './index';
import _ from 'lodash';

/*************************************************** */
// Newsletters
/*************************************************** */

export const getNewsletters = () => {
  return new Promise((resolve, reject) => {
    APIClient.get('Newsletters')
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const getNewsletter = id => {
  return new Promise((resolve, reject) => {
    APIClient.get(`Newsletters/${id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};
export const createNewsletter = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`Newsletters`, data)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const editNewsletter = (newsletter, updates) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`Newsletters/${newsletter.id}`, updates)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const deleteNewsletter = newsletter => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`Newsletters/${newsletter.id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

/*************************************************** */
// Newsletter Tabs
/*************************************************** */

export const createNewsletterTab = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`NewsletterTabs`, data)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const editNewsletterTab = (tab, updates) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`NewsletterTabs/${tab.id}`, updates)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const deleteNewsletterTab = tab => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`NewsletterTabs/${tab.id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

/*************************************************** */
// Newsletter Tags
/*************************************************** */

export const createNewsletterTag = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`NewsletterTags`, data)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const editNewsletterTag = (tag, updates) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`NewsletterTags/${tag.id}`, updates)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const deleteNewsletterTag = tag => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`NewsletterTags/${tag.id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

/*************************************************** */
// Newsletter Sections
/*************************************************** */

export const createNewsletterSection = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`NewsletterSections`, data)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const editNewsletterSection = (section, updates) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`NewsletterSections/${section.id}`, updates)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const deleteNewsletterSection = section => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`NewsletterSections/${section.id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

/*************************************************** */
// Newsletter Section Tabs
/*************************************************** */

export const createNewsletterSectionTab = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`NewsletterSectionTabs`, data)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const editNewsletterSectionTab = (tab, updates) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`NewsletterSectionTabs/${tab._pivot_id}`, updates)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const deleteNewsletterSectionTab = tab => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`NewsletterSectionTabs/${tab._pivot_id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

/*************************************************** */
// Newsletter Section Tags
/*************************************************** */

export const createNewsletterSectionTag = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`NewsletterSectionTags`, data)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const editNewsletterSectionTag = (tag, updates) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`NewsletterSectionTags/${tag._pivot_id}`, updates)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const deleteNewsletterSectionTag = tag => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`NewsletterSectionTags/${tag._pivot_id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

/*************************************************** */
// Newsletter Section Items
/*************************************************** */

export const createNewsletterSectionItem = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`NewsletterSectionItems`, data)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const editNewsletterSectionItem = (item, updates) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`NewsletterSectionItems/${item.id}`, updates)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const deleteNewsletterSectionItem = item => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`NewsletterSectionItems/${item.id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

/*************************************************** */
// Newsletter Section Item Tags
/*************************************************** */

export const createNewsletterSectionItemTag = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`NewsletterSectionItemTags`, data)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const deleteNewsletterSectionItemTag = tag => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`NewsletterSectionItemTags/${tag.id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};
