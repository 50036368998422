import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
// import _ from 'lodash'
// import cn from 'classnames'

import './ML.scss';

import MLApiTabs from './Elements/MLApiTabs';
import MLApiControls from './Elements/MLApiControls';
import MLApiResults from './Elements/MLApiResults';

const ML = props => {
  const [results, setResults] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  // Handle Tabs
  const [selectedTabVariable, setSelectedTabVariable] = useState('performers');
  const selectTab = tab => {
    setSelectedTabVariable(tab.variable);
    console.log(tab);
  };

  const tabs = [
    {
      display: 'All',
      isDisabled: true,
      variable: 'all'
    },
    {
      display: 'Similar Socials',
      isDisabled: true,
      variable: 'socials'
    },
    {
      display: 'Similar Performers',
      variable: 'performers'
    }
  ].map(tab => ({
    ...tab,
    isSelected: tab.variable === selectedTabVariable
  }));
  const selectedTab = tabs.find(t => t.isSelected);
  return (
    <div className='ml-outer-container'>
      <div className='ml-inner-container'>
        <MLApiTabs tabs={tabs} selectTab={selectTab} />
        <MLApiControls selectedTab={selectedTab} setResults={setResults} isFetching={isFetching} setIsFetching={setIsFetching} />
        <MLApiResults results={results} isFetching={isFetching} />
      </div>
    </div>
  );
};

ML.propTypes = {};

export default ML;
