import React from 'react';
import Loader from '../../General/Loader';

import { Table } from '../GraphTypes/Table';
import { getBrandReportHeaderLabel } from '../../../helpers/brand_helpers';
import PropTypes from 'prop-types';
import { faDownload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BrandShortSummary = props => {
  const { title, brandTopPromoters, downloading, groupingMethod } = props;
  const { allResults: promoters, dtcResults: dtcPromoters, nonDtcResults: nonDtcPromoters } = brandTopPromoters || {};
  const success = !!promoters;

  const download = async () => await props.download({ rows: make_table_rows(), fileName: 'short_summary' });

  const make_table_rows = () => {
    const total_promoters = {
      key: 'promoters_total',
      label: 'Total Promoters',
      description: '(anybody who linked to the brand direct or not)',
      values: promoters.map(order => {
        const { week, month, quarter, results } = order;
        const count = results.length;
        return { label: getBrandReportHeaderLabel({ week, month, quarter, groupingMethod }), value: count };
      })
    };

    const dtc_total_promoters = {
      key: 'dtc_promoters_total',
      label: 'DTC Promoters',
      description: '(anybody who linked to the brand directly)',
      values: dtcPromoters.map(order => {
        const { week, month, quarter, results } = order;
        const count = results.length;
        return { label: getBrandReportHeaderLabel({ week, month, quarter, groupingMethod }), value: count };
      })
    };

    const non_dtc_total_promoters = {
      key: 'non_dtc_promoters_total',
      label: 'Non DTC Promoters',
      description: '(anybody who linked to the brand through retailer)',
      values: nonDtcPromoters.map(order => {
        const { week, month, quarter, results } = order;
        const count = results.length;
        return { label: getBrandReportHeaderLabel({ week, month, quarter, groupingMethod }), value: count };
      })
    };

    const earning_promoters = {
      key: 'earning_total',
      label: 'Earning Promoters',
      description: '(anybody who earned commission from the brand direct or not)',
      values: promoters.map(order => {
        const { week, month, quarter, results } = order;
        const count = results.filter(promoter => promoter.orderVolumeTotal).length;
        return { label: getBrandReportHeaderLabel({ week, month, quarter, groupingMethod }), value: count };
      })
    };

    const dtc_earning_promoters = {
      key: 'dtc_earning_total',
      label: 'DTC Earning Promoters',
      description: '(anybody who earned commission from the brand directly)',
      values: dtcPromoters.map(order => {
        const { week, month, quarter, results } = order;
        const count = results.filter(promoter => promoter.orderVolumeTotal).length;
        return { label: getBrandReportHeaderLabel({ week, month, quarter, groupingMethod }), value: count };
      })
    };

    const non_dtc_earning_promoters = {
      key: 'non_dtc_earning_total',
      label: 'Non DTC Earning Promoters',
      description: '(anybody who earned commission from the brand through retailer)',
      values: nonDtcPromoters.map(order => {
        const { week, month, quarter, results } = order;
        const count = results.filter(promoter => promoter.orderVolumeTotal).length;
        return { label: getBrandReportHeaderLabel({ week, month, quarter, groupingMethod }), value: count };
      })
    };

    const active_promoters = {
      key: 'active_total',
      label: 'Active Promoters',
      description: '(anybody who linked to the brand direct or not)',
      values: promoters.map(order => {
        const { week, month, quarter, results } = order;
        const count = results.filter(promoter => promoter.views).length;
        return { label: getBrandReportHeaderLabel({ week, month, quarter, groupingMethod }), value: count };
      })
    };

    const dtc_active_promoters = {
      key: 'dtc_active_total',
      label: 'DTC Active Promoters',
      description: '(anybody who linked to the brand directly)',
      values: dtcPromoters.map(order => {
        const { week, month, quarter, results } = order;
        const count = results.filter(promoter => promoter.views).length;
        return { label: getBrandReportHeaderLabel({ week, month, quarter, groupingMethod }), value: count };
      })
    };

    const non_dtc_active_promoters = {
      key: 'non_dtc_active_total',
      label: 'Non DTC Active Promoters',
      description: '(anybody who linked to the brand through retailer)',
      values: nonDtcPromoters.map(order => {
        const { week, month, quarter, results } = order;
        const count = results.filter(promoter => promoter.views).length;
        return { label: getBrandReportHeaderLabel({ week, month, quarter, groupingMethod }), value: count };
      })
    };

    return [
      total_promoters,
      dtc_total_promoters,
      non_dtc_total_promoters,
      earning_promoters,
      dtc_earning_promoters,
      non_dtc_earning_promoters,
      active_promoters,
      dtc_active_promoters,
      non_dtc_active_promoters
    ];
  };

  return (
    <>
      <div className='brand-card' id={title}>
        <div className='padding'>
          {!brandTopPromoters ? (
            <Loader />
          ) : (
            <>
              {success && (
                <>
                  <div className='header'>
                    <div className='description'>
                      <div className='title'>{title}</div>
                      <div className='sub-title'>This shows the summary of aggregate promoter stats attributed to a brand month over month.</div>
                    </div>
                    <div className='brand-card-controls'>
                      <div className='rounded-button' onClick={() => download()}>
                        {downloading ? (
                          <Loader />
                        ) : (
                          <>
                            <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                            Download
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  <Table title='Brand Short Summary' data={make_table_rows('dtc')} />
                </>
              )}

              {!success && (
                <>
                  <div className='error-centered'>
                    <div className='title'>Error Fetching {title}</div>
                    <div className='sub-title'>Could not fetch results</div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

BrandShortSummary.propTypes = {
  title: PropTypes.string.isRequired,
  brandTopPromoters: PropTypes.object,
  groupingMethod: PropTypes.string.isRequired,

  download: PropTypes.func.isRequired,
  downloading: PropTypes.bool.isRequired
};

const BrandShortSummaryConfig = {
  title: 'Brand Short Summary'
};

export { BrandShortSummary, BrandShortSummaryConfig };
