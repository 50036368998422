import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import slugify from 'slugify';
import _ from 'lodash';
import classnames from 'classnames';
import './HierarchyEdit.scss';

import {
  addFeature,
  updateCategory,
  updateDepartment,
  updateTag,
  deleteCategory,
  deleteDepartment,
  deleteTag,
  deleteFeature
} from '../../../api/catalog';

class HierarchyEdit extends Component {
  static propTypes = {
    catalog: PropTypes.object.isRequired,
    syncCatalogHierarchy: PropTypes.func.isRequired,
    selectItem: PropTypes.func.isRequired,
    selectedItem: PropTypes.object,
    selectedItemType: PropTypes.string
  };

  componentDidUpdate(prevProps) {
    const itemChanged = _.get(this.props, 'selectedItem.id') !== _.get(prevProps, 'selectedItem.id');
    const typeChanged = _.get(this.props, 'selectedItemType') !== _.get(prevProps, 'selectedItemType');
    if (itemChanged || typeChanged) {
      this.setState({
        name: _.get(this.props.selectedItem, 'name') || '',
        seoTitle: _.get(this.props.selectedItem, 'seoTitle') || '',
        seoDescription: _.get(this.props.selectedItem, 'seoDescription') || '',
        seoDescriptionLong: _.get(this.props.selectedItem, 'seoDescriptionLong') || '',
        synonyms: _.get(this.props.selectedItem, 'synonyms') || '',
        urlStub: _.get(this.props.selectedItem, 'urlStub') || '',
        isFilter: _.get(this.props.selectedItem, 'isFilter', false),
        isFeatured: !_.isEmpty(_.get(this.props.selectedItem, 'feature', false)),
        isHidden: _.get(this.props.selectedItem, 'isHidden', false),
        isAutoTag: _.get(this.props.selectedItem, 'isAutoTag', false),
        autoTagShopPercentageMin: _.get(this.props.selectedItem, 'autoTagShopPercentageMin', 30)
      });
    }
  }

  deleteItem = () => {
    const { selectedItem } = this.props;
    const deleteFunction = this.isDepartment() ? deleteDepartment : this.isCategory() ? deleteCategory : this.isTag() ? deleteTag : null;

    deleteFunction &&
      deleteFunction(selectedItem.id).then(
        resp => {
          this.props.syncCatalogHierarchy();
          this.props.selectItem();
        },
        err => {
          console.error(err);
          cogoToast.error(JSON.stringify(err));
        }
      );
  };

  isCategory = () => this.props.selectedItemType === 'category';
  isDepartment = () => this.props.selectedItemType === 'department';
  isTag = () => this.props.selectedItemType === 'tag';

  updateItem = async () => {
    const { selectedItem, selectedItemType } = this.props;
    const {
      name,
      seoTitle,
      seoDescription,
      seoDescriptionLong,
      synonyms,
      urlStub,
      isFilter,
      isHidden,
      isAutoTag,
      autoTagShopPercentageMin,
      isFeatured
    } = this.state;
    const updateFunction =
      selectedItemType === 'department'
        ? updateDepartment
        : selectedItemType === 'category'
        ? updateCategory
        : selectedItemType === 'tag'
        ? updateTag
        : null;
    const featureData =
      selectedItemType === 'department'
        ? { Department_id: selectedItem.id }
        : selectedItemType === 'category'
        ? { Category_id: selectedItem.id }
        : selectedItemType === 'tag'
        ? { Tag_id: selectedItem.id }
        : null;

    const newData = {};

    const wantsToChangeName = name.length && name !== selectedItem.name;
    if (wantsToChangeName) {
      newData.name = name;
    }

    const wantsToChangeTitle =
      (seoTitle.length && seoTitle !== selectedItem.seoTitle) || // Updating title
      (!seoTitle.length && selectedItem.seoTitle); // Removing title
    if (wantsToChangeTitle) {
      newData.seoTitle = seoTitle;
    }

    const wantsToChangeDescription =
      (seoDescription.length && seoDescription !== selectedItem.seoDescription) || // Updating description
      (!seoDescription.length && selectedItem.seoDescription); // Removing description
    if (wantsToChangeDescription) {
      newData.seoDescription = seoDescription;
    }

    const wantsToChangeLongDescription =
      (seoDescriptionLong.length && seoDescription !== selectedItem.seoDescriptionLong) || // Updating description
      (!seoDescriptionLong.length && selectedItem.seoDescriptionLong); // Removing description
    if (wantsToChangeLongDescription) {
      newData.seoDescriptionLong = seoDescriptionLong;
    }

    const wantsToChangeSynonyms = (synonyms.length && synonyms !== selectedItem.synonyms) || (!synonyms.length && selectedItem.synonyms);
    if (wantsToChangeSynonyms) {
      newData.synonyms = synonyms;
    }

    const wantsToChangeUrlStub = (urlStub.length && urlStub !== selectedItem.urlStub) || (!urlStub.length && selectedItem.urlStub);
    if (wantsToChangeUrlStub) {
      newData.urlStub = urlStub;
    }

    const wantsToChangeFilter = !_.isNil(selectedItem.isFilter) && isFilter !== selectedItem.filter; // Only if filter is set on the object (in the case of a tag)
    if (wantsToChangeFilter) {
      newData.isFilter = isFilter;
    }

    const wantsToChangeVisibility = isHidden !== selectedItem.isHidden;
    if (wantsToChangeVisibility) {
      newData.isHidden = isHidden;
    }

    const wantsToChangeAutoTagging = isAutoTag !== selectedItem.isAutoTag;
    if (wantsToChangeAutoTagging) {
      newData.isAutoTag = isAutoTag;
    }

    const wantsToChangeAutoTagMin = autoTagShopPercentageMin !== String(selectedItem.autoTagShopPercentageMin);
    if (wantsToChangeAutoTagMin) {
      newData.autoTagShopPercentageMin = parseInt(autoTagShopPercentageMin);
    }

    const wantsToChangeFeature = (_.isEmpty(selectedItem.feature) && isFeatured) || (!_.isEmpty(selectedItem.feature) && !isFeatured);

    this.setState({ isSaving: true });
    try {
      updateFunction && !_.isEmpty(newData) && (await updateFunction(selectedItem.id, newData));
      if (wantsToChangeFeature) {
        isFeatured ? await addFeature(featureData) : await deleteFeature(selectedItem.feature.id);
      }
    } catch (error) {
      console.error(error);
      cogoToast.error(JSON.stringify(error));
    }

    await this.props.syncCatalogHierarchy();
    this.setState({ isSaving: false });
    cogoToast.success('Successful Update.');
  };

  state = {
    name: '',
    seoTitle: '',
    seoDescription: '',
    seoDescriptionLong: '',
    urlStub: '',
    isFilter: false,
    isSaving: false,
    isFeatured: false,
    isHidden: false,
    isAutoTag: false,
    autoTagShopPercentageMin: 30
  };

  render() {
    const { selectedItem, selectedItemType } = this.props;
    const {
      name,
      seoTitle,
      seoDescription,
      seoDescriptionLong,
      synonyms,
      urlStub,
      isFilter,
      isSaving,
      isFeatured,
      isHidden,
      isAutoTag,
      autoTagShopPercentageMin
    } = this.state;
    return (
      <div className='hierarchy-edit-outer-container panel'>
        {selectedItem ? (
          <>
            <input placeholder='Name' value={name} onChange={({ target }) => this.setState({ name: target.value })} />
            <input placeholder={`Best in ${name}`} value={seoTitle} onChange={({ target }) => this.setState({ seoTitle: target.value })} />
            <textarea
              placeholder='Keyword Optimized SEO Description'
              value={seoDescription}
              rows={4}
              onChange={({ target }) => this.setState({ seoDescription: target.value })}
            />
            <textarea
              placeholder='Long Form Keyword Optimized SEO Description'
              value={seoDescriptionLong}
              rows={12}
              onChange={({ target }) => this.setState({ seoDescriptionLong: target.value })}
            />
            {this.isCategory() && (
              <textarea
                placeholder='Synonyms (comma separated, no spaces)'
                value={synonyms}
                rows={4}
                onChange={({ target }) => this.setState({ synonyms: target.value })}
              />
            )}
            <input
              placeholder={slugify(`Best in ${name}`).toLowerCase()}
              value={urlStub}
              onChange={({ target }) => this.setState({ urlStub: target.value })}
            />
            <div className='checkbox-row'>
              <input
                type='checkbox'
                name='check'
                checked={isFeatured}
                onChange={e =>
                  this.setState({
                    isFeatured: e.target.checked
                  })
                }
              />
              <div className='label'>Featured</div>
            </div>
            <div className='checkbox-row'>
              <input
                type='checkbox'
                name='check'
                checked={isHidden}
                onChange={e =>
                  this.setState({
                    isHidden: e.target.checked
                  })
                }
              />
              <div className='label'>Hidden</div>
            </div>
            <div className='checkbox-row'>
              <input
                type='checkbox'
                name='check'
                checked={isAutoTag}
                onChange={e =>
                  this.setState({
                    isAutoTag: e.target.checked
                  })
                }
              />
              <div className='label'>Automatic Tagging: </div>
              <input
                placeholder='Auto Tagging Min (Default 30%)'
                className='field'
                value={autoTagShopPercentageMin}
                onChange={({ target }) => this.setState({ autoTagShopPercentageMin: target.value })}
              />
              <div className='label'>% Of Shop</div>
              <div className='subcomment'>(set to over 100 to only apply to brands)</div>
            </div>
            {selectedItemType === 'tag' && (
              <div className='radio-button' onClick={() => this.setState({ isFilter: !isFilter })}>
                <div className='label'>Show Filter In Search</div>
                <div className={classnames('radio', { selected: isFilter })}>✓</div>
              </div>
            )}
            <div className='action-btns'>
              <div onClick={this.deleteItem} className='action-btn delete'>
                Delete
              </div>
              <div onClick={this.updateItem} className='action-btn delete'>
                {isSaving ? 'Saving' : 'Save'}
              </div>
            </div>
          </>
        ) : (
          <div className='empty-message'>Please make a selection on the left</div>
        )}
      </div>
    );
  }
}

export default HierarchyEdit;
