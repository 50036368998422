import React, { useState } from 'react';
import cogoToast from 'cogo-toast';
import PropTypes from 'prop-types';

import './MLApiControls.scss';

import { getSimilarPerformers } from '../../../api/ml';

let debouncer;

const MLApiControls = props => {
  const [searchVal, setSearchVal] = useState('');

  const performSearch = async newSearchVal => {
    const User_id = parseInt(newSearchVal);
    if (!User_id) return cogoToast.warn(`Invalid User ID`);
    props.setIsFetching(true);
    try {
      const resp = await getSimilarPerformers(newSearchVal);
      props.setResults(resp.results);
    } catch (error) {
      cogoToast.error(`Error loading the results, placing test results.`);
      props.setResults([
        {
          instagram_username: 'itsmitroiboiii',
          user_id: 7377
        },
        {
          instagram_username: 'rebeccapomerantzz',
          user_id: 6751
        },
        {
          instagram_username: 'olivvveoil',
          user_id: 6929
        }
      ]);
    }
    props.setIsFetching(false);
  };

  const updateSearch = newSearchVal => {
    setSearchVal(newSearchVal);
    clearTimeout(debouncer);
    if (newSearchVal) {
      debouncer = setTimeout(() => {
        performSearch(newSearchVal);
      }, 750);
    } else {
      props.setResults([]);
    }
  };

  return (
    <div className='ml-api-controls-outer-container'>
      <input
        placeholder='Enter a User ID'
        value={searchVal}
        onChange={e => {
          updateSearch(e.target.value);
        }}
      />
    </div>
  );
};

MLApiControls.propTypes = {
  selectedTab: PropTypes.object.isRequired,
  setResults: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  setIsFetching: PropTypes.func.isRequired
};

export default MLApiControls;
