import React from 'react';
import PropTypes from 'prop-types';
import commaNumber from 'comma-number';
import cn from 'classnames';
import _ from 'lodash';

import './SubscriptionIOGenerator.scss';

const SubscriptionIOGenerator = props => {
  const { offers, bundles } = props;

  // Offers
  const [selectedOfferIds, setSelectedOfferIds] = React.useState([]);
  const isOfferSelected = offer => selectedOfferIds.includes(offer.id);
  const toggleOffer = offer =>
    setSelectedOfferIds(isOfferSelected(offer) ? selectedOfferIds.filter(id => id !== offer.id) : [...selectedOfferIds, offer.id]);
  const selectedOffers = offers.filter(isOfferSelected);
  const offerGroupings = _.groupBy(offers, 'module');
  const offerGroups = _.values(offerGroupings);

  // Bundles
  const [selectedBundle, setSelectedBundle] = React.useState(null);
  const selectBundle = bundle => {
    const isSelecting = bundle.id !== selectedBundle?.id;
    setSelectedBundle(isSelecting ? bundle : null);
    setSelectedOfferIds(isSelecting ? bundle.items.map(item => item.SubscriptionOffer_id) : []);
  };

  return (
    <div className='subscription-io-generator-container'>
      <h2>Generate IO</h2>
      <div className='select-offers-container'>
        <div className='groups'>
          <div className='group'>
            <div className='group-title'>Bundles</div>
            <div className='bundles'>
              {bundles.map(bundle => {
                const isSelected = selectedBundle?.id === bundle.id;
                const select = () => selectBundle(bundle);
                return (
                  <div key={bundle.id} onClick={select} className={cn('bundle', { selected: isSelected })}>
                    <div className='title'>{bundle.title}</div>
                    <div className='description'>{bundle.description || 'Default'}</div>
                  </div>
                );
              })}
            </div>
          </div>
          {offerGroups.map(group => {
            const title = group[0].module;
            return (
              <div className='group'>
                <div className='group-title'>{title}</div>
                <div className='offers'>
                  {group.map(offer => {
                    const isSelected = isOfferSelected(offer);
                    const toggle = () => toggleOffer(offer);
                    return (
                      <div key={offer.id} onClick={toggle} className={cn('offer', { selected: isSelected })}>
                        {offer.title} (${commaNumber(offer.retailPrice)})
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <IO selectedOffers={selectedOffers} selectedBundle={selectedBundle} />
    </div>
  );
};

const IO = props => {
  const { selectedOffers, selectedBundle } = props;
  const orderedOffers = _.orderBy(selectedOffers, ['isBase', 'retailPrice'], ['desc', 'desc']);
  const retailPrice = _.sumBy(selectedOffers, 'retailPrice');
  const discountAmount = selectedBundle?.discountAmount || 0;
  const totalPrice = retailPrice - discountAmount;
  return (
    <div className='subscription-io'>
      <h4>Modules & Services</h4>
      {orderedOffers.map(o => (
        <div>
          ${o.retailPrice} - {o.title}
          {o.isService ? ' (Service)' : ''}
        </div>
      ))}
      {selectedBundle && (
        <>
          <h4>Discounts</h4>
          <div>
            -${selectedBundle.discountAmount} - {selectedBundle.title} Discount
          </div>
        </>
      )}
      <h1>Total: ${commaNumber(totalPrice)}</h1>
    </div>
  );
};

SubscriptionIOGenerator.propTypes = {
  bundles: PropTypes.object.isRequired,
  offers: PropTypes.object.isRequired
};

export default SubscriptionIOGenerator;
