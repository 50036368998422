import React from 'react';
import PropTypes from 'prop-types';

import './ConciergeModalMainContainer.scss';
import ConciergeTalentSections, { allConciergeTalentSectionTitles } from './ConciergeModalTalentSections';

const ConciergeModalMainContainer = props => {
  const { talent, timePeriod, updateExpectedScore, updateSupportLevel } = props;

  const scrollToSection = sectionTitle => {
    const element = document.getElementById(sectionTitle);
    element && element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  };

  return (
    <div className='concierge-modal-main-container-outer'>
      <div className='main-container-shortcut-bar'>
        {allConciergeTalentSectionTitles.map(title => (
          <div className='shortcut' key={`shortcut_${title}`} id={`shortcut_${title}`} onClick={() => scrollToSection(title)}>
            {title}
          </div>
        ))}
      </div>
      <div className='main-container-sections'>
        <ConciergeTalentSections
          talent={talent}
          timePeriod={timePeriod}
          updateExpectedScore={updateExpectedScore}
          updateSupportLevel={updateSupportLevel}
        />
      </div>
    </div>
  );
};

ConciergeModalMainContainer.propTypes = {
  talent: PropTypes.object.isRequired,
  timePeriod: PropTypes.object.isRequired,
  updateSupportLevel: PropTypes.func.isRequired,
  updateExpectedScore: PropTypes.func.isRequired
};

export default ConciergeModalMainContainer;
