import React from 'react';
import { faDownload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import { Table } from '../../../BrandReports/GraphTypes/Table';
import Loader from '../../../General/Loader';
import { getBrandReportHeaderLabel } from '../../../../helpers/brand_helpers';

const ConciergeTopCollections = props => {
  const { title, downloading, topCollections, groupingMethod } = props;
  const success = !!topCollections?.length;

  const download_table = async () => await props.download({ rows: make_table_rows(), fileName: 'concierge_custom_codes' });

  const make_table_rows = () => {
    if (!topCollections || !topCollections.length) return [];
    const uniqueCollections = new Map();

    for (const timePeriod of topCollections) {
      for (const collection of timePeriod.collections || []) {
        uniqueCollections.set(collection.id, collection);
      }
    }

    const fetchPinFromGroup = (group, collectionId) => group?.collections?.find(collection => collection.id === collectionId);

    const finalPinRows = [];
    for (const pin of uniqueCollections.values()) {
      const { name, id: collectionId } = pin;

      const data = {
        key: `top_collections_${collectionId}`,
        label: name,
        smallerLabel: true,
        smallerValues: true,
        href: `https://shopmy.us/collections/${collectionId}`,
        values: []
      };

      let runningTotal = 0;
      for (const timePeriod of topCollections) {
        const { year, [groupingMethod]: group } = timePeriod;
        const collectionFromGroup = fetchPinFromGroup(timePeriod, collectionId);
        runningTotal += collectionFromGroup?.volume || 0;
        data.values.push({
          label: getBrandReportHeaderLabel({ [groupingMethod]: group, year }),
          value: `$${collectionFromGroup?.volume?.toFixed() || 0}`
        });
      }
      data.values.push({ label: 'Total', value: `$${runningTotal.toFixed()}` });
      data.runningTotal = runningTotal;

      finalPinRows.push(data);
    }

    // sort by total volume and return top 30
    return finalPinRows.sort((a, b) => b.runningTotal - a.runningTotal).slice(0, 30);
  };

  return (
    <div className='talent-report-card' id={title}>
      {!topCollections ? (
        <Loader />
      ) : (
        <>
          {success && (
            <>
              <div className='header'>
                <div className='description'>
                  <div className='title'>{title}</div>
                  <div className='sub-title'>Top collections show how the talent's best collections are performing in their profile over time.</div>
                </div>
                {!downloading && (
                  <div className='talent-report-controls' id={`${title}-card-controls`}>
                    <div className='rounded-button' onClick={() => download_table()}>
                      {downloading ? (
                        <Loader />
                      ) : (
                        <>
                          <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                          Download
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>

              <Table title='Top Retailers' data={make_table_rows()} />
            </>
          )}

          {!success && (
            <>
              <div className='error-centered'>
                <div className='title'>{title}</div>
                <div className='sub-title'>No results.</div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

ConciergeTopCollections.propTypes = {
  title: PropTypes.string.isRequired,
  topCollections: PropTypes.array,
  groupingMethod: PropTypes.string.isRequired,

  download: PropTypes.func.isRequired,
  downloading: PropTypes.bool.isRequired
};

const ConciergeTopCollectionsConfig = {
  title: 'Top Collections'
};

export { ConciergeTopCollections, ConciergeTopCollectionsConfig };
