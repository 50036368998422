import React from 'react';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faArrowUp, faArrowDown, faEyeDropper, faPlus, faEyeSlash, faEye } from '@fortawesome/pro-regular-svg-icons';
import { faMobile, faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { faMobile as faMobileEmpty } from '@fortawesome/pro-light-svg-icons';
import cn from 'classnames';
import _ from 'lodash';
import './NewsletterSection.scss';

import NewsletterSectionItem from './NewsletterSectionItem';

import { createNewsletterSectionTab, deleteNewsletterSectionTab, editNewsletterSectionTab } from '../../../api/newsletters';
import { createNewsletterSectionTag, deleteNewsletterSectionTag, editNewsletterSectionTag } from '../../../api/newsletters';

import { getDisplayForSectionType, getBackgroundColorOptions } from '../../../helpers/newsletter_helpers';
import { getServerFormattedDate } from '../../../helpers/formatting';

const NewsletterSection = props => {
  const { section, newsletter, updateSection, addSectionItem } = props;
  const { title, subtitle, items, type, isHidden, backgroundColor, isMobile } = section;

  const backgroundColorClassName = backgroundColor?.toLowerCase() || 'prettymuchwhite';

  const [isExpanded, setIsExpanded] = React.useState(false);
  const toggleExpandSections = () => setIsExpanded(!isExpanded);

  /*
    Actions to edit the NewsletterSection
  */
  const toggleVisibility = () => {
    const isPublishing = !!isHidden;
    updateSection({ isHidden: !isPublishing, publishedOn: isPublishing ? getServerFormattedDate(new Date()) : null });
    if (isPublishing && !newsletter.isHidden) cogoToast.success(`Section is now Live!`);
  };
  const toggleMobile = () => {
    const isPublishing = !isMobile;
    updateSection({ isMobile: !isMobile });
    isPublishing && cogoToast.success(`Element is now turned on for mobile!`);
  };

  // const toggleFeatured = () => {
  //   const isPublishing = !isFeatured;
  //   updateSection({ isFeatured: !isFeatured });
  //   isPublishing && cogoToast.success(`Element is now featured on the ALL tab`);
  // };

  const changeTitle = () => {
    const newTitle = window.prompt('Set new title', title || '');
    newTitle && updateSection({ title: newTitle });
  };

  const changeSubtitle = () => {
    const newSubtitle = window.prompt('Set new title', subtitle || '');
    !_.isNil(newSubtitle) && updateSection({ subtitle: newSubtitle });
  };

  const backgroundColors = getBackgroundColorOptions();
  const colorIndex = _.indexOf(getBackgroundColorOptions(), backgroundColor);
  const nextColorIndex = backgroundColor === _.last(backgroundColors) ? 0 : colorIndex + 1;
  const nextColor = backgroundColors[nextColorIndex];
  const toggleColor = () => {
    updateSection({ backgroundColor: nextColor });
  };

  /* Actions to edit the NewsletterSectionItems */
  const addItem = () => addSectionItem(section);

  return (
    <div className={cn('newsletter-section-container', backgroundColorClassName)}>
      <div className='title-section'>
        <div onClick={changeSubtitle} className='type'>
          {getDisplayForSectionType(type)} • {section.tabs.map(t => t.title).join(' • ')}
          {isHidden ? ' • HIDDEN' : ' • LIVE'}
        </div>
        <div onClick={changeTitle} className={cn('title', { empty: !title })}>
          {title || 'Title Here'}
        </div>
        <div onClick={changeSubtitle} className={cn('subtitle', { empty: !subtitle })}>
          {subtitle || 'Subtitle Here'}
        </div>
      </div>

      {props.isEditingStructure ? (
        <>
          <div className='section-header'>Tabs</div>
          <div className='section-tabs'>
            {newsletter.tabs.map(tab => {
              const isActive = section.tabs.find(t => t.id === tab.id);
              const numVisible = isActive?._pivot_defaultItemsVisible;

              const toggleActive = async () => {
                if (isActive) {
                  await deleteNewsletterSectionTab(isActive);
                  await props.syncNewsletter();
                } else {
                  await createNewsletterSectionTab({ NewsletterTab_id: tab.id, NewsletterSection_id: section.id });
                  await props.syncNewsletter();
                }
              };
              const toggleCount = async e => {
                e.stopPropagation();
                const newCount = window.prompt('How many items should be visible?', numVisible || 0);
                if (newCount) {
                  await editNewsletterSectionTab(isActive, { defaultItemsVisible: newCount });
                  await props.syncNewsletter();
                }
              };

              return (
                <div onClick={toggleActive} key={`section-${section.id}-tab-${tab.id}`} className={cn('section-tab', { active: isActive })}>
                  <div className='title'>{tab.title}</div>
                  <div onClick={toggleCount} className='num-visible'>
                    {numVisible ? `Show ${numVisible}` : '-'}
                  </div>
                </div>
              );
            })}
          </div>

          <div className='section-header'>Tags</div>
          <div className='section-tags'>
            {newsletter.tags.map(tag => {
              const isActive = section.tags.find(t => t.id === tag.id);

              const toggleActive = async () => {
                if (isActive) {
                  await deleteNewsletterSectionTag(isActive);
                  await props.syncNewsletter();
                } else {
                  await createNewsletterSectionTag({ NewsletterTag_id: tag.id, NewsletterSection_id: section.id });
                  await props.syncNewsletter();
                }
              };

              return (
                <div onClick={toggleActive} key={`section-${section.id}-tag-${tag.id}`} className={cn('section-tag', { active: isActive })}>
                  <div className='title'>{tag.title}</div>
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <div className='section-items'>
          {isExpanded ? (
            <>
              {items.map(item => {
                const updateItem = updates => props.updateSectionItem(section, item, updates);
                const updateItemWaitOnResponse = updates => props.updateSectionItemWaitOnResponse(section, item, updates);
                const addTag = data => props.addSectionItemTag(section, item, data);
                const deleteTag = tag => props.deleteSectionItemTag(section, item, tag);
                return (
                  <NewsletterSectionItem
                    key={item.id}
                    item={item}
                    section={section}
                    newsletter={newsletter}
                    updateSection={updateSection}
                    allBrands={props.allBrands}
                    allBrandPartners={props.allBrandPartners}
                    allCategories={props.allCategories}
                    allDepartments={props.allDepartments}
                    allTags={props.allTags}
                    deleteItem={props.deleteSectionItem}
                    updateItem={updateItem}
                    updateItemWaitOnResponse={updateItemWaitOnResponse}
                    moveItem={props.moveSectionItem}
                    addTag={addTag}
                    deleteTag={deleteTag}
                  />
                );
              })}
              <div onClick={toggleExpandSections} className='section-expand-items expanded'>
                Collapse Items
                <FontAwesomeIcon icon={faChevronUp} />
              </div>
            </>
          ) : (
            <div onClick={toggleExpandSections} className='section-expand-items'>
              Show Items
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
          )}
        </div>
      )}

      <div className='section-actions'>
        <div onClick={addItem} className='section-action'>
          <FontAwesomeIcon icon={faPlus} />
        </div>
        <div onClick={toggleColor} className='section-action color'>
          <FontAwesomeIcon icon={faEyeDropper} />
        </div>
        <div onClick={toggleVisibility} className={cn('section-action', { inactive: !isHidden })}>
          <FontAwesomeIcon icon={isHidden ? faEyeSlash : faEye} />
        </div>
        <div onClick={toggleMobile} className={cn('section-action', { inactive: !isMobile })}>
          <FontAwesomeIcon icon={isMobile ? faMobile : faMobileEmpty} />
        </div>
        {/* <div onClick={toggleFeatured} className={cn('section-action', { inactive: !isFeatured })}>
          <FontAwesomeIcon icon={isFeatured ? faLightbulb : faLightbulbEmpty} />
        </div> */}
        {props.moveUp && (
          <div onClick={props.moveUp} className='section-action'>
            <FontAwesomeIcon icon={faArrowUp} />
          </div>
        )}
        {props.moveDown && (
          <div onClick={props.moveDown} className='section-action'>
            <FontAwesomeIcon icon={faArrowDown} />
          </div>
        )}
        <div onClick={props.deleteSection} className='section-action'>
          <FontAwesomeIcon icon={faTimes} />
        </div>
      </div>
    </div>
  );
};

NewsletterSection.propTypes = {
  newsletter: PropTypes.object.isRequired,
  section: PropTypes.object.isRequired,
  allBrands: PropTypes.array.isRequired,
  allBrandPartners: PropTypes.array.isRequired,
  allCategories: PropTypes.array.isRequired,
  allDepartments: PropTypes.array.isRequired,
  allTags: PropTypes.array.isRequired,
  syncNewsletter: PropTypes.func.isRequired,
  updateSection: PropTypes.func.isRequired,
  deleteSection: PropTypes.func.isRequired,
  addSectionItem: PropTypes.func.isRequired,
  deleteSectionItem: PropTypes.func.isRequired,
  updateSectionItem: PropTypes.func.isRequired,
  updateSectionItemWaitOnResponse: PropTypes.func.isRequired,
  moveSectionItem: PropTypes.func.isRequired,
  addSectionItemTag: PropTypes.func.isRequired,
  deleteSectionItemTag: PropTypes.func.isRequired,
  moveUp: PropTypes.func,
  moveDown: PropTypes.func,

  isEditingStructure: PropTypes.bool.isRequired
};

export default NewsletterSection;
