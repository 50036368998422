import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

import Loader from '../../General/Loader';
import ProductSearchResults from './ProductSearchResults';

import { getProducts } from '../../../api/products';

import './ProductsSearchView.scss';

const TEST_SEARCH_TERM = '';

class ProductsSearchView extends Component {
  static propTypes = {
    catalog: PropTypes.object.isRequired,
    selectProduct: PropTypes.func.isRequired,
    matchSelectedTicket: PropTypes.func.isRequired,
    setSearchRefresh: PropTypes.func.isRequired,
    activeFilters: PropTypes.shape({
      department: PropTypes.object,
      category: PropTypes.object,
      tag: PropTypes.object,
      brand: PropTypes.string
    }),
    inMatchingMode: PropTypes.bool.isRequired,
    selectedTicket: PropTypes.object,
    selectedProduct: PropTypes.object
  };
  componentDidMount() {
    this.configureSearchDebouncer();
    TEST_SEARCH_TERM && setTimeout(() => this.changeSearch(TEST_SEARCH_TERM), 200);
    this.performSearch();
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.activeFilters, this.props.activeFilters)) {
      this.performSearch();
    }
  }

  state = {
    searchVal: '',
    isSearching: false,
    visibleProducts: []
  };

  performSearch = async () => {
    const { activeFilters } = this.props;
    const { category, department, tag, brand } = activeFilters;
    const filters = _.reduce(
      {
        Department_id: _.get(department, 'id'),
        Category_id: _.get(category, 'id'),
        Tag_id: _.get(tag, 'id'),
        brand: brand,
        query: this.state.searchVal,
        pageSize: 200
      },
      (res, v, k) => (v ? { ...res, [k]: v } : res),
      {}
    );
    this.setState({ isSearching: true });
    const { products } = await getProducts(filters);
    this.setState({
      visibleProducts: _.orderBy(products, ['totalRank', 'departmentRank', 'categoryRank']),
      isSearching: false
    });
  };

  configureSearchDebouncer = () => {
    this.searchProductsDebounced = AwesomeDebouncePromise(this.performSearch, 250);
    this.props.setSearchRefresh(newVal => {
      this.setState({ isSearching: true, searchVal: _.isNil(newVal) ? this.state.searchVal : newVal });
      setTimeout(
        () => {
          this.performSearch(newVal || this.state.searchVal);
        },
        newVal ? 0 : this.state.searchVal.length ? 1250 : 250
      ); // Due to asynchronous algolia writes
    });
  };

  changeSearch = newVal => {
    this.setState({ searchVal: newVal, isSearching: true });
    this.searchProductsDebounced(newVal);
  };

  getResultsHeader = () => {
    const { activeFilters } = this.props;
    const { department, category, tag, brand } = activeFilters;
    const { searchVal } = this.state;
    if (searchVal) {
      return null;
    }

    if (tag) return `Best in ${category.name} | ${tag.name}`;
    if (brand) return category ? `Best in ${category.name} | ${brand}` : `Best in ${brand}`;
    if (category) return `Best in ${category.name}`;
    if (department) return `Best in ${department.name}`;

    return 'Best in Beauty';
  };

  render() {
    const { catalog } = this.props;
    const { searchVal, visibleProducts, isSearching } = this.state;
    const header = this.getResultsHeader();
    return (
      <div className='products-search-view-outer-container panel'>
        <div className='search-input-container'>
          <input
            className='search-input'
            placeholder={`Search ${header}` || 'Search For Products'}
            value={searchVal}
            onChange={({ target }) => this.changeSearch(target.value)}
          />
          {!!searchVal.length && (
            <div className='search-clear-btn' onClick={() => this.changeSearch('')}>
              <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
            </div>
          )}
        </div>
        <div className={classnames('products-search-view-container', { searching: isSearching })}>
          {searchVal.length > 0 || visibleProducts.length ? (
            <ProductSearchResults
              catalog={catalog}
              results={visibleProducts}
              inMatchingMode={this.props.inMatchingMode}
              selectedTicket={this.props.selectedTicket}
              activeFilters={this.props.activeFilters}
              selectedProduct={this.props.selectedProduct}
              isSearching={isSearching}
              selectProduct={this.props.selectProduct}
              matchSelectedTicket={this.props.matchSelectedTicket}
            />
          ) : (
            <div>{/* Hierarchy goes here. */}</div>
          )}
          <div className={classnames('searching-overlay', { hidden: !isSearching })}>
            <Loader />
          </div>
        </div>
      </div>
    );
  }
}

export default ProductsSearchView;
