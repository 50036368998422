import React from 'react';
import Loader from '../../General/Loader';
import { Table } from '../GraphTypes/Table';
import PropTypes from 'prop-types';
import { faDownload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BrandTopTalentActivity = props => {
  const { title, brandTopTalentActivity, downloading } = props;
  const success = !!brandTopTalentActivity?.length;

  const download = async () => await props.download({ rows: make_table_rows(), fileName: 'brand_top_talent_activity' });

  const make_table_rows = () => {
    const final_results = [];

    for (const user of brandTopTalentActivity) {
      const { User_id, User_name, username, inbound, outbound, gifted, collabed, custom_rate, custom_code } = user;
      final_results.push({
        key: `brandTopTalentActivity-${username}-${User_id}`,
        label: User_name,
        href: `https://shopmy.us/${user.username}`,
        description: `shopmy.us/${user.username}`,
        values: [
          { label: 'Inbound', value: inbound },
          { label: 'Outbound', value: outbound },
          { label: 'Gifted', value: !!gifted ? 'Yes' : '-' },
          { label: 'Collab', value: !!collabed ? 'Yes' : '-' },
          { label: 'Rate', value: !!custom_rate ? 'Yes' : '-' },
          { label: 'Code', value: !!custom_code ? 'Yes' : '-' }
        ]
      });
    }
    return final_results.slice(0, 15);
  };

  return (
    <div className='brand-card' id={title}>
      <div className='padding'>
        {!brandTopTalentActivity ? (
          <Loader />
        ) : (
          <>
            {success && (
              <>
                <div className='header'>
                  <div className='description'>
                    <div className='title'>{title}</div>
                    <div className='sub-title'>Shows all the top talent and all the activity that was associated to them.</div>
                  </div>
                  <div className='brand-card-controls'>
                    <div className='rounded-button' onClick={() => download()}>
                      {downloading ? (
                        <Loader />
                      ) : (
                        <>
                          <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                          Download
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <Table title='Brand Top Talent Activity' data={make_table_rows()} />
              </>
            )}

            {!success && (
              <>
                <div className='error-centered'>
                  <div className='title'>Error Fetching {title}</div>
                  <div className='sub-title'>Could not fetch results</div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

BrandTopTalentActivity.propTypes = {
  title: PropTypes.string.isRequired,
  brandTopTalentActivity: PropTypes.array,

  download: PropTypes.func.isRequired,
  downloading: PropTypes.bool.isRequired
};

const brandTopTalentActivityConfig = {
  title: 'Top Talent Activity'
};

export { BrandTopTalentActivity, brandTopTalentActivityConfig };
