import Amplify from 'aws-amplify'

let AWSConfig

if (process.env.REACT_APP_NODE_ENV === 'production') {
  AWSConfig = {
    API: {
      endpoints: [
        {
          name: 'askemma-backend',
          // endpoint: 'http://ec2-54-187-27-207.us-west-2.compute.amazonaws.com/api'
          endpoint: process.env.REACT_APP_API_URL || 'https://shopmyshelf.io/api'
        }
      ]
    },
    Auth: {
      region: 'us-east-2',
      userPoolId: 'us-east-2_j8WO80PTk',
      userPoolWebClientId: 'bhrcscd30tp6nkn3gqohb6rbe',
      oauth: {
        redirectSignIn: 'https://shopmyshelf.io/',
        redirectSignOut: 'https://shopmyshelf.io/',
        responseType: 'token',
        domain: 'ask-emma-staging-domain.auth.us-east-2.amazoncognito.com',
        scope: ['email', 'profile', 'username', 'aws.cognito.signin.user.admin']
      },
      authenticationFlowType: 'USER_PASSWORD_AUTH'
    }
  }
} else {
  AWSConfig = {
    API: {
      endpoints: [
        {
          name: 'askemma-backend',
          // endpoint: 'http://ec2-54-187-27-207.us-west-2.compute.amazonaws.com/api'
          endpoint: process.env.REACT_APP_API_URL || 'https://shopmyshelf.io/api'
        }
      ]
    },
    Auth: {
      region: 'us-east-2',
      userPoolId: 'us-east-2_j8WO80PTk',
      userPoolWebClientId: 'bhrcscd30tp6nkn3gqohb6rbe',
      oauth: {
        redirectSignIn: 'http://localhost:3000/',
        redirectSignOut: 'http://localhost:3000/',
        responseType: 'token',
        domain: 'ask-emma-staging-domain.auth.us-east-2.amazoncognito.com',
        scope: ['email', 'profile', 'username', 'aws.cognito.signin.user.admin']
      },
      authenticationFlowType: 'USER_PASSWORD_AUTH'
    }
  }
}

Amplify.configure(AWSConfig)

const socialParams = {
  ClientId: AWSConfig.Auth.userPoolWebClientId,
  UserPoolId: AWSConfig.Auth.userPoolId,
  AppWebDomain: AWSConfig.Auth.oauth.domain,
  TokenScopesArray: ['email', 'profile'],
  RedirectUriSignIn: AWSConfig.Auth.oauth.redirectSignIn,
  RedirectUriSignOut: AWSConfig.Auth.oauth.redirectSignOut,
  ResponseType: 'token'
}

// These are custom url's that you can direct the user to which
// begin the login process. Alternatively:
// https://aws-amplify.github.io/docs/js/authentication#oauth-and-hosted-ui
// That has a better approach
AWSConfig.facebookUrl =
  'https://' +
  socialParams.AppWebDomain +
  '/oauth2/authorize' +
  '?redirect_uri=' +
  socialParams.RedirectUriSignIn +
  '&response_type=token' +
  '&client_id=' +
  socialParams.ClientId +
  '&identity_provider=Facebook'

AWSConfig.googleUrl =
  'https://' +
  socialParams.AppWebDomain +
  '/oauth2/authorize' +
  '?redirect_uri=' +
  socialParams.RedirectUriSignIn +
  '&response_type=token' +
  '&client_id=' +
  socialParams.ClientId +
  '&identity_provider=Google'

AWSConfig.socialParams = socialParams

export default AWSConfig
