import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import cogoToast from 'cogo-toast';
import Select from 'react-select';
import _ from 'lodash';
import './ProductMatchView.scss';

import { updateVariant } from '../../../api/products';

import ProductMatchTicket from './ProductMatchTicket';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '../../General/Tooltip';
import { faSync } from '@fortawesome/pro-regular-svg-icons';

class ProductMatchView extends Component {
  static propTypes = {
    queue: PropTypes.object.isRequired,
    syncVariantsQueue: PropTypes.func.isRequired,
    resyncVariantQueueFilters: PropTypes.func.isRequired,
    selectTicket: PropTypes.func.isRequired,
    searchVal: PropTypes.string.isRequired,
    updateSearchVal: PropTypes.func.isRequired,
    updateSearchDomainVal: PropTypes.func.isRequired,
    updateSearchBrandVal: PropTypes.func.isRequired,
    updateSearchRetailerVal: PropTypes.func.isRequired,
    queueSearchVal: PropTypes.string.isRequired,
    queueSearchDomainVal: PropTypes.string.isRequired,
    queueSearchBrandVal: PropTypes.string.isRequired,
    queueSearchRetailerVal: PropTypes.string.isRequired,
    performSearch: PropTypes.func,
    selectedTicket: PropTypes.object
  };

  componentDidMount() {
    this.searchTicketsDebouncer = AwesomeDebouncePromise(() => {
      this.setState({ isSearching: true });
      this.props.syncVariantsQueue().then(resp => {
        this.setState({ isSearching: false });
      });
    }, 250);

    document.addEventListener('mouseup', e => {
      const isChildOfHighlightZone = e.target && !!e.target.closest('.highlight-zone');
      const selection = window.getSelection().toString();
      if (selection && isChildOfHighlightZone) {
        this.props.performSearch(selection);
      }
    });
  }

  state = {
    isSearching: false,
    unmatchableTicketIds: [] // To speed up deletion, let's hide anything that is in the process of being removed
  };

  markAsUnmatchable = ticket => {
    this.setState({ unmatchableTicketIds: [...this.state.unmatchableTicketIds, ticket.id] });
    updateVariant(ticket.id, { requiresAdminAttention: true, adminMatchNotes: 'Could not find product.' }).then(
      resp => {
        this.props.syncVariantsQueue();
        this.setState({ unmatchableTicketsMap: this.state.unmatchableTicketIds.filter(id => id !== ticket.id) });
      },
      err => {
        cogoToast.error('There was an issue orphaning this variant.');
      }
    );
  };

  changeSearch = newVal => {
    this.props.updateSearchVal(newVal);
    this.searchTicketsDebouncer();
  };

  changeSearchDomain = newVal => {
    this.props.updateSearchDomainVal(newVal);
    this.searchTicketsDebouncer();
  };

  changeSearchBrand = newVal => {
    this.props.updateSearchBrandVal(newVal);
    this.searchTicketsDebouncer();
  };

  changeSearchRetailer = newVal => {
    this.props.updateSearchRetailerVal(newVal);
    this.searchTicketsDebouncer();
  };

  render() {
    const { queue, selectedTicket, searchVal, performSearch, selectTicket } = this.props;
    const { isSearching, unmatchableTicketIds } = this.state;
    const { variantTickets, variantTicketDomains, variantTicketBrands, variantTicketRetailers, variantTicketIsSearchingFilters } = queue;
    const visibleVariantTickets = variantTickets?.filter(ticket => !unmatchableTicketIds.includes(ticket.id));

    // Options
    const getOptions = (arr, key) =>
      _.map(arr, data => ({
        label: `${data[key]} (${data.count})`,
        value: data[key]
      }));
    const domainOptions = getOptions(variantTicketDomains, 'domain');
    const retailerOptions = getOptions(variantTicketRetailers, 'retailer');
    const brandOptions = getOptions(variantTicketBrands, 'brand');

    const currentRetailerOption = retailerOptions.find(({ value }) => value === this.props.queueSearchRetailerVal);
    const currentDomainOption = domainOptions.find(({ value }) => value === this.props.queueSearchDomainVal);
    const currentBrandOption = variantTicketBrands.find(({ brand }) => brand === this.props.queueSearchBrandVal);

    return (
      <div className='product-match-view-container panel'>
        <Tooltip outerClassName='resync-filters-button-container' message='Please use this sparingly, this puts a large strain on our database'>
          <button className='resync-filters-button' onClick={this.props.resyncVariantQueueFilters}>
            <FontAwesomeIcon icon={faSync} spin={variantTicketIsSearchingFilters} />
            Resync Filters
          </button>
        </Tooltip>
        <input
          placeholder='Search Match Tickets'
          className='search-input'
          value={searchVal}
          onChange={({ target }) => this.changeSearch(target.value)}
        />
        <Select
          isMulti
          isClearable
          className='select'
          placeholder='Website Domain'
          isLoading={variantTicketIsSearchingFilters}
          onChange={resp => this.changeSearchDomain(_.map(resp, 'value').join(','))}
          options={domainOptions}
          value={currentDomainOption}
        />
        <Select
          isMulti
          isClearable
          className='select'
          placeholder='Retailer'
          isLoading={variantTicketIsSearchingFilters}
          onChange={resp => this.changeSearchRetailer(_.map(resp, 'value').join(','))}
          options={retailerOptions}
          value={currentRetailerOption}
        />
        <Select
          isMulti
          isClearable
          className='select'
          placeholder='Brand Name'
          isLoading={variantTicketIsSearchingFilters}
          onInputChange={val => this.setState({ brandInputLengthForPerformance: val.length })}
          {...(variantTicketBrands?.length > 200
            ? {
                menuIsOpen: this.state.brandInputLengthForPerformance >= 2
              }
            : {})}
          onChange={resp => this.changeSearchBrand(_.map(resp, 'value').join(','))}
          options={brandOptions}
          value={currentBrandOption}
        />
        <div className='match-items-list'>
          {visibleVariantTickets && visibleVariantTickets.length
            ? visibleVariantTickets.map(ticket => (
                <ProductMatchTicket
                  ticket={ticket}
                  key={ticket.id}
                  performSearch={performSearch}
                  isSelected={!!selectedTicket && ticket.id === selectedTicket.id}
                  isVariant
                  markAsUnmatchable={this.markAsUnmatchable}
                  selectTicket={() => selectTicket(ticket)}
                />
              ))
            : !isSearching && <div className='empty-msg'>No results</div>}
        </div>
      </div>
    );
  }
}

export default ProductMatchView;
