import { getBrands as getBrandsAPI, getRegistrationCodes as getRegistrationCodesAPI } from '../api/brands';
import { applyTagToBrands as applyTagToBrandsAPI, deleteBrandTag as deleteBrandTagAPI } from '../api/tags';

export const GET_BRANDS_SUCCESS = 'GET_BRANDS_SUCCESS';
export const GET_REGISTRATION_CODES_SUCCESS = 'GET_REGISTRATION_CODES_SUCCESS';

export const APPLY_TAG_TO_BRANDS_REQUEST = 'APPLY_TAG_TO_BRANDS_REQUEST';
export const APPLY_TAG_TO_BRANDS_SUCCESS = 'APPLY_TAG_TO_BRANDS_SUCCESS';
export const DELETE_BRAND_TAG_REQUEST = 'DELETE_BRAND_TAG_REQUEST';
export const DELETE_BRAND_TAG_SUCCESS = 'DELETE_BRAND_TAG_SUCCESS';

export const getBrands = (params = {}) => async (dispatch, getState) => {
  const brands = await getBrandsAPI(params);
  return dispatch({
    type: GET_BRANDS_SUCCESS,
    brands
  });
};

export const getBrandRegistrationCodes = (params = {}) => async (dispatch, getState) => {
  const codes = await getRegistrationCodesAPI(params);
  return dispatch({
    type: GET_REGISTRATION_CODES_SUCCESS,
    codes
  });
};

export const applyTagToBrands = (tag, brands) => async (dispatch, getState) => {
  dispatch({
    type: APPLY_TAG_TO_BRANDS_REQUEST,
    tag,
    brands
  });
  await applyTagToBrandsAPI(tag, brands);
  return dispatch({
    type: APPLY_TAG_TO_BRANDS_SUCCESS,
    tag,
    brands
  });
};

export const deleteBrandTag = (tag, brand) => async (dispatch, getState) => {
  dispatch({
    type: DELETE_BRAND_TAG_REQUEST,
    tag,
    brand
  });
  await deleteBrandTagAPI(tag, brand);
  return dispatch({
    type: DELETE_BRAND_TAG_SUCCESS
  });
};
