import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import Mousetrap from 'mousetrap';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import _ from 'lodash';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faEye, faChevronDown, faChevronUp, faInfoCircle, faPlus, faTimes } from '@fortawesome/pro-light-svg-icons';
import { faPenSquare } from '@fortawesome/pro-regular-svg-icons';
import TagGPTEditModal from './elements/TagGPTEditModal';
import './TagSidebar.scss';

import Tooltip from '../General/Tooltip';

import {
  toggleActiveTag,
  toggleTaggingMode,
  toggleUserBeingTagged,
  toggleBrandBeingTagged,
  toggleMerchantBeingTagged,
  toggleTagTypeVisibility,
  setActiveTags,
  setUsersBeingTagged,
  setBrandsBeingTagged,
  setMerchantsBeingTagged
} from '../../actions/uiActions';
import { applyTagToUsers } from '../../actions/userActions';
import { applyTagToBrands } from '../../actions/brandActions';
import { applyTagToMerchants } from '../../actions/merchantActions';
import { getTags, addTag, editTag, deleteTag } from '../../actions/tagActions';

import {
  isTagActive,
  getActiveTags,
  getHiddenTagTypes,
  getUsersBeingTagged,
  getBrandsBeingTagged,
  getMerchantsBeingTagged,
  isTaggingMode,
  isTagTypeHidden
} from '../../helpers/ui_helpers';
import { objectMatchesActiveTagGroups } from '../../helpers/tag_helpers';

const TagSidebar = props => {
  const { ui, users, brands, merchants, toggleTaggingMode, getTags, editTag, deleteTag, addTag, toggleTagTypeVisibility } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [searchVal, setSearchVal] = useState('');
  const [selectedGPTEditTag, setSelectedGPTEditTag] = useState(null);
  const tags = props.tags?.tags || [];
  const isVisible = isTaggingMode(ui);
  const inputRef = useRef();
  const activeTags = getActiveTags(ui);

  const isUsersTab = window.location.href.includes('/users');
  const isBrandsTab = window.location.href.includes('/brands');
  const isMerchantsTab = window.location.href.includes('/merchants');

  // Fetching Initial Data
  useEffect(() => getTags() && undefined, [getTags]);

  // General Setup
  useEffect(() => {
    Mousetrap.bind('t', () => toggleTaggingMode());
    return () => Mousetrap.unbind('t');
  }, [toggleTaggingMode]);

  let toggleObjectBeingTagged, setObjectsBeingTagged, objectsBeingTagged, applyTagToObjects, relevantElements;
  if (isUsersTab) {
    toggleObjectBeingTagged = props.toggleUserBeingTagged;
    setObjectsBeingTagged = props.setUsersBeingTagged;
    applyTagToObjects = props.applyTagToUsers;
    objectsBeingTagged = getUsersBeingTagged(ui);
    relevantElements = users.users;
  } else if (isBrandsTab) {
    toggleObjectBeingTagged = props.toggleBrandBeingTagged;
    setObjectsBeingTagged = props.setBrandsBeingTagged;
    applyTagToObjects = props.applyTagToBrands;
    objectsBeingTagged = getBrandsBeingTagged(ui);
    relevantElements = brands.all;
  } else if (isMerchantsTab) {
    toggleObjectBeingTagged = props.toggleMerchantBeingTagged;
    setObjectsBeingTagged = props.setMerchantsBeingTagged;
    applyTagToObjects = props.applyTagToMerchants;
    objectsBeingTagged = getMerchantsBeingTagged(ui);
    relevantElements = merchants.all;
  } else {
    return cogoToast.warn(`Need to complete setup for this tab.`);
  }

  // Generate Counts
  let tagCounts = {};
  if (relevantElements) {
    const tagGroups = _.groupBy(activeTags, 'type');
    const groupsToCheck = _.values(tagGroups);
    relevantElements.forEach(userOrBrandOrMerchant => {
      if (!objectMatchesActiveTagGroups(userOrBrandOrMerchant, groupsToCheck)) return;
      userOrBrandOrMerchant.tags.forEach(tag => {
        tagCounts[tag.id] = (tagCounts[tag.id] || 0) + 1;
      });
    });
  }

  const sections = isUsersTab
    ? [
        { title: 'General Tags', type: 'general' },
        { title: 'Geo Tags', type: 'geo' },
        { title: 'Catalog Tags', type: 'catalog' },
        { title: 'Social Tags', type: 'social' },
        { title: 'Activity Tags', type: 'activity' },
        { title: 'Behavior Tags', type: 'behavior' },
        { title: 'Internal', type: 'internal' }
      ]
    : isBrandsTab
    ? [
        { title: 'Geo Tags', type: 'geo' },
        { title: 'Catalog Tags', type: 'catalog' },
        { title: 'Activity Tags', type: 'activity' },
        { title: 'Competitor Tags', type: 'brandpromoter' },
        { title: 'General Tags', type: 'general' }
      ]
    : [
        { title: 'Deal Tags', type: 'deal' },
        { title: 'Geo Tags', type: 'geo' },
        { title: 'Internal', type: 'internal' }
      ];

  const addNewTag = async type => {
    const value = window.prompt('Display Value?');
    value && addTag({ value, type });
  };

  const editTagValue = tag => {
    const value = window.prompt('Display Value?', tag.value || '');
    value && editTag(tag, { value });
  };

  const handleGPTEditClick = (evt, tag = null) => {
    evt.stopPropagation();
    setSelectedGPTEditTag(tag);
  };

  const handleGPTModalSave = async updates => {
    if (!selectedGPTEditTag) return;
    await editTag(selectedGPTEditTag, updates);
  };

  // Main Functions
  const hideAllTypes = () => {
    for (const section of sections) {
      !isTagTypeHidden(section.type, ui) && toggleTagTypeVisibility(section.type);
    }
  };
  const showAllTypes = () => {
    for (const section of sections) {
      // Don't open brandpromoter, it's too big
      isTagTypeHidden(section.type, ui) && section.type !== 'brandpromoter' && toggleTagTypeVisibility(section.type);
    }
  };

  // brandpromoter autohidden
  const hasHiddenTypes = getHiddenTagTypes(ui).filter(t => t !== 'brandpromoter').length > 0;
  return (
    <>
      <div className={cn('tag-sidebar-outer-container', { visible: isVisible })}>
        <div className='sidebar-header'>
          <div className='sidebar-header-title'>
            <input ref={inputRef} placeholder='Search Tags' value={searchVal} onChange={({ target }) => setSearchVal(target.value)} />
          </div>
          <div className='sidebar-header-toggle' onClick={() => (searchVal ? setSearchVal('') : setIsEditing(!isEditing))}>
            {searchVal ? 'CLEAR' : isEditing ? 'DONE' : 'EDIT'}
          </div>
        </div>
        {!isEditing && (
          <>
            <div className='sidebar-active-tags'>
              {activeTags.length ? (
                <>
                  {activeTags.map(tag => (
                    <div onClick={() => props.toggleActiveTag(tag)} key={tag.id} className='active-tag'>
                      {tag.type === 'brandpromoter' ? `Competitor: ` : ''}
                      {tag.value}
                      <FontAwesomeIcon icon={faTimes} />
                    </div>
                  ))}
                  <div onClick={() => props.setActiveTags([])} className='clear-all'>
                    Clear All
                  </div>
                </>
              ) : (
                <div className='empty'>No Selected Tags</div>
              )}
            </div>
            <div className='sidebar-active-user-tags'>
              {objectsBeingTagged.length ? (
                <>
                  {objectsBeingTagged.map(object => (
                    <div onClick={() => toggleObjectBeingTagged(object)} key={object.id} className='active-tag'>
                      {object.name}
                      <FontAwesomeIcon icon={faTimes} />
                    </div>
                  ))}
                  <div onClick={() => setObjectsBeingTagged([])} className='clear-all'>
                    Clear All
                  </div>
                </>
              ) : (
                <div className='empty'>No Selected Users</div>
              )}
            </div>
          </>
        )}
        <div className='sidebar-body'>
          <div onClick={hasHiddenTypes ? showAllTypes : hideAllTypes} className='hide-all-sections'>
            {hasHiddenTypes ? 'SHOW' : 'HIDE'} ALL TYPES
          </div>
          {sections.map(section => {
            const { title, type } = section;
            const sectionTags = _.orderBy(
              tags?.filter(t => t.type === type && t.value.toLowerCase().includes(searchVal.toLowerCase())),
              t => t.value.toLowerCase()
            );
            const isVisible = !isTagTypeHidden(type, ui);
            if (!sectionTags.length && searchVal) return null;
            return (
              <div key={section.title} className='section'>
                <div className={cn('section-header', { hidden: !isVisible })}>
                  <div className='title' onClick={() => toggleTagTypeVisibility(type)}>
                    {title}
                    <div className='section-toggle'>
                      <FontAwesomeIcon icon={isVisible ? faChevronUp : faChevronDown} />
                    </div>
                  </div>
                  {isEditing ? (
                    <div onClick={() => addNewTag(type)} className='add-new'>
                      <FontAwesomeIcon icon={faPlus} />
                    </div>
                  ) : (
                    !isVisible && (
                      <div className='hidden-warning' onClick={() => toggleTagTypeVisibility(type)}>
                        CURRENTLY HIDDEN
                      </div>
                    )
                  )}
                </div>
                {isVisible && (
                  <div className='tags'>
                    {sectionTags.map(tag => {
                      const { value, description, isHidden, isAutomated, isOnboarding, isExclusive, isUsedByGPT } = tag;
                      const isActive = isTagActive(tag, ui);
                      const count = tagCounts[tag.id];
                      return (
                        <div key={tag.id} className='tag'>
                          {isEditing ? (
                            <div onClick={() => editTagValue(tag)} className='value'>
                              {value}
                            </div>
                          ) : (
                            <div onClick={() => props.toggleActiveTag(tag)} className={cn('value', { active: isActive })}>
                              <div className={cn('checkbox', { checked: isActive })} />
                              {value}
                              {description && (
                                <Tooltip getIconDiv={() => <FontAwesomeIcon className='info' icon={faInfoCircle} />} message={description} />
                              )}
                              {count && <div className='count'>({count})</div>}
                              <div className='gpt-edit-wrapper' onClick={evt => handleGPTEditClick(evt, tag)}>
                                <span>GPT</span>
                                <FontAwesomeIcon icon={faPenSquare} />
                              </div>
                            </div>
                          )}
                          <div className='actions'>
                            {isEditing ? (
                              <>
                                <div onClick={() => editTag(tag, { isHidden: !isHidden })} className={cn('action hidden', { active: isHidden })}>
                                  <FontAwesomeIcon icon={isHidden ? faEyeSlash : faEye} />
                                </div>
                                <div
                                  onClick={() => editTag(tag, { isAutomated: !isAutomated })}
                                  className={cn('action automated', { active: isAutomated })}
                                >
                                  AUTO
                                </div>
                                <div
                                  onClick={() => editTag(tag, { isOnboarding: !isOnboarding })}
                                  className={cn('action onboarding', { active: isOnboarding })}
                                >
                                  ONB
                                </div>
                                <div
                                  onClick={() => editTag(tag, { isExclusive: !isExclusive })}
                                  className={cn('action exclusive', { active: isExclusive })}
                                >
                                  EXL
                                </div>
                                <div
                                  onClick={() => editTag(tag, { isUsedByGPT: !isUsedByGPT })}
                                  className={cn('action gpt', { active: isUsedByGPT })}
                                >
                                  GPT
                                </div>
                                <div onClick={() => deleteTag(tag)} className='action delete active'>
                                  <FontAwesomeIcon icon={faTimes} />
                                </div>
                              </>
                            ) : (
                              <>
                                {!!isHidden && (
                                  <div className='action hidden disabled active'>
                                    <FontAwesomeIcon icon={faEyeSlash} />
                                  </div>
                                )}
                                {!!isAutomated && <div className='action auto disabled active'>AUTO</div>}
                                {!!isOnboarding && <div className='action onboarding disabled active'>ONB</div>}
                                {!!isExclusive && <div className='action exclusive disabled active'>EXL</div>}
                                {!!isUsedByGPT && <div className='action gpt disabled active'>GPT</div>}
                                {!!objectsBeingTagged.length && (
                                  <div onClick={() => applyTagToObjects(tag, objectsBeingTagged)} className='action apply active'>
                                    Apply
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div onClick={toggleTaggingMode} className='toggle-tag-sidebar-btn'>
        {isVisible ? 'Close' : 'Open'} Tags{isVisible ? '' : ` (press "t")`}
      </div>
      {selectedGPTEditTag && <TagGPTEditModal tag={selectedGPTEditTag} onClose={() => setSelectedGPTEditTag(null)} onSave={handleGPTModalSave} />}
    </>
  );
};

TagSidebar.propTypes = {
  ui: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  brands: PropTypes.object.isRequired,
  merchants: PropTypes.object.isRequired,
  tags: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { ui, users, brands, merchants, tags } = state;
  return { ui, users, brands, merchants, tags };
};

export default connect(mapStateToProps, {
  getTags,
  addTag,
  editTag,
  deleteTag,
  toggleTaggingMode,
  toggleActiveTag,
  toggleUserBeingTagged,
  toggleBrandBeingTagged,
  toggleMerchantBeingTagged,
  toggleTagTypeVisibility,
  setActiveTags,
  setUsersBeingTagged,
  setBrandsBeingTagged,
  setMerchantsBeingTagged,
  applyTagToUsers,
  applyTagToBrands,
  applyTagToMerchants
})(TagSidebar);
