import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import cogoToast from 'cogo-toast';
import './GreenhouseSelectedJob.scss';

import ReactSwitch from 'react-switch';
import Select from 'react-select';
import SelectOption from '../General/SelectOption';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import TagTypeInput from '../General/TagTypeInput';
import Tooltip from '../General/Tooltip';

const greenhouseAutoCandidateActions = [
  { value: 'REJECT', label: 'Auto Reject' },
  { value: 'NOTHING', label: 'Do Nothing' },
  { value: 'PASS', label: 'Auto Pass to Next Stage' }
];

const GreenhouseSelectedJob = props => {
  const { jobOpenings, selectedJobOpeningId, slackUsers } = props;
  const slackUserOptions = slackUsers.map(user => ({ label: user.name, value: user.slackId }));

  const selectedJobOpening = jobOpenings.find(job => selectedJobOpeningId && job.id === selectedJobOpeningId);
  const { title, department, isSmartRecruiterEnabled, forceRereview } = selectedJobOpening || {};

  const [isUpdating, setIsUpdating] = useState(false);
  const [selectedSlackUsers, setSelectedSlackUsers] = useState([]);
  const [candidateTags, setCandidateTags] = useState(selectedJobOpening?.candidateTags?.split(',') || []);
  const [greenhousePrompt, setGreenhousePrompt] = useState(selectedJobOpening?.greenhousePrompt || '');
  const [greenhousePromptSettings, setGreenhousePromptSettings] = useState(
    selectedJobOpening?.greenhousePromptSettings
      ? JSON.parse(selectedJobOpening?.greenhousePromptSettings)
      : new Array(4).fill(null).map((_, index) => ({
          rating: index + 1,
          description: '',
          action: ''
        }))
  );

  useEffect(() => {
    setGreenhousePrompt(selectedJobOpening?.greenhousePrompt || '');
    setGreenhousePromptSettings(
      selectedJobOpening?.greenhousePromptSettings
        ? JSON.parse(selectedJobOpening?.greenhousePromptSettings)
        : new Array(4).fill(null).map((_, index) => ({
            rating: index + 1,
            description: '',
            action: ''
          }))
    );
    setCandidateTags(selectedJobOpening?.candidateTags?.split(',') || []);

    const splitSlackUserIds = selectedJobOpening?.slackUserIds ? selectedJobOpening.slackUserIds.split(' ') : [];
    setSelectedSlackUsers(slackUserOptions.filter(option => splitSlackUserIds.includes(option.value)));
  }, [selectedJobOpening]);

  const saveSettings = async () => {
    if (isUpdating) return window.ALERT.warn('Please wait for the current update to finish');

    setIsUpdating(true);
    for (const setting of greenhousePromptSettings) {
      if (!setting.description || !setting.action) {
        cogoToast.error('All ratings must have a description and action');
        return;
      }
    }

    const slackUserIds = selectedSlackUsers.length ? selectedSlackUsers.map(user => user.value).join(' ') : null;
    const tags = candidateTags.join(',');
    await props.updateJobOpening(selectedJobOpening, { greenhousePrompt, greenhousePromptSettings, slackUserIds, candidateTags: tags });
    setIsUpdating(false);
  };

  const updateJobOpening = async updates => {
    if (isUpdating) return window.ALERT.warn('Please wait for the current update to finish');

    setIsUpdating(true);
    await props.updateJobOpening(selectedJobOpening, updates);
    setIsUpdating(false);
  };

  if (!selectedJobOpening) return <div className='selected-job-placeholder'>No job selected, please select a job from the left panel.</div>;
  return (
    <div className='selected-job-outer'>
      <div className='selected-job-inner'>
        {/* General Job Info */}
        <div className='selected-job-header'>
          <div>
            <div className='selected-job-title'>{title}</div>
            <div className='selected-job-subtitle'>{department}</div>
          </div>

          <div>
            <div className='selected-job-smart-recruiting-toggle-container'>
              <div className='selected-job-smart-recruiting-enabled-text'>{isSmartRecruiterEnabled ? 'Disable' : 'Enable'} Smart Recruiting</div>
              <div>
                <ReactSwitch
                  checked={!!isSmartRecruiterEnabled}
                  onChange={checked => updateJobOpening({ isSmartRecruiterEnabled: checked })}
                  checkedIcon={<FontAwesomeIcon icon={faCheck} />}
                  uncheckedIcon={false}
                  onColor='#11835a'
                  height={24}
                  width={43}
                  handleDiameter={21}
                />
              </div>
            </div>
            <div className='selected-job-smart-recruiting-toggle-container'>
              <div className='selected-job-smart-recruiting-enabled-text'>
                <Tooltip message='If this is checked on, all applicants that were previously reviewed will be reviewed again instead of skipping them.'>
                  Force Rereview <FontAwesomeIcon icon={faInfoCircle} />
                </Tooltip>
              </div>
              <div>
                <ReactSwitch
                  checked={!!forceRereview}
                  onChange={checked => updateJobOpening({ forceRereview: checked })}
                  checkedIcon={<FontAwesomeIcon icon={faCheck} />}
                  uncheckedIcon={false}
                  onColor='#11835a'
                  height={24}
                  width={43}
                  handleDiameter={21}
                />
              </div>
            </div>
            <button className={cn('greenhouse-button')} onClick={saveSettings}>
              Save
            </button>
          </div>
        </div>

        {isSmartRecruiterEnabled ? (
          <>
            {/* Configuration */}
            <div className='selected-job-section'>
              <div className='selected-job-configuration-container'>
                <div className='selected-job-configuration-title'>Slack Notifications</div>
                {!!isSmartRecruiterEnabled && (
                  <Select
                    unstyled
                    isMulti
                    onChange={e => setSelectedSlackUsers(e)}
                    options={slackUserOptions}
                    value={selectedSlackUsers}
                    components={{ Option: SelectOption }}
                    className='selected-job-slack-user-select'
                    placeholder='Add slack admin to @ when a candidate advances'
                  />
                )}
              </div>

              <div className='selected-job-configuration-container'>
                <div className='selected-job-configuration-title'>
                  <Tooltip message='After a candidate is run, they will be tagged with tags specified here. Some examples could be: "Top School", "No Engineering Degree", etc...'>
                    Candidate Tags <FontAwesomeIcon icon={faInfoCircle} />
                  </Tooltip>
                </div>
                <TagTypeInput
                  tags={candidateTags}
                  handleSaveOnBlur={newTags => setCandidateTags(newTags)}
                  maxTagLength={40}
                  showDescription={false}
                />
              </div>
            </div>

            {/* Smart Recruiting */}
            <div className='selected-job-section'>
              <div className='selected-job-smart-recruiting-settings'>
                <div className='selected-job-smart-recruiting-settings-section'>
                  <div className='selected-job-smart-recruiting-settings-section-header'>
                    <div>
                      <div className='selected-job-smart-recruiting-settings-section-title'>Prompt</div>
                      <div className='selected-job-smart-recruiting-settings-section-subtitle'>
                        Give a description of the job and any job requirements and expectations for the candidate.
                      </div>
                    </div>
                  </div>

                  <textarea
                    value={greenhousePrompt}
                    onChange={e => setGreenhousePrompt(e.target.value)}
                    className='selected-job-smart-recruiting-settings-section-textarea'
                  />
                </div>

                {greenhousePromptSettings.map(({ rating, description, action }, index) => (
                  <div key={index} className='selected-job-smart-recruiting-settings-section'>
                    <div className='selected-job-smart-recruiting-settings-section-header'>
                      <div>
                        <div className='selected-job-smart-recruiting-settings-section-title'>Rating {rating}</div>
                        <div className='selected-job-smart-recruiting-settings-section-subtitle'>
                          {rating === 1 ? '(Lowest rating) ' : ''}
                          {rating === 4 ? '(Highest rating) ' : ''}
                          List specific qaulities of a candidate that distinguish them at this rating.
                        </div>
                      </div>

                      <div>
                        <Select
                          unstyled
                          onChange={e =>
                            setGreenhousePromptSettings(
                              greenhousePromptSettings.map((setting, i) => (i === index ? { ...setting, action: e.value } : setting))
                            )
                          }
                          options={greenhouseAutoCandidateActions}
                          value={greenhouseAutoCandidateActions.find(option => option.value === action)}
                          components={{ Option: SelectOption }}
                          className='selected-job-smart-recruiting-settings-section-select'
                        />
                      </div>
                    </div>
                    <textarea
                      value={description}
                      onChange={e => {
                        const updatedSettings = [...greenhousePromptSettings];
                        updatedSettings[index].description = e.target.value;
                        setGreenhousePromptSettings(updatedSettings);
                      }}
                      className='selected-job-smart-recruiting-settings-section-textarea'
                    />
                  </div>
                ))}
              </div>
            </div>
          </>
        ) : (
          <div className='selected-job-placeholder'>Enable smart recruiting to view options</div>
        )}
      </div>
    </div>
  );
};

GreenhouseSelectedJob.propTypes = {
  jobOpenings: PropTypes.array.isRequired,
  selectedJobOpeningId: PropTypes.number,
  slackUsers: PropTypes.array,

  updateJobOpening: PropTypes.func.isRequired
};

export default GreenhouseSelectedJob;
