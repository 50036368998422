import React, { useState, useEffect } from 'react';
import cogoToast from 'cogo-toast';
import './MlTraining.scss';

import { getHierarchy } from '../../api/catalog';
import { getSimilarBrands, postTrainingSample } from '../../api/ml_training';

import Loader from '../General/Loader';
import MlTrainingControls from './MlTrainingControls';
import MlTrainingSimilarBrands from './MlTrainingSimilarBrands';

const MlTraining = () => {
  const [loading, setLoading] = useState(true);
  const [brandsLoading, setBrandsLoading] = useState(true);

  const [allBrands, setAllBrands] = useState([]);
  const [brand, setBrand] = useState(null);
  const [specficBrand, setSpecificBrand] = useState(null);
  const [similarBrands, setSimilarBrands] = useState([]);

  // fetch all brands
  useEffect(() => {
    setLoading(true);

    getHierarchy()
      .then(hierarchy => setAllBrands(hierarchy.brands))
      .catch(error => cogoToast.error(error))
      .finally(() => setLoading(false));
  }, []);

  // fetch similar brands
  useEffect(() => {
    const shouldFetch = !!brand;
    if (!shouldFetch) return;

    const data = {};
    data.Brand_name = brand;
    if (specficBrand) data.SimilarBrand_name = specficBrand;

    setBrandsLoading(true);
    getSimilarBrands(data)
      .then(similarBrands => setSimilarBrands(similarBrands.brands))
      .catch(error => cogoToast.error(error.message))
      .finally(() => setBrandsLoading(false));
  }, [brand, specficBrand]);

  /**
   * Function that sets the feedback for a similar brand, after setting the score,
   * the feedback is returned from the server and the state is updated
   *
   * @param {string} similarBrand - name of the brand the score is being set for
   * @param {number} score - score of how similar the brand is to the selected brand
   */
  const setFeedback = async (similarBrand, score) => {
    const data = {
      Brand_name: brand,
      SimilarBrand_name: similarBrand,
      score,
      model: 'similar_brands',
      message: ''
    };

    const response = await postTrainingSample(data);
    const newSimilarBrands = similarBrands.map(brand => (brand.brand === similarBrand ? { ...brand, feedback: response.feedback } : brand));
    setSimilarBrands(newSimilarBrands);
  };

  return (
    <div className='ml-training-outer'>
      <div className='ml-training-inner'>
        {loading ? (
          <Loader />
        ) : (
          <>
            <MlTrainingControls
              allBrands={allBrands}
              brand={brand}
              specificBrand={specficBrand}
              setBrand={setBrand}
              setSpecificBrand={setSpecificBrand}
            />
            <MlTrainingSimilarBrands
              setFeedback={setFeedback}
              similarBrands={similarBrands}
              brandsLoading={brandsLoading}
              brand={brand}
              similarBrand={specficBrand}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default MlTraining;
