import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';
import moment from 'moment';
import cogoToast from 'cogo-toast';
import _ from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import Tooltip from '../General/Tooltip';

import * as waitlistHelpers from '../../helpers/waitlist_helpers';
import * as socialHelpers from '../../helpers/social_helpers';
import { formatCityCountryFromLocation } from '../../helpers/geo_helpers';
import { copyToClipboard } from '../../helpers/helpers';

import * as waitlistAPI from '../../api/waitlist';

const WaitlistUser = props => {
  const { waitlist, setWaitlist, waitlistUser, selectedFilter, setSelectedApplicationId, rejectUser } = props;

  const { createdAt, name, email, pitch, notes, remindersSent, User_id, isAccepted, isRejected, id, location, howTheyHeard } = waitlistUser;
  const { RegistrationCode_id, code, uses } = waitlistUser;
  const socialUrls = waitlistHelpers.getPitchSocials(pitch) || [];
  const hasAccount = User_id;

  const parsed = JSON.parse(location);
  const formattedWaitlistUserLocation = parsed ? formatCityCountryFromLocation(parsed) : '-';

  const getUserTag = waitlistUser => {
    const { User_id, isPro } = waitlistUser;
    const PRO = User_id && isPro;
    const label = PRO ? 'ShopMy Pro' : 'ShopMy';
    return (
      <div className='decision user'>
        {label} <FontAwesomeIcon icon={faCheck} />
      </div>
    );
  };

  const remindWithNoNotification = async waitlistUser => remind(waitlistUser, true);
  const remind = async (waitlistUser, handledOffline = false) => {
    waitlistAPI.updateWaitlistUser(waitlistUser, { remindersSent: waitlistUser.remindersSent + 1, handledOffline }).then(
      resp => {
        setWaitlist(waitlist.map(u => (u.id === resp.id ? resp : u)));
        !handledOffline && cogoToast.success(`Successfully sent a reminder email to ${waitlistUser.email}`);
      },
      error => cogoToast.error(error)
    );
  };

  const adjustNotes = waitlistUser => {
    const notes = window.prompt('Add Notes', waitlistUser.notes || '');
    if (!_.isNil(notes)) {
      waitlistAPI.updateWaitlistUser(waitlistUser, { notes }).then(
        resp => setWaitlist(waitlist.map(u => (u.id === resp.id ? resp : u))),
        error => cogoToast.error(error)
      );
    }
  };

  if (!selectedFilter.filter(waitlistUser)) return null;
  // else if (idx > visibleCount) return null;
  return (
    <div className={cn('waitlist-user row', { accepted: isAccepted, rejected: isRejected })} key={id}>
      <div className='cell'>{moment(createdAt).format('MMM Do')}</div>
      <div className='cell md'>{name}</div>
      <div onClick={() => copyToClipboard(email, true)} className='cell btn'>
        COPY
      </div>
      <div className='cell'>
        {socialUrls.map(socialUrl => {
          const platform = socialUrl.includes('instagram')
            ? 'Instagram'
            : socialUrl.includes('tiktok')
            ? 'TikTok'
            : socialUrl.includes('youtube')
            ? 'Youtube'
            : socialUrl.includes('facebook')
            ? 'Facebook'
            : socialUrl.includes('twitter')
            ? 'Twitter'
            : socialUrl.includes('substack')
            ? 'Substack'
            : socialUrl.includes('linktr')
            ? 'Linktree'
            : socialUrl.includes('pinterest')
            ? 'Pinterest'
            : 'Instagram';

          return (
            <a target='_blank' rel='noopener noreferrer' href={socialUrl} key={socialUrl}>
              <img className='social-icon' src={socialHelpers.getSocialIcon(platform)} alt='Social' />
            </a>
          );
        })}
      </div>
      <div className='cell xl'>
        {pitch} || Heard through: {howTheyHeard || 'N/A'}
      </div>
      <div className='cell lg'>
        {waitlistHelpers.waitlistUserIsAccepted(waitlistUser) ? (
          User_id || uses > 0 ? (
            getUserTag(waitlistUser)
          ) : !remindersSent ? (
            <div className='decision remind'>
              <div onClick={() => remind(waitlistUser)} className='action'>
                Send Reminder
              </div>
              <div onClick={() => remindWithNoNotification(waitlistUser)} className='action manual'>
                Done Manually
              </div>
            </div>
          ) : (
            <div className='decision reminder-sent'>Reminder Sent</div>
          )
        ) : waitlistHelpers.waitlistUserIsRejected(waitlistUser) ? (
          <div className='decision rejected'>Rejected</div>
        ) : hasAccount ? (
          <Tooltip message={User_id ? `User ID: ${User_id}` : `Registration code used ${uses} time${uses > 1 ? 's' : ''}`}>
            {getUserTag(waitlistUser)}
          </Tooltip>
        ) : (
          <>
            <div className='btns'>
              <div onClick={() => setSelectedApplicationId(waitlistUser.id)} className='btn accept'>
                {RegistrationCode_id ? (
                  <Tooltip
                    message={`Warning: This waitlist user already has a registration code (${code}) despite not having been accepted yet. Accepting them will create a new registration code.`}
                  >
                    Accept
                  </Tooltip>
                ) : (
                  'Accept'
                )}
              </div>
              <div onClick={() => rejectUser(waitlistUser)} className='btn reject'>
                Reject
              </div>
            </div>
          </>
        )}
      </div>
      <div onClick={() => adjustNotes(waitlistUser)} className='cell md'>
        {notes || '-'}
      </div>
      <div className='cell md'>{formattedWaitlistUserLocation}</div>
    </div>
  );
};

WaitlistUser.propTypes = {
  waitlist: PropTypes.array.isRequired,
  setWaitlist: PropTypes.func.isRequired,
  selectedFilter: PropTypes.object.isRequired,

  waitlistUser: PropTypes.object.isRequired,

  // actions
  setSelectedApplicationId: PropTypes.func.isRequired,
  rejectUser: PropTypes.func.isRequired
};

export default WaitlistUser;
