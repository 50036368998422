import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import cn from 'classnames';
import './Newsletters.scss';

import Newsletter from './Newsletter';

import { getNewsletters, createNewsletter, deleteNewsletter } from '../../api/newsletters';

const Newsletters = props => {
  const [newsletters, setNewsletters] = useState([]);
  const [selectedNewsletter, setSelectedNewsletter] = useState(null);

  useEffect(() => {
    getNewsletters().then(resp => {
      const newslettersResp = _.orderBy(resp, 'createdAt', 'desc');
      setNewsletters(newslettersResp);
      setSelectedNewsletter(newslettersResp[0]);
    });
  }, []);

  const createNew = async () => {
    const nextWeek = moment().add(1, 'week');
    const nextWeekStart = nextWeek.clone().startOf('week');
    const nextWeekEnd = nextWeek.clone().endOf('week');
    const proposedTitle = `${nextWeekStart.format('MMMM D')} - ${nextWeekEnd.format('Do')}`;
    const title = window.prompt('Title of the Newsletter?', proposedTitle);
    const newNewsletter = { title };
    const resp = await createNewsletter(newNewsletter);
    setNewsletters([resp, ...newsletters]);
    setSelectedNewsletter(resp);
  };

  return (
    <div className='newsletters-outer-container'>
      <div className='newsletters-container'>
        <div className='header-section'>
          <h1>All Newsletters</h1>
          <div onClick={createNew} className='create-btn'>
            Create New
          </div>
        </div>
        <div className='newsletter-rows'>
          {newsletters.map(newsletter => {
            const { id, title } = newsletter;
            const isSelected = id === selectedNewsletter?.id;
            const select = () => !isSelected && setSelectedNewsletter(newsletter);
            const remove = async () => {
              var confirm = window.confirm(`Are you sure you want to delete this newsletter?`);
              if (!confirm) return;
              await deleteNewsletter(newsletter);
              const newNewsletters = _.filter(newsletters, n => n.id !== id);
              setNewsletters(newNewsletters);
              setSelectedNewsletter(isSelected ? newNewsletters[0] : null);
            };
            return (
              <div key={id} onClick={select} className={cn('newsletter-row', { selected: isSelected })}>
                {isSelected ? <Newsletter newsletter={newsletter} deleteNewsletter={remove} /> : <div className='title'>{title}</div>}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

Newsletters.propTypes = {};

export default Newsletters;
