import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faEye, faEyeSlash, faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import cn from 'classnames';
import _ from 'lodash';
import cogoToast from 'cogo-toast';

import './NewsletterTags.scss';

import { createNewsletterTag, editNewsletterTag, deleteNewsletterTag } from '../../api/newsletters';
import { getGMTTime } from '../../helpers/formatting';

const NewsletterTags = props => {
  const { setActiveTag, setIsEditingStructure, isEditingStructure, activeTag, newsletter, syncNewsletter } = props;
  const tags = _.orderBy(newsletter.tags, 'sortOrderRank', 'asc');

  const toggleEditing = () => setIsEditingStructure(!isEditingStructure);

  const addNewTag = async () => {
    const title = window.prompt('Enter a name for the new tag');
    if (title) {
      try {
        await createNewsletterTag({ title, Newsletter_id: newsletter.id });
        cogoToast.success(`"${title}" tag created successfully!`);
        syncNewsletter();
      } catch (e) {
        console.error(e);
        cogoToast.error(`"${title}" tag creation failed!`);
      }
    }
  };

  return (
    <div className='newsletter-tags-container-outer'>
      <div className='tag-header'>Tags</div>
      <div className='newsletter-tags'>
        {tags.map((tag, idx) => {
          const { id, title, isHidden } = tag;
          const select = () => !isEditingStructure && setActiveTag(isActive ? null : tag);
          const prevTag = tags[idx - 1];
          const nextTag = tags[idx + 1];
          const isActive = activeTag && activeTag.id === id;

          const moveLeft = () => {
            if (prevTag) {
              const prevPrevTag = tags[idx - 2];
              const newRank = prevPrevTag ? (prevTag.sortOrderRank + prevPrevTag.sortOrderRank) / 2 : prevTag.sortOrderRank / 2;
              editNewsletterTag(tag, { sortOrderRank: newRank });
              syncNewsletter();
            }
          };

          const moveRight = () => {
            if (nextTag) {
              const nextNextTag = tags[idx + 2];
              const newRank = nextNextTag ? (nextTag.sortOrderRank + nextNextTag.sortOrderRank) / 2 : getGMTTime();
              editNewsletterTag(tag, { sortOrderRank: newRank });
              syncNewsletter();
            }
          };

          const toggleVisibility = async () => {
            await editNewsletterTag(tag, { isHidden: !isHidden });
            syncNewsletter();
          };

          const edit = async () => {
            const newTitle = window.prompt('Enter a name for the new tag', tag.title);
            if (newTitle) {
              await editNewsletterTag(tag, { title: newTitle });
              syncNewsletter();
            }
          };

          const remove = async () => {
            const confirm = window.confirm('Are you sure you want to delete this tag?');
            if (confirm) {
              await deleteNewsletterTag(tag);
              syncNewsletter();
            }
          };

          return (
            <div key={id} onClick={select} className={cn('tag-container', { active: isActive, hidden: isHidden })}>
              <div className={isActive ? 'tag active' : 'tag'}>
                <div className='title-container'>
                  <div className='title'>{title}</div>
                </div>
                {isEditingStructure && (
                  <div className='edit-actions'>
                    {prevTag && (
                      <div onClick={moveLeft} className='action'>
                        <FontAwesomeIcon icon={faChevronLeft} />
                      </div>
                    )}
                    {nextTag && (
                      <div onClick={moveRight} className='action'>
                        <FontAwesomeIcon icon={faChevronRight} />
                      </div>
                    )}
                    <div onClick={toggleVisibility} className='action'>
                      <FontAwesomeIcon icon={isHidden ? faEyeSlash : faEye} />
                    </div>
                    <div onClick={edit} className='action'>
                      <FontAwesomeIcon icon={faEdit} />
                    </div>
                    <div onClick={remove} className='action'>
                      <FontAwesomeIcon icon={faTrash} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}

        <div className='tag-actions'>
          {isEditingStructure ? (
            <>
              <div onClick={addNewTag} className='action'>
                <div className={'tag'}>ADD NEW</div>
              </div>
              <div onClick={toggleEditing} className='action'>
                <div className={'tag'}>DONE</div>
              </div>
            </>
          ) : (
            <div onClick={toggleEditing} className='action'>
              <div className={'tag'}>EDIT</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

NewsletterTags.propTypes = {
  newsletter: PropTypes.object.isRequired,
  activeTag: PropTypes.object,
  setActiveTag: PropTypes.func.isRequired,
  syncNewsletter: PropTypes.func.isRequired,

  isEditingStructure: PropTypes.bool.isRequired,
  setIsEditingStructure: PropTypes.func.isRequired
};

export default NewsletterTags;
