import React, { Component } from 'react';

import './MainContainer.scss';

class MainContainer extends Component {
  render() {
    return (
      <div className='main-container-outer-container'>
        <div className='header'>
          <a href='/hierarchy' className='header-tab'>
            Hierarchy
          </a>
          <a href='/products' className='header-tab'>
            Products
          </a>
          <a href='/pins' className='header-tab'>
            Pins
          </a>
          <a href='/brands' className='header-tab'>
            Brands
          </a>
          <a href='/scrapers' className='header-tab'>
            Scrapers
          </a>
          <a href='/payments' className='header-tab'>
            Payments
          </a>
          <a href='/invoices' className='header-tab'>
            Invoices
          </a>
          <a href='/consults' className='header-tab'>
            Consults
          </a>
          <a href='/waitlist' className='header-tab'>
            Waitlist
          </a>
          <a href='/merchants' className='header-tab'>
            Merchants
          </a>
          <a href='/collaborations' className='header-tab'>
            Collaborations
          </a>
          <a href='/contract-task-templates' className='header-tab'>
            Tasks
          </a>
          <a href='/users' className='header-tab'>
            Users
          </a>
          <a href='/analytics' className='header-tab'>
            Analytics
          </a>
          <a href='/chat' className='header-tab'>
            Chat
          </a>
          <a href='/growthscraper' className='header-tab'>
            Growth
          </a>
          <a href='/newsletters' className='header-tab'>
            Newsletter
          </a>
          <a href='/lookbookorders' className='header-tab'>
            Lookbooks
          </a>
          <a href='/ml' className='header-tab'>
            Machine Learning
          </a>
          <a href='/concierge' className='header-tab'>
            Concierge
          </a>
          <a href='/ml-training' className='header-tab'>
            Ml Training
          </a>
          <a href='/prompts' className='header-tab'>
            Prompts
          </a>
          <a href='/subscriptions' className='header-tab'>
            Subscriptions
          </a>
          <a href='/greenhouse' className='header-tab'>
            Greenhouse
          </a>
        </div>
        {this.props.children}
      </div>
    );
  }
}

export default MainContainer;
