import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import cogoToast from 'cogo-toast';
import PropTypes from 'prop-types';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import cn from 'classnames';
import Creatable from 'react-select/creatable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLockOpenAlt, faLockAlt } from '@fortawesome/pro-regular-svg-icons';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import './Merchants.scss';

import { updateMerchant, getMerchantTestCases, addMerchantTestCase, updateMerchantTestCase, deleteMerchantTestCase } from '../../api/merchants';

import { getMerchants, deleteMerchantTag } from '../../actions/merchantActions';
import { toggleMerchantBeingTagged, setMerchantsBeingTagged } from '../../actions/uiActions';

import TagSidebar from '../Tags/TagSidebar';

import { isTaggingMode, isTagTypeHidden, getActiveTags, isMerchantBeingTagged } from '../../helpers/ui_helpers';
import { objectMatchesActiveTagGroups } from '../../helpers/tag_helpers';

class Merchants extends Component {
  static propTypes = {
    ui: PropTypes.object.isRequired,
    merchants: PropTypes.object.isRequired,
    getMerchants: PropTypes.func.isRequired,
    deleteMerchantTag: PropTypes.func.isRequired,
    toggleMerchantBeingTagged: PropTypes.func.isRequired,
    setMerchantsBeingTagged: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.fetchMerchants();
    this.fetchTests();
    this.searchMerchantsDebounced = AwesomeDebouncePromise(term => {
      this.fetchMerchants();
    }, 500);
  }
  state = {
    tests: [],
    isFetching: true,

    // Testing
    showingTests: false,
    newName: '',
    newSource: '',
    newDomain: '',
    newPayout: '',

    // Paging
    offset: 0,
    limit: 250,
    query: ''
  };

  fetchMerchants = async () => {
    const { offset, limit, query } = this.state;
    await this.props.getMerchants({ offset, limit, query });
    this.setState({ isFetching: false });
  };

  fetchTests = () => {
    getMerchantTestCases().then(resp => {
      this.setState({ tests: resp || [] });
    });
  };

  updateSearchVal = newVal => {
    this.setState({ query: newVal, isFetching: true, showingTests: false });
    this.searchMerchantsDebounced();
  };

  changeMerchantVal = (merchant, type, initialVal = '', forceVal, additionalNotes) => {
    // Change with prompting
    const newVal = forceVal || window.prompt(`${additionalNotes ? `Warning: ${additionalNotes}. ` : ''}Update ${type} to:`, initialVal || '');
    if (_.isNil(newVal)) return;
    updateMerchant(merchant, { [type]: _.isNaN(parseFloat(newVal)) || newVal.match(/[a-zA-Z]/) ? newVal : parseFloat(newVal) }).then(
      resp => {
        cogoToast.success(`Successfully updated the merchant.`);
        this.fetchMerchants();
      },
      err => cogoToast.error('Had an error saving, check the network tab or ping Harry!')
    );
  };

  changeMerchantVals = (merchant, updates, successMsg) => {
    // Change without prompting
    updateMerchant(merchant, updates).then(
      resp => {
        cogoToast.success(successMsg || `Successfully updated the merchant.`);
        this.fetchMerchants();
      },
      err => cogoToast.error('Had an error saving, check the network tab or ping Harry!')
    );
  };

  changeTestVal = (test, type, initialVal = '', forceVal, additionalNotes) => {
    // Change with prompting
    const newVal = forceVal || window.prompt(`${additionalNotes ? `Warning: ${additionalNotes}. ` : ''}Update ${type} to:`, initialVal || '');
    if (_.isNil(newVal)) return;
    updateMerchantTestCase(test, { [type]: _.isNaN(parseFloat(newVal)) || newVal.match(/[a-zA-Z]/) ? newVal : parseFloat(newVal) }).then(
      resp => {
        cogoToast.success(`Successfully updated the test.`);
        this.fetchTests();
      },
      err => cogoToast.error('Had an error saving, check the network tab or ping Harry!')
    );
  };

  changeTestVals = (test, updates, successMsg) => {
    // Change without prompting
    updateMerchantTestCase(test, updates).then(
      resp => {
        cogoToast.success(successMsg || `Successfully updated the test.`);
        this.fetchTests();
      },
      err => cogoToast.error('Had an error saving, check the network tab or ping Harry!')
    );
  };

  getSourceOptions = () => {
    const { tests } = this.state;
    return _.uniqBy(tests, 'source').map(test => ({
      value: test.source,
      label: test.source
    }));
  };

  addTestCase = e => {
    const { newDomain, newSource, newName, newPayout } = this.state;
    e.preventDefault();
    if (!newDomain || !newSource || !newName || !parseFloat(newPayout)) return cogoToast.warn('Must complete all fields');

    const alreadyHasDomain = _.find(this.state.tests, t => t.domain === newDomain);
    if (alreadyHasDomain) return cogoToast.warn(`${newDomain} has already been defined, please edit it above.`);

    addMerchantTestCase({ domain: newDomain, name: newName, source: newSource, fullPayout: parseFloat(newPayout) }).then(resp => {
      this.setState({ newDomain: '', newName: '', newSource: '', newPayout: '' });
      this.fetchTests();
      this.newNameInput.focus();
    });
  };

  deleteTest = test => deleteMerchantTestCase(test).then(this.fetchTests);

  render() {
    const { ui } = this.props;
    const { isFetching, query, showingTests, tests } = this.state;
    const inTaggingMode = isTaggingMode(ui);
    const selectedTagIds = _.map(getActiveTags(ui), 'id');

    const activeTags = getActiveTags(ui);
    const tagGroups = _.groupBy(activeTags, 'type');
    const allMerchants = this.props.merchants?.all;
    const groupsToCheck = _.values(tagGroups);
    const filteredMerchants = _.filter(allMerchants, merchant => {
      const matchesTags = !isTaggingMode(ui) || objectMatchesActiveTagGroups(merchant, groupsToCheck);
      return matchesTags;
    });

    return (
      <div className={cn('merchants-outer-container', { tagging: inTaggingMode })}>
        <TagSidebar />
        <div className='merchants-inner-container'>
          <div className='header-container'>
            <div className='search-container'>
              <input placeholder='Search by name, domain or source' value={query} onChange={({ target }) => this.updateSearchVal(target.value)} />
            </div>
            <div className='mode-selector'>
              <div onClick={() => this.setState({ showingTests: !showingTests })} className={cn('mode-toggle', { active: !showingTests })}>
                Merchants
              </div>
              <div onClick={() => this.setState({ showingTests: !showingTests })} className={cn('mode-toggle', { active: showingTests })}>
                Tests & Locks
              </div>
            </div>
          </div>
          {showingTests ? (
            <>
              <div className='scraper row header'>
                <div className='cell'>Name</div>
                <div className='cell'>Domain</div>
                <div className='cell'>Source</div>
                <div className='cell'>Rate (fullPayout)</div>
                <div className='cell'>Disclaimer</div>
                <div className='cell sm'>Delete Test</div>
              </div>
              <div className={cn('merchant-results', { fetching: isFetching })}>
                {_.map(
                  _.orderBy(tests, t => t.name.toLowerCase()),
                  (test, idx) => {
                    const { id, name, merchants, domain, disclaimer, source, fullPayout } = test;
                    const winningMerchant = _.find(merchants, m => m.isSMSWinner);

                    const isCorrectName = winningMerchant?.name === name;
                    const isCorrectSource = winningMerchant?.source === source;
                    const isCorrectDisclaimer = (winningMerchant?.disclaimer || null) === (disclaimer || null);
                    const isCorrectPayout = parseInt(winningMerchant?.fullPayout) === parseInt(fullPayout);

                    const isWarned = !isCorrectPayout || !isCorrectSource || !isCorrectPayout || !isCorrectDisclaimer;
                    const status = !winningMerchant ? 'fail' : isWarned ? 'warn' : 'success';

                    const getLockIcon = (lockedFieldName, fieldName, requiresUpdate) => {
                      const locked = !!test[lockedFieldName];
                      const cannotPerformMerchantUpdate = fieldName === 'source';
                      const toggleLock = async e => {
                        e.stopPropagation();
                        const confirmed = window.confirm(
                          `Are you sure you want to ${locked ? 'unlock' : 'lock'} the ${fieldName}?${
                            !locked && requiresUpdate && winningMerchant && !cannotPerformMerchantUpdate
                              ? ` If so we will update "${fieldName}" to "${test[fieldName] || '-'}" on the merchant immediately.`
                              : ''
                          }`
                        );
                        if (!confirmed) return;

                        this.changeTestVals(
                          test,
                          { [lockedFieldName]: !locked },
                          `Successfully ${
                            locked
                              ? `unlocked the ${fieldName}.`
                              : `locked the ${fieldName}. This ensures this value will not change until an unlock is performed.`
                          }`
                        );

                        if (!locked && requiresUpdate && winningMerchant) {
                          if (fieldName === 'source') {
                            cogoToast.warn(
                              'We applied the lock but did not update the current status, you must do that manually by searching for the merchant and changing the source to the desired result.',
                              { hideAfter: 8 }
                            );
                          } else {
                            this.changeMerchantVals(winningMerchant, { [fieldName]: test[fieldName] });
                          }
                        }
                      };
                      return (
                        <FontAwesomeIcon onClick={toggleLock} className={cn('lock-icn', { locked })} icon={locked ? faLockAlt : faLockOpenAlt} />
                      );
                    };
                    return (
                      <div className={cn('row', status)} key={id}>
                        <div onClick={() => this.changeTestVal(test, 'name', name)} className='cell clickable'>
                          {getLockIcon('isNameLocked', 'name', !isCorrectName)}
                          <span className={isCorrectName ? 'value' : 'incorrect-value'}>{name}</span>
                          {!isCorrectName && <span className='correct-value'>{winningMerchant?.name}</span>}
                        </div>
                        <div onClick={() => this.changeTestVal(test, 'domain', domain)} className='cell clickable'>
                          {domain}
                        </div>
                        <div onClick={() => this.changeTestVal(test, 'source', source)} className='cell clickable'>
                          {getLockIcon('isSourceLocked', 'source', !isCorrectSource)}
                          <span className={isCorrectSource ? 'value' : 'incorrect-value'}>{source}</span>
                          {!isCorrectSource && <span className='correct-value'>{winningMerchant?.source}</span>}
                        </div>
                        <div onClick={() => this.changeTestVal(test, 'fullPayout', fullPayout)} className='cell clickable'>
                          {getLockIcon('isPayoutLocked', 'fullPayout', !isCorrectPayout)}
                          <span className={isCorrectPayout ? 'value' : 'incorrect-value'}>{fullPayout}</span>
                          {!isCorrectPayout && <span className='correct-value'>{winningMerchant?.fullPayout}</span>}
                        </div>
                        <div
                          onClick={() => this.changeTestVal(test, 'disclaimer', disclaimer || winningMerchant?.disclaimer)}
                          className='cell clickable'
                        >
                          {getLockIcon('isDisclaimerLocked', 'disclaimer', !isCorrectDisclaimer)}
                          <span className={isCorrectDisclaimer ? 'value' : 'incorrect-value'}>
                            {disclaimer ? disclaimer.slice(0, 12) + '...' : '-'}
                          </span>
                          {!isCorrectDisclaimer && (
                            <span className='correct-value'>
                              {winningMerchant?.disclaimer ? winningMerchant?.disclaimer.slice(0, 12) + '...' : '-'}
                            </span>
                          )}
                        </div>
                        <div onClick={() => this.deleteTest(test)} className='cell sm clickable'>
                          x
                        </div>
                      </div>
                    );
                  }
                )}
                <div className='row'>
                  <form className='cell' onSubmit={this.addTestCase}>
                    <input
                      ref={ref => (this.newNameInput = ref)}
                      placeholder='Name'
                      value={this.state.newName}
                      onChange={({ target }) => this.setState({ newName: target.value })}
                    />
                  </form>
                  <form className='cell' onSubmit={this.addTestCase}>
                    <input
                      id='new-domain-input'
                      placeholder='domain.com'
                      value={this.state.newDomain}
                      onChange={({ target }) => this.setState({ newDomain: target.value })}
                    />
                  </form>
                  <form className='cell' onSubmit={this.addTestCase}>
                    <Creatable
                      className='source-selection'
                      placeholder='Source'
                      value={this.state.newSourceValue}
                      onChange={data => this.setState({ newSource: data.value, newSourceValue: data })}
                      options={this.getSourceOptions()}
                    />
                  </form>
                  <form className='cell' onSubmit={this.addTestCase}>
                    <input
                      placeholder='Payout %'
                      value={this.state.newPayout}
                      onChange={({ target }) => this.setState({ newPayout: target.value })}
                    />
                  </form>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className='scraper row header'>
                <div onClick={() => this.props.setMerchantsBeingTagged(filteredMerchants.filter(m => m.isSMSWinner))} className='cell xs'>
                  Select
                </div>
                <div className='cell'>Name</div>
                <div className='cell'>Domain</div>
                <div className='cell'>Source</div>
                <div className='cell'>Disclaimer</div>
                {inTaggingMode && <div className='cell xl'>Tags</div>}
                <div className='cell sm'>Rate</div>
                <div className='cell sm'>Is Live</div>
                <div className='cell md'>Recent Pin Count</div>
              </div>
              <div className={cn('merchant-results', { fetching: isFetching })}>
                {_.map(filteredMerchants, (merchant, idx) => {
                  const { id, name, domain, source, tags, disclaimer, fullPayout, isSMSWinner, lastMonthPinCount } = merchant;
                  const visibleTags = _.filter(tags, tag => !isTagTypeHidden(tag.type, ui));
                  return (
                    <div className={cn('row', { faded: !query && idx % 2, highlighted: query && isSMSWinner })} key={id}>
                      <div
                        onClick={() =>
                          isSMSWinner
                            ? this.props.toggleMerchantBeingTagged(merchant)
                            : cogoToast.warn(`You can only set tags on live rates (See the is Live Column)`)
                        }
                        className='cell xs'
                      >
                        <div className={cn('checkbox', { checked: isMerchantBeingTagged(merchant, ui), disabled: !isSMSWinner })}></div>
                      </div>
                      <div onClick={() => this.changeMerchantVal(merchant, 'name', name)} className='cell clickable'>
                        {name}
                      </div>
                      <div
                        onClick={() => cogoToast.warn('You cannot change the domain name here, please ask a developer for help.')}
                        className='cell'
                      >
                        {domain}
                      </div>
                      <div
                        onClick={() =>
                          cogoToast.warn('You cannot change the source here, please use search to uncover all options for a given merchant.')
                        }
                        className='cell'
                      >
                        {source}
                      </div>
                      <div onClick={() => this.changeMerchantVal(merchant, 'disclaimer', disclaimer)} className='cell clickable'>
                        {disclaimer ? disclaimer.slice(0, 20) + '...' : '-'}
                      </div>
                      {inTaggingMode && (
                        <div className='cell xl tags'>
                          {_.orderBy(
                            visibleTags,
                            tag => {
                              if (selectedTagIds.includes(tag.id)) return 10;
                              if (tag.type === 'general') return 9;
                              if (tag.type === 'geo') return 8;
                              if (tag.type === 'catalog') return 7;
                              if (tag.type === 'social') return 6;
                              if (tag.type === 'activity') return 5;
                              if (tag.type === 'behavior') return 4;
                              return 0;
                            },
                            'desc'
                          ).map((tag, idx) => {
                            const isSelected = selectedTagIds.includes(tag.id);
                            return (
                              <div
                                onClick={() => this.props.deleteMerchantTag(tag, merchant)}
                                key={tag.value + idx}
                                className={cn('tag', tag.type, { selected: isSelected })}
                              >
                                {tag.value}
                                <FontAwesomeIcon icon={faTimes} />
                              </div>
                            );
                          })}
                        </div>
                      )}
                      <div onClick={() => this.changeMerchantVal(merchant, 'fullPayout', fullPayout)} className='cell sm clickable'>
                        {fullPayout}
                      </div>
                      <div onClick={() => !isSMSWinner && this.changeMerchantVals(merchant, { isSMSWinner: true })} className='cell sm clickable'>
                        {isSMSWinner ? '✓' : '-'}
                      </div>
                      <div className='cell md'>{lastMonthPinCount}</div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { ui, merchants } = state;
  return { ui, merchants };
};

export default connect(mapStateToProps, {
  getMerchants,
  deleteMerchantTag,
  toggleMerchantBeingTagged,
  setMerchantsBeingTagged
})(Merchants);
