import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import cogoToast from 'cogo-toast';
import _ from 'lodash';
import cn from 'classnames';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faArrowUp, faArrowDown, faPencil, faChevronRight, faEyeSlash, faEye } from '@fortawesome/pro-regular-svg-icons';
import { faMobile, faLightbulb } from '@fortawesome/pro-solid-svg-icons';
import { faMobile as faMobileEmpty, faLightbulb as faLightbulbEmpty } from '@fortawesome/pro-light-svg-icons';

import './NewsletterSectionItem.scss';

import { getProductsForBrand } from '../../../api/products';
import { getDomain, getServerFormattedDate } from '../../../helpers/formatting';
import { getDisplayForCtaType } from '../../../helpers/newsletter_helpers';

const NewsletterSectionItem = props => {
  const { item, section, newsletter } = props;
  const { type } = section;
  const { id, brand, merchant, product, blog_post, ctaType, tags, user, collection, isHidden, isMobile, isFeatured } = item;
  const [isEditing, setIsEditing] = useState(!brand && !product);
  const isFirst = _.first(section.items).id === id;
  const isLast = _.last(section.items).id === id;

  // Allow searching for products
  const [productBrandFilter, setProductBrandFilter] = useState(product?.brand || '');
  const [allProducts, setAllProducts] = useState([]);
  useEffect(() => {
    if (productBrandFilter) {
      getProductsForBrand(productBrandFilter).then(setAllProducts);
    } else {
      setAllProducts([]);
    }
  }, [productBrandFilter]);

  const toggleVisibility = () => {
    const isPublishing = !!isHidden;
    props.updateItem({ isHidden: !isPublishing, publishedOn: isPublishing ? getServerFormattedDate(new Date()) : null });
    if (isPublishing && !section.isHidden && !newsletter.isHidden) cogoToast.success(`Element is now Live!`);
  };
  const toggleMobile = () => {
    const isPublishing = !isMobile;
    props.updateItem({ isMobile: !isMobile });
    if (isPublishing && section.isMobile) cogoToast.success(`Element is now turned on for mobile!`);
  };
  const toggleFeatured = () => {
    const isPublishing = !isFeatured;
    props.updateItem({ isFeatured: !isFeatured });
    if (isPublishing && section.isFeatured) cogoToast.success('Element will now have a spotlight design!');
  };

  const deleteItem = item => props.deleteItem(section, item);
  const moveItemUp = item => props.moveItem(section, item, -1);
  const moveItemDown = item => props.moveItem(section, item, 1);
  const updateField = (variable, msg) => {
    const isDate = variable === 'activeStartDate' || variable === 'activeEndDate';
    const curValue = item[variable]
      ? isDate
        ? moment(item[variable]).format('YYYY-MM-DD')
        : item[variable] || ''
      : isDate
      ? moment().format('YYYY-MM-DD')
      : '';
    let newVal = window.prompt(msg || `Enter ${_.startCase(variable)}`, curValue);
    if (_.isNil(newVal)) return;
    if (isDate) {
      if (newVal.length && newVal.length !== 10) return cogoToast.warn('Invalid format, please use YYYY-MM-DD');
      newVal = newVal ? moment(newVal).format('YYYY-MM-DD 12:00:00') : null; // 12 ensures time zones won't matter
    }
    props.updateItemWaitOnResponse({ [variable]: variable === 'domain' ? getDomain(newVal) : newVal });
  };

  let itemTitle,
    itemSubtitle,
    itemBadge,
    itemDomain,
    itemImage,
    itemUrl,
    itemCta,
    itemActiveStartDate,
    itemActiveEndDate,
    config = {};
  switch (type) {
    case 'BRANDS':
      itemTitle = brand?.name;
      itemSubtitle = brand?.description || 'No Brand Description, please add in Brands Admin Tab';
      itemImage = item.image || brand?.mobileCoverImage;
      config = {
        canEditImage: true,
        hasBrandSelect: true,
        ctaOptions: ['ABOUT', 'GIFTING', 'CHAT']
      };
      itemCta = ctaType ? getDisplayForCtaType(ctaType) : null;
      break;
    case 'PRODUCTS':
      itemTitle = product ? `${product.brand.toUpperCase()} | ${product.title}` : '';
      itemSubtitle = item.subtitle || 'Write Description';
      itemImage = item.image || product?.image;
      itemUrl = item.url || (ctaType === 'URL' ? 'REQUIRED: Add Url' : 'Add Specific Url');
      itemCta = ctaType ? getDisplayForCtaType(ctaType) : null;
      config = {
        canEditTitle: true,
        canEditImage: true,
        canEditSubtitle: true,
        canEditUrl: ctaType === 'URL' || ctaType === 'ABOUT',
        hasBrandSelect: ctaType === 'GIFTING',
        ctaOptions: ['GIFTING', 'ABOUT', 'URL'],
        hasProductSelect: true
      };
      break;
    case 'SALES':
      itemTitle = item.title || merchant?.name || null;
      itemSubtitle = merchant ? item.subtitle || 'Enter Explanation Of Sale Here ("title | subtitle" for featured sales)' : null;
      itemBadge = item.badge || 'Specify Discount Code';
      itemDomain = item.domain || 'Select Domain';
      itemImage = item.image || merchant?.logo;
      itemActiveStartDate = item.activeStartDate ? moment(item.activeStartDate).format('MMMM Do') : 'Now';
      itemActiveEndDate = item.activeEndDate ? moment(item.activeEndDate).format('MMMM Do') : 'Forever';
      config = {
        canEditTitle: !!merchant,
        canEditImage: true,
        canEditSubtitle: !!merchant,
        ctaOptions: ['ABOUT', 'URL'],
        canEditBadge: true,
        canEditDomain: true,
        canEditDates: true
      };
      break;
    case 'RATES':
      itemTitle = merchant?.name || '-';
      itemSubtitle = merchant ? `${merchant.fullPayout}% - ${merchant.source} - ${item.subtitle || 'Write Description Here'}` || '-' : null;
      itemDomain = item.domain || 'Select Domain';
      itemImage = item.image || merchant?.logo;
      config = {
        canEditImage: true,
        canEditSubtitle: true,
        canEditDomain: true
      };
      break;
    case 'ARTICLES':
      itemTitle = item.title || blog_post?.title || 'Make Title or Click Below to Link Blog';
      itemSubtitle = item.subtitle || blog_post?.subtitle || 'Enter Explanation';
      itemImage = item.image || blog_post?.coverImage || null;
      itemUrl = item.url || (blog_post ? `https://shopmy.us/blog/${blog_post.titleStub}` : null) || 'Enter URL';
      config = {
        canEditImage: true,
        canEditTitle: true,
        canEditSubtitle: true,
        canEditBlogId: true,
        canEditUrl: true
      };
      break;
    case 'WEBINARS':
      itemTitle = item.title || blog_post?.title || 'Webinar Title';
      itemSubtitle = item.subtitle || blog_post?.subtitle || 'Enter Explanation';
      itemImage = item.image || blog_post?.coverImage || null;
      itemUrl = item.url || 'Enter URL To Registration';
      itemActiveStartDate = item.activeStartDate ? moment(item.activeStartDate).format('MMMM Do, YYYY') : 'Now';
      config = {
        canEditImage: true,
        canEditTitle: true,
        canEditSubtitle: true,
        canEditSingleStartDate: true,
        canEditUrl: true
      };
      break;
    case 'SHOPS':
      itemTitle = user?.name || '-';
      itemSubtitle = item.subtitle || (user ? 'Description here' : '-');
      itemImage = user?.image;
      itemUrl = item.url || (user ? `https://shopmy.us/${user.username}` : null);
      config = {
        canEditImage: true,
        canEditUser: true,
        canEditSubtitle: true,
        canEditUrl: true
      };
      break;
    case 'COLLECTIONS':
      itemTitle = collection?.name || '-';
      itemSubtitle = collection?.user?.name || '-';
      itemImage = collection?.preview_pins?.[0]?.image;
      itemUrl = collection ? `https://shopmy.us/collections/${collection.id}` : null;
      config = {
        canEditCollection: true
      };
      break;
    default:
      return <div>Unknown Section Type: {type}</div>;
  }

  const makeTagHash = tag => `${tag.Department_id || null}-${tag.Category_id || null}-${tag.Tag_id || null}`;
  const changeTags = newTagsRaw => {
    const newTags = _.map(newTagsRaw, 'value');
    const tagsToAdd = newTags.filter(t1 => !tags.find(t2 => makeTagHash(t1) === makeTagHash(t2)));
    const tagsToDelete = tags.filter(t1 => !newTags.find(t2 => makeTagHash(t1) === makeTagHash(t2)));
    for (let tag of tagsToAdd) {
      props.addTag({
        ...tag,
        isRequired: true
      });
    }
    for (let tag of tagsToDelete) {
      props.deleteTag(tag);
    }
  };

  const getTagDisplay = tag => tag?.category?.name || tag?.department?.name || tag?.tag?.value || `Tag #${tag.id}`;
  const {
    canEditTitle,
    canEditSubtitle,
    canEditDomain,
    canEditImage,
    canEditBadge,
    canEditUrl,
    canEditUser,
    canEditCollection,
    canEditBlogId,
    canEditDates,
    canEditSingleStartDate
  } = config;
  return (
    <div className='section-item' key={id}>
      <div className='data'>
        <div onClick={canEditImage ? () => updateField('image', 'Enter Image URL') : null} className={cn('image', { clickable: canEditImage })}>
          {itemImage ? <img src={itemImage} alt={itemTitle || 'Something'} /> : <div className='empty' />}
        </div>
        <div className='meta'>
          {(itemTitle || canEditTitle) && (
            <div onClick={canEditTitle ? () => updateField('title') : null} className={cn('title', { clickable: canEditTitle })}>
              {itemTitle}
              {canEditTitle && <FontAwesomeIcon icon={faPencil} />}
            </div>
          )}
          {(itemSubtitle || canEditSubtitle) && (
            <div
              onClick={canEditSubtitle ? () => updateField('subtitle', 'Enter Description') : null}
              className={cn('subtitle', { clickable: canEditSubtitle })}
            >
              {itemSubtitle}
              {canEditSubtitle && <FontAwesomeIcon icon={faPencil} />}
            </div>
          )}
          {canEditBlogId && (
            <div
              onClick={canEditSubtitle ? () => updateField('BlogPost_id', 'Blog ID (get from PopSQL)') : null}
              className={cn('subtitle', { clickable: canEditSubtitle })}
            >
              Click to enter blog ID
              {canEditBlogId && <FontAwesomeIcon icon={faPencil} />}
            </div>
          )}
          {(itemDomain || canEditDomain) && (
            <div onClick={canEditDomain ? () => updateField('domain') : null} className={cn('domain', { clickable: canEditDomain })}>
              {itemDomain}
              {canEditDomain && <FontAwesomeIcon icon={faPencil} />}
            </div>
          )}
          {(itemBadge || canEditBadge) && (
            <div
              onClick={canEditDomain ? () => updateField('badge') : null}
              className={cn('badge', { clickable: canEditBadge, active: !!item.badge })}
            >
              {itemBadge}
              {canEditBadge && <FontAwesomeIcon icon={faPencil} />}
            </div>
          )}
          {(itemUrl || canEditUrl) && (
            <div onClick={canEditUrl ? () => updateField('url', 'Enter URL') : null} className={cn('url', { clickable: canEditUrl })}>
              {itemUrl}
              {canEditUrl && <FontAwesomeIcon icon={faPencil} />}
            </div>
          )}
          {canEditUser && (
            <div
              onClick={canEditUser ? () => updateField('FeaturedUser_id', 'User ID (get from PopSQL)') : null}
              className={cn('button', { 'needs-action': !user, clickable: canEditUser })}
            >
              {user ? 'Change User' : 'Click to enter User ID'}
              {canEditUser && user && <FontAwesomeIcon icon={faPencil} />}
            </div>
          )}
          {canEditCollection && (
            <div
              onClick={canEditCollection ? () => updateField('FeaturedCollection_id', 'Collection ID (get from URL)') : null}
              className={cn('button', { 'needs-action': !collection, clickable: canEditCollection })}
            >
              {collection ? 'Change Collection' : 'Click to enter Collection ID'}
              {canEditCollection && collection && <FontAwesomeIcon icon={faPencil} />}
            </div>
          )}
          {canEditSingleStartDate && (
            <div className={cn('dates')}>
              <div
                onClick={canEditSingleStartDate ? () => updateField('activeStartDate', 'Date (Format: YYYY-MM-DD)') : null}
                className={cn('date', { active: item.activeStartDate, clickable: canEditSingleStartDate })}
              >
                {itemActiveStartDate || 'Set Date'}
              </div>
            </div>
          )}
          {itemActiveStartDate && itemActiveEndDate && (
            <div className={cn('dates')}>
              <div
                onClick={canEditDates ? () => updateField('activeStartDate', 'Date (Format: YYYY-MM-DD)') : null}
                className={cn('date', { active: item.activeStartDate, clickable: canEditDates })}
              >
                {itemActiveStartDate}
              </div>
              <div className='to'>to</div>
              <div
                onClick={canEditDates ? () => updateField('activeEndDate', 'Date (Format: YYYY-MM-DD)') : null}
                className={cn('date', { active: item.activeEndDate, clickable: canEditDates })}
              >
                {itemActiveEndDate}
              </div>
            </div>
          )}
          {itemCta && (
            <div className='cta'>
              {itemCta}
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
          )}
          <div className='tags'>
            {tags.map(tag => {
              const remove = () => props.deleteTag(tag);
              return (
                <div key={tag.id} onClick={remove} className='tag clickable'>
                  {getTagDisplay(tag)}
                  <div className='delete'>
                    <FontAwesomeIcon icon={faTimes} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {isEditing && (
        <div className='config'>
          {config.hasBrandSelect && (
            <div className='config-section'>
              <div className='config-section-header'>Our Partner Brand</div>
              <Select
                className='select brand-select'
                isSearchable
                defaultValue={brand ? { value: brand.id, label: brand.name } : null}
                name='brand'
                closeMenuOnScroll
                onChange={resp => {
                  props.updateItemWaitOnResponse({ Brand_id: resp.value });
                }}
                options={_.orderBy(props.allBrandPartners, 'id', 'desc').map(brand => ({
                  value: brand.id,
                  label: brand.name
                }))}
              />
            </div>
          )}
          {config.hasProductSelect && (
            <>
              <div className='config-section'>
                <div className='config-section-header'>Brand Name</div>
                <Select
                  className='select brand-select'
                  isSearchable
                  isClearable
                  value={productBrandFilter ? { value: productBrandFilter, label: productBrandFilter } : null}
                  name='brand'
                  closeMenuOnScroll
                  onChange={resp => {
                    setProductBrandFilter(resp.value);
                  }}
                  options={_.orderBy(props.allBrands, 'name').map(brand => ({
                    value: brand,
                    label: brand
                  }))}
                />
              </div>
              {!!productBrandFilter && (
                <div className='config-section'>
                  <div className='config-section-header'>Product</div>
                  <Select
                    className='select brand-select'
                    isSearchable
                    isClearable
                    value={product ? { value: product.id, label: product.title } : null}
                    name='product'
                    closeMenuOnScroll
                    onChange={resp => {
                      props.updateItemWaitOnResponse({ Product_id: resp.value });
                    }}
                    options={_.orderBy(allProducts, 'title').map(product => ({
                      value: product.id,
                      label: `${product.title} (${product.id})`
                    }))}
                  />
                </div>
              )}
            </>
          )}
          {!!config.ctaOptions?.length && (
            <div className='config-section'>
              <div className='config-section-header'>Call To Action Type</div>
              <Select
                className='select cta-select'
                isSearchable
                name='cta'
                closeMenuOnScroll
                isClearable
                defaultValue={ctaType ? { value: ctaType, label: getDisplayForCtaType(ctaType) } : null}
                onChange={resp => {
                  props.updateItemWaitOnResponse({ ctaType: resp?.value });
                }}
                options={config.ctaOptions.map(option => ({
                  value: option,
                  label: getDisplayForCtaType(option)
                }))}
              />
            </div>
          )}
          <div className='config-section'>
            <div className='config-section-header'>Required Tags, Categories or Departments</div>
            <Select
              isMulti
              isClearable
              className='select'
              placeholder='Select Tags'
              onChange={changeTags}
              value={tags.map(tag => ({
                value: {
                  ...(tag.Category_id ? { Category_id: tag.Category_id } : {}),
                  ...(tag.Department_id ? { Department_id: tag.Department_id } : {}),
                  ...(tag.Tag_id ? { Tag_id: tag.Tag_id } : {})
                },
                label: getTagDisplay(tag)
              }))}
              maxLength={10}
              options={_.concat(
                props.allDepartments.map(d => ({ value: { Department_id: d.id }, label: d.name })),
                props.allCategories.map(c => ({ value: { Category_id: c.id }, label: c.name })),
                props.allTags.map(t => ({ value: { Tag_id: t.id }, label: `${t.value} (${t.count})` }))
              )}
            />
          </div>
          <div className='done-btn' onClick={() => setIsEditing(false)}>
            DONE
          </div>
        </div>
      )}
      <div className='item-actions'>
        <div className='item-action' onClick={() => setIsEditing(!isEditing)}>
          <FontAwesomeIcon icon={faPencil} />
        </div>
        <div onClick={toggleVisibility} className={cn('item-action', { inactive: !isHidden })}>
          <FontAwesomeIcon icon={isHidden ? faEyeSlash : faEye} />
        </div>
        <div onClick={toggleMobile} className={cn('item-action', { inactive: !isMobile })}>
          <FontAwesomeIcon icon={isMobile ? faMobile : faMobileEmpty} />
        </div>
        <div onClick={toggleFeatured} className={cn('item-action', { inactive: !isFeatured })}>
          <FontAwesomeIcon icon={isFeatured ? faLightbulb : faLightbulbEmpty} />
        </div>
        {!isFirst && (
          <div className='item-action' onClick={() => moveItemUp(item)}>
            <FontAwesomeIcon icon={faArrowUp} />
          </div>
        )}
        {!isLast && (
          <div className='item-action' onClick={() => moveItemDown(item)}>
            <FontAwesomeIcon icon={faArrowDown} />
          </div>
        )}
        <div className='item-action' onClick={() => (isEditing ? setIsEditing(false) : deleteItem(item))}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
      </div>
    </div>
  );
};

NewsletterSectionItem.propTypes = {
  item: PropTypes.object.isRequired,
  section: PropTypes.object.isRequired,
  newsletter: PropTypes.object.isRequired,
  allBrands: PropTypes.array.isRequired,
  allBrandPartners: PropTypes.array.isRequired,
  allCategories: PropTypes.array.isRequired,
  allDepartments: PropTypes.array.isRequired,
  allTags: PropTypes.array.isRequired,
  updateSection: PropTypes.func.isRequired,
  updateItem: PropTypes.func.isRequired,
  updateItemWaitOnResponse: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  moveItem: PropTypes.func.isRequired,
  addTag: PropTypes.func.isRequired,
  deleteTag: PropTypes.func.isRequired
};

export default NewsletterSectionItem;
