import { getMerchants as getMerchantsAPI } from '../api/merchants';
import { applyTagToMerchants as applyTagToMerchantsAPI, deleteMerchantTag as deleteMerchantTagAPI } from '../api/tags';

export const GET_MERCHANTS_SUCCESS = 'GET_MERCHANTS_SUCCESS';
export const APPLY_TAG_TO_MERCHANTS_REQUEST = 'APPLY_TAG_TO_MERCHANTS_REQUEST';
export const APPLY_TAG_TO_MERCHANTS_SUCCESS = 'APPLY_TAG_TO_MERCHANTS_SUCCESS';
export const DELETE_MERCHANT_TAG_REQUEST = 'DELETE_MERCHANT_TAG_REQUEST';
export const DELETE_MERCHANT_TAG_SUCCESS = 'DELETE_MERCHANT_TAG_SUCCESS';

export const getMerchants = (params = {}) => async (dispatch, getState) => {
  const resp = await getMerchantsAPI(params);
  return dispatch({
    type: GET_MERCHANTS_SUCCESS,
    merchants: resp.merchants
  });
};

export const applyTagToMerchants = (tag, merchants) => async (dispatch, getState) => {
  dispatch({
    type: APPLY_TAG_TO_MERCHANTS_REQUEST,
    tag,
    merchants
  });
  try {
    await applyTagToMerchantsAPI(tag, merchants);
    return dispatch({
      type: APPLY_TAG_TO_MERCHANTS_SUCCESS,
      tag,
      merchants
    });
  } catch (error) {
    return null;
  }
};

export const deleteMerchantTag = (tag, merchant) => async (dispatch, getState) => {
  dispatch({
    type: DELETE_MERCHANT_TAG_REQUEST,
    tag,
    merchant
  });
  await deleteMerchantTagAPI(tag, merchant);
  return dispatch({
    type: DELETE_MERCHANT_TAG_SUCCESS
  });
};
