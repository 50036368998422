import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classnames from 'classnames';

import './ProductCatalogHierarchyView.scss';

class ProductCatalogHierarchyView extends Component {
  static propTypes = {
    catalog: PropTypes.object.isRequired,
    setActiveFilters: PropTypes.func.isRequired,
    activeFilters: PropTypes.shape({
      department: PropTypes.object,
      çategory: PropTypes.object,
      tag: PropTypes.object,
      brand: PropTypes.string
    })
  };

  state = {
    searchVal: '',
    displayingBrands: false
  };

  toggleDepartment = department => {
    const turningOff = department.id === _.get(this.props, 'activeFilters.department.id');
    this.props.setActiveFilters({
      department: turningOff ? null : department,
      category: turningOff ? null : this.props.activeFilters.category,
      tag: null,
      brand: null
    });
  };

  toggleCategory = category => {
    const turningOff = category.id === _.get(this.props, 'activeFilters.category.id');
    this.props.setActiveFilters({
      category: turningOff ? null : category,
      tag: null,
      brand: null
    });
  };

  toggleTag = (tag, category) => {
    const turningOff = tag.id === _.get(this.props, 'activeFilters.tag.id');
    this.props.setActiveFilters({
      tag: turningOff ? null : tag,
      brand: null,
      category
    });
  };

  toggleBrand = (brand, category) => {
    const turningOff = brand === _.get(this.props, 'activeFilters.brand');
    this.props.setActiveFilters({
      tag: null,
      brand: turningOff ? null : brand,
      department: category ? this.props.activeFilters.department : null,
      category
    });
  };

  render() {
    const { catalog, activeFilters } = this.props;
    const { searchVal, displayingBrands } = this.state;
    const activeDepartment = activeFilters.department;
    const activeCategory = activeFilters.category;
    const activeTag = activeFilters.tag;
    const activeBrand = activeFilters.brand;
    const { departments, categories, tags } = catalog;
    const isSearching = !!searchVal.length;
    const allBrands = _.get(catalog, 'brands');
    return (
      <div className='product-catalog-hierarchy-view-outer-container panel'>
        <input
          className='search-input'
          placeholder='Search For Category'
          value={searchVal}
          onChange={({ target }) => this.setState({ searchVal: target.value })}
        />
        {departments &&
          _.orderBy(departments, 'name').map(department => {
            const { name, id } = department;
            const depCats = _.orderBy(
              categories.filter(category => category?.Department_id === id),
              'name'
            );
            const active = activeDepartment && activeDepartment.id === id;
            const visible = !isSearching || name.toLowerCase().includes(searchVal.toLowerCase());
            return (
              <Fragment key={name}>
                {visible && (
                  <div onClick={() => this.toggleDepartment(department)} className={classnames('row', { active: active })}>
                    <div className='main'>{name}</div>
                  </div>
                )}
                {(active || isSearching) &&
                  depCats.map(category => {
                    const { name, id, brands } = category;
                    const catTags = _.orderBy(
                      tags.filter(tag => tag.Category_id === id),
                      'name'
                    );
                    const isCategoryActive = activeCategory && activeCategory.id === id;
                    const visible = !isSearching || name.toLowerCase().includes(searchVal.toLowerCase());
                    return (
                      <Fragment key={name}>
                        {visible && (
                          <div
                            key={name}
                            onClick={() => this.toggleCategory(category)}
                            className={classnames('row level-two', { active: isCategoryActive })}
                          >
                            <div className='main'>{name}</div>
                          </div>
                        )}
                        {(isCategoryActive || isSearching) &&
                          catTags.map(tag => {
                            const { name, id } = tag;
                            const active = activeTag && activeTag.id === id && isCategoryActive;
                            const visible = !isSearching || name.toLowerCase().includes(searchVal.toLowerCase());
                            if (!visible) return null;
                            return (
                              <div
                                key={name}
                                onClick={() => this.toggleTag(tag, category)}
                                className={classnames('row level-three', { active: active })}
                              >
                                <div className='main'>{name}</div>
                              </div>
                            );
                          })}
                        {(isCategoryActive || isSearching) &&
                          brands.map(brand => {
                            const active = activeBrand === brand && isCategoryActive;
                            const visible = !isSearching || brand.toLowerCase().includes(searchVal.toLowerCase());
                            if (!visible) return null;
                            return (
                              <div
                                key={brand}
                                onClick={() => this.toggleBrand(brand, category)}
                                className={classnames('row level-three', { active: active })}
                              >
                                <div className='main'>{isSearching ? `${brand} in ${name}` : brand}</div>
                              </div>
                            );
                          })}
                      </Fragment>
                    );
                  })}
              </Fragment>
            );
          })}
        <Fragment>
          {!isSearching && (
            <div onClick={() => this.setState({ displayingBrands: !displayingBrands })} className={classnames('row', { active: displayingBrands })}>
              <div className='main'>Brands</div>
            </div>
          )}
          {(displayingBrands || isSearching) &&
            allBrands.map(brand => {
              const active = activeBrand === brand;
              const visible = !isSearching || brand.toLowerCase().includes(searchVal.toLowerCase());
              if (!visible) return null;
              return (
                <div key={brand} onClick={() => this.toggleBrand(brand, null)} className={classnames('row level-two', { active: active })}>
                  <div className='main lower'>{isSearching ? `${brand} in All` : brand}</div>
                </div>
              );
            })}
        </Fragment>
      </div>
    );
  }
}

export default ProductCatalogHierarchyView;
