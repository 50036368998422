import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './ConciergeControls.scss';

import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import SelectOption from '../General/SelectOption';

// algolia
import { getSearchIndex } from '../../helpers/search_helpers';
const searchIndex = getSearchIndex('sms_users_production');

const ConciergeControls = props => {
  const { AccountManager_id, accountManagers } = props;
  const [selectedUser, setSelectedUser] = useState({});

  // select options
  const accountManagerOptions = accountManagers
    ? accountManagers.map(manager => ({ label: `${manager.name} (${manager.accounts_managed})`, value: manager.id }))
    : [];
  const searchForTalent = async searchValue => {
    const { hits } = await searchIndex.search(searchValue, { hitsPerPage: 30 });
    const options = hits.map(user => ({ label: `${user.name}`, sublabels: [user.username, user.email], value: user.id }));
    return options;
  };

  const updateAccountManagerId = user => {
    setSelectedUser(null);
    props.setTalentQuery({ AccountManager_id: `${user.value}`, Talent_id: undefined });
  };

  const updateTalentId = user => {
    setSelectedUser(user);
    props.setTalentQuery({ Talent_id: `${user.value}`, AccountManager_id: undefined });
  };

  return (
    <div className='concierge-controls-outer'>
      <div className='header'>Configuration</div>
      <div className='controls'>
        <div className='control-section'>
          <div className='control-section-header'>owner</div>
          <Select
            className='select'
            placeholder='Account Manager'
            value={accountManagerOptions.find(manager => manager.value === parseInt(AccountManager_id)) || null}
            onChange={updateAccountManagerId}
            options={accountManagerOptions}
          />
        </div>

        <div className='control-section'>
          <div className='control-section-header'>specific talent</div>
          <AsyncSelect
            className='select'
            cacheOptions
            loadOptions={searchForTalent}
            value={selectedUser}
            onChange={updateTalentId}
            components={{ Option: SelectOption }}
          />
        </div>
      </div>
    </div>
  );
};

ConciergeControls.propTypes = {
  AccountManager_id: PropTypes.string,
  Talent_id: PropTypes.string,
  setTalentQuery: PropTypes.func.isRequired,
  accountManagers: PropTypes.array.isRequired
};

export default ConciergeControls;
