import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import './ScraperModal.scss';
import { uniq } from 'lodash';
import cogoToast from 'cogo-toast';

import { fetchScraperDetails } from '../../api/scrapers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/pro-solid-svg-icons';
import { getPrettyNumber } from '../../helpers/formatting';

import Modal from '../General/Modal';
import Loader from '../General/Loader';

const ScraperModal = props => {
  const { close, selectedScraperName, scrapers } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [scraperDetails, setScraperDetails] = useState(null);
  const scraper = scrapers.find(scrape => scrape.name === selectedScraperName);
  const { name, type, scraperObject, hasShopifyScraper, hasManualScraper } = scraper || {};
  const isUpcoming = scraperObject.isUpcoming || scraperObject.forceRecrawl;

  const subtitle = [];
  if (scraperDetails?.domain) subtitle.push(scraperDetails.domain);
  if (type.tooltip) subtitle.push(type.tooltip);

  const [notes, setNotes] = useState(scraperObject?.notes || '');

  // used to set url parameters whenever the state time period changes
  useEffect(() => {
    if (!scraper) return;

    const data = { name: scraper.name };
    if (scraper.hasShopifyScraper) data.BrandIntegration_id = scraper.shopifyScraper.id;
    if (scraper.hasManualScraper) data.Scraper_id = scraper.manualScraper.id;

    fetchScraperDetails(data)
      .then(response => setScraperDetails(response.scraperDetails))
      .catch(() => cogoToast.error('Error fetching scraper details'))
      .finally(() => setIsLoading(false));
  }, [scraper]);

  const createManualScraper = async () => {
    const daysBetweenCrawls = window.prompt('Enter the number of days between crawls');
    if (!daysBetweenCrawls) return;
    else if (!parseInt(daysBetweenCrawls)) return cogoToast.error('Invalid number of days between crawls');

    props.createManualScraper({ name: scraper.name, daysBetweenCrawls: parseInt(daysBetweenCrawls) });
  };

  const primaryButtons = [];
  const secondaryButtons = [];

  if (hasShopifyScraper) {
    primaryButtons.push({
      text: 'Notify Engineer to Fix',
      className: 'notify-engineer',
      onClick: () => cogoToast.info('Ping engineer on slack to fix')
    });
  } else if (hasManualScraper) {
    primaryButtons.push({
      text: 'Delete',
      className: 'delete',
      onClick: () => props.deleteScraper(scraper)
    });
    primaryButtons.push({
      text: 'Save',
      className: 'save',
      disabled: scraperObject.notes === notes,
      onClick: () => (scraperObject.notes !== notes ? props.updateScraper(scraper, { notes }) : cogoToast.info('No changes to save'))
    });

    secondaryButtons.push({
      text: 'Force Run Tonight',
      className: 'force-run',
      onClick: () => props.updateScraper(scraper, { forceRecrawl: !scraperObject.forceRecrawl })
    });
    secondaryButtons.push({
      text: 'Mark Needs Fix',
      className: 'needs-fix',
      onClick: () => props.updateScraper(scraper, { needsFix: !scraperObject.needsFix })
    });
  } else {
    primaryButtons.push({
      text: 'Create Manual Scraper',
      className: 'create-manual',
      onClick: createManualScraper
    });
  }

  if (!scraper) return null;
  return (
    <Modal close={close}>
      <div className='scraper-modal-outer'>
        <div className='scraper-modal-inner'>
          <div className='scraper-modal-header'>
            <div className='scraper-modal-title'>
              {name}
              {!!type?.title && (
                <div className='scraper-modal-title-label' style={{ color: type.color, backgroundColor: `${type.color}1A` }}>
                  {type.title}
                </div>
              )}
            </div>
            {!!subtitle.length && <div className='scraper-modal-subtitle'>{uniq(subtitle).join(' || ')}</div>}
          </div>

          <div className='scraper-modal-details'>
            <div className='scraper-modal-detail'>
              {isLoading ? <Loader size={23} /> : <div className='scraper-modal-detail-value'>{getPrettyNumber(scraperDetails?.variantCount)}</div>}
              <div className='scraper-modal-detail-label'>Variant Count</div>
            </div>

            <div className='scraper-modal-detail'>
              {isLoading ? <Loader size={23} /> : <div className='scraper-modal-detail-value'>{scraperDetails?.matchedVariantCount || 'N/A'}</div>}
              <div className='scraper-modal-detail-label'>Matched Variants</div>
            </div>

            <div className='scraper-modal-detail'>
              {isLoading ? <Loader size={23} /> : <div className='scraper-modal-detail-value'>{scraperDetails?.unmatchedVariantCount || 'N/A'}</div>}
              <div className='scraper-modal-detail-label'>Unmatched Variants</div>
            </div>

            <div className='scraper-modal-detail'>
              {isLoading ? <Loader size={23} /> : <div className='scraper-modal-detail-value'>{scraperDetails?.pinCount || 'N/A'}</div>}
              <div className='scraper-modal-detail-label'>Total Pin Count</div>
            </div>

            <div className='scraper-modal-detail'>
              {isLoading ? <Loader size={23} /> : <div className='scraper-modal-detail-value'>{scraperDetails?.pinCountLastMonth || 'N/A'}</div>}
              <div className='scraper-modal-detail-label'>Pin Count Last Month</div>
            </div>

            <div className='scraper-modal-detail'>
              {isLoading ? (
                <Loader size={23} />
              ) : (
                <div className='scraper-modal-detail-value'>{getPrettyNumber(scraperDetails?.commissionAmount)}</div>
              )}
              <div className='scraper-modal-detail-label'>Total Commissions</div>
            </div>

            <div className='scraper-modal-detail'>
              {isLoading ? <Loader size={23} /> : <div className='scraper-modal-detail-value'>{getPrettyNumber(scraperDetails?.orderAmount)}</div>}
              <div className='scraper-modal-detail-label'>Total Volume</div>
            </div>

            <div className='scraper-modal-detail'>
              {isLoading ? <Loader size={23} /> : <div className='scraper-modal-detail-value'>{scraperDetails?.payoutCount || 'N/A'}</div>}
              <div className='scraper-modal-detail-label'>Orders</div>
            </div>

            <div className='scraper-modal-detail'>
              {isLoading ? (
                <Loader size={23} />
              ) : (
                <div className='scraper-modal-detail-value'>{getPrettyNumber(scraperDetails?.orderAmountLastMonth)}</div>
              )}
              <div className='scraper-modal-detail-label'>Volume Last Month</div>
            </div>

            <div className='scraper-modal-detail'>
              <div className='scraper-modal-detail-value'>{moment(scraperObject.lastCrawl).format('MMM Do, YYYY') || 'N/A'}</div>
              <div className='scraper-modal-detail-label'>Last Crawl</div>
            </div>

            <div className='scraper-modal-detail'>
              <div className='scraper-modal-detail-value'>
                {scraperObject.daysBetweenCrawls || 'N/A'}
                {!!hasManualScraper && (
                  <FontAwesomeIcon
                    icon={faEdit}
                    onClick={() => {
                      const daysBetweenCrawls = window.prompt('Enter the number of days between crawls', scraperObject.daysBetweenCrawls);
                      if (!daysBetweenCrawls) return;
                      else if (!parseInt(daysBetweenCrawls)) return cogoToast.error('Invalid number of days between crawls');

                      props.updateScraper(scraper, { daysBetweenCrawls: parseInt(daysBetweenCrawls) });
                    }}
                  />
                )}
              </div>
              <div className='scraper-modal-detail-label'>Days Between Crawl</div>
            </div>

            <div className='scraper-modal-detail'>
              <div className='scraper-modal-detail-value'>{isUpcoming ? 'Yes' : 'No'}</div>
              <div className='scraper-modal-detail-label'>Running Tonight</div>
            </div>

            <div className='scraper-modal-detail'>
              <div className='scraper-modal-detail-value'>
                {hasShopifyScraper ? 'N/A' : scraperObject.requiresManual ? 'Yes' : scraperObject.needsFix ? 'Yes' : 'No'}
              </div>
              <div className='scraper-modal-detail-label'>Needs Fix</div>
            </div>
          </div>

          {!hasShopifyScraper && (
            <div className='scraper-modal-notes'>
              <div className='scraper-modal-notes-header'>Notes</div>
              <textarea
                className='scraper-modal-notes-text'
                value={notes}
                onChange={e => setNotes(e.target.value)}
                placeholder='Add a note if applicable...'
              />
            </div>
          )}
        </div>

        <div className='scraper-modal-footer'>
          {secondaryButtons.length > 0 && (
            <div className='scraper-modal-footer-button-container'>
              {secondaryButtons.map(button => (
                <div
                  key={button.text}
                  className={`scraper-modal-footer-button ${button.className}`}
                  onClick={button.onClick}
                  disabled={button.disabled}
                >
                  {button.text}
                </div>
              ))}
            </div>
          )}

          {primaryButtons.length > 0 && (
            <div className='scraper-modal-footer-button-container'>
              {primaryButtons.map(button => (
                <div
                  key={button.text}
                  className={`scraper-modal-footer-button ${button.className}`}
                  onClick={button.onClick}
                  disabled={button.disabled}
                >
                  {button.text}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

ScraperModal.propTypes = {
  close: PropTypes.func.isRequired,
  scrapers: PropTypes.array.isRequired,
  selectedScraperName: PropTypes.string,

  updateScraper: PropTypes.func.isRequired,
  deleteScraper: PropTypes.func.isRequired,
  createManualScraper: PropTypes.func.isRequired
};

export default ScraperModal;
