import React from 'react';
import Loader from '../../General/Loader';

import { Table } from '../GraphTypes/Table';
import PropTypes from 'prop-types';
import { faDownload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BrandChatUsers = props => {
  const { title, brandChatUsers: chats, downloading } = props;
  const success = !!chats?.length;
  const errorMessage = !success ? 'No Results' : undefined;

  const download = async () => await props.download({ rows: make_table_rows(), fileName: 'brand_chat_users' });

  const make_table_rows = () => {
    if (!success) return;
    const final_results = [];

    for (const chatUser of chats) {
      final_results.push({
        key: `brandChatUsers-${chatUser.username}-${chatUser.User_id}`,
        label: chatUser.name,
        href: `https://shopmy.us/${chatUser.username}`,
        description: `shopmy.us/${chatUser.username}`,
        values: [
          { label: 'Score', value: parseFloat(chatUser.score) },
          { label: 'Predicted Score', value: chatUser.predicted },
          { label: 'Inbound', value: +chatUser.inbound },
          { label: 'Outbound', value: +chatUser.outbound }
        ]
      });
    }
    return final_results;
  };

  return (
    <div className='brand-card' id={title}>
      <div className='padding'>
        {!chats ? (
          <Loader />
        ) : (
          <>
            <>
              <div className='header'>
                <div className='description'>
                  <div className='title'>{title}</div>
                  <div className='sub-title'>Shows all the top talent that haven't been responded to yet.</div>
                </div>
                <div className='brand-card-controls'>
                  <div className='rounded-button' onClick={() => download()}>
                    {downloading ? (
                      <Loader />
                    ) : (
                      <>
                        <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                        Download
                      </>
                    )}
                  </div>
                </div>
              </div>

              <Table title='Brand Chat Users' data={make_table_rows()} errorMessage={errorMessage} />
            </>
          </>
        )}
      </div>
    </div>
  );
};

BrandChatUsers.propTypes = {
  title: PropTypes.string.isRequired,
  brandChatUsers: PropTypes.array,

  download: PropTypes.func.isRequired,
  downloading: PropTypes.bool.isRequired
};

const brandChatUsersConfig = {
  title: 'Unread Chat Users'
};

export { BrandChatUsers, brandChatUsersConfig };
