import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons';
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';

import './PendingCommissions.scss';

import { formatAmountInCurrency } from '../../helpers/formatting';

class PendingCommissions extends Component {
  static propTypes = {
    users: PropTypes.array.isRequired,
    brands: PropTypes.array.isRequired,
    accounts: PropTypes.array.isRequired,
    commissions: PropTypes.array.isRequired,
    selectedCommissions: PropTypes.array.isRequired,
    setSelectedCommissions: PropTypes.func.isRequired,
    updateCommission: PropTypes.func.isRequired
  };

  state = {
    selectedBrand: null
  };

  isCommissionSelected = commission => !!_.find(this.props.selectedCommissions, c => c.id === commission.id);
  selectCommission = commission => {
    const isSelected = this.isCommissionSelected(commission);
    this.props.setSelectedCommissions(
      isSelected ? _.filter(this.props.selectedCommissions, c => c.id !== commission.id) : [...this.props.selectedCommissions, commission]
    );
  };

  selectName = name => {
    const isSelected = this.state.selectedBrand === name;
    this.setState({ selectedBrand: isSelected ? null : name });
    this.props.setSelectedCommissions([]);
  };

  getBrandForCommission = commission => _.find(this.props.brands, brand => brand.id === commission.Brand_id);
  getInvoiceForCommission = commission => _.find(this.props.invoices, invoice => invoice.id === commission.Invoice_id);

  getPendingCommissions = () => {
    return _.filter(this.props.commissions, commission => {
      return !commission.Invoice_id && !commission.isCancelled;
    });
  };

  isCommissionLocked = commission => {
    const { brands } = this.props;
    const brand = brands.find(brand => brand.id === commission.Brand_id);
    const daysAgo = Math.floor((Date.now() - new Date(commission.transaction_date).getTime()) / 1000 / 60 / 60 / 24);
    return daysAgo >= (brand.returnWindowLength || 30);
  };

  render() {
    const { brands, users, accounts } = this.props;
    const { selectedBrand } = this.state;
    const pendingCommissions = this.getPendingCommissions();

    // For quick lookups
    const brandsById = _.keyBy(brands, 'id');
    const brandsByName = _.keyBy(brands, 'name');
    const usersByBrandId = _.keyBy(users, 'Brand_id');
    const accountsByUserId = _.keyBy(accounts, 'User_id');

    // Get brand commissions by brand
    let brandCommissions = {};
    pendingCommissions.forEach(commission => {
      const brand = brandsById[commission.Brand_id];
      brandCommissions[brand.name] = brandCommissions[brand.name] || [];
      brandCommissions[brand.name].push(commission);
    });

    const names = _.orderBy(_.keys(brandCommissions));
    return (
      <div className='pending-commissions-outer-container'>
        <div>
          <h1>Unpaid Commissions</h1>
          <div className='pending-commission-accordion accordion'>
            {_.map(names, name => {
              const brand = brandsByName[name];
              const user = usersByBrandId[brand.id];
              const account = accountsByUserId[user.id];
              const commissions = brandCommissions[name];
              const isSelected = selectedBrand === name;
              const locked = _.filter(commissions, this.isCommissionLocked);
              return (
                <div key={name} className='section'>
                  <div
                    className={classnames('header', { selected: isSelected, faded: selectedBrand && !isSelected })}
                    onClick={() => this.selectName(name)}
                  >
                    <div>
                      {name} -{' '}
                      <span className='small'>
                        {locked.length} Locked, {commissions.length - locked.length} Pending
                      </span>
                    </div>
                    <div className='icons'>
                      {brand?.stripeCustomerId && <div className='stripe-icon'>STRIPE</div>}
                      {!account && <FontAwesomeIcon icon={faExclamationTriangle}></FontAwesomeIcon>}
                      <FontAwesomeIcon icon={isSelected ? faChevronUp : faChevronDown}></FontAwesomeIcon>
                    </div>
                  </div>
                  {isSelected && (
                    <div className='body'>
                      {!account && (
                        <div className='row warning'>
                          <div className='cell'>This brand does not have an associated payment account.</div>
                        </div>
                      )}
                      <div className='row header'>
                        <div onClick={() => this.props.setSelectedCommissions(locked)} className='cell small'>
                          [ all ]
                        </div>
                        <div className='cell'>Date</div>
                        <div className='cell'>Order ID</div>
                        <div className='cell'>Order</div>
                        <div className='cell'>Commission</div>
                        <div className='cell'>Order USD</div>
                        <div className='cell'>Commission USD</div>
                        <div className='cell'>Code</div>
                        <div className='cell'>Status</div>
                      </div>
                      {_.map(_.orderBy(commissions, 'transaction_date'), (commission, idx) => {
                        const isSelected = this.isCommissionSelected(commission);
                        const {
                          order_id,
                          currency,
                          order_amount,
                          order_amount_usd,
                          commission_amount,
                          commission_amount_usd,
                          transaction_date,
                          isCancelled,
                          code
                        } = commission;
                        const isLocked = this.isCommissionLocked(commission);
                        return (
                          <div key={idx} className='row'>
                            <div
                              className={classnames('cell toggle small', { selected: isSelected })}
                              onClick={() => this.selectCommission(commission)}
                            >
                              {isSelected ? '[✓]' : '[ ]'}
                            </div>
                            <div className='cell'>{moment(transaction_date).format('MMMM Do')}</div>
                            <div className='cell'>{order_id}</div>
                            <div className='cell'>{formatAmountInCurrency(order_amount, currency)}</div>
                            <div className='cell'>{formatAmountInCurrency(commission_amount, currency)}</div>
                            <div className='cell'>${order_amount_usd.toFixed(2)}</div>
                            <div className='cell'>${commission_amount_usd.toFixed(2)}</div>
                            <div className='cell'>{code ? code : '-'}</div>
                            <div
                              onClick={() => {
                                const cancel = window.confirm(`Do you want to ${isCancelled ? 'un-cancel' : 'cancel'} this order?`);
                                cancel && this.props.updateCommission(commission, { isCancelled: !isCancelled });
                              }}
                              className='cell'
                            >
                              {isCancelled ? 'Cancelled' : isLocked ? 'Locked' : 'Pending'}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default PendingCommissions;
