import React, { useState } from 'react';
import BarGraph from '../GraphTypes/BarGraph';
import Loader from '../../General/Loader';
import { Table } from '../GraphTypes/Table';
import cn from 'classnames';
import { getBrandReportHeaderLabel } from '../../../helpers/brand_helpers';
import PropTypes from 'prop-types';
import { faDownload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BrandChats = props => {
  const { title, brandChats: chats, downloading } = props;
  const success = !!chats?.length;

  const [tab, setTab] = useState('table');
  const download_table = async () => await props.download({ rows: make_table_rows(), fileName: 'brand_chats' });
  const download_graph = async () => await props.download({ graph: title });

  const make_table_rows = () => {
    let inbound_running_total = 0;
    const inbound_chats = {
      key: 'inbound_chats',
      label: 'Inbound Chats',
      description: '(chats that were sent to the brand by creators)',
      values: chats.map(click => {
        const { quarter, month, week, inbound } = click;
        inbound_running_total += parseInt(inbound || '0');
        return { label: getBrandReportHeaderLabel({ quarter, month, week }), value: inbound };
      })
    };
    inbound_chats.values.push({ label: 'Total', value: inbound_running_total });

    let outbound_running_total = 0;
    const outbound_chats = {
      key: 'outbound_chats',
      label: 'Outbound Chats',
      description: `(chats that were sent to user by brand)`,
      values: chats.map(click => {
        const { quarter, month, week, outbound } = click;
        outbound_running_total += parseInt(outbound || '0');
        return { label: getBrandReportHeaderLabel({ quarter, month, week }), value: outbound };
      })
    };
    outbound_chats.values.push({ label: 'Total', value: outbound_running_total });

    const unique_users = {
      key: 'unique_user_total',
      label: 'Unique Users',
      description: `(Amount of unique users were chatted)`,
      values: chats.map(click => {
        const { quarter, month, week, users } = click;

        return { label: getBrandReportHeaderLabel({ quarter, month, week }), value: users };
      })
    };
    unique_users.values.push({ label: 'Total', value: '-' });

    const difference = {
      key: 'inbound_outbound_diff_percentage',
      label: 'Response Rate',
      description: '(percentage difference in outbound vs inbound chats sent)',
      values: chats.map(click => {
        const { month, quarter, week, inbound, outbound } = click;

        return {
          label: getBrandReportHeaderLabel({ quarter, month, week }),
          value: (parseInt(outbound || '0') / parseInt(inbound || '0')) * 100,
          isPercent: true
        };
      })
    };
    difference.values.push({
      label: 'Total',
      value: `${((parseInt(outbound_running_total) / parseInt(inbound_running_total)) * 100).toFixed()}%`
    });

    let bulk_total_running = 0;
    const bulk_total = {
      key: 'bulk_total',
      label: 'Total Bulk Chats',
      description: '(sum of all bulk chats sent)',
      values: chats.map(click => {
        const { month, quarter, bulk_total } = click;
        bulk_total_running += parseInt(bulk_total || '0');
        return {
          label: getBrandReportHeaderLabel({ quarter, month }),
          value: `${bulk_total}`
        };
      })
    };
    bulk_total.values.push({ label: 'Total', value: `${bulk_total_running}` });

    let bulk_standard_total_running = 0;
    const bulk_standard_total = {
      key: 'bulk_standard_total',
      label: 'Total Bulk Standard Chats',
      description: '(sum of all standard bulk chats sent)',
      values: chats.map(click => {
        const { month, quarter, bulk_standard } = click;
        bulk_total_running += parseInt(bulk_standard || '0');
        return {
          label: getBrandReportHeaderLabel({ quarter, month }),
          value: `${bulk_standard}`
        };
      })
    };
    bulk_standard_total.values.push({ label: 'Total', value: `${bulk_standard_total_running}` });

    let bulk_gifting_total_running = 0;
    const bulk_gifting_total = {
      key: 'bulk_gifting_total',
      label: 'Total Bulk Gifting Chats',
      description: '(sum of all bulk chats sent for gifting)',
      values: chats.map(click => {
        const { month, quarter, bulk_gifting } = click;
        bulk_total_running += parseInt(bulk_gifting || '0');
        return {
          label: getBrandReportHeaderLabel({ quarter, month }),
          value: `${bulk_gifting}`
        };
      })
    };
    bulk_gifting_total.values.push({ label: 'Total', value: `${bulk_gifting_total_running}` });

    let bulk_rates_total_running = 0;
    const bulk_rates_total = {
      key: 'bulk_rates_total',
      label: 'Total Bulk Rate Chats',
      description: '(sum of all bulk chats sent for custom rates)',
      values: chats.map(click => {
        const { month, quarter, bulk_rate } = click;
        bulk_total_running += parseInt(bulk_rate || '0');
        return {
          label: getBrandReportHeaderLabel({ quarter, month }),
          value: `${bulk_rate}`
        };
      })
    };
    bulk_rates_total.values.push({ label: 'Total', value: `${bulk_rates_total_running}` });

    return [inbound_chats, outbound_chats, unique_users, difference, bulk_standard_total, bulk_gifting_total, bulk_rates_total, bulk_total];
  };

  return (
    <div className='brand-card' id={title}>
      <div className='padding'>
        {!chats ? (
          <Loader />
        ) : (
          <>
            {success && (
              <>
                <div className='header'>
                  <div className='description'>
                    <div className='title'>{title}</div>
                    <div className='sub-title'>Chats are defined as all the messages to and from creators / brands in the chat dashboard.</div>
                  </div>
                  {!downloading && (
                    <div className='brand-card-controls'>
                      <div className='radio-btns'>
                        <div className={cn('radio-btn', { active: tab === 'graph' })} onClick={() => setTab('graph')}>
                          Graph
                        </div>
                        <div className={cn('radio-btn', { active: tab === 'table' })} onClick={() => setTab('table')}>
                          Table
                        </div>
                      </div>
                      <div className='rounded-button' onClick={() => (tab === 'table' ? download_table() : download_graph())}>
                        {downloading ? (
                          <Loader />
                        ) : (
                          <>
                            <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                            Download
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>

                {tab === 'table' && (
                  <Table
                    title='Brand Chats'
                    data={make_table_rows()}
                    // data={[ { } ]}
                  />
                )}
                {tab === 'graph' && (
                  <BarGraph
                    title='Total Chats'
                    data={chats.map(click => {
                      const { week, month, quarter, year, inbound } = click;

                      const data = {
                        key: `${week || month || year}${year}clicks`,
                        value: inbound,
                        label: getBrandReportHeaderLabel({ week, month, quarter })
                      };
                      return data;
                    })}
                  />
                )}
              </>
            )}

            {!success && (
              <>
                <div className='error-centered'>
                  <div className='title'>Error Fetching {title}</div>
                  <div className='sub-title'>Could not fetch results</div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

BrandChats.propTypes = {
  title: PropTypes.string.isRequired,
  brandChats: PropTypes.array,

  download: PropTypes.func.isRequired,
  downloading: PropTypes.bool.isRequired
};

const brandChatsConfig = {
  title: 'Chats'
};

export { BrandChats, brandChatsConfig };
