import * as ActionTypes from '../actions/healthActions';
import _ from 'lodash';

export const health = (
  state = {
    links: []
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.GET_ALL_LINK_HEALTH_SUCCESS:
      const { page, limit } = action.params;
      return {
        ...state,
        links: _.concat(state.links.slice(0, page * limit), action.links),
        summary: action.summary
      };

    case ActionTypes.GET_LINK_HEALTH_SUCCESS:
      return {
        ...state,
        links: _.map(state.links, link => (link.id === action.link.id ? action.link : link))
      };

    case ActionTypes.UPDATE_LINK_HEALTH_REQUEST:
    case ActionTypes.UPDATE_LINK_HEALTH_SUCCESS:
      return {
        ...state,
        links: _.map(state.links, link =>
          link.id === action.link.id
            ? {
                ...link,
                ...action.updates
              }
            : link
        )
      };

    default:
      return state;
  }
};
