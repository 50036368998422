import React, { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faEdit } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import _ from 'lodash';
import cn from 'classnames';
import Modal, { ModalButton, ModalTextIput, ModalCheckboxInput, ModalSection } from '../General/Modal';
import './GrowthScraper.scss';

import {
  create_new_growth_scraper_tag,
  delete_existing_growth_scraper_tag,
  fetch_all_growth_scraper_tags,
  bulk_update_growth_scraper_tags
} from '../../api/growth_scraper';

/**
 * @typedef {TagRow[]} TagRows
 *
 * @typedef {Object} TagRow
 * @property {number} id - the unique id that is associated to this row in the database
 * @property {string} tag - the hashtag that is run on social media to find users
 * @property {string} lastRun - timestamp of the last time the tag was scraped
 * @property {boolean} forceRerun - whether or not the tag is being forcively run next time
 * @property {string} previousBatchid - batch id generated from last time tag was run
 * @property {string} persistCampaignid - id of the persist campaign associated to tag
 * @property {string} demoPersistCampaignid - id of the persist campaign for if we make a demo shop for user
 * @property {number} timesRun - total times this tag has been run
 * @property {string} createdAt - timestamp of when this tag was added to the database
 * @property {string} updatedAt - timestamp of when the last change occured on the tag
 * @property {number} total_count - the amount of users this tag has ever scraped
 * @property {number} previous_count - amount of users that were retrieved last time it was run
 */

const GrowthScraper = () => {
  const [tags, setTags] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [sortedColumn, setSortedColumn] = useState('tag');
  const [sortOrder, setSortOrder] = useState('asc');
  const [curSearchVal, setCurSearchVal] = useState('');
  const [selectedTagMap, setSelectedTagMap] = useState({});

  // Fetch Data
  const sync_tags = async () => {
    const all_growth_scraper_tags = await fetch_all_growth_scraper_tags();
    all_growth_scraper_tags ? setTags(all_growth_scraper_tags) : setTags([]);
  };
  useEffect(() => {
    sync_tags();
  }, []);

  // Handle Visibility and sorting
  const visibleTags = tags.filter(tag => {
    if (!curSearchVal) return true;
    if (tag.tag.toLowerCase().includes(curSearchVal.toLowerCase())) return true;
    if (tag.persistCampaignid?.toLowerCase().includes(curSearchVal.toLowerCase())) return true;
    if (tag.demoPersistCampaignid?.toLowerCase().includes(curSearchVal.toLowerCase())) return true;
    if (tag.previousBatchid?.toLowerCase().includes(curSearchVal.toLowerCase())) return true;
    return false;
  });
  const sortedTags = _.orderBy(visibleTags, sortedColumn, sortOrder);

  const close_modal = () => {
    setShowModal(false);
  };

  const delete_existing_tag = async tag_id => {
    const wants_to_continue = window.confirm('Are you sure you want to delete this tag? This cannot be undone.');
    const shouldnt_continue_deleting = !tag_id && !wants_to_continue;

    if (shouldnt_continue_deleting) return;

    const deleted_tag = await delete_existing_growth_scraper_tag({ tag_id });
    if (!deleted_tag.success) window.alert('There was an error deleting the tag. Check console for more data');
    clearTagSelections();
    await sync_tags();
  };

  const handleSearchChange = val => {
    setCurSearchVal(val);
    clearTagSelections();
  };

  const toggleSelectAllTags = () => {
    if (Object.keys(selectedTagMap).length === sortedTags.length) {
      clearTagSelections();
    } else {
      const newSelectedTagMap = {};
      sortedTags.forEach(tag => {
        newSelectedTagMap[tag.id] = true;
      });
      setSelectedTagMap(newSelectedTagMap);
    }
  };

  const toggleSelectTag = tag_id => {
    const newSelectedTagMap = { ...selectedTagMap };
    if (newSelectedTagMap[tag_id]) {
      delete newSelectedTagMap[tag_id];
    } else {
      newSelectedTagMap[tag_id] = true;
    }
    setSelectedTagMap(newSelectedTagMap);
  };

  const clearTagSelections = () => {
    setSelectedTagMap({});
  };

  const columns = [
    [tag => tag.tag, 'Tag', 'tag'],
    [tag => tag.category || '-', 'Category', 'category', 'md'],
    [tag => tag.timesRun, 'Times Run', 'timesRun', 'sm'],
    [tag => tag.totalUsersFound, 'Total Users', 'totalUsersFound', 'md'],
    [tag => tag.previousBatchUsersFound, 'Previous Users', 'previousBatchUsersFound', 'md'],
    [tag => moment(tag.lastRun).format('MMMM Do'), 'Last Ran', 'lastRun', 'sm'],
    [tag => tag.forceRerun, 'Force Rerun', 'forceRerun', 'sm'],
    [tag => (tag.persistCampaignid ? tag.persistCampaignid : 'N/A'), 'Persist Campaign', 'persistCampaignid'],
    [tag => (tag.demoPersistCampaignid ? tag.demoPersistCampaignid : 'N/A'), 'Demo Persist Campaign', 'demoPersistCampaignid'],
    [tag => (tag.previousBatchid ? tag.previousBatchid : 'N/A'), 'Last Batch Id', 'timesRun']
  ];

  const editBntDisabled = Object.keys(selectedTagMap).length === 0;

  return (
    <div className='growth_scraper_outer_container'>
      <div className='growth_inner_container'>
        <div className='actions'>
          <div className='button' onClick={() => setShowModal(true)}>
            Add New Tag
          </div>
          <div className='search-input'>
            <input value={curSearchVal} type='text' placeholder='Search Tags' onChange={e => handleSearchChange(e.target.value)} />
          </div>
          {/* <div className='button'>Run The Scraper</div> */}
          <div
            className={cn('button', { disabled: editBntDisabled })}
            onClick={() => {
              if (editBntDisabled) return;
              setShowModal(true);
            }}
          >
            <FontAwesomeIcon icon={faEdit} />
            Edit Selections
          </div>
        </div>
        <div className='row header'>
          <div className='cell xs'>
            <input
              type='checkbox'
              className='checkbox'
              checked={sortedTags.length && Object.keys(selectedTagMap).length === sortedTags.length}
              onChange={toggleSelectAllTags}
            />
          </div>
          {columns.map(([_, display, sort, size = 'lg']) => {
            const isActiveSort = sortedColumn === sort;
            const toggleSort = () => {
              if (isActiveSort) {
                setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
              } else {
                setSortedColumn(sort);
                setSortOrder('asc');
              }
              clearTagSelections();
            };
            return (
              <div key={display} className={`cell ${size}`} onClick={toggleSort}>
                {display}
                <FontAwesomeIcon icon={faSort} className={cn({ active: isActiveSort })} />
              </div>
            );
          })}
          <div className='cell sm'>Delete Tag</div>
        </div>
        {sortedTags.map((tag, index) => (
          <div className={cn('row', { warning: !tag.persistCampaignid || !tag.demoPersistCampaignid })} key={tag.id}>
            <div className='cell xs'>
              <input type='checkbox' className='checkbox' checked={!!selectedTagMap[tag.id]} onChange={() => toggleSelectTag(tag.id)} />
            </div>
            {columns.map(([valueFn, display, sort, size = 'lg']) => (
              <div key={display} className={`cell ${size}`}>
                {valueFn(tag)}
              </div>
            ))}
            <div className='cell sm'>
              <div
                className='delete'
                onClick={e => {
                  e.stopPropagation();
                  delete_existing_tag(tag.id);
                }}
              >
                Delete
              </div>
            </div>
          </div>
        ))}
      </div>
      {showModal && (
        <Modal close={close_modal}>
          <AddTagModal
            tags={tags}
            selectedTagIds={Object.keys(selectedTagMap).map(id => +id)}
            sync_tags={sync_tags}
            close_modal={() => {
              close_modal();
              clearTagSelections();
            }}
          />
        </Modal>
      )}
    </div>
  );
};

/**
 * component that encapsulates all logic for adding growth scraper
 * tags. created such that it can be imported anywhere and inserted
 * into a modal.
 *
 * @param {Object} props
 * @param {() => void} props.close_modal
 * @param {string} props.id
 * @param {string} props.tag
 * @param {boolean} props.forceRerun
 * @param {string} props.persistCampaignid
 * @param {string} props.demoPersistCampaignid
 */
const AddTagModal = ({ tags, selectedTagIds, sync_tags, close_modal }) => {
  const [tagId, setTagId] = useState(null);
  const [tag, setTag] = useState('');
  const [category, setCategory] = useState(null);
  const [forceRun, setForceRun] = useState(null);
  const [persistCampaignid, setPersistCampaignid] = useState('');
  const [demoPersistCampaignid, setDemoPersistCampaignid] = useState('');

  const isBulkUpdate = selectedTagIds.length > 1;

  useEffect(() => {
    const singleTagToUpdate = selectedTagIds.length === 1 && _.find(tags, { id: selectedTagIds[0] });

    if (singleTagToUpdate) {
      setTagId(singleTagToUpdate.id);
      setTag(singleTagToUpdate.tag);
      setCategory(singleTagToUpdate.category);
      setForceRun(singleTagToUpdate.forceRerun);
      setPersistCampaignid(singleTagToUpdate.persistCampaignid);
      setDemoPersistCampaignid(singleTagToUpdate.demoPersistCampaignid);
    }
  }, [selectedTagIds, tags]);

  const upsert = async () => {
    let response;

    if (isBulkUpdate) {
      // bulk update
      const updateFields = {};

      // only update fields that have been set
      if (category !== null) updateFields.category = category;
      if (forceRun !== null) updateFields.forceRerun = forceRun;
      if (persistCampaignid !== '') updateFields.persistCampaignid = persistCampaignid;
      if (demoPersistCampaignid !== '') updateFields.demoPersistCampaignid = demoPersistCampaignid;

      if (Object.keys(updateFields).length === 0) {
        window.alert('There needs to be at least one field to update');
        return;
      }

      response = await bulk_update_growth_scraper_tags({
        ids: selectedTagIds,
        updates: updateFields
      });
    } else {
      // single add/update
      if (!tag || typeof tag !== 'string') {
        window.alert('There needs to be a tag in order to submit');
        return;
      }

      response = await create_new_growth_scraper_tag({
        id: tagId,
        tag: tag.toLowerCase(),
        category,
        forceRun,
        persistCampaignid,
        demoPersistCampaignid
      });
    }

    !response?.success ? window.alert('There was an error inserting the new tag, please check console for error.') : close_modal();
    await sync_tags();
  };

  // category Option
  const categoryOptions = _.filter(_.uniq(_.map(tags, 'category'))).map(c => ({ value: c, label: c }));
  const selectedOption = categoryOptions.find(o => o.value === category);
  const handleChange = option => setCategory(option.value);

  return (
    <>
      <ModalSection>
        <>
          <ModalTextIput
            disabled={isBulkUpdate}
            type='text'
            title='Tag'
            value={tag}
            subtitle='This can be any hashtag found on Instagram, Tiktok, or Youtube'
            oc={e => setTag(e.target.value)}
          />
          <div>Category</div>
          <CreatableSelect isClearable onChange={handleChange} options={categoryOptions} value={selectedOption} />
          <ModalTextIput
            type='text'
            title='Persist Campaign ID'
            value={persistCampaignid}
            subtitle='Found in url for each Persist campaign.'
            oc={e => setPersistCampaignid(e.target.value)}
          />
          <ModalTextIput
            type='text'
            title='Demo Persist Campaign ID'
            value={demoPersistCampaignid}
            subtitle='Same as persist campaign id but for when we already have a demo setup for them.'
            oc={e => setDemoPersistCampaignid(e.target.value)}
          />
          <ModalCheckboxInput
            key={Math.random()}
            name='growth_scraper_checkbox'
            title='Force Run'
            subtitle='If you want this tag to have priority over other tags created'
            oc={e => {
              setForceRun(e.target.checked);
            }}
            checked={forceRun}
          />
        </>
      </ModalSection>
      <ModalSection>
        <ModalButton oc={upsert}>{selectedTagIds.length ? `Update Tag${isBulkUpdate ? 's' : ''}` : 'Add New Tag'}</ModalButton>
      </ModalSection>
    </>
  );
};

export default GrowthScraper;
