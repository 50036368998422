import React, { Component } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import './Hierarchy.scss';

import HierarchyView from './elements/HierarchyView'
import HierarchyEdit from './elements/HierarchyEdit'

import { syncCatalogHierarchy } from '../../actions/catalogActions'

class Hierarchy extends Component {
  static propTypes = {
    catalog: PropTypes.object.isRequired,
    syncCatalogHierarchy: PropTypes.func.isRequired,
  }

  state = {
    selectedItem: null
  }

  getSelectedItemType = () => {
    const { selectedItem } = this.state;
    if (!selectedItem) {
      return null
    } else if (!('Department_id' in selectedItem)) {
      return 'department'
    } else if ('Department_id' in selectedItem && 'Category_id' in selectedItem) {
      return 'tag'
    } else {
      return 'category'
    } 
  }

  selectItem = selectedItem => {
    this.setState({ selectedItem })
  }

  render() {
    const { catalog, syncCatalogHierarchy } = this.props;
    const { selectedItem } = this.state;
    const selectedItemType = this.getSelectedItemType()
    return (
      <div className='hierarchy-outer-container'>
        <HierarchyView 
          catalog={catalog} 
          syncCatalogHierarchy={syncCatalogHierarchy} 
          selectedItem={selectedItem}
          selectedItemType={selectedItemType}
          selectItem={this.selectItem}
        />
        <HierarchyEdit 
          catalog={catalog} 
          syncCatalogHierarchy={syncCatalogHierarchy} 
          selectedItem={selectedItem}
          selectedItemType={selectedItemType}
          selectItem={this.selectItem}
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  const { catalog } = state
  return { catalog }
}

export default connect(mapStateToProps, {
  syncCatalogHierarchy
})(Hierarchy)
