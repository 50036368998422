import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import './ConciergeModal.scss';

import Modal from '../../General/Modal';
import Loader from '../../General/Loader';

import { getTalentReportModalUser } from '../../../api/talent_report';
import { setActiveTalentId } from '../../../actions/uiActions';
import { getUrlParam, setUrlParam } from '../../../helpers/helpers';

import ConciergeModalSidebar from './ConciergeModalSideBar';
import ConciergeModalMainContainer from './ConciergeModalMainContainer';

const ConciergeModal = props => {
  const { close, activeTalentId, accountManagers } = props;

  const getStartingValues = () => {
    const groupingMethod = getUrlParam('groupingMethod') || 'week';
    const endDate = getUrlParam('endDate') ? moment(getUrlParam('endDate')).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
    const startDate = getUrlParam('startDate')
      ? moment(getUrlParam('startDate')).format('YYYY-MM-DD')
      : moment(endDate)
          .subtract(3, `${groupingMethod}s`)
          .format('YYYY-MM-DD');

    return { groupingMethod, endDate, startDate };
  };

  const [loading, setLoading] = useState(true);
  const [talent, setTalent] = useState({});
  const [timePeriod, setTimePeriod] = useState({ ...getStartingValues() });

  // used to set url parameters whenever the state time period changes
  useEffect(() => {
    const { startDate, endDate, groupingMethod } = timePeriod;

    startDate && setUrlParam('startDate', startDate);
    endDate && setUrlParam('endDate', endDate);
    groupingMethod && setUrlParam('groupingMethod', groupingMethod);
  }, [timePeriod]);

  // used to fetch talent information whenever the talent id changes
  useEffect(() => {
    getTalentReportModalUser(activeTalentId)
      .then(res => setTalent(res.user))
      .finally(() => setLoading(false));
  }, [activeTalentId]);

  const updateExpectedScore = async User_id => {
    const updatedExpectedScore = await props.updateExpectedScore(User_id);
    updatedExpectedScore && setTalent({ ...talent, expectedScore: updatedExpectedScore });
  };

  const updateSupportLevel = async User_id => {
    const updatedSupportLevel = await props.updateSupportLevel(User_id);
    updatedSupportLevel && setTalent({ ...talent, supportLevel: updatedSupportLevel });
  };

  const updateUserAddress = async updates => {
    const updatedTalent = await props.updateUserAddress({ User_id: activeTalentId, updates });
    updatedTalent && setTalent(updatedTalent);
  };

  const updateComment = async comment => {
    const allNotes = await props.updateComment(comment);
    const selectedTalentNotes = allNotes.filter(note => note.User_id === activeTalentId);
    allNotes && setTalent({ ...talent, notes: selectedTalentNotes });
  };

  const createComment = async (User_id, type) => {
    const allNotes = await props.createComment(User_id, type);
    const selectedTalentNotes = allNotes.filter(note => note.User_id === activeTalentId);
    allNotes && setTalent({ ...talent, notes: selectedTalentNotes });
  };

  const updateUser = async updates => {
    const updatedUser = await props.updateUser({ User_id: talent.id, updates });
    updatedUser && setTalent(updatedUser);
  };

  return (
    <Modal close={close} container_class='concierge-modal-outer'>
      {loading ? (
        <Loader />
      ) : (
        <div className='concierge-modal-inner'>
          <div className='sidebar-container'>
            <ConciergeModalSidebar
              accountManagers={accountManagers}
              talent={talent}
              setTimePeriod={setTimePeriod}
              updateUser={updateUser}
              updateUserAddress={updateUserAddress}
              timePeriod={timePeriod}
              updateComment={updateComment}
              createComment={createComment}
            />
          </div>
          <div className='main-container'>
            <ConciergeModalMainContainer
              talent={talent}
              timePeriod={timePeriod}
              updateExpectedScore={updateExpectedScore}
              updateSupportLevel={updateSupportLevel}
            />
          </div>
        </div>
      )}
    </Modal>
  );
};

ConciergeModal.propTypes = {
  close: PropTypes.func.isRequired,
  activeTalentId: PropTypes.number,
  accountManagers: PropTypes.array.isRequired,
  updateUser: PropTypes.func.isRequired,
  updateUserAddress: PropTypes.func.isRequired,
  updateComment: PropTypes.func.isRequired,
  createComment: PropTypes.func.isRequired,
  updateSupportLevel: PropTypes.func.isRequired,
  updateExpectedScore: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { ui } = state;
  return { ui };
};

export default connect(mapStateToProps, {
  setActiveTalentId
})(ConciergeModal);
