import { useEffect } from 'react';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';

import { syncGreenhouseJobs, getAllJobOpenings } from '../../api/greenhouse';
import { getSlackUserIds } from '../../api/admin';

const GreenhouseData = props => {
  const { setIsLoadingJobOpenings, isSyncingWithGreenhouse, setIsSyncingWithGreenhouse, setJobOpenings, setSlackUsers } = props;

  useEffect(() => {
    getAllJobOpenings()
      .then(response => setJobOpenings(response))
      .catch(() => cogoToast.error('Failed to fetch job openings'))
      .finally(() => setIsLoadingJobOpenings(false));

    getSlackUserIds()
      .then(response => setSlackUsers(response.slackUsers))
      .catch(() => cogoToast.error('Failed to fetch slack user ids'));
  }, []);

  useEffect(() => {
    if (!isSyncingWithGreenhouse) return;

    syncGreenhouseJobs()
      .then(response => {
        cogoToast.success('Greenhouse jobs synced successfully');
        setJobOpenings(response.jobOpenings);
      })
      .catch(() => cogoToast.error('Failed to sync greenhouse jobs'))
      .finally(() => setIsSyncingWithGreenhouse(false));

    return () => setIsLoadingJobOpenings(false);
  }, [isSyncingWithGreenhouse]);

  return null;
};

GreenhouseData.propTypes = {
  setIsLoadingJobOpenings: PropTypes.func.isRequired,
  isSyncingWithGreenhouse: PropTypes.bool.isRequired,
  setIsSyncingWithGreenhouse: PropTypes.func.isRequired,
  setJobOpenings: PropTypes.func.isRequired,
  setSlackUsers: PropTypes.func.isRequired
};

export default GreenhouseData;
