import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './ConciergeSummary.scss';
import { conciergeColors, scoreIntervals } from '../../helpers/concierge_helpers';

const ConciergeSummary = props => {
  const { allTalent } = props;

  const [data, setData] = useState({
    total_support_points: 0,
    total_delta: 0,
    total_notifications: 0,
    scored_talent_count: allTalent.length,
    health: [
      { count: 0, className: 'health poor' },
      { count: 0, className: 'health below-average' },
      { count: 0, className: 'health average' },
      { count: 0, className: 'health above-average' },
      { count: 0, className: 'health great' }
    ]
  });

  useEffect(() => {
    /**
     * health points are calculated with this idea:
     * each talent has a score on our platform, our managers will
     * assign an expectedScore estimating how many points the talent should have.
     *
     * Therefore the health of the talent is expectedScore - score. This number will
     * vary witih time but the general idea is that if a talent with a high expected score
     * and a result of -15, we need to re-engage them or re-evaluate how much time and effort
     * we are putting into that creator
     *
     * great health: +10 expected vs actual
     * poor health: -10 expected vs actual
     *
     * support points are calculated based on supportLevel of talent also set by a manager
     */
    const calculate_all_talent_health = () => {
      const temp_data = {
        total_support_points: 0,
        total_delta: 0,
        total_notifications: 0,
        scored_talent_count: 0,
        health: [0, 0, 0, 0, 0]
      };

      for (const talent of allTalent) {
        const { score, expectedScore, supportLevel, outstanding_gifting_requests, outstanding_collab_proposals, unread_chat_messages } = talent;
        if (!supportLevel > 0) continue;
        temp_data.scored_talent_count++;
        const delta = (parseInt(expectedScore) || 0) - (score || 0);

        temp_data.total_notifications += +outstanding_gifting_requests + +outstanding_collab_proposals + +unread_chat_messages;
        temp_data.total_support_points += parseInt(supportLevel) || 0;
        temp_data.total_delta -= delta;
        if (delta >= scoreIntervals[3]) temp_data.health[0]++;
        else if (delta >= scoreIntervals[2]) temp_data.health[1]++;
        else if (delta <= scoreIntervals[0]) temp_data.health[4]++;
        else if (delta <= scoreIntervals[1]) temp_data.health[3]++;
        else temp_data.health[2]++;
      }

      setData(temp_data);
    };

    calculate_all_talent_health();
  }, [allTalent]);

  return (
    <div className='concierge-summary-outer'>
      <div className='header'>Summary</div>
      <div className='summary'>
        <div className='summary-section'>
          <div className='summary-section-header'>Health</div>
          <div className='summary-section-body'>
            <div className='health fit'>
              {data.total_delta > 0 && '+'}
              {data.total_delta.toFixed()}
            </div>
          </div>
        </div>

        <div className='summary-section'>
          <div className='summary-section-header'>Support</div>
          <div className='summary-section-body fit'>
            <div className='health fit'>{data.total_support_points.toFixed()}</div>
          </div>
        </div>

        <div className='summary-section'>
          <div className='summary-section-header'>Notifications</div>
          <div className='summary-section-body fit'>
            <div className='health fit'>{data.total_notifications}</div>
          </div>
        </div>

        <div className='summary-breakdown'>
          <div className='summary-section-header'>Health Breakdown</div>
          <div className='summary-section-body health-container'>
            {data.health.map((health, idx) => {
              const length = data.scored_talent_count;

              if (health) {
                return (
                  <div
                    key={`health_${idx}_${health}`}
                    className={'health'}
                    style={{ width: `${((health / length) * 100).toFixed(1)}%`, backgroundColor: `${conciergeColors[idx].base}88` }}
                  >
                    {((health / length) * 100).toFixed(1)}%
                  </div>
                );
              }

              return null;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

ConciergeSummary.propTypes = {
  allTalent: PropTypes.array
};

export default ConciergeSummary;
