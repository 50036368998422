import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Mousetrap from 'mousetrap';
import commaNumber from 'comma-number';
import _ from 'lodash';
import moment from 'moment';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faBolt } from '@fortawesome/pro-light-svg-icons';
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';

import './PendingPayouts.scss';

class PendingPayouts extends Component {
  static propTypes = {
    accounts: PropTypes.array.isRequired,

    // Payouts
    payouts: PropTypes.array.isRequired,
    selectedPayouts: PropTypes.array.isRequired,
    setSelectedPayouts: PropTypes.func.isRequired,

    // Consult Results
    consultResults: PropTypes.array.isRequired,
    selectedConsultResults: PropTypes.array.isRequired,
    setSelectedConsultResults: PropTypes.func.isRequired,

    // Contracts
    contracts: PropTypes.array.isRequired,
    selectedContracts: PropTypes.array.isRequired,
    setSelectedContracts: PropTypes.func.isRequired
  };

  state = {
    selectedName: null
  };

  componentDidMount() {
    Mousetrap.bind('q', () => this.setState({ inQuickRenderMode: !this.state.inQuickRenderMode }));
  }

  componentWillUnmount() {
    Mousetrap.unbind('q');
  }

  isPayoutSelected = payout => !!_.find(this.props.selectedPayouts, p => p.id === payout.id);
  selectPayout = payout => {
    const isSelected = this.isPayoutSelected(payout);
    this.props.setSelectedPayouts(
      isSelected ? _.filter(this.props.selectedPayouts, p => p.id !== payout.id) : [...this.props.selectedPayouts, payout]
    );
  };
  isConsultResultSelected = result => !!_.find(this.props.selectedConsultResults, r => r.id === result.id);
  selectConsultResult = result => {
    const isSelected = this.isConsultResultSelected(result);
    this.props.setSelectedConsultResults(
      isSelected ? _.filter(this.props.selectedConsultResults, r => r.id !== result.id) : [...this.props.selectedConsultResults, result]
    );
  };

  isContractSelected = result => !!_.find(this.props.selectedContracts, r => r.id === result.id);
  selectContract = result => {
    const isSelected = this.isContractSelected(result);
    this.props.setSelectedContracts(
      isSelected ? _.filter(this.props.selectedContracts, r => r.id !== result.id) : [...this.props.selectedContracts, result]
    );
  };

  selectName = name => {
    const isSelected = this.state.selectedName === name;
    this.setState({ selectedName: isSelected ? null : name });
    this.props.setSelectedPayouts([]);
    this.props.setSelectedConsultResults([]);
    this.props.setSelectedContracts([]);
  };

  autoSelectAll = (name, data) => {
    const payouts = data.payouts || [];
    const consultResults = data.consultResults || [];
    const contracts = data.contracts || [];
    const lockedPayouts = _.filter(payouts, payout => payout.isLocked);
    const lockedConsultResults = _.filter(consultResults, result => result.isLocked);
    const lockedContracts = _.filter(contracts, result => result.isLocked);
    this.setState({ selectedName: name });
    setTimeout(
      () => {
        this.props.setSelectedPayouts(lockedPayouts);
        this.props.setSelectedConsultResults(lockedConsultResults);
        this.props.setSelectedContracts(lockedContracts);
      },
      lockedPayouts.length > 200 ? 800 : 400
    );
  };

  render() {
    const { payouts, consultResults, contracts, accounts } = this.props;
    const { selectedName, inQuickRenderMode } = this.state;
    let users = [];
    let totalPending = 0;
    payouts.forEach(payout => {
      users[payout.name]
        ? users[payout.name].payouts.push(payout)
        : (users[payout.name] = {
            payouts: [payout],
            consultResults: [],
            contracts: []
          });
      totalPending = payout.isLocked ? totalPending + payout.amountEarned : totalPending;
    });
    consultResults.forEach(result => {
      users[result.name]
        ? users[result.name].consultResults.push(result)
        : (users[result.name] = {
            consultResults: [result],
            contracts: []
          });

      totalPending = result.isLocked ? totalPending + result.amountEarned : totalPending;
    });

    contracts.forEach(result => {
      users[result.name]
        ? users[result.name].contracts.push(result)
        : (users[result.name] = {
            contracts: [result]
          });

      totalPending = result.isLocked ? totalPending + result.amountEarned : totalPending;
    });
    const names = _.orderBy(
      _.keys(users),
      [
        name =>
          _.sum([
            ..._.map(users[name].payouts, payout => (payout.isLocked ? payout.amountEarned : 0)),
            ..._.map(users[name].consultResults, result => (result.isLocked ? result.amountEarned : 0)),
            ..._.map(users[name].contracts, result => (result.isLocked ? result.amountEarned : 0))
          ]),
        'name'
      ],
      ['desc']
    );
    return (
      <div className='pending-payouts-outer-container'>
        <div className='payouts-header-container'>
          <h1>Pending Payouts</h1>
          <div className='stats'>
            <b>${commaNumber(totalPending.toFixed(0))}</b> Locked
          </div>
        </div>

        <div className='pending-payout-accordion accordion'>
          {_.map(names, name => {
            const contracts = users[name].contracts || [];
            const payouts = users[name].payouts || [];
            const consultResults = users[name].consultResults || [];
            const lockedPayouts = _.filter(payouts, payout => payout.isLocked);
            const lockedConsultResults = _.filter(consultResults, result => result.isLocked);
            const lockedContracts = _.filter(contracts, result => result.isLocked);
            const stripeAccountId = _.get(payouts, '0.stripeAccountId');
            const stripeOnboardingComplete = _.get(payouts, '0.stripeOnboardingComplete');
            const hasStripeConfigured = stripeAccountId && !!stripeOnboardingComplete;
            const isSelected = selectedName === name;
            const totalEarned =
              _.sum(_.map(payouts, 'commission_amount')) + _.sum(_.map(consultResults, 'amountEarned')) + _.sum(_.map(contracts, 'amountEarned'));
            const totalLocked =
              _.sum(_.map(lockedPayouts, payout => payout.commission_amount)) +
              _.sum(_.map(lockedConsultResults, result => result.amountEarned)) +
              _.sum(_.map(lockedContracts, result => result.amountEarned));
            const totalOwed =
              _.sum(_.map(payouts, payout => (payout.isLocked ? payout.amountEarned : 0))) +
              _.sum(_.map(consultResults, result => (result.isLocked ? result.amountEarned : 0))) +
              _.sum(_.map(contracts, result => (result.isLocked ? result.amountEarned : 0)));

            const account = _.find(
              accounts,
              account =>
                account.User_id === _.get(payouts, '0.User_id') ||
                account.User_id === _.get(consultResults, '0.User_id') ||
                account.User_id === _.get(contracts, '0.User_id')
            );
            return (
              <div key={name} className='section'>
                <div
                  className={classnames('header', { selected: isSelected, faded: selectedName && !isSelected })}
                  onClick={() => this.selectName(name)}
                >
                  <div>
                    <div>
                      {name} -{' '}
                      <span className='small'>
                        {totalLocked ? `$${totalLocked.toFixed(2)}` : '-'} of ${totalEarned.toFixed(2)} locked
                        <b>{totalOwed ? ` - $${totalOwed.toFixed(2)} owed` : ''}</b>
                      </span>
                    </div>
                  </div>
                  <div className='icons'>
                    {!account && !hasStripeConfigured && <FontAwesomeIcon icon={faExclamationTriangle}></FontAwesomeIcon>}
                    {!account && hasStripeConfigured && stripeOnboardingComplete && (
                      <a
                        href={`https://dashboard.stripe.com/${window.__IS_PROD__ ? '' : 'test/'}connect/accounts/${stripeAccountId}`}
                        target='_blank'
                        rel='noopener noreferrer'
                        className='stripe-icon'
                      >
                        STRIPE
                      </a>
                    )}

                    <FontAwesomeIcon onClick={() => this.autoSelectAll(name, users[name])} icon={faBolt}></FontAwesomeIcon>
                    <FontAwesomeIcon icon={isSelected ? faChevronUp : faChevronDown}></FontAwesomeIcon>
                  </div>
                </div>
                {isSelected && (
                  <div className='body'>
                    {!account && (
                      <div className='row warning'>
                        <div className='cell'>This user does not have an associated payment account.</div>
                      </div>
                    )}
                    {!!contracts.length && (
                      <>
                        <div className='row header'>
                          <div onClick={() => this.props.setSelectedContracts(lockedContracts)} className='cell'>
                            [] Select
                          </div>
                          <div className='cell'>Created At</div>
                          <div className='cell md'>Brand</div>
                          <div className='cell md'>Contract Title</div>
                          <div className='cell md'>Contract Status</div>
                          <div className='cell md'>Invoice Status</div>
                          <div className='cell'>User Pay</div>
                          <div className='cell'>SMS Pay</div>
                          <div className='cell'>Link</div>
                        </div>
                        {_.map(_.orderBy(contracts, 'createdAt'), (result, idx) => {
                          const isSelected = this.isContractSelected(result);
                          const { id, createdAt, title, brandName, user_amount, sms_amount, isLocked, invoiceStatus, status } = result;
                          return (
                            <div key={id} className={classnames('row', { disabled: !isLocked })}>
                              <div className={classnames('cell toggle', { selected: isSelected })} onClick={() => this.selectContract(result)}>
                                {isSelected ? '[✓]' : '[ ]'}
                              </div>
                              <div className='cell'>{moment(createdAt).format('MMMM Do')}</div>
                              <div className='cell md'>{brandName}</div>
                              <div className='cell md'>{title}</div>
                              <div className='cell md'>{status}</div>
                              <div className='cell md'>{invoiceStatus}</div>
                              <div className='cell'>${user_amount.toFixed(2)}</div>
                              <div className='cell'>${sms_amount.toFixed(2)}</div>
                              <a href={`https://shopmy.us/collaboration/${id}`} target='_blank' rel='noopener noreferrer' className='cell'>
                                link
                              </a>
                            </div>
                          );
                        })}
                      </>
                    )}
                    {!!consultResults.length && (
                      <>
                        <div className='row header'>
                          <div onClick={() => this.props.setSelectedConsultResults(lockedConsultResults)} className='cell'>
                            [] Select
                          </div>
                          <div className='cell'>Created At</div>
                          <div className='cell'>Completed At</div>
                          <div className='cell md'>Consult Title</div>
                          <div className='cell'>User Pay</div>
                          <div className='cell'>SMS Pay</div>
                          <div className='cell'>Client</div>
                          <div className='cell'>Link</div>
                        </div>
                        {_.map(_.orderBy(consultResults, 'createdAt'), (result, idx) => {
                          const isSelected = this.isConsultResultSelected(result);
                          const { title, id, stub, clientName, paymentAmount, completedAt, createdAt, isLocked, amountEarned } = result;
                          return (
                            <div key={id} className={classnames('row', { disabled: !isLocked })}>
                              <div className={classnames('cell toggle', { selected: isSelected })} onClick={() => this.selectConsultResult(result)}>
                                {isSelected ? '[✓]' : '[ ]'}
                              </div>
                              <div className='cell'>{moment(createdAt).format('MMMM Do')}</div>
                              <div className='cell'>{completedAt ? moment(completedAt).format('MMMM Do') : '-'}</div>
                              <div className='cell md'>{title}</div>
                              <div className='cell'>${paymentAmount && amountEarned.toFixed(2)}</div>
                              <div className='cell'>${paymentAmount && (paymentAmount - amountEarned).toFixed(2)}</div>
                              <div className='cell'>{clientName}</div>
                              <a href={`https://shopmy.us/consults/results/${stub}`} target='_blank' rel='noopener noreferrer' className='cell'>
                                link
                              </a>
                            </div>
                          );
                        })}
                      </>
                    )}
                    {!!payouts.length && (
                      <>
                        <div className='row header'>
                          <div onClick={() => this.props.setSelectedPayouts(lockedPayouts)} className='cell'>
                            [] Select
                          </div>
                          <div className='cell'>Date</div>
                          {!inQuickRenderMode && <div className='cell'>Order</div>}
                          {!inQuickRenderMode && <div className='cell'>Payout</div>}
                          <div className='cell'>User Pay</div>
                          {!inQuickRenderMode && <div className='cell'>Merchant</div>}
                          {!inQuickRenderMode && <div className='cell'>Status</div>}
                          {!inQuickRenderMode && <div className='cell'>Payment Status</div>}
                          {!inQuickRenderMode && <div className='cell'>Link</div>}
                          <div className='cell'>Source</div>
                        </div>
                        {_.map(_.orderBy(payouts, 'transaction_date'), (payout, idx) => {
                          const isSelected = this.isPayoutSelected(payout);
                          const {
                            order_amount,
                            commission_amount,
                            amountEarned,
                            merchant,
                            status,
                            payment_status,
                            transaction_date,
                            isLocked,
                            source
                          } = payout;
                          return (
                            <div key={idx} className={classnames('row', { disabled: !isLocked })}>
                              <div className={classnames('cell toggle', { selected: isSelected })} onClick={() => this.selectPayout(payout)}>
                                {isSelected ? '[✓]' : '[ ]'}
                              </div>
                              <div className='cell'>{moment(transaction_date).format('MMM Do')}</div>
                              {!inQuickRenderMode && <div className='cell'>${order_amount.toFixed(2)}</div>}
                              {!inQuickRenderMode && <div className='cell'>${commission_amount.toFixed(2)}</div>}
                              <div className='cell'>${amountEarned.toFixed(2)}</div>
                              {!inQuickRenderMode && <div className='cell'>{merchant}</div>}
                              {!inQuickRenderMode && <div className='cell'>{status}</div>}
                              {!inQuickRenderMode && <div className='cell'>{payment_status}</div>}
                              <div className='cell'>{source}</div>
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default PendingPayouts;
