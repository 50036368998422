import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Mousetrap from 'mousetrap';

// import './Modal.scss';
import styles from './Modal.module.scss';

/**
 *
 * @param {Object} props
 * @param {string} props.title - the title of the modal if you want to supply one
 * @param {() => void} props.close - function that can be called to close the modal
 * @param {JSX.Element} props.children - all the content that will go inside of the modal
 * @param {string} props.container_class - css class that defines the modal container in case you want to define your own
 */
const Modal = props => {
  const { children, close: close_modal, container_class, title } = props;
  useEffect(() => {
    Mousetrap.bind('esc', close_modal);
    return () => Mousetrap.unbind('esc');
  }, [close_modal]);

  const stopProp = e => {
    e.stopPropagation();
  };

  return (
    <div onClick={close_modal} className={styles.modal_outer_container}>
      <div onClick={stopProp} className={container_class ? container_class : styles.modal_inner_container}>
        {container_class ? (
          <>{children}</>
        ) : (
          <div className={styles.modal_padded_content}>
            {title && <div className={styles.title}>{title}</div>}
            {children}
          </div>
        )}
      </div>
    </div>
  );
};

Modal.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  close: PropTypes.func.isRequired,
  container_class: PropTypes.string
};

/**
 * @param {Object} props
 * @param {JSX.Element} props.children
 */
export const ModalSection = ({ children }) => {
  return <div className={styles.modal_generic_section}>{children}</div>;
};

/**
 *
 * @param {Object} props
 * @param {string} props.children
 * @param {() => void} props.oc
 */
export const ModalButton = ({ children, oc }) => {
  return (
    <div className={styles.modal_generic_button} onClick={oc}>
      {children}
    </div>
  );
};

/**
 * functional component that can be imported and used as a normal input.
 * this will have taken care of the css already though
 *
 * @param {Object} props
 * @param {string=} props.name - name given to input for checkboxes
 * @param {string=} props.title - the text that will be display in bold above the input
 * @param {string=} props.subtitle - extra text that will be small under the title (for descriptions)
 * @param {React.HTMLInputTypeAttribute} props.type - the type of input you want to create
 * @param {React.ChangeEventHandler<HTMLInputElement>} props.oc - function handler for when input changes
 * @param {string=} props.ph - placeholder text that you want to show up when input is empty
 * @param {string=} props.default - default text that you want to show up on creation
 * @param {string=} props.value - value of the input for a controlled component
 * @param {boolean=} props.checked - for a checkbox to show whether or not it is checked
 */
export const ModalTextIput = props => {
  return (
    <div className={styles.modal_generic_input}>
      {props.title && (
        <div className={styles.text}>
          <div className={styles.input_title}>{props.title}</div>
          <div className={styles.subtitle}>{props.subtitle}</div>
        </div>
      )}
      <input
        defaultValue={props.default}
        type={props.type}
        className={styles.input}
        onChange={props.oc}
        value={props.value}
        disabled={!!props.disabled}
      />
      {/* <input className={styles.input} type={'text'} onChange={props.oc} placeholder={props.ph} defaultValue={props.default} /> */}
    </div>
  );
};

/**
 * functional component that can be imported and used as a normal input.
 * this will have taken care of the css already though
 *
 * @param {Object} props
 * @param {string=} props.name - name given to input for checkboxes
 * @param {string=} props.title - the text that will be display in bold above the input
 * @param {string=} props.subtitle - extra text that will be small under the title (for descriptions)
 * @param {(e: React.ChangeEventHandler<HTMLInputElement>) => void} props.oc - function handler for when input changes
 * @param {string=} props.default - default text that you want to show up on creation
 * @param {boolean=} props.checked - for a checkbox to show whether or not it is checked
 */
export const ModalCheckboxInput = props => {
  return (
    <div className={styles.modal_generic_input}>
      {props.title && (
        <div className={styles.text}>
          <div className={styles.input_title}>{props.title}</div>
          <div className={styles.subtitle}>{props.subtitle}</div>
        </div>
      )}
      <input
        name={props.name}
        className={styles.input}
        type={'checkbox'}
        onChange={props.oc}
        defaultChecked={props.default}
        checked={props.checked}
      />
    </div>
  );
};

export default Modal;
