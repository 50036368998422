import React, { useState } from 'react';
import BarGraph from '../GraphTypes/BarGraph';
import Loader from '../../General/Loader';
import { Table } from '../GraphTypes/Table';
import cn from 'classnames';
import { getBrandReportHeaderLabel } from '../../../helpers/brand_helpers';
import PropTypes from 'prop-types';
import { faDownload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BrandClicks = props => {
  const { title, brandClicks, downloading } = props;
  const success = !!brandClicks?.length;

  const [tab, setTab] = useState('table');
  const download_table = async () => await props.download({ rows: make_table_rows(), fileName: 'brand_clicks' });
  const download_graph = async () => await props.download({ graph: title });

  const make_table_rows = () => {
    let total_running_total = 0;
    const total_clicks = {
      key: 'clicks_total',
      label: 'Total Clicks',
      description: '(clicks to retail site + DTC site in given month)',
      values: brandClicks.map(click => {
        const { quarter, week, month, total_clicks } = click;
        total_running_total += parseInt(total_clicks || '0');
        return { label: getBrandReportHeaderLabel({ quarter, week, month }), value: total_clicks };
      })
    };
    total_clicks.values.push({ label: 'Total', value: total_running_total });

    let dtc_running_total = 0;
    const dtc_clicks = {
      key: 'clicks_dtc',
      label: 'Total Clicks DTC',
      description: `(clicks to DTC site)`,
      values: brandClicks.map(click => {
        const { quarter, week, month, total_clicks_dtc } = click;
        dtc_running_total += parseInt(total_clicks_dtc || '0');
        return { label: getBrandReportHeaderLabel({ quarter, week, month }), value: total_clicks_dtc };
      })
    };
    dtc_clicks.values.push({ label: 'Total', value: dtc_running_total });

    let retailer_running_total = 0;
    const retailers_clicks = {
      key: 'clicks_retailers_total',
      label: 'Total Clicks to Retailers',
      description: `(clicks to retailers of the brand)`,
      values: brandClicks.map(click => {
        const { quarter, week, month, total_clicks, total_clicks_dtc } = click;
        retailer_running_total += parseInt(total_clicks - total_clicks_dtc || '0');
        return { label: getBrandReportHeaderLabel({ quarter, week, month }), value: total_clicks - total_clicks_dtc };
      })
    };
    retailers_clicks.values.push({ label: 'Total', value: retailer_running_total });

    const difference = {
      key: 'clicks_diff_total',
      label: 'Share of Clicks going Direct',
      description: '(total clicks DTC / total clicks overall)',
      values: brandClicks.map(click => {
        const { week, month, quarter, total_clicks, total_clicks_dtc } = click;
        return {
          label: getBrandReportHeaderLabel({ quarter, week, month }),
          value: (parseInt(total_clicks_dtc) / parseInt(total_clicks)) * 100,
          isPercent: true
        };
      })
    };
    difference.values.push({ label: 'Total', value: `${((parseInt(dtc_running_total) / parseInt(total_running_total)) * 100).toFixed()}%` });

    let dtc_is_same_as_total = true;
    for (let i = 0; i < total_clicks.values.length; ++i) {
      const total = total_clicks.values[i].value,
        dtc = dtc_clicks.values[i].value;
      dtc_is_same_as_total = dtc_is_same_as_total && total === dtc;
    }

    return dtc_is_same_as_total ? [total_clicks] : [total_clicks, dtc_clicks, retailers_clicks, difference];
  };

  return (
    <div className='brand-card' id={title}>
      <div className='padding'>
        {!brandClicks ? (
          <Loader />
        ) : (
          <>
            {success && (
              <>
                <div className='header'>
                  <div className='description'>
                    <div className='title'>{title}</div>
                    <div className='sub-title'>
                      Clicks are defined as the clicks on Pins for a brand grouped by a time period. The clicks are also split by DTC and Non DTC pins
                    </div>
                  </div>
                  {!downloading && (
                    <div className='brand-card-controls'>
                      <div className='radio-btns'>
                        <div className={cn('radio-btn', { active: tab === 'graph' })} onClick={() => setTab('graph')}>
                          Graph
                        </div>
                        <div className={cn('radio-btn', { active: tab === 'table' })} onClick={() => setTab('table')}>
                          Table
                        </div>
                      </div>
                      <div className='rounded-button' onClick={() => (tab === 'table' ? download_table() : download_graph())}>
                        {downloading ? (
                          <Loader />
                        ) : (
                          <>
                            <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                            Download
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>

                {tab === 'table' && (
                  <Table
                    title='Brand Clicks'
                    data={make_table_rows()}
                    // data={[ { } ]}
                  />
                )}
                {tab === 'graph' && (
                  <BarGraph
                    title='Brand Clicks'
                    data={brandClicks.map(click => {
                      const { week, month, quarter, year, total_clicks } = click;

                      const data = {
                        key: `${week || month || quarter}${year}clicks`,
                        value: total_clicks,
                        label: getBrandReportHeaderLabel({ month, quarter })
                      };
                      return data;
                    })}
                  />
                )}
              </>
            )}

            {!success && (
              <>
                <div className='error-centered'>
                  <div className='title'>Error Fetching {title}</div>
                  <div className='sub-title'>Could not fetch results</div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

BrandClicks.propTypes = {
  title: PropTypes.string.isRequired,
  brandClicks: PropTypes.array,

  download: PropTypes.func.isRequired,
  downloading: PropTypes.bool.isRequired
};

const brandClickConfig = {
  title: 'Clicks'
};

export { BrandClicks, brandClickConfig };
