import React, { Component } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import moment from 'moment';
import _ from 'lodash';
import { Bar } from 'react-chartjs-2';
import './Analytics.scss';

import { getAnalytics } from '../../api/analytics';

import AnalyticsCollections from './elements/AnalyticsCollections';
import AnalyticsPayouts from './elements/AnalyticsPayouts';
import AnalyticsPins from './elements/AnalyticsPins';
import AnalyticsUsers from './elements/AnalyticsUsers';

class Analytics extends Component {
  state = {
    analytics: {},
    selectedUserId: null,
    curPanel: 'charts', // charts, payouts, collections, pins, users
    curTimeframe: 'days', // days, weeks, months
    curTimeWindow: 'week', // day, week, month, custom
    startTime: null,
    endTime: null,
    isFetching: false
  };

  componentDidMount() {
    this.syncAnalytics();
  }

  syncAnalytics = async () => {
    const { selectedUserId, curTimeWindow } = this.state;
    const params = {};

    if (selectedUserId) {
      params.User_id = selectedUserId;
    }
    switch (curTimeWindow) {
      case 'day':
        params.startDate = moment()
          .subtract(1, 'day')
          .toISOString();
        break;
      case 'week':
        params.startDate = moment()
          .subtract(6, 'days')
          .toISOString();
        break;
      case 'month':
        params.startDate = moment()
          .subtract(30, 'days')
          .toISOString();
        break;
      case 'custom':
      case 'all':
      default:
        break;
    }
    const noParams = _.isEmpty(params);
    this.setState({ isFetching: true });
    const resp = noParams && this.blankResp ? this.blankResp : await getAnalytics(params);
    if (noParams) {
      this.blankResp = resp; // Cache this for perf reasons
    }
    console.log(resp.queryDuration);
    this.setState({ analytics: resp, isFetching: false });
  };

  getFilteredUsers = () => {
    const { analytics } = this.state;
    const { users } = analytics;
    return users;
  };

  getFilteredCollections = () => {
    const { analytics } = this.state;
    const { collections } = analytics || {};
    const filteredUsers = this.getFilteredUsers();
    const userIds = _.map(filteredUsers, 'id');
    return _.filter(collections, collection => userIds.includes(collection.User_id));
  };

  getFilteredPayouts = () => {
    const { analytics } = this.state;
    const { payouts } = analytics || {};
    const filteredUsers = this.getFilteredUsers();
    const userIds = _.map(filteredUsers, 'id');
    return _.filter(payouts, payout => userIds.includes(payout.User_id));
  };

  getFilteredPins = () => {
    const { analytics } = this.state;
    const { pins } = analytics || {};
    const filteredUsers = this.getFilteredUsers();
    const userIds = _.map(filteredUsers, 'id');
    return _.filter(pins, pin => userIds.includes(pin.User_id));
  };

  updateTimeWindow = newWindow => {
    this.setState({ curTimeWindow: newWindow }, this.syncAnalytics);
  };

  getPayoutDataArray = () => {
    const { analytics, curTimeWindow } = this.state;
    const { days, weeks, months } = analytics;
    const rows = {
      days,
      weeks,
      months
    }[this.state.curTimeframe];
    const reverseRows = rows ? rows.slice().reverse() : [];
    return curTimeWindow === 'all'
      ? reverseRows.slice(1) // drop first since it might be incomplete
      : reverseRows;
  };

  getClickData = () => {
    const rows = this.getPayoutDataArray();
    const highestCount = _.max(_.map(rows, 'countData.count'));
    return {
      labels: _.map(rows, 'label'),
      datasets: [
        {
          label: 'Product Clicks',
          data: _.map(rows, 'countData.count'),
          backgroundColor: _.map(rows, row => `rgba(99, 180, 132, ${(_.get(row, 'countData.count', 0) / highestCount) ** 0.5 || 1})`),
          borderWidth: 1
        }
      ]
    };
  };

  getPayoutData = () => {
    const rows = this.getPayoutDataArray();
    const highestOrderAmount = _.max(_.map(rows, 'payoutData.orderAmount'));
    const highestCommissionAmount = _.max(_.map(rows, 'payoutData.commissionAmount'));
    return {
      labels: _.map(rows, 'label'),
      datasets: [
        {
          label: 'Order Volume',
          data: _.map(rows, 'payoutData.orderAmount'),
          backgroundColor: _.map(rows, row => `rgba(99, 180, 132, ${(_.get(row, 'payoutData.orderAmount', 0) / highestOrderAmount) ** 0.5 || 1})`),
          borderWidth: 1
        },
        {
          label: 'Commissions',
          data: _.map(rows, 'payoutData.commissionAmount'),
          backgroundColor: _.map(
            rows,
            row => `rgba(99, 180, 255, ${(_.get(row, 'payoutData.commissionAmount', 0) / highestCommissionAmount) ** 0.5 || 1})`
          ),
          borderWidth: 1
        }
      ],
      options: {
        scales: {
          xAxes: [{ stacked: true }],
          yAxes: [{ stacked: true }]
        }
      }
    };
  };

  isChartsPanel = () => this.state.curPanel === 'charts';
  isCollectionsPanel = () => this.state.curPanel === 'collections';
  isPinsPanel = () => this.state.curPanel === 'pins';
  isPayoutsPanel = () => this.state.curPanel === 'payouts';
  isUsersPanel = () => this.state.curPanel === 'users';

  downloadDataToCSV = () => {
    let rows = [];
    rows = this.isPinsPanel() ? this.getFilteredPins() : rows;
    rows = this.isPayoutsPanel() ? this.getFilteredPayouts() : rows;
    rows = this.isUsersPanel() ? this.getFilteredUsers() : rows;
    rows = this.isCollectionsPanel() ? this.getFilteredCollections() : rows;
    let csvContent = 'data:text/csv;charset=utf-8,' + _.keys(rows[0]) + '\n' + rows.map(e => _.values(e).join(',')).join('\n');
    var encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  };

  render() {
    const { selectedUserId, isFetching } = this.state;
    const { curTimeframe, curTimeWindow, curPanel } = this.state;
    const clickData = this.getClickData();
    const payoutData = this.getPayoutData();
    const filteredUsers = this.getFilteredUsers();
    const filteredCollections = this.getFilteredCollections();
    const filteredPins = this.getFilteredPins();
    const filteredPayouts = this.getFilteredPayouts();
    const isChartsPanel = this.isChartsPanel();
    const isCollectionsPanel = this.isCollectionsPanel();
    const isPinsPanel = this.isPinsPanel();
    const isPayoutsPanel = this.isPayoutsPanel();
    const isUsersPanel = this.isUsersPanel();
    return (
      <div className='analytics-outer-container'>
        <div className='analytics-inner-container'>
          <div className='analytics-side-container'>
            <div className='section'>
              <div className='sidebar-header'>Time Window</div>
              <div className='tabs-container'>
                <div onClick={() => this.updateTimeWindow('all')} className={cn('tab', { active: curTimeWindow === 'all' })}>
                  All
                </div>
                <div onClick={() => this.updateTimeWindow('day')} className={cn('tab', { active: curTimeWindow === 'day' })}>
                  Today
                </div>
                <div onClick={() => this.updateTimeWindow('week')} className={cn('tab', { active: curTimeWindow === 'week' })}>
                  Week
                </div>
                <div onClick={() => this.updateTimeWindow('month')} className={cn('tab', { active: curTimeWindow === 'month' })}>
                  Month
                </div>
              </div>
            </div>
            <div className='section'>
              <div className='sidebar-header'>Users</div>
              <div
                onClick={() => this.setState({ selectedUserId: null }, this.syncAnalytics)}
                className={cn('user-cell', { active: !selectedUserId })}
              >
                All Users
              </div>
              {_.map(filteredUsers, user => {
                const active = selectedUserId === user.id;
                return (
                  <div
                    key={user.id}
                    onClick={() => {
                      this.setState({ selectedUserId: active ? null : user.id }, this.syncAnalytics);
                    }}
                    className={cn('user-cell', { active })}
                  >
                    {user.name}
                  </div>
                );
              })}
            </div>
          </div>
          <div className={cn('analytics-main-container', { fetching: isFetching })}>
            <div className='header-container'>
              <div className='tabs-container'>
                <div onClick={() => this.setState({ curPanel: 'charts' })} className={cn('tab', { active: curPanel === 'charts' })}>
                  Charts
                </div>
                <div onClick={() => this.setState({ curPanel: 'collections' })} className={cn('tab', { active: curPanel === 'collections' })}>
                  Collections
                </div>
                <div onClick={() => this.setState({ curPanel: 'pins' })} className={cn('tab', { active: curPanel === 'pins' })}>
                  Pins
                </div>
                <div onClick={() => this.setState({ curPanel: 'payouts' })} className={cn('tab', { active: curPanel === 'payouts' })}>
                  Payouts
                </div>
                <div onClick={() => this.setState({ curPanel: 'users' })} className={cn('tab', { active: curPanel === 'users' })}>
                  Users
                </div>
              </div>
              {isChartsPanel ? (
                <div className='tabs-container'>
                  <div onClick={() => this.setState({ curTimeframe: 'days' })} className={cn('tab', { active: curTimeframe === 'days' })}>
                    Daily
                  </div>
                  <div onClick={() => this.setState({ curTimeframe: 'weeks' })} className={cn('tab', { active: curTimeframe === 'weeks' })}>
                    Weekly
                  </div>
                  <div onClick={() => this.setState({ curTimeframe: 'months' })} className={cn('tab', { active: curTimeframe === 'months' })}>
                    Monthly
                  </div>
                </div>
              ) : (
                <div className='download-btn' onClick={this.downloadDataToCSV}>
                  Download as CSV
                </div>
              )}
            </div>
            <div className='analytics-main-body'>
              {isCollectionsPanel && !_.isEmpty(filteredCollections) && <AnalyticsCollections collections={filteredCollections} />}
              {isChartsPanel && (
                <>
                  <h2>Product Clicks</h2>
                  <Bar data={clickData} width={1200} height={300} />
                  <h2>Payouts</h2>
                  <Bar data={payoutData} width={1200} height={300} />
                </>
              )}
              {isPinsPanel && !_.isEmpty(filteredPins) && <AnalyticsPins pins={filteredPins} />}
              {isPayoutsPanel && !_.isEmpty(filteredPayouts) && <AnalyticsPayouts payouts={filteredPayouts} />}
              {isUsersPanel && !_.isEmpty(filteredUsers) && <AnalyticsUsers users={filteredUsers} />}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  // functions go here
})(Analytics);
