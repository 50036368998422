import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import slugify from 'slugify';
import _ from 'lodash';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faEyeSlash, faChevronUp, faTag } from '@fortawesome/pro-light-svg-icons';
import { faExclamationTriangle, faStar } from '@fortawesome/pro-regular-svg-icons';

import './HierarchyView.scss';

import Tooltip from '../../General/Tooltip';

import { addCategory, addDepartment, addTag } from '../../../api/catalog';

class HierarchyView extends Component {
  static propTypes = {
    catalog: PropTypes.object.isRequired,
    syncCatalogHierarchy: PropTypes.func.isRequired,
    selectItem: PropTypes.func.isRequired,
    selectedItem: PropTypes.object
  };

  state = {
    activeDepartment: null,
    activeCategory: null,
    activeTag: null,
    newDepartmentVal: '',
    newCategoryVal: '',
    newTagVal: ''
  };

  toggleDepartment = department => {
    const turningOff = department.id === _.get(this.state, 'activeDepartment.id');
    this.setState({
      activeDepartment: turningOff ? null : department,
      activeCategory: turningOff ? null : this.state.activeCategory,
      activeTag: turningOff ? null : this.state.activeTag
    });
    this.props.selectItem(turningOff ? null : department);
  };

  toggleCategory = category => {
    const turningOff = category.id === _.get(this.state, 'activeCategory.id');
    this.setState({
      activeCategory: turningOff ? null : category,
      activeTag: turningOff ? null : this.state.activeTag
    });
    this.props.selectItem(turningOff ? null : category);
  };

  toggleTag = tag => {
    const turningOff = tag.id === _.get(this.state, 'activeTag.id');
    this.setState({
      activeTag: turningOff ? null : tag
    });
    this.props.selectItem(turningOff ? null : tag);
  };

  addNewDepartment = e => {
    const { newDepartmentVal } = this.state;
    e.preventDefault();
    addDepartment({
      name: newDepartmentVal,
      seoTitle: `Best in ${newDepartmentVal}`,
      urlStub: slugify(`Best in ${newDepartmentVal}`)
    }).then(
      resp => {
        this.props.syncCatalogHierarchy();
      },
      err => {
        console.error(err);
        cogoToast.error(JSON.stringify(err));
      }
    );
    this.setState({
      newDepartmentVal: ''
    });
  };

  addNewCategory = e => {
    const { newCategoryVal, activeDepartment } = this.state;
    e.preventDefault();
    addCategory({
      name: newCategoryVal,
      Department_id: activeDepartment.id,
      seoTitle: `Best in ${newCategoryVal}`,
      urlStub: slugify(`Best in ${newCategoryVal}`)
    }).then(
      resp => {
        this.props.syncCatalogHierarchy();
      },
      err => {
        console.error(err);
        cogoToast.error(JSON.stringify(err));
      }
    );
    this.setState({
      newCategoryVal: ''
    });
  };

  addNewTag = e => {
    const { newTagVal, activeDepartment, activeCategory } = this.state;
    e.preventDefault();
    console.log(slugify(`Best in ${activeCategory.name} ${newTagVal}`).toLowerCase());
    addTag({
      name: newTagVal,
      Department_id: activeDepartment.id,
      Category_id: activeCategory.id,
      seoTitle: `Best in ${activeCategory.name} | ${newTagVal}`,
      urlStub: slugify(`Best in ${activeCategory.name} ${newTagVal}`).toLowerCase()
    }).then(
      resp => {
        this.props.syncCatalogHierarchy();
      },
      err => {
        console.error(err);
        cogoToast.error(JSON.stringify(err));
      }
    );
    this.setState({
      newTagVal: ''
    });
  };

  isSEOComplete = item => {
    return _.get(item, 'seoDescription.length') > 0 && _.get(item, 'urlStub.length') > 0;
  };

  render() {
    const { catalog } = this.props;
    const { activeDepartment, activeCategory, activeTag, newDepartmentVal, newCategoryVal, newTagVal } = this.state;
    const { departments, categories, tags } = catalog;
    return (
      <div className='hierarchy-view-outer-container panel'>
        {departments &&
          _.orderBy(departments, 'name').map(department => {
            const { name, id, feature, isHidden, isAutoTag } = department;
            const depCats = _.orderBy(
              categories.filter(category => category?.Department_id === id),
              'name'
            );
            const active = activeDepartment && activeDepartment.id === id;
            return (
              <Fragment key={name}>
                <div onClick={() => this.toggleDepartment(department)} className={classnames('row', { active })}>
                  <div className='main'>
                    {name} {depCats.length ? `(${depCats.length})` : ''}
                  </div>
                  <div className='secondary'>
                    {!!isAutoTag && <FontAwesomeIcon icon={faTag}></FontAwesomeIcon>}
                    {!!isHidden && <FontAwesomeIcon icon={faEyeSlash}></FontAwesomeIcon>}
                    {!_.isEmpty(feature) && <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>}
                    {!this.isSEOComplete(department) && (
                      <Tooltip
                        message='SEO information is not yet complete'
                        getIconDiv={() => <FontAwesomeIcon icon={faExclamationTriangle}></FontAwesomeIcon>}
                      />
                    )}
                    <FontAwesomeIcon icon={active ? faChevronUp : faChevronDown}></FontAwesomeIcon>
                  </div>
                </div>
                {active &&
                  depCats.map(category => {
                    const { name, id, feature, isHidden, isAutoTag } = category;
                    const catTags = _.orderBy(
                      tags.filter(tag => tag.Category_id === id),
                      'name'
                    );
                    const active = activeCategory && activeCategory.id === id;
                    return (
                      <Fragment key={name}>
                        <div key={name} onClick={() => this.toggleCategory(category)} className={classnames('row level-two', { active })}>
                          <div className='main'>
                            {name} {catTags.length ? `(${catTags.length})` : ''}
                          </div>
                          <div className='secondary'>
                            {!!isAutoTag && <FontAwesomeIcon icon={faTag}></FontAwesomeIcon>}
                            {!!isHidden && <FontAwesomeIcon icon={faEyeSlash}></FontAwesomeIcon>}
                            {!_.isEmpty(feature) && <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>}
                            {!this.isSEOComplete(category) && (
                              <Tooltip
                                message='SEO information is not yet complete'
                                getIconDiv={() => <FontAwesomeIcon icon={faExclamationTriangle}></FontAwesomeIcon>}
                              />
                            )}
                            <FontAwesomeIcon icon={active ? faChevronUp : faChevronDown}></FontAwesomeIcon>
                          </div>
                        </div>
                        {active &&
                          catTags.map(tag => {
                            const { name, id, feature } = tag;
                            const active = activeTag && activeTag.id === id;
                            return (
                              <div key={name} onClick={() => this.toggleTag(tag)} className={classnames('row level-three', { active })}>
                                <div className='main'>{name}</div>
                                <div className='secondary'>
                                  {!!isHidden && <FontAwesomeIcon icon={faEyeSlash}></FontAwesomeIcon>}
                                  {!_.isEmpty(feature) && <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>}
                                  {!this.isSEOComplete(tag) && (
                                    <Tooltip
                                      message='SEO information is not yet complete'
                                      getIconDiv={() => <FontAwesomeIcon icon={faExclamationTriangle}></FontAwesomeIcon>}
                                    />
                                  )}
                                  <FontAwesomeIcon icon={active ? faChevronUp : faChevronDown}></FontAwesomeIcon>
                                </div>
                              </div>
                            );
                          })}
                        {active && (
                          <form onSubmit={this.addNewTag} className='add-element-form level-three'>
                            <input
                              placeholder={`Add New ${activeCategory.name} Tag`}
                              value={newTagVal}
                              onChange={({ target }) => this.setState({ newTagVal: target.value })}
                            />
                          </form>
                        )}
                      </Fragment>
                    );
                  })}
                {active && !activeCategory && (
                  <form onSubmit={this.addNewCategory} className='add-element-form level-two'>
                    <input
                      placeholder={`Add New ${activeDepartment.name} Category`}
                      value={newCategoryVal}
                      onChange={({ target }) => this.setState({ newCategoryVal: target.value })}
                    />
                  </form>
                )}
              </Fragment>
            );
          })}
        {!activeDepartment && (
          <form onSubmit={this.addNewDepartment} className='add-element-form level-one'>
            <input
              placeholder='Add New Department'
              value={newDepartmentVal}
              onChange={({ target }) => this.setState({ newDepartmentVal: target.value })}
            />
          </form>
        )}
      </div>
    );
  }
}

export default HierarchyView;
