import _ from 'lodash';
import * as ActionTypes from '../actions/brandActions';

export const brands = (
  state = {
    all: [],
    codes: []
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.GET_BRANDS_SUCCESS:
      return {
        ...state,
        all: _.filter(_.orderBy(action.brands, 'createdAt', 'desc'), b => !b.isArchived)
      };

    case ActionTypes.GET_REGISTRATION_CODES_SUCCESS:
      return {
        ...state,
        codes: _.filter(_.orderBy(action.codes, 'id', 'desc'), code => !!code.brandName)
      };

    case ActionTypes.APPLY_TAG_TO_BRANDS_REQUEST:
      const brandTagsBeingApplied = _.reduce(action.brands, (r, u) => ({ ...r, [u.id]: true }), {}); // For performance
      return {
        ...state,
        all: state.all.map(brand => {
          const isTagApplied = brandTagsBeingApplied[brand.id];
          return {
            ...brand,
            tags: isTagApplied ? _.uniq(_.concat(brand.tags, action.tag), 'id') : brand.tags
          };
        })
      };

    case ActionTypes.DELETE_BRAND_TAG_REQUEST:
      return {
        ...state,
        all: state.all.map(brand => {
          return {
            ...brand,
            tags: brand.id === action.brand.id ? _.filter(brand.tags, t => t.id !== action.tag.id) : brand.tags
          };
        })
      };

    default:
      return state;
  }
};
