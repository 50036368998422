import APIClient from './index';
import _ from 'lodash';

export const getRecentMessages = () => {
  return new Promise((resolve, reject) => {
    APIClient.get(`Admin/get_recent_messages`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

/** @param {('user'|'brand'|'lookbookorders'|'brandwaitlist')} accountType */
export const getAccountManagers = accountType => {
  return new Promise((resolve, reject) => {
    APIClient.get(`Admin/account_managers?account_type=${accountType}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const getSlackUserIds = () => {
  return new Promise((resolve, reject) => {
    APIClient.get(`Admin/get_slack_user_ids`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};
