import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import cogoToast from 'cogo-toast';
import './WaitlistControls.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import OutsideClickHandler from 'react-outside-click-handler';

import * as waitlistAPI from '../../api/waitlist';

const WaitlistControls = props => {
  const { curTab, setCurTab, curSearchValue, setCurSearchValue, filterOpen, setFilterOpen } = props;
  const { resetSearch, resetFilters, selectedFilter, filterOptions, changeFilter } = props;

  const createManagerCode = () => createCode(true);
  const createCode = (isManager = false) => {
    const code = window.prompt('What code would you like to create?');
    if (!code) return;
    let codeObject = { code };

    if (isManager) {
      let ManagesUser_id;
      const managerIdResp = window.prompt('Would you like this manager to manage an existing User ID? If so, type it here.');
      if (managerIdResp) ManagesUser_id = parseInt(managerIdResp);

      // Build Object
      codeObject.useLimit = 3;
      codeObject.isManager = true;
      if (ManagesUser_id) codeObject.ManagesUser_id = ManagesUser_id;
    } else {
      // Number Uses
      const useLimitResp = window.prompt('How many uses would you like?', 3);
      const useLimit = parseInt(useLimitResp);
      if (!useLimit) return;

      // Specific Rate
      const rateResp = window.prompt('What % do you want for the user?', 82);
      const userPercentage = parseInt(rateResp);
      if (!userPercentage) return;

      codeObject.useLimit = useLimit;
      codeObject.userPercentage = userPercentage;
    }

    // Create the code
    waitlistAPI.createRegistrationCode(codeObject).then(
      () => cogoToast.success(`Created code ${code}${codeObject.ManagesUser_id ? ` to manage user ${codeObject.ManagesUser_id}` : ''}.`),
      () => cogoToast.error(`This code already exists.`)
    );
  };

  return (
    <div className='waitlist-controls-outer'>
      <div className='waitlist-controls-inner'>
        <div className='waitlist-controls-tab-container'>
          <div
            className={cn('tab', { active: curTab === 'Users' })}
            onClick={() => {
              resetSearch();
              resetFilters();
              setCurTab('Users');
            }}
          >
            Users
          </div>
          <div
            className={cn('tab', { active: curTab === 'Brands' })}
            onClick={() => {
              resetSearch();
              resetFilters();
              setCurTab('Brands');
            }}
          >
            Brands
          </div>
          <div className='search-container'>
            <input type='text' placeholder='Search' value={curSearchValue} onChange={e => setCurSearchValue(e.target.value)} />
          </div>
        </div>

        <div className='waitlist-controls-action-container'>
          <div className='create-registration-code-btns'>
            <div className='filter-container'>
              <div className='filter-label' onClick={() => setFilterOpen(!filterOpen)}>
                {selectedFilter?.label !== 'Needs Decision' && <FontAwesomeIcon onClick={resetFilters} icon={faTimesCircle} />}
                {selectedFilter?.label !== 'Needs Decision' ? selectedFilter?.label : <>Filter Status&nbsp;&nbsp;&nbsp;&nbsp;+</>}
              </div>
              {filterOpen && (
                <OutsideClickHandler onOutsideClick={() => setFilterOpen(false)}>
                  <div className='filter-dropdown'>
                    {filterOptions.map(option => {
                      const { label } = option;

                      return (
                        <div
                          className={cn('filter-option', { active: selectedFilter.label === label })}
                          key={label}
                          onClick={() => changeFilter(option)}
                        >
                          {option.label}
                        </div>
                      );
                    })}
                  </div>
                </OutsideClickHandler>
              )}
            </div>

            {curTab === 'Users' && (
              <>
                <div onClick={() => createManagerCode()} className='create-registration-code-btn secondary'>
                  Create Manager Code
                </div>{' '}
                <div onClick={() => createCode()} className='create-registration-code-btn'>
                  Create Code
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

WaitlistControls.propTypes = {
  curTab: PropTypes.string.isRequired,
  setCurTab: PropTypes.func.isRequired,

  curSearchValue: PropTypes.string.isRequired,
  setCurSearchValue: PropTypes.func.isRequired,

  selectedFilter: PropTypes.object.isRequired,
  filterOpen: PropTypes.bool.isRequired,
  setFilterOpen: PropTypes.func.isRequired,
  filterOptions: PropTypes.array.isRequired,
  changeFilter: PropTypes.func.isRequired,

  resetSearch: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired
};

export default WaitlistControls;
