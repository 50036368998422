import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import _ from 'lodash';

import './AnalyticsUsers.scss';

import { copyToClipboard } from '../../../helpers/helpers';

class AnalyticsUsers extends Component {
  static propTypes = {
    users: PropTypes.array.isRequired
  };

  state = {
    sortTerm: 'pinViews',
    sortOrder: 'desc'
  };

  changeSort = newSort => {
    this.setState({
      sortTerm: newSort,
      sortOrder: newSort === this.state.sortTerm ? (this.state.sortOrder === 'desc' ? 'asc' : 'desc') : 'desc'
    });
  };

  getSortedUsers = () => {
    const { users } = this.props;
    const { sortTerm, sortOrder } = this.state;
    switch (sortTerm) {
      default:
        return _.orderBy(users, [sortTerm, 'pinViews'], [sortOrder, 'desc']);
    }
  };

  copyEmail = user => {
    copyToClipboard(user.email);
    cogoToast.success(`Copied ${user.email}`);
  };

  render() {
    const getSortedUsers = this.getSortedUsers();
    return (
      <div className='analytics-collections-outer-container'>
        <div className='table'>
          <div className='row row-header'>
            <div onClick={() => this.changeSort('name')} className='cell wide'>
              Name
            </div>
            <div onClick={() => this.changeSort('username')} className='cell medium'>
              Username
            </div>
            <div onClick={() => this.changeSort('collections')} className='cell'>
              # Collections
            </div>
            <div onClick={() => this.changeSort('collectionViews')} className='cell'>
              Collection Views
            </div>
            <div onClick={() => this.changeSort('pinViews')} className='cell'>
              Product Views
            </div>
            <div className='cell'>Email</div>
          </div>
          {_.map(getSortedUsers, (user, idx) => {
            const { name, username, collectionViews, pinViews, collections } = user;
            return (
              <div className='row' key={username}>
                <a href={`https://shopmy.us/shops/${username}`} target='_blank' rel='noopener noreferrer' className='cell wide'>
                  {name}
                </a>
                <div className='cell medium'>{username}</div>
                <div className='cell'>{collections || '-'}</div>
                <div className='cell'>{collectionViews || '-'}</div>
                <div className='cell'>{pinViews || '-'}</div>
                <div onClick={() => this.copyEmail(user)} className='cell btn'>
                  Copy
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default AnalyticsUsers;
