import React, { useEffect, useState } from 'react';
import Loader from '../../General/Loader';
import Select from 'react-select';
import { downloadToCsv } from '../../../helpers/helpers';

import { getBrandCompetitorComparison } from '../../../api/analytics';
import { Table } from '../GraphTypes/Table';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { faDownload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BrandCompetitorComparison = ({ title, Brand_id, allBrands, allCompetitorBrands }) => {
  // this will be the result of the api call
  const [competitorData, setCompetitorData] = useState([]);
  const [CompetitorBrand_id, setCompetitorBrand_id] = useState(-1);
  const [CompetitorName, setCompetitorName] = useState('');
  const [competitor, setCompetitor] = useState({});

  const [isLoading, setLoading] = useState(false);
  const [success, setSuccess] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    const get_analytics = async () => {
      if (CompetitorBrand_id < 0) return;
      setLoading(true);
      const { success, results, error } = await getBrandCompetitorComparison({ Brand_id, CompetitorBrand_id, CompetitorName });
      console.log({ results });

      // console.log({ results });
      setCompetitorData(results);
      setSuccess(success);
      setErrorMessage(error);
      setLoading(false);
    };

    get_analytics();
    // console.log({ Brand_id, startDate, endDate, groupingMethod });
  }, [Brand_id, CompetitorBrand_id, CompetitorName]);

  const make_table_rows = () => {
    const final_results = [];
    const data = competitorData.slice(0, 25);

    for (const competitor of data) {
      final_results.push({
        key: competitor.username,
        label: competitor.name,
        href: `https://shopmy.us/${competitor.username}`,
        description: `shopmy.us/${competitor.username}`,
        values: [
          { label: 'Score', value: parseFloat(competitor.score) },
          { label: 'Gifted', value: competitor.Gifted },
          { label: 'Is Promoter', value: competitor['Is Brand Promoter'] },
          { label: 'Their Orders', value: parseInt(competitor.their_orders) },
          { label: 'Their Volume', value: `$${parseInt(competitor.their_volume)}` },
          { label: 'Your Orders', value: parseInt(competitor.your_orders) },
          { label: 'Your Volume', value: `$${parseInt(competitor.your_volume)}` }
        ]
      });
    }
    return final_results;
  };

  const download = async () => {
    setDownloading(true);
    const rows = make_table_rows();
    const csvRows = rows.map(row => {
      const { label, values } = row;
      const data = { label };
      values.forEach(value => (data[value.label] = value.value));
      return data;
    });

    await downloadToCsv(csvRows, 'Competitor Comparison');
    setDownloading(false);
  };

  const createCompetitorBrandOptions = () => {
    const options = [];

    for (const brand of allBrands) options.push({ label: brand.name, value: brand.id, brandName: brand.name, brandId: brand.id });
    for (const brand of allCompetitorBrands) options.push({ label: brand, value: brand, brandName: brand, brandId: null });

    _.orderBy(options, 'brandName', 'desc');
    return options;
  };

  return (
    <div className='brand-card' id={title}>
      <div className='padding'>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {success && (
              <>
                <div className='header'>
                  <div className='description'>
                    <div className='title'>{title}</div>
                    <div className='sub-title'>
                      Compares top users from brands that do not promote each other. This will only show the top 25 results for each brand.
                    </div>
                  </div>
                  <div className='brand-card-controls'>
                    <div className='rounded-button' onClick={() => download()}>
                      {downloading ? (
                        <Loader />
                      ) : (
                        <>
                          <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                          Download
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <Select
                  className='select'
                  placeholder='Competitor Brand'
                  menuPlacement='top'
                  value={competitor}
                  options={createCompetitorBrandOptions()}
                  onChange={resp => {
                    setCompetitor(resp);
                    const { brandId, brandName } = resp;
                    setCompetitorBrand_id(brandId);
                    setCompetitorName(brandName);
                  }}
                />

                {CompetitorBrand_id < 0 && (
                  <>
                    <div className='error-centered'>
                      <div className='title'>Please Select a Competitor</div>
                    </div>
                  </>
                )}

                {(CompetitorBrand_id > 0 || CompetitorName) && (
                  <>
                    <Table data={make_table_rows()} title='Brand Competitor Comparison' />
                  </>
                )}
              </>
            )}

            {!success && (
              <>
                <div className='error-centered'>
                  <div className='title'>Error Fetching {title}</div>
                  <div className='sub-title'>{errorMessage}</div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

BrandCompetitorComparison.propTypes = {
  notes: PropTypes.object.isRequired,
  allBrands: PropTypes.array.isRequired,
  allCompetitorBrands: PropTypes.array.isRequired,

  // time interval variables
  title: PropTypes.string.isRequired,
  Brand_id: PropTypes.number.isRequired,
  endDate: PropTypes.string,
  startDate: PropTypes.string,
  previousPeriods: PropTypes.string,
  groupingMethod: PropTypes.string,
  brand: PropTypes.object
};

const brandCompetitorComparisonConfig = {
  title: 'Competitor Comparison'
};

export { BrandCompetitorComparison, brandCompetitorComparisonConfig };
