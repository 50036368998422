import React from 'react';
import PropTypes from 'prop-types';
import './MlTrainingSimilarBrands.scss';
import Loader from '../General/Loader';
import MlTrainingSimilarBrand from './MlTrainingSimilarBrand';

const MlTrainingSimilarBrands = props => {
  const { brandsLoading, brand, similarBrand, similarBrands, setFeedback } = props;
  console.log(similarBrands);

  if (!brand)
    return (
      <div className='ml-training-similar-brands-outer'>
        <div className='no-brand-selected'>
          <p>Please select a brand to see similar brands</p>
        </div>
      </div>
    );

  if (brandsLoading) {
    const skeletonLoaders = similarBrand ? new Array(1).fill(0) : new Array(100).fill(0);
    return (
      <div className='ml-training-similar-brands-outer'>
        <div className='skeleton-container'>
          {skeletonLoaders.map((_, index) => (
            <div className='skeleton' key={`skeleton-${index}`}>
              <Loader />
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className='ml-training-similar-brands-outer'>
      <div className='similar-brands-container'>
        {similarBrands.map((brand, index) => (
          <MlTrainingSimilarBrand setFeedback={setFeedback} brand={brand} key={`similar-brand-${index}`} />
        ))}
      </div>
    </div>
  );
};

MlTrainingSimilarBrands.propTypes = {
  setFeedback: PropTypes.func.isRequired,
  brandsLoading: PropTypes.bool.isRequired,
  brand: PropTypes.string,
  similarBrand: PropTypes.string,
  similarBrands: PropTypes.array.isRequired
};

export default MlTrainingSimilarBrands;
