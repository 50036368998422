import React, { useEffect, useState, useCallback } from 'react';
import _ from 'lodash';
import moment from 'moment';
import cogoToast from 'cogo-toast';
import cn from 'classnames';
import './Consults.scss';

import { getConsultResults, updateConsultResult } from '../../api/consults';
import { getPrettyPriceDisplay, getServerFormattedDate, copyToClipboard } from '../../helpers/helpers';

const Consults = props => {
  const [results, setResults] = useState([]);
  const syncResults = useCallback(() => {
    getConsultResults().then(results => {
      setResults(_.orderBy(results, ['completedAt', 'createdAt'], ['desc', 'desc']));
    });
  }, []);

  const toggleCompleted = async result => {
    var confirm = window.confirm(`Are you sure you want to mark this as ${result.completedAt ? 'pending' : 'completed'}`);
    if (confirm) {
      if (result.paymentRefundedAt) {
        return cogoToast.warn('Cannot reset a refunded result.');
      }
      await updateConsultResult(result, {
        completedAt: result.completedAt ? null : getServerFormattedDate()
      });
      syncResults();
    }
  };
  const provideRefund = async result => {
    var confirm = window.confirm(
      `Are you sure you want to offer a refund to ${result.clientName} for ${getPrettyPriceDisplay(
        result.paymentAmount
      )}? If so, please send them a note at ${
        result.clientEmail
      } afterwards describing why we had to refund them. Stripe will send them details on the refund as well.`
    );
    if (confirm) {
      await updateConsultResult(result, {
        paymentRefundedAt: getServerFormattedDate(),
        ...(!result.completedAt ? { completedAt: getServerFormattedDate() } : {})
      });
      syncResults();
    }
  };

  useEffect(() => {
    syncResults();
  }, [syncResults]);

  return (
    <div className='consults-outer-container'>
      <div className='consults-inner-container'>
        <div className='user row header'>
          <div className='cell md'>Created</div>
          <div className='cell md'>Completed</div>
          <div className='cell sm'>id</div>
          <div className='cell lg'>Name</div>
          <div className='cell xl'>Consultation</div>
          <div className='cell md'>Email</div>
          <div className='cell md'>Price</div>
          <div className='cell md'>Status</div>
          <div className='cell md'>Action</div>
        </div>
        {_.map(results, (result, idx) => {
          const { user, clientName, completedAt, createdAt, clientEmail, paymentAmount, paymentRefundedAt, paymentId, stub, consult, id } = result;
          const { title } = consult || {};
          const { name, username } = user || {};
          return (
            <div className={cn('consults row', { odd: idx % 2, completed: !!completedAt })} key={id}>
              <div className='cell md'>{moment(createdAt).format('MMMM Do')}</div>
              <div className='cell md'>{completedAt ? `${moment(completedAt).format('MMMM Do')}` : '-'}</div>
              <div className='cell sm'>{id}</div>
              <a target='_blank' rel='noopener noreferrer' href={`https://shopmy.us/${username}`} className='cell lg'>
                {name}
              </a>
              <a target='_blank' rel='noopener noreferrer' href={`https://shopmy.us/consults/results/${stub}`} className='cell xl'>
                {title ? `${title} for ${clientName}` : clientName}
              </a>
              <div className='cell md btn' onClick={() => copyToClipboard(clientEmail, true)}>
                COPY EMAIL
              </div>
              <div className='cell md'>{paymentAmount ? getPrettyPriceDisplay(paymentAmount) : '-'}</div>
              <div onClick={() => !paymentRefundedAt && toggleCompleted(result)} className='cell md btn'>
                {paymentRefundedAt ? 'REFUNDED' : completedAt ? 'COMPLETED' : 'PENDING'}
              </div>
              <div onClick={() => provideRefund(result)} className='cell md btn'>
                {!paymentId || paymentRefundedAt ? '-' : completedAt ? 'REFUND' : 'REFUND'}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Consults;
