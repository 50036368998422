import React, { useState } from 'react';
import Loader from '../../General/Loader';
import cn from 'classnames';

import { Table } from '../GraphTypes/Table';
import { getBrandReportHeaderLabel } from '../../../helpers/brand_helpers';
import PropTypes from 'prop-types';
import { faDownload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BrandTopPromoterDelta = props => {
  const { title, brandTopPromoters, downloading, groupingMethod } = props;
  const { allResults: promoters, dtcResults: dtcPromoters, nonDtcResults: nonDtcPromoters } = brandTopPromoters || {};
  const success = !!promoters;
  const notEnoughData = promoters?.length < 2;
  const errorMessage = notEnoughData ? 'Time interval not long enough for delta comparison' : undefined;

  const [tab, setTab] = useState('all');
  const download = async () => await props.download({ rows: make_table_rows(tab), fileName: `brand_top_promoters_delta_${tab}` });

  const make_table_rows = tab => {
    let promoterType;

    if (tab === 'all') promoterType = promoters;
    else if (tab === 'dtc') promoterType = dtcPromoters;
    else promoterType = nonDtcPromoters;

    if (!promoterType || !promoterType[0]) return;
    if (notEnoughData) return;

    const uniquePromoters = new Map();
    for (const { results: apiResults } of promoterType) {
      for (const promoter of apiResults) {
        if (!uniquePromoters.has(promoter.User_name)) uniquePromoters.set(promoter.User_name, promoter);
      }
    }

    const fetchPromoterFromGroup = (group, User_id) => group.find(promoter => promoter.User_id === User_id);
    const getChangeInPerformance = (promoterPrevious, promoterCurrent) =>
      parseInt(promoterCurrent.orderVolumeTotal || 0) - parseInt(promoterPrevious.orderVolumeTotal || 0);

    let groups = [];
    for (const promoter of uniquePromoters.values()) {
      const { User_id } = promoter;

      const promoterPrevious = fetchPromoterFromGroup(promoterType[1].results, User_id) || {};
      const promoterCurrent = fetchPromoterFromGroup(promoterType[0].results, User_id) || {};

      promoterPrevious.delta = getChangeInPerformance(promoterPrevious, promoterCurrent);
      promoterCurrent.delta = getChangeInPerformance(promoterPrevious, promoterCurrent);

      groups.push({ promoterPrevious, promoterCurrent });
    }

    const sortByDelta = (a, b) => b.promoterCurrent.delta - a.promoterCurrent.delta;
    const topGroups = groups.slice(0, 10).filter(group => group.promoterCurrent.delta);
    const bottomGroups = groups.slice(-10).filter(group => group.promoterCurrent.delta);

    const previousWeek = promoterType[0].week;
    const previousMonth = promoterType[0].month;
    const previousQuarter = promoterType[0].quarter;
    const currentWeek = promoterType[1].week;
    const currentMonth = promoterType[1].month;
    const currentQuarter = promoterType[1].quarter;

    const finalValues = [];
    for (const group of [...topGroups, ...bottomGroups].sort(sortByDelta)) {
      const { promoterCurrent, promoterPrevious } = group;
      const full_promoter = promoterCurrent.User_name ? promoterCurrent : promoterPrevious;
      const { User_id, User_name, username } = full_promoter;

      const data = {
        key: `${User_id}_brand_top_promoters`,
        label: User_name,
        href: `https://shopmy.us/${username}`,
        description: `shopmy.us/${username}`,
        values: [
          {
            label: getBrandReportHeaderLabel({ week: previousWeek, month: previousMonth, quarter: previousQuarter, groupingMethod }),
            value: `$${parseInt(promoterPrevious.orderVolumeTotal || 0)}`
          },
          {
            label: getBrandReportHeaderLabel({ week: currentWeek, month: currentMonth, quarter: currentQuarter, groupingMethod }),
            value: `$${parseInt(promoterCurrent.orderVolumeTotal || 0)}`
          },
          { label: 'Change', value: `${promoterCurrent.delta < 0 ? '-' : '+'}$${Math.abs(promoterCurrent.delta)}` }
        ]
      };

      finalValues.push(data);
    }

    return finalValues;
  };

  return (
    <>
      <div className='brand-card' id={title}>
        <div className='padding'>
          {!brandTopPromoters ? (
            <Loader />
          ) : (
            <>
              {success && (
                <>
                  <div className='header'>
                    <div className='description'>
                      <div className='title'>{title}</div>
                      <div className='sub-title'>
                        The top promoter delta shows the same users seen in top promoters but shows the largest change in revenues. It displays at max
                        the 10 best and 10 worst changes. This will only show the last two periods.
                      </div>
                    </div>
                    <div className='brand-card-controls'>
                      <div className='radio-btns'>
                        <div className={cn('radio-btn', { active: tab === 'dtc' })} onClick={() => setTab('dtc')}>
                          DTC
                        </div>
                        <div className={cn('radio-btn', { active: tab === 'non_dtc' })} onClick={() => setTab('non_dtc')}>
                          NON-DTC
                        </div>
                        <div className={cn('radio-btn', { active: tab === 'all' })} onClick={() => setTab('all')}>
                          All
                        </div>
                      </div>
                      <div className='rounded-button' onClick={() => download()}>
                        {downloading ? (
                          <Loader />
                        ) : (
                          <>
                            <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                            Download
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  {tab === 'dtc' && <Table title='Brand Top Promoter Delta' data={make_table_rows('dtc')} errorMessage={errorMessage} />}
                  {tab === 'non_dtc' && <Table title='Brand Top Promoter Delta' data={make_table_rows('non_dtc')} errorMessage={errorMessage} />}
                  {tab === 'all' && <Table title='Brand Top Promoter Delta' data={make_table_rows('all')} errorMessage={errorMessage} />}
                </>
              )}

              {!success && (
                <>
                  <div className='error-centered'>
                    <div className='title'>Error Fetching {title}</div>
                    <div className='sub-title'>Could not fetch results</div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

BrandTopPromoterDelta.propTypes = {
  title: PropTypes.string.isRequired,
  brandTopPromoters: PropTypes.object,
  groupingMethod: PropTypes.string.isRequired,

  download: PropTypes.func.isRequired,
  downloading: PropTypes.bool.isRequired
};

const BrandTopPromoterDeltaConfig = {
  title: 'Top Promoters Delta'
};

export { BrandTopPromoterDelta, BrandTopPromoterDeltaConfig };
