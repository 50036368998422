import React, { Component, useState } from 'react';
import _ from 'lodash';
import cn from 'classnames';
import moment from 'moment';
import commaNumber from 'comma-number';
import cogoToast from 'cogo-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faTimes, faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { faBolt } from '@fortawesome/pro-regular-svg-icons';
import { faExclamationTriangle, faArrowLeft } from '@fortawesome/pro-solid-svg-icons';

import { getContracts, updateContract, updateContractTask, addContractSale, deleteContractSale, quickPayContract } from '../../api/contracts';
import {
  getStatusTimeDisplayForContract,
  getStatusDisplayForContract,
  getStatusDataForContractTask,
  CONTRACT_INVOICE_DUE_DAYS
} from '../../helpers/contract_helpers';
import { copyToClipboard } from '../../helpers/helpers';
import { getPrettyNumber } from '../../helpers/formatting';

import Loader from '../General/Loader';
import Tooltip from '../General/Tooltip';

import './Contracts.scss';

class Contracts extends Component {
  static propTypes = {};

  componentDidMount() {
    this.fetchContracts();

    window.addEventListener('scroll', e => {
      const pixelsFromBottom = document.body.offsetHeight - window.pageYOffset - window.innerHeight;
      if (pixelsFromBottom < 250 && this.state.canFetchMore && !this.state.isFetchingNextPage) {
        this.setState({ page: this.state.page + 1 }, this.fetchContractsNextPage);
      }
    });
  }

  state = {
    contracts: [],
    allBrands: [],
    allUsers: [],

    // Filters
    searchVal: '',
    selectedBrandName: '',
    selectedUserName: '',
    selectedStatuses: ['accepted', 'active'],
    limit: 40,
    page: 0,

    // UI Handling
    isFetching: true,
    isFetchingNextPage: false,
    canFetchMore: true
  };

  isStatusSelected = status => this.state.selectedStatuses.includes(status);
  toggleStatus = status =>
    this.setState(
      {
        selectedStatuses: this.isStatusSelected(status)
          ? this.state.selectedStatuses.filter(s => s !== status)
          : [...this.state.selectedStatuses, status]
      },
      this.fetchContractsAndResetPage
    );

  fetchContractsNextPage = () => this.fetchContracts({ isNextPage: true });
  fetchContractsAndResetPage = () => this.setState({ page: 0, isFetchingNextPage: false, canFetchMore: true }, this.fetchContracts);
  fetchContracts = ({ isNextPage } = {}) => {
    isNextPage ? this.setState({ isFetchingNextPage: true }) : this.setState({ isFetching: true });
    getContracts({
      statuses: this.state.selectedStatuses.join(','),
      page: this.state.page,
      query: this.state.searchVal,
      limit: this.state.limit,
      brandName: this.state.selectedBrandName || '',
      userName: this.state.selectedUserName || ''
    }).then(resp => {
      this.setState({
        contracts: [...this.state.contracts.slice(0, this.state.page * this.state.limit), ...resp.contracts],
        isFetching: false,
        isFetchingNextPage: false,
        canFetchMore: !!resp.contracts.length,
        allBrands: resp.allBrands,
        allUsers: resp.allUsers
      });
    });
  };

  updateContract = (contract, updates) => {
    updateContract(contract, updates).then(newContract => {
      this.setState({
        contracts: this.state.contracts.map(c => (c.id === newContract.id ? { ...c, ...newContract } : c))
      });
    });
  };

  updateContractUI = (contract, updates) => {
    // If we performed the update and just need to update the UI
    this.setState({
      contracts: this.state.contracts.map(c => (c.id === contract.id ? { ...c, ...updates } : c))
    });
  };

  updateContractTask = (task, updates) => {
    updateContractTask(task, updates).then(newTask => {
      this.setState({
        contracts: this.state.contracts.map(c => ({
          ...c,
          tasks: c.tasks.map(t => (t.id === task.id ? newTask : t))
        }))
      });
    });
  };

  addSale = sale => {
    addContractSale(sale).then(newSale => {
      this.setState({
        contracts: this.state.contracts.map(c =>
          c.id === newSale.Contract_id
            ? {
                ...c,
                sales: [...c.sales, newSale]
              }
            : c
        )
      });
    });
  };

  deleteSale = sale => {
    deleteContractSale(sale).then(newTask => {
      this.setState({
        contracts: this.state.contracts.map(c =>
          c.id === sale.Contract_id
            ? {
                ...c,
                sales: c.sales.filter(s => s.id !== sale.id)
              }
            : c
        )
      });
    });
  };

  updateSearch = newVal => {
    clearTimeout(this.searchDebounce);
    this.setState({ isFetching: true, searchVal: newVal, selectedBrandName: null, selectedUserName: null });
    this.searchDebounce = setTimeout(() => {
      this.fetchContractsAndResetPage();
    }, 500);
  };

  updateSelectedBrandName = newBrand =>
    this.setState({ selectedBrandName: newBrand.includes('All') ? '' : newBrand }, this.fetchContractsAndResetPage);
  updateSelectedUserName = newUser => this.setState({ selectedUserName: newUser.includes('All') ? '' : newUser }, this.fetchContractsAndResetPage);

  render() {
    const { contracts, searchVal, allBrands, allUsers, selectedBrandName, selectedUserName, isFetching, isFetchingNextPage } = this.state;

    const allStatuses = ['pending', 'proposed', 'accepted', 'active', 'closed', 'rejected', 'expired'];

    const salesNote =
      'Whoever leads the sale of the collaboration gets a 10% commission of what ShopMy earns on the collaboration. If two or more people make a sale, they split 12%.';

    return (
      <div className='contracts-outer-container'>
        <div className='contracts-inner-container'>
          <div className='controls'>
            <div>
              <input placeholder='Search Brands, Users, Titles' value={searchVal} onChange={({ target }) => this.updateSearch(target.value)} />
              <select value={selectedBrandName} onChange={e => this.updateSelectedBrandName(e.target.value)}>
                <option value={null}>All Brands</option>
                {allBrands?.map(brand => (
                  <option key={brand.name} value={brand.name}>
                    {brand.name}
                  </option>
                ))}
              </select>
              <select value={selectedUserName} onChange={e => this.updateSelectedUserName(e.target.value)}>
                <option value={null}>All Users</option>
                {allUsers?.map(user => (
                  <option key={user.name} value={user.name}>
                    {user.name}
                  </option>
                ))}
              </select>
            </div>
            <div className='statuses'>
              {allStatuses.map(status => {
                const selected = this.isStatusSelected(status);
                const toggle = () => this.toggleStatus(status);
                return (
                  <div key={status} onClick={toggle} className={cn('status', { selected })}>
                    {getStatusDisplayForContract({ status })}
                  </div>
                );
              })}
            </div>
          </div>
          <div className='row header'>
            <div className='cell sm'>ID</div>
            <div className='cell md'>Status</div>
            <div className='cell lg'>Date</div>
            <div className='cell'>Title</div>
            <div className='cell'>Proposed By</div>
            <div className='cell'>Proposed To</div>
            <div className='cell'>Content</div>
            <div className='cell sm'>Views</div>
            <div className='cell sm'>Engs</div>
            <div className='cell sm'>Clicks</div>
            <div className='cell sm'>Volume</div>
            <div className='cell sm'>Price</div>
            <div className='cell sm'>Invoice</div>
            <div className='cell sm'>Paid</div>
            <div className='cell'>
              Seller
              <Tooltip message={salesNote} getIconDiv={() => <FontAwesomeIcon className='tooltip-icn' icon={faInfoCircle}></FontAwesomeIcon>} />
            </div>
            <div className='cell xs'>Tasks</div>
          </div>
          {isFetching ? (
            <div>
              <Loader />
            </div>
          ) : (
            contracts.map((contract, idx) => (
              <ContractRow
                key={contract.id}
                idx={idx}
                contract={contract}
                updateContractTask={this.updateContractTask}
                updateContract={this.updateContract}
                updateContractUI={this.updateContractUI}
                allStatuses={allStatuses}
                addSale={this.addSale}
                deleteSale={this.deleteSale}
              />
            ))
          )}
          {isFetchingNextPage && (
            <div>
              <Loader />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const ContractRow = props => {
  const { contract, updateContract, updateContractUI, updateContractTask, addSale, deleteSale, allStatuses, idx } = props;
  const { id, title, user, tasks, sales, isCreatedByBrand, brand, Payment_id, invoice, status, stats, pins, collections, consults, media } = contract;
  const { views, engagement, clicks, orderVolume } = stats || {};
  const [showingTasks, setShowingTasks] = useState(false);
  const [isQuickPaying, setIsQuickPaying] = useState(false);

  const changeStatus = newStatus => {
    updateContract(contract, { status: newStatus });
  };
  let attachedContent = _.filter([
    pins.length ? `${pins.length} Pin${pins.length === 1 ? '' : 's'}` : '',
    collections.length ? `${collections.length} Collection${collections.length === 1 ? '' : 's'}` : '',
    consults.length ? `${consults.length} Consult${consults.length === 1 ? '' : 's'}` : '',
    media.length ? `${media.length} Social` : ''
  ]).join(', ');

  const taskStatuses = ['pending', 'inreview', 'approved', 'active', 'complete'];
  const salesNames = ['Tiffany', 'Em', 'Kellee', 'Rachel', 'Austin', 'Harry', 'Chris', 'Leslie'];
  const sellersNames = sales.map(s => s.name);

  const taskStatusData = tasks.map(task => getStatusDataForContractTask(task, contract));
  const warningMsg = _.find(taskStatusData, d => d.warningMsg);
  const errorMsg = _.find(taskStatusData, d => d.errorMsg);

  const addSeller = name => {
    if (sellersNames.includes(name)) return;
    if (sales.find(s => s.isPaid)) return cogoToast.warn(`Cannot edit this as we have already paid the commission.`);
    addSale({ name, Contract_id: contract.id });
  };
  const removeSale = sale => {
    if (sale.isPaid) return cogoToast.warn(`Cannot remove this as we have already paid out the commission.`);
    deleteSale(sale);
  };

  const quickPay = () => {
    if (isQuickPaying) return cogoToast.warn('Quick pay in progress.');
    setIsQuickPaying(true);
    quickPayContract(contract)
      .then(resp => {
        updateContractUI(contract, { Payment_id: 102312 });
        cogoToast.success(`Successfully submitted quick payment!`);
      })
      .catch(error => {
        cogoToast.error(error || `Successfully submitted quick payment!`);
      })
      .finally(() => {
        setIsQuickPaying(false);
      });
  };

  const quickPayWhilePending = () => {
    const confirm = window.confirm(
      `Are you sure you want to pay this out even before we have collected the payment? Please check on Stripe to make sure we have the necessary funds.`
    );
    confirm && quickPay();
  };

  const userCell = (
    <a target='_blank' rel='noopener noreferrer' href={`https://shopmy.us/${user.username}`} className='cell'>
      {user.name}
    </a>
  );
  const brandCell = <div className='cell'>{brand.name}</div>;
  const requiresInvoicePayment = invoice?.status === 'unpaid' && moment().diff(invoice.createdAt, 'days') > CONTRACT_INVOICE_DUE_DAYS;
  return (
    <>
      <div className={cn('row', { odd: idx % 2 === 0, error: errorMsg, warning: warningMsg })}>
        <div className='cell sm'>{id}</div>
        <div className='cell md'>
          <select value={status} onChange={e => changeStatus(e.target.value)}>
            {allStatuses.map(status => (
              <option key={status} value={status}>
                {getStatusDisplayForContract({ status })}
              </option>
            ))}
          </select>
        </div>
        <div className='cell lg'>{getStatusTimeDisplayForContract(contract)}</div>
        <a href={`https://shopmy.us/collaboration/${id}`} className='cell'>
          {title ? title.slice(0, 15) : '-'}...
        </a>
        {isCreatedByBrand ? brandCell : userCell}
        {isCreatedByBrand ? userCell : brandCell}
        <div className='cell'>{attachedContent || '-'}</div>
        <div className='cell sm'>{views ? getPrettyNumber(views) : '-'}</div>
        <div className='cell sm'>{engagement ? getPrettyNumber(engagement) : '-'}</div>
        <div className='cell sm'>{clicks ? getPrettyNumber(clicks) : '-'}</div>
        <div className='cell sm'>{orderVolume ? getPrettyNumber(orderVolume) : '-'}</div>
        <div className='cell sm'>{contract.price ? `$${commaNumber(contract.price)}` : '-'}</div>
        <div className='cell sm'>
          {invoice?.id ? (
            invoice.stripeInvoiceId ? (
              <a
                href={`https://dashboard.stripe.com/${window.__IS_PROD__ ? '' : 'test/'}invoices/${invoice.stripeInvoiceId}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                {requiresInvoicePayment && <FontAwesomeIcon onClick={() => cogoToast.warn(`Invoice past due`)} icon={faExclamationTriangle} />}
                {invoice.status === 'paid' ? 'PAID' : 'STRIPE'}
              </a>
            ) : (
              <FontAwesomeIcon
                onClick={() => cogoToast.warn(`Invoice sent without Stripe association, please investigate.`)}
                icon={faExclamationTriangle}
              />
            )
          ) : (
            '-'
          )}
        </div>
        <div className='cell sm payment'>
          {!invoice?.id ? (
            '-'
          ) : Payment_id ? (
            <div onClick={() => copyToClipboard(Payment_id, `Payment_id ${Payment_id} copied to clipboard.`)} className='already-paid'>
              PAID
            </div>
          ) : !user.stripeAccountId ? (
            <div className='strikethrough' onClick={() => cogoToast.warn(`No connected Stripe account.`)}>
              Stripe
            </div>
          ) : !user.stripeOnboardingComplete ? (
            <div className='strikethrough' onClick={() => cogoToast.warn(`Stripe account not fully configured.`)}>
              STR-SETUP
            </div>
          ) : invoice?.status === 'paid' ? (
            <div className='clickable quick-pay' onClick={quickPay}>
              {isQuickPaying ? (
                'Paying...'
              ) : (
                <>
                  <FontAwesomeIcon icon={faBolt} />
                  Pay
                </>
              )}
            </div>
          ) : (
            <div className='clickable quick-pay' onClick={quickPayWhilePending}>
              <FontAwesomeIcon icon={faArrowLeft} />
              Pending
            </div>
          )}
        </div>
        <div className='cell sales'>
          {sales.map(sale => {
            return (
              <div key={sale.id} onClick={() => removeSale(sale)} className={cn('sale', sale.name.toLowerCase().replace(' ', ''))}>
                {sale.name}
                {!sale.isPaid && <FontAwesomeIcon icon={faTimes} />}
              </div>
            );
          })}
          <select onChange={e => addSeller(e.target.value)} className='names' id='names'>
            <option value='None'></option>
            {_.orderBy(salesNames).map(name => {
              return (
                <option key={name} value={name}>
                  {name}
                </option>
              );
            })}
          </select>
        </div>
        <div onClick={() => setShowingTasks(!showingTasks)} className='cell xs clickable'>
          <FontAwesomeIcon icon={showingTasks ? faChevronUp : faChevronDown} />
        </div>
      </div>
      {showingTasks && (
        <div className='tasks'>
          {tasks.map(task => {
            const { status, id } = task;
            const statusData = getStatusDataForContractTask(task, contract);
            const updateStatus = newStatus => {
              // Need to reset contract back to the pending state
              if (newStatus !== 'complete' && contract.status === 'active') {
                changeStatus('accepted');
              }
              updateContractTask(task, { status: newStatus });
            };

            return (
              <div key={id} className='task'>
                <select value={status} onChange={e => updateStatus(e.target.value)}>
                  {task.template.isAddOn ? (
                    <option>Add On</option>
                  ) : (
                    taskStatuses.map(status => (
                      <option key={status} value={status}>
                        {getStatusDataForContractTask({ status }, contract).display}
                      </option>
                    ))
                  )}
                </select>
                <div className='cell'>{task.title} - </div>
                <div className='cell badge'>{statusData.displayMsg}</div>
                {statusData.errorMsg && <div className='cell error'>{statusData.errorMsg}</div>}
                {statusData.warningMsg && <div className='cell warning'>{statusData.warningMsg}</div>}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default Contracts;
