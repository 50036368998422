import _ from 'lodash';

import { getActiveTags } from './ui_helpers';

export const objectMatchesActiveTags = (userBrandOrMerchant, ui) => {
  /*
    Checks if a user or brand passes the current set of active tags.

    Requires OR within a group of the same TYPE and AND across type groups 
  */
  const activeTags = getActiveTags(ui);
  const tagGroups = _.groupBy(activeTags, 'type');
  const groupsToCheck = _.values(tagGroups);
  return objectMatchesActiveTagGroups(userBrandOrMerchant, groupsToCheck);
};

export const objectMatchesActiveTagGroups = (userBrandOrMerchant, groupsToCheck) => {
  /*
    Use this function if you need a speed improvement to avoid recalculating
    the tag groups at every step. For example, on the users tab we are loading
    a ton of rows, so this is a minor optimization.

    How to use:

    const tagGroups = _.groupBy(activeTags, 'type');
    const groupsToCheck = _.values(tagGroups);
    for (const userBrandOrMerchant of userBrandOrMerchants) {
      const passes = userMatchesActiveTagGroups(userBrandOrMerchant, groupsToCheck)
    }
  */
  if (!userBrandOrMerchant.tags) return console.error('Could not find tags on the object', userBrandOrMerchant);
  let [tagIds, failsMatch] = [{}, false];
  (userBrandOrMerchant.tags || []).forEach(tag => (tagIds[tag.id] = true));
  groupsToCheck.forEach(group => {
    const foundMatch = _.find(group, tag => tagIds[tag.id]);
    if (!foundMatch) failsMatch = true;
  });
  return !failsMatch;
};
