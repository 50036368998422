import AWSConfig from './AWSConfig';
import { Auth } from 'aws-amplify';
import _ from 'lodash';
import axios from 'axios';

Auth.configure(AWSConfig);

export const cognitoSignUp = async credentials => {
  return new Promise((resolve, reject) => {
    Auth.signUp(credentials)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        console.log(err);
        resolve({
          ...err,
          error: 1
        });
      });
  });
};

export const cognitoLogin = async credentials => {
  return new Promise((resolve, reject) => {
    Auth.signIn(credentials)
      .then(async res => {
        configureAxios(res);
        resolve(res);
      })
      .catch(err => {
        resolve({
          ...err,
          error: 1
        });
      });
  });
};

// Local variable to keep track of number of times Auth retries has triggered.
let retryAuthAttempt = 0;

export const configureAxios = cognitoRes => {
  // Add Authorization access token to every request.
  const accessToken = _.get(cognitoRes, 'signInUserSession.accessToken.jwtToken') || _.get(cognitoRes, 'accessToken.jwtToken');
  const idToken = _.get(cognitoRes, 'signInUserSession.idToken.jwtToken') || _.get(cognitoRes, 'idToken.jwtToken');

  axios.defaults.headers.common['Authorization'] = accessToken;
  axios.defaults.headers.common['x-Authorization-id'] = idToken;

  // Disable caching on every API request.
  axios.defaults.headers.common['Cache-Control'] = 'no-cache';
  // Bypass cache results on every API request.
  axios.defaults.headers.common['x-apicache-bypass'] = 'true';
  // Retry requests of unauthorized failures.
  axios.interceptors.response.use(
    response => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      return response;
    },
    async error => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger.

      // If Access Token is timed out, refresh this token.
      if (_.get(error, 'response.status') === 401 && retryAuthAttempt < 3) {
        const currentSession = await Auth.currentSession();
        const newAccessToken = _.get(currentSession, 'accessToken.jwtToken');
        const newIdAccessToken = _.get(currentSession, 'idToken.jwtToken');

        axios.defaults.headers.common['Authorization'] = newAccessToken;
        error.response.config.headers['Authorization'] = newAccessToken;
        axios.defaults.headers.common['x-Authorization-id'] = newIdAccessToken;
        error.response.config.headers['x-Authorization-id'] = newIdAccessToken;

        retryAuthAttempt += 1;
        return axios.request(error.config);
      }
      retryAuthAttempt = 0;
      return Promise.reject(error.response);
    }
  );
};

export const cognitoSignOut = () => {
  Auth.signOut();
};

export const currentUser = () => {
  return Auth.currentUserInfo();
};

export const cognitoHubListen = () => {
  // DEPRECATED
};
