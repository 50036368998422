import React, { Component } from 'react';
import PropTypes from 'prop-types';
import commaNumber from 'comma-number';
import _ from 'lodash';

import './AnalyticsCollections.scss';

class AnalyticsCollections extends Component {
  static propTypes = {
    collections: PropTypes.array.isRequired
  };

  state = {
    sortTerm: 'order_amount',
    sortOrder: 'desc'
  };

  changeSort = newSort => {
    this.setState({
      sortTerm: newSort,
      sortOrder: newSort === this.state.sortTerm ? (this.state.sortOrder === 'desc' ? 'asc' : 'desc') : 'desc'
    });
  };

  getSortedCollections = () => {
    const { collections } = this.props;
    const { sortTerm, sortOrder } = this.state;
    switch (sortTerm) {
      default:
        return _.orderBy(collections, sortTerm, sortOrder);
    }
  };

  render() {
    const sortedCollections = this.getSortedCollections();
    return (
      <div className='analytics-collections-outer-container'>
        <div className='table'>
          <div className='row row-header'>
            <div onClick={() => this.changeSort('User_name')} className='cell medium'>
              Name
            </div>
            <div onClick={() => this.changeSort('name')} className='cell wide'>
              Collection
            </div>
            <div onClick={() => this.changeSort('orderAmount')} className='cell'>
              Order Amount
            </div>
            <div onClick={() => this.changeSort('numClicks')} className='cell'>
              Clicks
            </div>
            <div onClick={() => this.changeSort('EPC')} className='cell'>
              EPC
            </div>
            <div onClick={() => this.changeSort('numViews')} className='cell'>
              Views
            </div>
            <div onClick={() => this.changeSort('EPV')} className='cell'>
              EPV
            </div>
            <div onClick={() => this.changeSort('commissionAmount')} className='cell'>
              Commission
            </div>
          </div>
          {_.map(sortedCollections, (collection, idx) => {
            const { id, name, orderAmount, numClicks, username, User_name, numViews, commissionAmount, EPC, EPV } = collection;
            return (
              <div className='row' key={`${id}${idx}`}>
                <a href={`https://shopmy.us/shops/${username}`} target='_blank' rel='noopener noreferrer' className='cell medium'>
                  {User_name}
                </a>
                <a href={`https://shopmy.us/c/${id}`} target='_blank' rel='noopener noreferrer' className='cell wide'>
                  {name.slice(0, 28)}
                  {name.length > 28 ? '...' : ''}
                </a>
                <div className='cell'>{orderAmount ? `$${commaNumber(orderAmount.toFixed(2))}` : '-'}</div>
                <div className='cell'>{numClicks || '-'}</div>
                <div className='cell'>{EPC ? `$${commaNumber(EPC.toFixed(2))}` : '-'}</div>
                <div className='cell'>{numViews || '-'}</div>
                <div className='cell'>{EPV ? `$${commaNumber(EPV.toFixed(2))}` : '-'}</div>
                <div className='cell dark'>${commaNumber(commissionAmount.toFixed(2))}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default AnalyticsCollections;
