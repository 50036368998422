import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Pins.scss';

import PinResult from './elements/PinResult';
import PinSearchPanel from './elements/PinSearchPanel';

import { searchPins } from '../../api/pins';

class Pins extends Component {
  state = {
    pins: [],
    filters: {
      type: null // 'sms', 'sl'
    }
  };

  componentDidMount() {
    this.search([]);
  }

  search = async vals => {
    const resp = await searchPins(vals);
    this.setState({ pins: resp.pins });
  };

  render() {
    const { filters, pins } = this.state;
    return (
      <div className='pins-outer-container'>
        <PinSearchPanel search={this.search} filters={filters} setFilters={newFilters => this.setState({ filters: newFilters })} />
        <div className='pin-results-container'>
          {pins.map(pin => (
            <PinResult key={pin.id} pin={pin} />
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  // functions go here
})(Pins);
