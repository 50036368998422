import React from 'react';
import Loader from '../../General/Loader';

import { Table } from '../GraphTypes/Table';
import moment from 'moment';
import PropTypes from 'prop-types';
import { faDownload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BrandDiscountCodeUsers = props => {
  const { title, brandDiscountCodeUsers, downloading } = props;
  const success = !!brandDiscountCodeUsers?.length;
  const errorMessage = !success ? 'No Discount Code Users Available' : undefined;

  const download = async () => await props.download({ rows: make_table_rows(), fileName: 'brand_discount_code_users' });

  const make_table_rows = () => {
    if (!success) return;
    if (!brandDiscountCodeUsers || !brandDiscountCodeUsers[0]) return;

    // row: {name, username, displayText, rate, createdAt}
    const users = [];
    for (const user of brandDiscountCodeUsers) {
      const { User_id, name, username, displayText, rate, createdAt } = user;

      const data = { key: User_id, label: name, href: `https://shopmy.us/${username}`, description: `shopmy.us/${username}`, values: [] };
      data.values = [
        { label: 'Code', value: displayText },
        { label: 'Rate', value: rate },
        { label: 'Since', value: moment(createdAt).format('MMM DD, YYYY') }
      ];

      users.push(data);
    }

    return users;
  };

  return (
    <div className='brand-card' id={title}>
      <div className='padding'>
        {!brandDiscountCodeUsers ? (
          <Loader />
        ) : (
          <>
            <div className='header'>
              <div className='description'>
                <div className='title'>{title}</div>
                <div className='sub-title'>
                  This displays all of the users that have been granted a discount code. Showing you who they are, what their code is, and when they
                  received it.
                </div>
              </div>
              {!downloading && (
                <div className='brand-card-controls'>
                  <div className='rounded-button' onClick={() => download()}>
                    {downloading ? (
                      <Loader />
                    ) : (
                      <>
                        <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                        Download
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>

            <Table title='Brand Custom Code Users' data={make_table_rows()} errorMessage={errorMessage} />
          </>
        )}
      </div>
    </div>
  );
};

BrandDiscountCodeUsers.propTypes = {
  title: PropTypes.string.isRequired,
  brandDiscountCodeUsers: PropTypes.array,

  download: PropTypes.func.isRequired,
  downloading: PropTypes.bool.isRequired
};

const brandDiscountCodeUsersConfig = {
  title: 'Discount Code Users'
};

export { BrandDiscountCodeUsers, brandDiscountCodeUsersConfig };
