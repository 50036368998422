import React from 'react';
import './Subscriptions.scss';

import SubscriptionOffers from './SubscriptionOffers';
import SubscriptionBundles from './SubscriptionBundles';
import SubscriptionIOGenerator from './SubscriptionIOGenerator';

import { getSubscriptionBundles, getSubscriptionOffers } from '../../api/subscriptions';

const Subscriptions = props => {
  const [bundles, setBundles] = React.useState([]);
  const [offers, setOffers] = React.useState([]);

  const syncOffers = () => getSubscriptionOffers().then(setOffers);
  const syncBundles = () => getSubscriptionBundles().then(setBundles);
  React.useEffect(() => {
    syncOffers();
    syncBundles();
  }, []);

  return (
    <div className='subscriptions-outer-container'>
      <div className='subscriptions-inner-container'>
        <SubscriptionOffers syncOffers={syncOffers} offers={offers} />
        <SubscriptionBundles syncBundles={syncBundles} bundles={bundles} />
        <SubscriptionIOGenerator bundles={bundles} offers={offers} />
      </div>
    </div>
  );
};

export default Subscriptions;
