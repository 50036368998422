import * as ActionTypes from '../actions/uiActions';

import { isTagActive, isUserBeingTagged, isBrandBeingTagged, isMerchantBeingTagged, isTagTypeHidden } from '../helpers/ui_helpers';

export const ui = (
  state = {
    // Tag related
    isTaggingMode: false,
    activeTags: [],
    hiddenTagTypes: ['brandpromoter'],
    usersBeingTagged: [],
    brandsBeingTagged: [],
    merchantsBeingTagged: [],
    activeTalentId: undefined
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.SET_ACTIVE_TAGS:
      return {
        ...state,
        activeTags: action.tags
      };

    case ActionTypes.SET_USERS_BEING_TAGGED:
      return {
        ...state,
        usersBeingTagged: action.users
      };

    case ActionTypes.SET_BRANDS_BEING_TAGGED:
      return {
        ...state,
        brandsBeingTagged: action.brands
      };

    case ActionTypes.SET_MERCHANTS_BEING_TAGGED:
      return {
        ...state,
        merchantsBeingTagged: action.merchants
      };

    case ActionTypes.TOGGLE_ACTIVE_TAG:
      return {
        ...state,
        activeTags: isTagActive(action.tag, state)
          ? (state.activeTags || []).filter(t => t.id !== action.tag.id)
          : [...(state.activeTags || []), action.tag]
      };

    case ActionTypes.TOGGLE_TAG_TYPE_VISIBILITY:
      return {
        ...state,
        hiddenTagTypes: isTagTypeHidden(action.tagType, state)
          ? (state.hiddenTagTypes || []).filter(t => t !== action.tagType)
          : [...(state.hiddenTagTypes || []), action.tagType]
      };

    case ActionTypes.TOGGLE_USER_BEING_TAGGED:
      return {
        ...state,
        usersBeingTagged: isUserBeingTagged(action.user, state)
          ? (state.usersBeingTagged || []).filter(u => u.id !== action.user.id)
          : [...(state.usersBeingTagged || []), action.user]
      };

    case ActionTypes.TOGGLE_BRAND_BEING_TAGGED:
      return {
        ...state,
        brandsBeingTagged: isBrandBeingTagged(action.brand, state)
          ? (state.brandsBeingTagged || []).filter(u => u.id !== action.brand.id)
          : [...(state.brandsBeingTagged || []), action.brand]
      };

    case ActionTypes.TOGGLE_MERCHANT_BEING_TAGGED:
      return {
        ...state,
        merchantsBeingTagged: isMerchantBeingTagged(action.merchant, state)
          ? (state.merchantsBeingTagged || []).filter(u => u.id !== action.merchant.id)
          : [...(state.merchantsBeingTagged || []), action.merchant]
      };

    case ActionTypes.TOGGLE_TAGGING_MODE:
      return {
        ...state,
        isTaggingMode: !state.isTaggingMode
      };

    case ActionTypes.SET_ACTIVE_TALENT_ID:
      return {
        ...state,
        activeTalentId: action.user?.id
      };

    default:
      return state;
  }
};
