import APIClient from './index';
import _ from 'lodash';

export const getProduct = id => {
  return new Promise((resolve, reject) => {
    APIClient.get(`Products/${id}?fullData=1`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const getProducts = (data = {}) => {
  return new Promise((resolve, reject) => {
    APIClient.get(`Products?${new URLSearchParams(data).toString()}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const getProductsForBrand = brandName => {
  return new Promise((resolve, reject) => {
    APIClient.post('Products/for_brand', { brand: brandName })
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const addProduct = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`Products`, data)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const updateProduct = (id, data) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`Products/${id}`, data)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const deleteProduct = id => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`Products/${id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const updateVariant = (id, data = {}) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`Variants/${id}`, data)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const updatePin = (id, data) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`Pins/${id}`, data)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};
export const updateProductTags = (Product_id, tagIds) => {
  return new Promise((resolve, reject) => {
    APIClient.post(`ProductTags/updateTagsForProduct`, { Product_id, tagIds })
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};
