import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './ConciergeTalentResults.scss';
import Switch from 'react-switch';

import ConciergeTalentResult from './ConciergeTalentResult';
import ConciergeUnsetTalent from './ConciergeUnsetTalent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown } from '@fortawesome/pro-solid-svg-icons';
import cn from 'classnames';

const ConciergeTalentResults = props => {
  const {
    allTalent,
    createComment,
    updateComment,
    setActiveTalentId,
    allComments,
    usersWithNoManager,
    updateExpectedScore,
    updateSupportLevel,
    extraFilterOptions,
    extraFilters,
    setExtraFilters,
    selectedSortValue,
    setSelectedSortValue,
    sortDirection,
    setSortDirection,
    sortValues
  } = props;

  const [showUnsetUsers, setShowUnsetUsers] = useState(false);
  const [showOnlyArchivedUsers, setShowOnlyArchivedUsers] = useState(false);

  const setSortingValue = value => {
    setExtraFilters({});
    setSelectedSortValue(value);
  };

  return (
    <div className='concierge-talent-results-outer'>
      <div className='options'>
        <div className='sorting-options-container'>
          {/* SORTING VALUES */}
          {sortValues.map(sortValue => {
            const { display } = sortValue;

            return (
              <div
                className={cn('sorting-option', { selected: selectedSortValue.display === display })}
                onClick={() => (selectedSortValue.display === display ? setSortDirection(!sortDirection) : setSortingValue(sortValue))}
              >
                {display}
                {selectedSortValue.display === display && <FontAwesomeIcon icon={sortDirection ? faSortUp : faSortDown} />}
              </div>
            );
          })}
          {extraFilterOptions.map(({ conditionalSortValue, values }) => {
            // Only show these if the sortValue is the same as the conditionalSortValue listed in the extraFilterOptions
            const shouldNotDisplayExtraFilterOptions = selectedSortValue.value !== conditionalSortValue;
            if (shouldNotDisplayExtraFilterOptions) return null;

            return values.map(value => (
              <div
                className={cn('sorting-option', { selected: extraFilters[conditionalSortValue]?.display === value.display })}
                key={`extra_filter_${conditionalSortValue}_${value.display}`}
                onClick={() =>
                  extraFilters[conditionalSortValue]?.display === value.display
                    ? setExtraFilters({})
                    : setExtraFilters({ [conditionalSortValue]: value })
                }
              >
                {value.display}
              </div>
            ));
          })}
        </div>

        <div className='toggles'>
          <div className='analytics-toggle'>
            <div onClick={() => setShowUnsetUsers(!showUnsetUsers)}>Show Unset Talent</div>
            <Switch
              onChange={() => setShowUnsetUsers(!showUnsetUsers)}
              checked={showUnsetUsers}
              height={14}
              width={24}
              onColor='#333'
              offColor='#ccc'
              className='switch'
              checkedIcon={false}
              uncheckedIcon={false}
            />
          </div>
          <div className='analytics-toggle'>
            <div onClick={() => setShowOnlyArchivedUsers(!showOnlyArchivedUsers)}>Show Only Archived Users</div>
            <Switch
              onChange={() => setShowOnlyArchivedUsers(!showOnlyArchivedUsers)}
              checked={showOnlyArchivedUsers}
              height={14}
              width={24}
              onColor='#333'
              offColor='#ccc'
              className='switch'
              checkedIcon={false}
              uncheckedIcon={false}
            />
          </div>
        </div>
      </div>

      {showUnsetUsers ? (
        <div className='unset-users'>
          {usersWithNoManager.map(user => (
            <ConciergeUnsetTalent setActiveTalentId={setActiveTalentId} unsetUser={user} key={`unset_user_${user.User_id}`} />
          ))}
        </div>
      ) : (
        <>
          <div className='talent-holder'>
            {allTalent
              .filter(talent => (showOnlyArchivedUsers ? talent.isArchived : true) && talent.supportLevel > 0)
              .map(talent => (
                <ConciergeTalentResult
                  key={`talent_${talent.id}`}
                  talent={talent}
                  createComment={createComment}
                  updateComment={updateComment}
                  setActiveTalentId={setActiveTalentId}
                  allComments={allComments}
                  updateExpectedScore={updateExpectedScore}
                  updateSupportLevel={updateSupportLevel}
                />
              ))}
          </div>
          {!showOnlyArchivedUsers && (
            <>
              <div className='unsupported-talent'>Unsupported Talent</div>
              <div className='talent-holder'>
                {allTalent
                  .filter(talent => !(talent.supportLevel > 0))
                  .map(talent => (
                    <ConciergeTalentResult
                      key={`talent_${talent.id}`}
                      talent={talent}
                      createComment={createComment}
                      updateComment={updateComment}
                      setActiveTalentId={setActiveTalentId}
                      allComments={allComments}
                      updateExpectedScore={updateExpectedScore}
                      updateSupportLevel={updateSupportLevel}
                    />
                  ))}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

ConciergeTalentResults.propTypes = {
  allTalent: PropTypes.array,
  usersWithNoManager: PropTypes.array,
  setActiveTalentId: PropTypes.func.isRequired,
  allComments: PropTypes.array,
  createComment: PropTypes.func.isRequired,
  updateComment: PropTypes.func.isRequired,
  updateSupportLevel: PropTypes.func.isRequired,
  updateExpectedScore: PropTypes.func.isRequired,

  sortValues: PropTypes.array.isRequired,
  selectedSortValue: PropTypes.string,
  setSelectedSortValue: PropTypes.func.isRequired,
  sortDirection: PropTypes.bool,
  setSortDirection: PropTypes.func.isRequired,

  // extra filters
  extraFilterOptions: PropTypes.array,
  extraFilters: PropTypes.object,
  setExtraFilters: PropTypes.func
};

export default ConciergeTalentResults;
