import APIClient from './index';
import _ from 'lodash';

export const fetchScrapers = () => {
  return new Promise((resolve, reject) => {
    APIClient.get(`Scrapers`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const fetchScraperDetails = data => {
  return new Promise((resolve, reject) => {
    APIClient.get(`Scrapers/details?${new URLSearchParams(data).toString()}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const addScraper = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`Scrapers`, data)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const updateScraper = (scraper, data) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`Scrapers/${scraper.id}`, data)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const deleteScraper = scraper => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`Scrapers/${scraper.id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(err));
  });
};
