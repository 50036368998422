import React, { useEffect, useState } from 'react';
import './BrandReportSections.scss';
import PropTypes from 'prop-types';
import { downloadImageUsingElementId, downloadToCsv } from '../../helpers/helpers';

import { get_pin_analytics } from '../../helpers/brand_report_helpers';
import * as queries from '../../api/analytics';

import { BrandInformation, brandInformationConfig } from './Sections/BrandInformation';
import { BrandTopTalentActivity, brandTopTalentActivityConfig } from './Sections/BrandTopTalentActivity';
import { BrandClicks, brandClickConfig } from './Sections/BrandClicks';
import { BrandOrders, brandOrderConfig } from './Sections/BrandOrders';
import { BrandVolume, brandVolumeConfig } from './Sections/BrandVolume';
import { BrandGifting, brandGiftingConfig } from './Sections/BrandGifting';
import { BrandDiscountCodes, brandDiscountCodeConfig } from './Sections/BrandDiscountCodes';
import { BrandCompetitorComparison, brandCompetitorComparisonConfig } from './Sections/BrandCompetitorComparison';
import { BrandTopPromoters, BrandTopPromotersConfig } from './Sections/BrandTopPromoters';
import { BrandTopClicks, BrandTopClicksConfig } from './Sections/BrandTopClicks';
import { BrandTopMentions, brandTopMentionsConfig } from './Sections/BrandTopMentions';
import { BrandTopProducts, brandTopProductsConfig } from './Sections/BrandTopProducts';
import { BrandTopProductsClicks, brandTopProductsClicksConfig } from './Sections/BrandTopProductsClicks';
import { BrandConversionRates, brandConversionRatesConfig } from './Sections/BrandConversionRates';
import { BrandShortSummary, BrandShortSummaryConfig } from './Sections/BrandShortSummary';
import { BrandTopPromoterDelta, BrandTopPromoterDeltaConfig } from './Sections/BrandTopPromoterDelta';
import { BrandCustomRateUsers, brandCustomRateUsersConfig } from './Sections/BrandCustomRateUsers';
import { BrandDiscountCodeUsers, brandDiscountCodeUsersConfig } from './Sections/BrandDiscountCodeUsers';
import { BrandChatUsers, brandChatUsersConfig } from './Sections/BrandChatUsers';
import { BrandChats, brandChatsConfig } from './Sections/BrandChats';

export const BrandReportSections = ({ brand, ...props }) => {
  const { startDate, endDate, groupingMethod } = props;

  // all using apiPins
  const [brandTopPromoters, setBrandTopPromoters] = useState(); // group by user
  const [brandTopMentions, setBrandTopMentions] = useState(); // group by mention
  const [brandTopProducts, setBrandTopProducts] = useState(); // group by product
  // ShortSummary uses apiPins grouped by user
  // BrandTopClicks uses apiPins grouped by user
  // BrandTopPromoterDelta uses apiPins grouped by user
  // BrandTopProductsClicks usess apiPins grouped by product

  // all using apiBrandReports
  const [brandTopTalentActivity, setBrandTopTalentActivity] = useState();
  const [brandCustomRateUsers, setBrandCustomRateUsers] = useState();
  const [brandDiscountCodeUsers, setBrandDiscountCodeUsers] = useState();
  const [brandClicks, setBrandClicks] = useState();
  const [brandOrders, setBrandOrders] = useState();
  // BrandVolume uses orders
  const [brandChats, setBrandChats] = useState();
  const [brandChatUsers, setBrandChatUsers] = useState();
  // ConversionRates uses orders and clicks
  const [brandGifting, setBrandGifting] = useState();
  const [brandDiscountCodes, setBrandDiscountCodes] = useState();
  // const [brandCompetitorComparison, setBrandCompetitorComparison] = useState();

  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    // reset all data
    setBrandTopPromoters();
    setBrandTopMentions();
    setBrandTopProducts();
    setBrandTopTalentActivity();
    setBrandCustomRateUsers();
    setBrandDiscountCodeUsers();
    setBrandClicks();
    setBrandOrders();
    setBrandChats();
    setBrandChatUsers();
    setBrandGifting();
    setBrandDiscountCodes();
    // setBrandCompetitorComparison();

    // get new data
    const queryData = { startDate, endDate, groupingMethod, Brand_id: brand.id };

    const fetch_all_data = async () => {
      /******************************************************************************************* */
      // apiPins QUERIES
      /******************************************************************************************* */

      const topPromoters = await get_pin_analytics({ ...queryData, groupByMode: 'users' });
      setBrandTopPromoters(topPromoters);

      const topProducts = await get_pin_analytics({ ...queryData, groupByMode: 'products' });
      setBrandTopProducts(topProducts);

      const topMentions = await get_pin_analytics({ ...queryData, groupByMode: 'mentions' });
      setBrandTopMentions(topMentions);

      /******************************************************************************************* */
      // apiBrandReports QUERIES
      /******************************************************************************************* */

      const brandTopTalentActivity = await queries.getBrandTopTalentActivity(queryData);
      setBrandTopTalentActivity(brandTopTalentActivity.results);

      const brandCustomRateUsers = await queries.getBrandCustomRateUsers(queryData);
      setBrandCustomRateUsers(brandCustomRateUsers.results);

      const brandDiscountCodeUsers = await queries.getBrandCustomCodeUsers(queryData);
      setBrandDiscountCodeUsers(brandDiscountCodeUsers.results);

      const brandClicks = await queries.getBrandClicks(queryData);
      setBrandClicks(brandClicks.results);

      const brandOrders = await queries.getBrandOrders(queryData);
      setBrandOrders(brandOrders.results);

      const brandChats = await queries.getBrandChats(queryData);
      setBrandChats(brandChats.results);

      const brandChatUsers = await queries.getBrandUnreadChatUsers(queryData);
      setBrandChatUsers(brandChatUsers.results);

      const brandGifting = await queries.getBrandGifting(queryData);
      setBrandGifting(brandGifting.results);

      const brandDiscountCodes = await queries.getBrandCodes(queryData);
      setBrandDiscountCodes(brandDiscountCodes.results);
    };

    fetch_all_data();
  }, [startDate, endDate, groupingMethod, brand.id]);

  /**
   *
   * @param {Object} params
   * @param {Array} params.rows - array of objects with label and values - in this case download to csv
   * @param {String} params.fileName - name of file to download
   * @param {String} params.graph - string to the id of an element on the page to download picture of
   */
  const download = async params => {
    const { rows, fileName, graph } = params;

    setDownloading(true);

    if (fileName) {
      const csvRows = rows.map(row => {
        const { label, values } = row;
        const data = { label };
        values.forEach(value => (data[value.label] = value.value));
        return data;
      });

      await downloadToCsv(csvRows, fileName);
    } else if (graph) {
      await downloadImageUsingElementId(graph);
    }
    setDownloading(false);
  };

  return (
    <div className='brand-report-sections-outer'>
      <div className='brand-report-sections-inner'>
        <BrandInformation title={brandInformationConfig.title} brand={brand} key='brand_information' />
        <BrandShortSummary
          title={BrandShortSummaryConfig.title}
          brandTopPromoters={brandTopPromoters}
          groupingMethod={groupingMethod}
          download={download}
          downloading={downloading}
          key={BrandShortSummaryConfig.title}
        />
        <BrandTopTalentActivity
          title={brandTopTalentActivityConfig.title}
          brandTopTalentActivity={brandTopTalentActivity}
          download={download}
          downloading={downloading}
          key={brandTopTalentActivityConfig.title}
        />
        <BrandTopPromoters
          title={BrandTopPromotersConfig.title}
          brandTopPromoters={brandTopPromoters}
          groupingMethod={groupingMethod}
          download={download}
          downloading={downloading}
          key={BrandTopPromotersConfig.title}
        />
        <BrandTopMentions
          title={brandTopMentionsConfig.title}
          brandTopMentions={brandTopMentions}
          groupingMethod={groupingMethod}
          download={download}
          downloading={downloading}
          key={brandTopMentionsConfig.title}
        />
        <BrandTopProducts
          title={brandTopProductsConfig.title}
          brandTopProducts={brandTopProducts}
          groupingMethod={groupingMethod}
          download={download}
          downloading={downloading}
          key={brandTopProductsConfig.title}
        />
        <BrandTopProductsClicks
          title={brandTopProductsClicksConfig.title}
          brandTopProducts={brandTopProducts}
          groupingMethod={groupingMethod}
          download={download}
          downloading={downloading}
          key={brandTopProductsClicksConfig.title}
        />
        <BrandTopClicks
          title={BrandTopClicksConfig.title}
          brandTopPromoters={brandTopPromoters}
          groupingMethod={groupingMethod}
          download={download}
          downloading={downloading}
          key={BrandTopClicksConfig.title}
        />
        <BrandTopPromoterDelta
          title={BrandTopPromoterDeltaConfig.title}
          brandTopPromoters={brandTopPromoters}
          groupingMethod={groupingMethod}
          download={download}
          downloading={downloading}
          key={BrandTopPromoterDeltaConfig.title}
        />

        <BrandCustomRateUsers
          title={brandCustomRateUsersConfig.title}
          brandCustomRateUsers={brandCustomRateUsers}
          download={download}
          downloading={downloading}
          key={brandCustomRateUsersConfig.title}
        />
        <BrandDiscountCodeUsers
          title={brandDiscountCodeUsersConfig.title}
          brandDiscountCodeUsers={brandDiscountCodeUsers}
          download={download}
          downloading={downloading}
          key={brandDiscountCodeUsersConfig.title}
        />
        <BrandClicks
          title={brandClickConfig.title}
          brandClicks={brandClicks}
          download={download}
          downloading={downloading}
          key={brandClickConfig.title}
        />
        <BrandOrders
          title={brandOrderConfig.title}
          brandOrders={brandOrders}
          download={download}
          downloading={downloading}
          key={brandOrderConfig.title}
        />
        <BrandVolume
          title={brandVolumeConfig.title}
          brandOrders={brandOrders}
          download={download}
          downloading={downloading}
          key={brandVolumeConfig.title}
        />
        <BrandChats
          title={brandChatsConfig.title}
          brandChats={brandChats}
          download={download}
          downloading={downloading}
          key={brandChatsConfig.title}
        />
        <BrandChatUsers
          title={brandChatUsersConfig.title}
          brandChatUsers={brandChatUsers}
          download={download}
          downloading={downloading}
          key={brandChatUsersConfig.title}
        />
        <BrandConversionRates
          title={brandConversionRatesConfig.title}
          brandClicks={brandClicks}
          brandOrders={brandOrders}
          download={download}
          downloading={downloading}
          key={brandConversionRatesConfig.title}
        />
        <BrandGifting
          title={brandGiftingConfig.title}
          brandGifting={brandGifting}
          download={download}
          downloading={downloading}
          key={brandGiftingConfig.title}
        />
        <BrandDiscountCodes
          title={brandDiscountCodeConfig.title}
          brandDiscountCodes={brandDiscountCodes}
          download={download}
          downloading={downloading}
          key={brandDiscountCodeConfig.title}
        />
        <BrandCompetitorComparison
          title={brandCompetitorComparisonConfig.title}
          {...props}
          Brand_id={brand.id}
          key={brandCompetitorComparisonConfig.title}
        />
      </div>
    </div>
  );
};

BrandReportSections.propTypes = {
  notes: PropTypes.object.isRequired,
  allBrands: PropTypes.array.isRequired,
  allCompetitorBrands: PropTypes.array.isRequired,

  // time interval variables
  Brand_id: PropTypes.number.isRequired,
  endDate: PropTypes.string,
  startDate: PropTypes.string,
  previousPeriods: PropTypes.string,
  groupingMethod: PropTypes.string,
  brand: PropTypes.object
};

export const allPageSectionTitles = () => [
  brandInformationConfig.title,
  BrandShortSummaryConfig.title,
  brandTopTalentActivityConfig.title,
  BrandTopPromotersConfig.title,
  brandTopMentionsConfig.title,
  brandTopProductsConfig.title,
  brandTopProductsClicksConfig.title,
  BrandTopClicksConfig.title,
  BrandTopPromoterDeltaConfig.title,
  brandCustomRateUsersConfig.title,
  brandDiscountCodeUsersConfig.title,
  brandClickConfig.title,
  brandOrderConfig.title,
  brandVolumeConfig.title,
  brandChatsConfig.title,
  brandChatUsersConfig.title,
  brandConversionRatesConfig.title,
  brandGiftingConfig.title,
  brandDiscountCodeConfig.title,
  brandCompetitorComparisonConfig.title
];
