import APIClient from './index';
import _ from 'lodash';

export const getConsultResults = () => {
  return new Promise((resolve, reject) => {
    APIClient.get(`ConsultResults`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const updateConsultResult = (result, data) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`ConsultResults/${result.id}`, data)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};
