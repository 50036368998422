import React from 'react';
import { faDownload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import { Table } from '../../../BrandReports/GraphTypes/Table';
import Loader from '../../../General/Loader';

const ConciergeTopRetailers = props => {
  // console.log({ props });
  const { title, downloading, topRetailers } = props;
  const success = !!topRetailers?.length;

  const download_table = async () => await props.download({ rows: make_table_rows(), fileName: 'concierge_custom_codes' });

  const make_table_rows = () => {
    const customCodeRows = topRetailers.map(retailer => {
      const { domain, orders, volume, avg_order_amount, avg_commission_amount } = retailer;

      return {
        key: `top_retailers_${domain}`,
        label: domain,
        description: ``,
        values: [
          { label: 'Orders', value: orders },
          {
            label: 'Volume',
            value: `$${volume}`
          },
          {
            label: 'Avg Volume',
            value: `$${avg_order_amount?.toFixed(1)}`
          },
          {
            label: 'Avg Commission',
            value: `$${avg_commission_amount?.toFixed(1)}`
          }
        ]
      };
    });

    return customCodeRows;
  };

  return (
    <div className='talent-report-card' id={title}>
      {!topRetailers ? (
        <Loader />
      ) : (
        <>
          {success && (
            <>
              <div className='header'>
                <div className='description'>
                  <div className='title'>{title}</div>
                  <div className='sub-title'>Top retailers show rank all of the retailers that are performing the best for the over all time.</div>
                </div>
                {!downloading && (
                  <div className='talent-report-controls' id={`${title}-card-controls`}>
                    <div className='rounded-button' onClick={() => download_table()}>
                      {downloading ? (
                        <Loader />
                      ) : (
                        <>
                          <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                          Download
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>

              <Table title='Top Retailers' data={make_table_rows()} />
            </>
          )}

          {!success && (
            <>
              <div className='error-centered'>
                <div className='title'>Error Fetching {title}</div>
                <div className='sub-title'>No results.</div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

ConciergeTopRetailers.propTypes = {
  title: PropTypes.string.isRequired,
  topRetailers: PropTypes.array,

  download: PropTypes.func.isRequired,
  downloading: PropTypes.bool.isRequired
};

const ConciergeTopRetialersConfig = {
  title: 'Top Retailers'
};

export { ConciergeTopRetailers, ConciergeTopRetialersConfig };
