import React, { useState } from 'react';
import Modal from '../../General/Modal';
import './TagGPTEditModal.scss';
import cn from 'classnames';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';

const TagGPTEditModal = props => {
  const { onClose, onSave, tag } = props;
  const { isUsedByGPT, gptExplainer = '' } = tag;
  const [shouldUseForGPT, setShouldUseForGPT] = useState(isUsedByGPT);
  const [explainerText, setExplainerText] = useState(gptExplainer);
  const [isSaving, setIsSaving] = useState(false);

  const handleSave = async () => {
    try {
      setIsSaving(true);
      const updates = { isUsedByGPT: shouldUseForGPT, gptExplainer: explainerText };
      await onSave(updates);
      onClose();
      cogoToast.success(`Saved tag settings`);
    } catch (e) {
      console.error(e);
      cogoToast.error(`Error saving tag settings`);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Modal close={onClose} title={`${tag.value} - GPT Settings`}>
      <div className={'tag-gpt-modal-content'}>
        <div className='field-title'>Summary:</div>
        <textarea
          className='modal-explainer-textarea'
          value={explainerText}
          onChange={e => setExplainerText(e.target.value)}
          placeholder={'Short summary of this tag'}
        />
        <div className={'field-row'}>
          <div className={'field-title'}>Enable for GPT: </div>
          <input type='checkbox' checked={shouldUseForGPT} onChange={() => setShouldUseForGPT(!shouldUseForGPT)} />
        </div>
        <div className='modal-footer'>
          <button className={'action-btn-cancel'} onClick={onClose}>
            Cancel
          </button>
          <button className={cn('action-btn-save', { disabled: isSaving })} onClick={handleSave} disabled={isSaving}>
            Save
          </button>
        </div>
      </div>
    </Modal>
  );
};

TagGPTEditModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  tag: PropTypes.object.isRequired
};

export default TagGPTEditModal;
