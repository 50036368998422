import React, { Component } from 'react';
import PropTypes from 'prop-types'
import classnames from 'classnames'
import './ProductMatchTicket.scss';

class ProductMatchTicket extends Component {
  static propTypes = {
    // performSearch: PropTypes.func.isRequired,  
    selectTicket: PropTypes.func.isRequired,  
    isSelected: PropTypes.bool.isRequired,  
    ticket: PropTypes.object.isRequired,
    markAsUnmatchable: PropTypes.func.isRequired,
  }

  render() {
    const { markAsUnmatchable, ticket, selectTicket, isSelected } = this.props;
    const { image, rawBrand, rawTitle, retailer, url } = ticket || {}
    return (
      <div onClick={selectTicket} className={classnames('match-ticket-container', { selected: isSelected })}>
        <div className='ticket-header'>Assign to product</div>
        <div className='match-ticket highlight-zone'>
          <img alt='Ticket Product' src={image}/>
          <div className='product-data'>
            <div className='title'>{rawTitle}</div>
            <div className='brand'>{rawBrand}</div>
            <a href={url} target='_blank' rel="noopener noreferrer" className='retailer'>{retailer}</a>
          </div>
        </div>
        <div 
          onClick={e => {
            e.preventDefault() 
            e.stopPropagation() 
            markAsUnmatchable(ticket)
          }} 
          className='no-match-btn'>Cannot Find Match</div>
      </div>
    )
  }
}

export default ProductMatchTicket
