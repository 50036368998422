import React from 'react';
import PropTypes from 'prop-types';
import Loader from '../../../General/Loader';
import ScoreBreakdown from './ScoreBreakdown';

import * as concierge_helpers from '../../../../helpers/concierge_helpers';
import './ConciergeSummary.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/pro-light-svg-icons';
import { getTierDisplay } from '../../../../helpers/tier_helpers';

const ConciergeSummary = props => {
  const { talent, title, talentSummary = {}, updateExpectedScore, updateSupportLevel } = props;
  const success = talentSummary?.id;

  const { id: User_id } = talent || {};
  const { rawScore } = talentSummary || {};
  const { tier, score } = talentSummary || {};
  const { outstanding_gifting_requests, outstanding_collab_proposals, unread_chat_messages } = talentSummary || {};
  const { new_discount_codes, new_custom_rates, new_relevant_sales } = talentSummary || {};

  const scoreDelta = concierge_helpers.getScoreDelta(talentSummary);
  const activityPercentDelta = concierge_helpers.getActivityPercentDelta(talentSummary);
  const notificationCount = concierge_helpers.getNotificationCount(talentSummary);
  const actionableCount = concierge_helpers.getActionableCount(talentSummary);

  return (
    <>
      {!talentSummary ? (
        <Loader />
      ) : (
        <>
          {success && (
            <div className='talent-report-card' id={title}>
              <div className='concierge-summary-outer'>
                <ScoreBreakdown rawScore={rawScore} />
                <div className='stats'>
                  <div className='stat-container'>
                    <div className='stat' style={concierge_helpers.getConciergeAttributeColor('score', scoreDelta)}>
                      <div className='stat-text'>Score</div>
                      <div className='stat-value'>
                        {score?.toFixed(1)} ({scoreDelta > 0 ? `+${scoreDelta.toFixed(1)}` : scoreDelta.toFixed(1)})
                      </div>
                    </div>
                    <div className='stat' onClick={() => updateExpectedScore(User_id)}>
                      <div className='stat-text'>
                        Expected
                        <FontAwesomeIcon icon={faPencil} className='edit-icon' />
                      </div>
                      <div className='stat-value'>{talent.expectedScore || 'N/A'}</div>
                    </div>
                    <div className='stat' onClick={() => updateSupportLevel(User_id)}>
                      <div className='stat-text'>
                        Support
                        <FontAwesomeIcon icon={faPencil} className='edit-icon' />
                      </div>
                      <div className='stat-value'>{talent.supportLevel || 'N/A'}</div>
                    </div>
                    <div className='stat'>
                      <div className='stat-text'>Tier</div>
                      <div className='stat-value'>{getTierDisplay(tier)}</div>
                    </div>
                  </div>

                  <div className='stat-container'>
                    <div
                      className='stat'
                      style={concierge_helpers.getConciergeAttributeColor('analytics', activityPercentDelta.average_delta_percent)}
                    >
                      <div className='stat-text'>Analytics</div>
                      <div className='stat-value'>
                        {activityPercentDelta.average_delta_percent > 0
                          ? `+${activityPercentDelta.average_delta_percent.toFixed(1)}%`
                          : `${activityPercentDelta.average_delta_percent.toFixed(1)}%`}
                      </div>
                    </div>
                    <div className='stat'>
                      <div className='stat-text'>Clicks</div>
                      <div className='stat-value'>
                        {activityPercentDelta.click_delta_percent > 0
                          ? `+${activityPercentDelta.click_delta_percent.toFixed(1)}%`
                          : `${activityPercentDelta.click_delta_percent.toFixed(1)}%`}
                      </div>
                    </div>
                    <div className='stat'>
                      <div className='stat-text'>Pins</div>
                      <div className='stat-value'>
                        {activityPercentDelta.pin_delta_percent > 0
                          ? `+${activityPercentDelta.pin_delta_percent.toFixed(1)}%`
                          : `${activityPercentDelta.pin_delta_percent.toFixed(1)}%`}
                      </div>
                    </div>
                    <div className='stat'>
                      <div className='stat-text'>Volume</div>
                      <div className='stat-value'>
                        {activityPercentDelta.volume_delta_percent > 0
                          ? `+${activityPercentDelta.volume_delta_percent.toFixed(1)}%`
                          : `${activityPercentDelta.volume_delta_percent.toFixed(1)}%`}
                      </div>
                    </div>
                  </div>

                  <div className='stat-container'>
                    <div className='stat' style={concierge_helpers.getConciergeAttributeColor('notifications', notificationCount)}>
                      <div className='stat-text'>Notifications</div>
                      <div className='stat-value'>{notificationCount}</div>
                    </div>
                    <div className='stat'>
                      <div className='stat-text'>Gifting</div>
                      <div className='stat-value'>{outstanding_gifting_requests}</div>
                    </div>
                    <div className='stat'>
                      <div className='stat-text'>Collabs</div>
                      <div className='stat-value'>{outstanding_collab_proposals}</div>
                    </div>
                    <div className='stat'>
                      <div className='stat-text'>Chats</div>
                      <div className='stat-value'>{unread_chat_messages}</div>
                    </div>
                  </div>

                  <div className='stat-container'>
                    <div className='stat' style={concierge_helpers.getConciergeAttributeColor('actionables', actionableCount)}>
                      <div className='stat-text'>Actionables</div>
                      <div className='stat-value'>{actionableCount}</div>
                    </div>
                    <div className='stat'>
                      <div className='stat-text'>Codes</div>
                      <div className='stat-value'>{new_discount_codes}</div>
                    </div>
                    <div className='stat'>
                      <div className='stat-text'>Rates</div>
                      <div className='stat-value'>{new_custom_rates}</div>
                    </div>
                    <div className='stat'>
                      <div className='stat-text'>Sales</div>
                      <div className='stat-value'>{new_relevant_sales}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {!success && (
            <>
              <div className='error-centered'>
                <div className='title'>Concierge Summary</div>
                <div className='sub-title'>No Results</div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

ConciergeSummary.propTypes = {
  talent: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  talentSummary: PropTypes.object,
  updateSupportLevel: PropTypes.func.isRequired,
  updateExpectedScore: PropTypes.func.isRequired
};

const ConciergeSummaryConfig = {
  title: 'Summary'
};

export { ConciergeSummary, ConciergeSummaryConfig };
