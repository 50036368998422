import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './ConciergeComments.scss';
import _ from 'lodash';

import ConciergeComment from './ConciergeComment';
import Switch from 'react-switch';

const ConciergeComments = props => {
  const { allComments, updateComment } = props;
  const [showOnlyUnreadComments, setShowOnlyUnreadComments] = useState(true);
  const [search, setSearch] = useState();

  const displayComments = _.sortBy(allComments, ['dueDate', 'createdAt', 'isRead'], ['asc', 'desc', 'asc'])
    .filter(commentObject => {
      const { comment, Commenter_name, User_name, isRead, isDismissed } = commentObject;

      if (isDismissed ) return false;
      else if (showOnlyUnreadComments && isRead) return false;
      else if (!search || !search.length) return true;
      else return comment.toLowerCase().includes(search) || Commenter_name.toLowerCase().includes(search) || User_name.toLowerCase().includes(search); // prettier-ignore
    })
    .map(comment => <ConciergeComment key={`comment_${comment.Comment_id}`} comment={comment} updateComment={updateComment} />);

  return (
    <div className='concierge-comments-outer'>
      <div className='options'>
        <div className='search'>
          <div className='radio-btns'></div>
          <input
            type='text'
            name='search'
            id='search'
            className='input'
            placeholder='Search Comments...'
            onChange={e => setSearch(e.target.value.toLowerCase())}
          />
        </div>

        <div className='toggles'>
          <div className='analytics-toggle'>
            <div onClick={() => setShowOnlyUnreadComments(!showOnlyUnreadComments)}>Show Only Unread Comments</div>
            <Switch
              onChange={() => setShowOnlyUnreadComments(!showOnlyUnreadComments)}
              checked={showOnlyUnreadComments}
              height={14}
              width={24}
              onColor='#333'
              offColor='#ccc'
              className='switch'
              checkedIcon={false}
              uncheckedIcon={false}
            />
          </div>
        </div>
      </div>
      <div className='comments-container'>{displayComments}</div>
    </div>
  );
};

ConciergeComments.propTypes = {
  allComments: PropTypes.array.isRequired,
  updateComment: PropTypes.func.isRequired
};

export default ConciergeComments;
