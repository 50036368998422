export const TOGGLE_TAGGING_MODE = 'TOGGLE_TAGGING_MODE';
export const SET_ACTIVE_TAGS = 'SET_ACTIVE_TAGS';
export const SET_USERS_BEING_TAGGED = 'SET_USERS_BEING_TAGGED';
export const SET_BRANDS_BEING_TAGGED = 'SET_BRANDS_BEING_TAGGED';
export const SET_MERCHANTS_BEING_TAGGED = 'SET_MERCHANTS_BEING_TAGGED';
export const TOGGLE_ACTIVE_TAG = 'TOGGLE_ACTIVE_TAG';
export const TOGGLE_TAG_TYPE_VISIBILITY = 'TOGGLE_TAG_TYPE_VISIBILITY';
export const TOGGLE_USER_BEING_TAGGED = 'TOGGLE_USER_BEING_TAGGED';
export const TOGGLE_BRAND_BEING_TAGGED = 'TOGGLE_BRAND_BEING_TAGGED';
export const TOGGLE_MERCHANT_BEING_TAGGED = 'TOGGLE_MERCHANT_BEING_TAGGED';
export const SET_ACTIVE_TALENT_ID = 'SET_ACTIVE_TALENT_ID';

export const toggleTaggingMode = tag => async (dispatch, getState) => {
  return dispatch({
    type: TOGGLE_TAGGING_MODE
  });
};

export const setActiveTags = tags => async (dispatch, getState) => {
  return dispatch({
    type: SET_ACTIVE_TAGS,
    tags
  });
};

export const setUsersBeingTagged = users => async (dispatch, getState) => {
  return dispatch({
    type: SET_USERS_BEING_TAGGED,
    users
  });
};

export const setBrandsBeingTagged = brands => async (dispatch, getState) => {
  return dispatch({
    type: SET_BRANDS_BEING_TAGGED,
    brands
  });
};

export const setMerchantsBeingTagged = merchants => async (dispatch, getState) => {
  return dispatch({
    type: SET_MERCHANTS_BEING_TAGGED,
    merchants
  });
};

export const toggleActiveTag = tag => async (dispatch, getState) => {
  return dispatch({
    type: TOGGLE_ACTIVE_TAG,
    tag
  });
};

export const toggleTagTypeVisibility = tagType => async (dispatch, getState) => {
  return dispatch({
    type: TOGGLE_TAG_TYPE_VISIBILITY,
    tagType
  });
};

export const toggleUserBeingTagged = user => async (dispatch, getState) => {
  return dispatch({
    type: TOGGLE_USER_BEING_TAGGED,
    user
  });
};

export const toggleBrandBeingTagged = brand => async (dispatch, getState) => {
  return dispatch({
    type: TOGGLE_BRAND_BEING_TAGGED,
    brand
  });
};

export const toggleMerchantBeingTagged = merchant => async (dispatch, getState) => {
  return dispatch({
    type: TOGGLE_MERCHANT_BEING_TAGGED,
    merchant
  });
};

export const setActiveTalentId = user => async (dispatch, getState) => {
  return dispatch({
    type: SET_ACTIVE_TALENT_ID,
    user
  });
};
